import React, { useEffect,useState, useRef } from "react";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Checkblue from '../../../assets/icons/checkblue.svg';
import Noeditorfound from '../../../assets/icons/noeditorfound.svg';


function TargetLanguage(props) {
    const {
        filteredResults,
        setFilteredResults,
        searchInput,
        setSearchInput,
        onFocusWrap,
        setOnFocusWrap,
        searchAreaRef,
        alreadySelecetedTarLangID,
        handleTargetLangClick
    } = props;
    
    let selectedTargetLanguages = useRef([]);
    useEffect(() => {
        if (searchInput !== '') {
            let re = new RegExp('^'+searchInput, 'i')
            let filteredData = props.targetLanguageOptions?.filter((item) => {
              return(
                re.test(item.language)
              )
            })
            setFilteredResults(filteredData)
        }
        else{
            setFilteredResults(props.targetLanguageOptions)
        }
    }, [searchInput])

    const handleOnclick = (e) => {
        searchAreaRef.current?.focus();
        setOnFocusWrap(true)
      }
  
    useEffect(() => {
        if (props?.targetLanguage && typeof props?.targetLanguage == "Array") {
            props.targetLanguage?.map((target) => {
                selectedTargetLanguages.current.push(target?.id);
            });
        }
    }, [props.targetLanguage]);

    

    if (!props.quickProjectSetup) {
        return (
            <React.Fragment>
                <div className="ai-source-language-cont">
                    <div className={"lang-modal-search-area " + (onFocusWrap ? "focused" : null)}>
                        <input
                            value={searchInput}
                            ref={searchAreaRef}
                            onChange={(e) => setSearchInput(e.target.value)}
                            onClick={(e) => handleOnclick(e)}
                            type="text"
                            placeholder="Search target language"
                            className="search-input"
                        />
                        <div className={onFocusWrap ? "icon-wrap" : "search-icon"}>
                            {
                                onFocusWrap ?
                                <CloseOutlinedIcon onClick={(e) => {setSearchInput(""); setOnFocusWrap(false)}} className="icon"/>
                                :
                                <SearchOutlinedIcon className="icon"/>
                            }
                        </div>
                    </div>
                    <div className="ai-src-language-part">
                        <ul className="ai-source-langs-list">
                            {searchInput?.length >= 1 ?
                             (filteredResults?.length == 0 ?
                                    (
                                        <div className="search-not-found-wrapper">
                                            <img src={Noeditorfound} alt="no-results"/>
                                            <h1>No results</h1>
                                        </div>
                                    )
                                    :
                                    (filteredResults?.map((value) => (
                                        <li
                                            key={value?.id}
                                            onClick={(e) => handleTargetLangClick(value, e)}
                                            className={
                                                ((alreadySelecetedTarLangID?.includes(value?.id) || (props.targetLanguage != "" && props.targetLanguage?.some(each => each?.id === value?.id))) ? "list selected" : "list")  
                                            }
                                        >
                                            <img className="checked-icon" src={Checkblue} alt="check_blue" />{" "}
                                            {value.language}
                                        </li>
    
                                    )))
                                )
                                :
                                (props.targetLanguageOptions != null) &&
                                    props.targetLanguageOptions?.map((value) => (
                                        <li
                                            key={value?.id}
                                            onClick={(e) => handleTargetLangClick(value, e)}
                                            className={
                                                ((alreadySelecetedTarLangID?.includes(value?.id) || (props.targetLanguage != "" && props.targetLanguage?.some(each => each?.id === value?.id))) ? "list selected" : "list")  
                                            }
                                        >
                                            <img className="checked-icon" src={Checkblue} alt="check_blue" />{" "}
                                            {value.language}
                                        </li>
                                ))
                              
                            }
                        </ul>
                      
                        <div className={"text-end "+(props?.alreadySelectedTarLang?.length !== 0  ? "selected-lang-wrap" : "")}>
                            <button className="button-AiNavButton" onClick={() => {props.setshowTarLangModal(false); setSearchInput(''); setOnFocusWrap(false); 
                                }}>
                                <span className="login-btn new-padd-style">Add / Update</span>
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <div className="ai-source-language-cont">
                    <div className={"lang-modal-search-area " + (onFocusWrap ? "focused" : null)}>
                        <input
                            value={searchInput}
                            ref={searchAreaRef}
                            onChange={(e) => setSearchInput(e.target.value)}
                            onClick={(e) => handleOnclick(e)}
                            type="text"
                            placeholder="Search target language"
                            className="search-input"
                        />
                        <div className={onFocusWrap ? "icon-wrap" : "search-icon"}>
                            {
                                onFocusWrap ?
                                <CloseOutlinedIcon onClick={(e) => {setSearchInput(""); setOnFocusWrap(false)}} className="icon"/>
                                :
                                <SearchOutlinedIcon className="icon"/>
                            }
                        </div>
                    </div>
                    <div className="ai-src-language-part">
                        <ul className="ai-source-langs-list">
                            { searchInput.length >= 1 ?
                                (filteredResults.length == 0 ?
                                    (
                                        <div className="search-not-found-wrapper">
                                            <img src={Noeditorfound} alt="no-results"/>
                                            <h1>No results</h1>
                                        </div>
                                    )
                                    :
                                    (filteredResults.map((value) => (
                                        <li
                                            key={value.id}
                                            onClick={(e) => props.handleTargetLangClick(value.id, value.language, e, props.quickProjectSetup)}
                                            className={value.id == props.targetLanguage ? "list selected" : "list"}
                                        >
                                        <img className="checked-icon" src={Checkblue} alt="check_blue" />{" "}
                                        {value.language}
                                    </li>
    
                                    )))
                                )
                                :
                                (
                                    props.targetLanguageOptions != null &&
                                        props.targetLanguageOptions?.map((value) => (
                                            <li
                                                key={value.id}
                                                onClick={(e) => props.handleTargetLangClick(value.id, value.language, e, props.quickProjectSetup)}
                                                className={value.id == props.targetLanguage ? "list selected" : "list"}
                                            >
                                                <img className="checked-icon" src={Checkblue} alt="check_blue" />{" "}
                                                {value.language}
                                            </li>
                                        ))
                                )
                            }
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TargetLanguage;
