import React from 'react';
import Login from './authentication/Login';
import AilaysaCanvasRoutes from './routes/AilaysaCanvasRoutes';
import Config from "./config/Config";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/Style/styles.css'

function App() {
  return (
    <React.Fragment>
       {Config.userState ? (
            <AilaysaCanvasRoutes />
       ) :(
        <Login />

       )}
    </React.Fragment>
  );
}

export default App;
