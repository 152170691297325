import { Popover } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import CloseIcon from '@mui/icons-material/Close';
import useEyeDropper from 'use-eye-dropper';
import ColorizeIcon from '@mui/icons-material/Colorize';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { ReactComponent as Bordersvg } from '../../assets/elements/border.svg';
import { SketchPicker  } from 'react-color';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import Slider from '@mui/material/Slider';
import Draggable from 'react-draggable';
import { DragIndicator } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';







const StrokeStyle = (props) => {
    const {
         editor, 
        totaltext,
        totalimage,
    } = props

    
    const [state, setState] = useState({
        activeDrags: 0,
        deltaPosition: {
            x: 0, y: 0
        },
        controlledPosition: {
            x: -400, y: 200
        }
    });
    
    const onStart = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags + 1 });
    };
    const onStop = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags - 1 });
    };
    
    const dragHandlers = { onStart, onStop };
    
    
    const nonstroke = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()){
                editor.canvas.getActiveObjects().forEach(e=>{
                setObjectstrokewidth(0)
                e.set({ stroke: null })
                e.set({ strokeWidth: null })
                editor.canvas.renderAll()
                })
                

            }
        }
    }

    const stroke = () => {
        if (editor) {
            if (editor.canvas.getActiveObjects()) {
                editor.canvas.getActiveObjects().forEach(e=>{
                    if (!e?.stroke) {
                        e.set({ stroke: '#111' })
                    }
                    if (!e?.strokeWidth) {
                        e.set({ strokeWidth: 3 })
                    }
                    e.set({ strokeDashArray: '' })
    
                    editor.canvas.renderAll()
                })
               

            }
        }
    }

    const strokedash = () => {
        if (editor) {
            if (editor.canvas.getActiveObjects()) {
                editor.canvas.getActiveObjects().forEach(e=>{
                    if (!e?.stroke) {
                        e.set({ stroke: '#111' })
                    }
                    if (!e?.strokeWidth) {
                        e.set({ strokeWidth: 3 })
                    }
                   e.set({ strokeDashArray: [1] })
                    editor.canvas.renderAll()
                })
            }
        }
    }
    const strokesmalldash = () => {
        if (editor) {
            if (editor.canvas.getActiveObjects()) {
                editor.canvas.getActiveObjects().forEach(e=>{
                    e.set({ stroke: bordercolor })
                    editor.canvas.renderAll()
                    if (!e?.stroke) {
                        e.set({ stroke: '#111' })
                    }
                    if (!e?.strokeWidth) {
                        e.set({ strokeWidth: 3 })
                    }
                    e.set({ strokeDashArray: [5, 20, 20, 5] })
                    editor.canvas.renderAll()
                })
               

            }
        }
    }

  
    const [bordercolor, setBordercolor] = useState(editor?.canvas?.getActiveObject()?.stroke ? editor.canvas.getActiveObject().stroke : "#111")
   

    const onchangestrokecolor = () => {
        if (editor) {
            if (editor.canvas.getActiveObjects()) {
                editor.canvas.getActiveObjects().forEach(e=>{
                    e.set({ stroke: bordercolor })
                    editor.canvas.renderAll()
                })
            }
        }
    }
    const temp = useRef(true)
    useEffect(() => {
        if (temp.current) {
            temp.current = false
            return
        }
        if (editor) {
            if (editor.canvas.getActiveObject()) {

                try {
                    editor.canvas.getActiveObject().set({ stroke: bordercolor })
                    editor.canvas.renderAll()
                    // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
                    let activeObjects = editor.canvas.getActiveObjects();
                    if (activeObjects.length) {
                        activeObjects.forEach(function (object) {
                            object.set({ stroke: bordercolor })
                            editor.canvas.renderAll()
                        });
                    }
                }
                catch {
                    // console.log('color not change');
                }
            }
            else {
                try {

                    editor.canvas.getActiveObject().set(({ stroke: bordercolor }))
                    editor.canvas.renderAll()
                }
                catch {
                    // console.log('color not change');
                }
            }

        }
    }, [bordercolor])


    const [anchorEl, setAnchorEl] = useState(null);

  

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [objectstrokewidth, setObjectstrokewidth] = useState(100)
    const strokewidthinputer = useRef(null)

    useEffect(() => {

        if (editor) {

            if (editor.canvas.getActiveObjects()) {
                let temp = 0
                editor.canvas.getActiveObjects().forEach((object) => {
                    if (object.strokeWidth > temp) {
                        temp = object.strokeWidth
                    }
                })
                setObjectstrokewidth(temp * 2)
                editor.canvas.renderAll()

            }
        }
    }, [editor])





    const changestrokewidthvalue = (e) => {
        setObjectstrokewidth(e.target.value)
        if (editor.canvas.getActiveObject()) {
           
            try {
                editor.canvas.getActiveObject().set({ strokeWidth: e.target.value / 2 })
                editor.canvas.renderAll()
                // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
                let activeObjects = editor.canvas.getActiveObjects();
                if (activeObjects.length) {
                    activeObjects.forEach(function (object) {
                        object.set({ strokeWidth: e.target.value / 2 })
                        if(object.stroke == '#111' || object.stroke == null ){
                            object.set({ stroke: 'rgba(0,0,0,1)' })
                        }
                        editor.canvas.renderAll()
                    });
                }
            }
            catch {
            }
        }
    }




    const [objectradius, setObjectradius] = useState(0)
    const objectradiusinputer = useRef(null)





    useEffect(() => {

        if (editor) {

            if (editor.canvas.getActiveObjects()) {
                let temp = 0
                if (editor.canvas?.getActiveObject()?.type != 'ellipse') {
                    editor.canvas.getActiveObjects().forEach((object) => {
                        if (object.rx > temp) {
                            temp = object.rx
                        }
                    })
                }
                setObjectradius(temp * 2)
                editor.canvas.renderAll()

            }
        }
    }, [editor])


  


    const changobjectradiusvalue = (e) => {
        setObjectradius(e.target.value)
        if (editor.canvas.getActiveObject()) {

            try {
                if (editor) {
                    if (editor.canvas.getActiveObject().type != 'ellipse') {
                        editor.canvas.getActiveObject().set({ rx: e.target.value / 2, ry: e.target.value / 2 })
                        editor.canvas.renderAll()
                        // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
                        let activeObjects = editor.canvas.getActiveObjects();
                        if (activeObjects.length) {
                            activeObjects.forEach(function (object) {
                                object.set({ rx: e.target.value / 2, ry: e.target.value / 2 })
                                editor.canvas.renderAll()
                            });
                        }
                    }
                }
            }
            catch {
            }
        }
    }


  

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [bordercolorOpen, setBordercolorOpen] = useState(false)

    const handleClickOp = () => {
        setBordercolorOpen(!bordercolorOpen)

    }

    const Eye = () => {
        const { open, close } = useEyeDropper()
        const [eyecolor, setEyecolor] = useState(bordercolor)
        const [eyeerror, setEyeerror] = useState()
        // useEyeDropper will reject/cleanup the open() promise on unmount,
        // so setState never fires when the component is unmounted.
        const pickColor = () => {
            open()
                .then(eyecolor => setEyecolor(eyecolor.sRGBHex))
                .catch(e => {
                    // console.log(e)
                    // Ensures component is still mounted
                    // before calling setState
                    if (!e.canceled) setEyeerror(e)
                })
        }
        setBordercolor(eyecolor)
        return (
            <>
                <button onClick={pickColor} className='eyecplacer border eyeinborder' style={{ border: 'none', }}><ColorizeIcon /></button>
                {!!eyeerror && <span>{eyeerror.message}</span>}
            </>
        )
    }

    
const SingleElementPathColor = () => {

    const [svgcolor, setSvgColor] = useState(editor?.canvas?.getActiveObject()?.fill)
    const tempSvgColor = useRef(true)

   
   useEffect(()=>{
    if (editor) {
        // if ((editor.canvas.getActiveObject()?.type == 'path') || (editor.canvas.getActiveObject().type == 'rect') || (editor.canvas.getActiveObject().type == 'polygon') || (editor.canvas.getActiveObject().type == 'circle') || (editor.canvas.getActiveObject().type == 'ellipse') || (editor.canvas.getActiveObject().type == 'triangle') || (editor.canvas.getActiveObject().type == 'polyline')) {
        if (editor?.canvas?.getActiveObject()?.type != 'textbox'  ) {
            setSvgColor(editor.canvas?.getActiveObject()?.fill)
            editor.canvas.renderAll()
        }
    }
},[editor]) 

    
    const  changeobjcolor=(i)=>{
        if (editor) {
            // if ((editor.canvas.getActiveObject()?.type == 'path') || (editor.canvas.getActiveObject()?.type == 'rect') || (editor.canvas.getActiveObject()?.type == 'polygon') || (editor.canvas.getActiveObject()?.type == 'circle') || (editor.canvas.getActiveObject()?.type == 'ellipse') || (editor.canvas.getActiveObject()?.type == 'triangle') || (editor.canvas.getActiveObject()?.type == 'polyline')) {
            if ( (editor.canvas.getActiveObject().type != 'textbox') ) {
                editor.canvas.getActiveObjects().forEach(e=>{
                    e.set({
                        fill: i
                    })
                    editor.canvas.renderAll();
                })
                
            }
        }
        setSvgColor(i)
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickcolor = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClosecolor = () => {
        setAnchorEl(null);
    };
    const open1 = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const Eye = () => {
        const { open, close } = useEyeDropper()
        const [eyecolor, setEyecolor] = useState(svgcolor)
        const [eyeerror, setEyeerror] = useState()
        // useEyeDropper will reject/cleanup the open() promise on unmount,
        // so setState never fires when the component is unmounted.
        const pickColor = () => {
          open()
            .then(eyecolor =>{ setEyecolor(eyecolor.sRGBHex);changeobjcolor(eyecolor.sRGBHex)})
            .catch(e => {
              // Ensures component is still mounted
              // before calling setState
              if (!e.canceled) setEyeerror(e)
            })
        }
        
        return (
          <>
            <button onClick={pickColor} className="eyecplacer" style={{border:'none',}}><ColorizeIcon/></button>
            {!!eyeerror && <span>{eyeerror.message}</span>}
          </>
        )
      }


    return (

        <>
            <div  color="white" size="sm" variant="outline" style={{ background: editor?.canvas.getActiveObject()?.fill, width: '28px', height: '28px',border: '1px solid rgba(0,0,0,0.1)',  }} className="bordercolor-btn"  onClick={handleClickcolor}>

            </div>
                <Popover
                    id={id}
                    open={open1}
                    anchorEl={anchorEl}
                    onClose={handleClosecolor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}  
                >
                    <div className="div-fontcolorsection box no-cursor">
                        <SketchPicker className="singleelementcolor-sketch"  style={{ width: '20px', height: '20px', border: ' 2px solid #ccc' }} color={svgcolor}  onChange={(color) => {
                            changeobjcolor("rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")")
                        }} />
                        <Eye />
                    </div>
                </Popover>
          
        </>


    )
}
const handleCloseAndOpen = (event) => {
    if (anchorEl == null) {
        setAnchorEl(event.currentTarget);
    }
    else {
        setAnchorEl(null);
    }
}

    return (
        <>
        { <div className='borderproperty' >  
        {!totaltext && !totalimage  && !totalimage && editor.canvas.getActiveObject().type != 'group' &&  <SingleElementPathColor/>}
        <div className="borderstyle-container" >
            <Tooltip 
             componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: '#2A2A2A',
                        '& .MuiTooltip-arrow': {
                            color: '#2A2A2A',
                        },
                    },
                },
            }}
            title="Stroke" placement="top" arrow>
            <span onClick={handleCloseAndOpen}>
            <LineStyleIcon  className={anchorEl ? "borderstyle-button toolbar-icons-active" : "borderstyle-button toolbar-icons"} />
            </span>
            </Tooltip>
            <Draggable handle="strong" {...dragHandlers}>
            <Popover
                    
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}

                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                <div className="borderstyle-div no-cursor">
                    <div className="bordersize">
                        <div className='drag-box-container'>
                            <strong className="drag-me ">
                                <DragIndicator />
                            </strong>
                            <CloseIcon className="close-icon" onClick={handleClose} />

                        </div>
                        <div className="strokewidthcontainor">
                            <span className="stroke-span">Stroke</span>
                            <p>
                                
                            <Slider size="small" aria-label="Small" value={objectstrokewidth} min={0}   {...(editor.canvas.getActiveObject().type == 'textbox' ? { max: 3 , step:0.1 } : {max:100})}  ref={strokewidthinputer} onChange={(e) => {
                                changestrokewidthvalue(e);
                            }} id="customRange1"  valueLabelDisplay="auto" />
                                </p>
                        </div>
                      <div className="strokewidthcontainor" style={{ display: (editor?.canvas.getActiveObject()?.type == 'circle' || editor?.canvas.getActiveObject()?.type == 'ellipse' || editor?.canvas.getActiveObject()?.type == 'textbox' || editor?.canvas.getActiveObject()?.type == 'image' ? 'none' : 'flex') }}>
                            <label style={{ display: (editor?.canvas.getActiveObject()?.type == 'circle' || editor?.canvas.getActiveObject()?.type == 'ellipse' ? 'none' : 'inline-block') }}>Stroke radius</label>
                            <p> 
                            
                            <Slider size="small" aria-label="Small" value={objectradius} min={0} max={100} ref={objectradiusinputer} onChange={(e) => {
                                changobjectradiusvalue(e);
                            }} id="customRange1"  valueLabelDisplay="auto" />
                                </p>
                        </div>
                    </div>
                    <div className="borderdefault">
                        <span>Stroke style</span>
                        <div>
                            <div className="styleborder" style={{ background: `${bordercolor}`,border:'0.5px solid rgba(0,0,0,1)'}} onClick={handleClickOp}>
                                <div aria-describedby={id} color="white" size="sm" variant="outline" className="bordercolor-btn " />
                            </div>
                            <div className="styleborder" onClick={nonstroke}><DoNotDisturbAltIcon className='borderstyle4' /></div>
                            <div className="styleborder" onClick={stroke}><BorderAllIcon className='borderstyle4' /></div>
                            <div className="styleborder" onClick={strokesmalldash}><Bordersvg className='borderstyle4' /></div>
                            <div className="styleborder" onClick={strokedash}><BorderClearIcon className='borderstyle4' /></div>
                        </div>
                    </div>
                    <div className="colorselectborder">
                        {bordercolorOpen &&
                            <>
                                <SketchPicker color={bordercolor} className='bordersketch' onChange={(color) => {
                                    setBordercolor("rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")");
                                    onchangestrokecolor();
                                }} />
                             
                                
                            </>
                        }
                    </div>
                    <Eye />

                </div>
            </Popover>
            </Draggable>
        </div>
        </div>}
        </>
        

    )
}


export default StrokeStyle