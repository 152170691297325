import React, { useEffect, useLayoutEffect, useState, useRef, useCallback } from "react";
import Skeleton from '@mui/material/Skeleton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Square from '../../assets/shapes/square.png' 
import Circle from '../../assets/shapes/circle.png' 
import Rectangle from '../../assets/shapes/rectangle.png' 
import Triangle from '../../assets/shapes/triangle.png'
import useFetch from "./graphicfetch";
import Masonry from '@mui/lab/Masonry';


const Graphics = (props) => {


    const {
        handleSidebarClose, 
        editor,
        fabric,
        fectchVector,
        onAddImage,
        debounce,
        generateUID,
        makeid
    } = props

    const [focusOnSearch, setFocusOnSearch] = useState(false)
    const photosearchinputer = useRef()
    const [photosearchword, setPhotosearchword] = useState('graphics')

    const fetchImageOnSearch = (e) => {
        if (e.key === 'Enter') {
            if ((e.target.value.length > 0) && (e.target.value != ' ')) {
                fectchVector()
            }
            else {
                setPhotosearchword('nature')
            }
        }
    }

    const handleSearchChange = (e) => {
        if (e.target.value.length > 0 && e.target.value != ' ') {
          setPhotosearchword(e.target.value)
        }
        else {
          setPhotosearchword('nature')
        }
    }

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
      setToggleState(index);
    };





    // const [query, setQuery] = useState("random");
    
    const [oddpagephotos, setoddpagephotos] = useState(1);
    const [evenpagephotos, setEvenpagephotos] = useState(2);
    // const [photopage, setphotopage] = useState(1);
    const { loading, error, list , firstoddhalf ,secondevenhalf  } = useFetch(photosearchword, oddpagephotos, evenpagephotos );
    const loader1 = useRef(null);
    const loader2 = useRef(null);
  
    // const handleChange = (e) => {
    //   setQuery(e.target.value);
    // };
    useEffect(()=>{
      setoddpagephotos(1);
      setEvenpagephotos(2)
    },[photosearchword])
  
    const handleObserver = useCallback((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        setoddpagephotos((prev) => prev + 2);
        setEvenpagephotos((prev) => prev + 2)
      }
    }, []);
  
    useEffect(() => {
      const option = {
        root: null,
        rootMargin: "20px",
        threshold: 0
      };
      const observer = new IntersectionObserver(handleObserver, option);
      if (loader1.current) observer.observe(loader1.current);
      if (loader2.current) observer.observe(loader2.current);
    }, [handleObserver]);





    const [squareid, setSquareid] = useState(editor?.canvas?.getObjects('Rect').length)

    const addSquare = () => {

        setSquareid(squareid + 1)

        var square = new fabric.Rect({
            name: 'Square_'+generateUID(9) + makeid(4),
            id: 'Square-'+generateUID(9) + makeid(4),
            left: 50,
            top: 50,
            width: 100,
            height: 100,
            fill: '#000',
            minScaleLimit:0.1,
            strokeWidth:null,
            globalCompositeOperation: 'source-atop',


        });
        editor.canvas.add(square);
        editor.canvas.centerObject(square);

        editor.canvas.renderAll()


    }
    const [rectid, setRectid] = useState(1)

    const addRectangle = () => {

        setRectid(rectid + 1)

        var square = new fabric.Rect({
            name: 'Rectangle_'+generateUID(9) + makeid(4),
            id: 'rectangle-'+generateUID(9) + makeid(4),
            left: 50,
            top: 50,
            width: 200,
            height: 100,
            rx:0,
            ry:0,
            fill: '#000',
            minScaleLimit:0.1,
            strokeWidth:null,
            globalCompositeOperation: 'source-atop',




        });
        editor.canvas.add(square);
        editor.canvas.centerObject(square);

        editor.canvas.renderAll()


    }
    const [circleid, setCircleid] = useState(1)

    const addCircle = () => {

        setCircleid(circleid + 1)

        var square = new fabric.Circle({
            name: "Circle_" +generateUID(9) + makeid(4),
            id: "circle-" +generateUID(9) + makeid(4),
            left: 50,
            top: 50,
            radius: 75,
            fill: '#000',
            minScaleLimit:0.1,
            strokeWidth:null,
            globalCompositeOperation: 'source-atop',


        });
        editor.canvas.add(square);
        editor.canvas.centerObject(square);

        editor.canvas.renderAll()



    }
    const [triangleid, setTriangleid] = useState(1)

    const addTriangle = () => {

        setTriangleid(triangleid + 1)

        var square = new fabric.Triangle({
            name: 'Triangle_' +generateUID(9) + makeid(4),
            id: 'triangle-' +generateUID(9) + makeid(4),
            left: 50,
            top: 50,
            width: 100,
            height: 100,
            fill: '#000',
            minScaleLimit:0.1,
            strokeWidth:null,
            globalCompositeOperation: 'source-atop',

        });
        editor.canvas.add(square);
        editor.canvas.centerObject(square);
        editor.canvas.renderAll()
    }

  //   const myHeaders = new Headers();
  //   myHeaders.append('Access-Control-Allow-Origin', '*')
  //   myHeaders.append("Accept-Language", "en-GB");
  //   myHeaders.append("Accept", "application/json");
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append("X-Freepik-API-Key", process.env.REACT_APP_FREEPIC_KEY);
    
  //   var requestOptions = {
  //     method: 'GET',
  //     headers: myHeaders,
  //     redirect: 'follow'
  //   };

  // const getfreePic =async () => {
  //   await fetch("https://api.freepik.com/v1/resources?locale=en-GB&page=1&limit=10&order=latest&term=car", requestOptions)
  //     .then(response => response.text())
  //     .then(result => console.log(result))
  //     .catch(error => console.log('error', error));
  //     console.log(process.env.REACT_APP_FREEPIC_KEY);
  // }


    return (

        <div className="container graphictab">
        <div className="bloc-tabs">
        <button
          className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(1)}
        >
          Graphics
        </button>
        <button
          className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
          onClick={() => {toggleTab(2)}}
        >
          Icon
        </button>
        <button
          className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(3)}
        >
          Shapes
        </button>
      </div>

      <div className="content-tabs">
          <div
            className={toggleState === 1 ? "content  active-content" : "content"}
          >
           
              <div className="sidebar-content">
                <div className={focusOnSearch ? "main-tab-search active-search-bar" : "main-tab-search"}>
                  <SearchIcon className="seacrh-icon" onClick={fectchVector} />
                  <span className="search-placeholder-text">
                    <input type="text" onKeyDown={(e) => { fetchImageOnSearch(e) }} onClick={() => {
                      setFocusOnSearch(true)
                    }} onBlur={(e) => {
                      setFocusOnSearch(false);
                    }} className="searcher" ref={photosearchinputer} onChange={(e) => { debounce(handleSearchChange, e) }} placeholder="Search..." />
                  </span>
                </div>
                <div className=" grid-photos api-photos">

                  <div className="odd-photos">
                    {firstoddhalf?.filter(e=> e.type != "vector/ai").map((item, i) => (
                      <>
                       <img className="images-section"  src={item.previewURL} data-id={item.vectorURL} onClick={(e) => {
                        onAddImage(e,'svg')}} onDragEnd={(e) => {
                          onAddImage(e,'svg')}}  alt="" />
                        </>
                    ))}
                    {/* {error && <p>Error!</p>} */}
                    {loading &&
                      <>
                        <Skeleton variant="rectangular" width={160} height={50} />
                        <Skeleton variant="rectangular" width={160} height={200} />
                        <Skeleton variant="rectangular" width={160} height={320} />
                        <Skeleton variant="rectangular" width={160} height={150} />
                        <Skeleton variant="rectangular" width={160} height={300} />
                        <Skeleton variant="rectangular" width={160} height={106} />
                      </>
                    }
                    {error && <p>No result!</p>}
                    <div ref={loader1} />

                  </div>


                  <div className="even-photos">
                    {secondevenhalf?.filter(e=> e.type != "vector/ai").map((item, i) => (
                      <>
                         <img className="images-section"  src={item.previewURL}  data-id={item.vectorURL} onClick={(e) => {
                        onAddImage(e,'svg')}} onDragEnd={(e) => {
                          onAddImage(e,'svg')}}  alt="" />
                         </>
                    ))}
                    {loading &&
                      <>
                        <Skeleton variant="rectangular" width={160} height={150} />
                        <Skeleton variant="rectangular" width={160} height={300} />
                        <Skeleton variant="rectangular" width={160} height={100} />
                        <Skeleton variant="rectangular" width={160} height={320} />
                        <Skeleton variant="rectangular" width={160} height={200} />
                        <Skeleton variant="rectangular" width={160} height={320} />
                      </>
                    }
                    {error && <p>No result!</p>}
                    <div ref={loader2} />

                  </div>
                </div>
                {firstoddhalf.length == 0 && secondevenhalf.length == 0 && !loading ? <> no result
                </> : ''}

              </div>
           
          </div>

          <div
          className={toggleState === 2 ? "content  active-content" : "content"}
        >
          {/* <h2>Icons</h2> */}
          {/* <hr /> */}
          <>
          <div className="sidebar-content">
            <div className="sidebar-search">
            <div className={focusOnSearch ? "main-tab-search active-search-bar" : "main-tab-search"}>
                <SearchIcon className="seacrh-icon"  />
                <span className="search-placeholder-text">
                    <input type="text" onKeyDown={(e) => { }} onClick={() => {
                    }} onBlur={(e) => {
                        
                    }} className="searcher"  onChange={(e) => {}} placeholder="Search..." />
                </span>
            </div>
                
            </div>
            <div className="grid-photos api-photos">
                {/* {elementsImages == '' || elementsImages?.length == 0 && */}
                <Masonry columns={2} spacing={0.8}>
                        <Skeleton variant="rectangular" width={150} height={150} />
                        <Skeleton variant="rectangular" width={150} height={250} />
                        <Skeleton variant="rectangular" width={150} height={350} />
                        <Skeleton variant="rectangular" width={150} height={170} />
                        <Skeleton variant="rectangular" width={150} height={220} />
                        <Skeleton variant="rectangular" width={150} height={350} />
                        <Skeleton variant="rectangular" width={150} height={120} />
                        <Skeleton variant="rectangular" width={150} height={220} />
                        <Skeleton variant="rectangular" width={150} height={550} />
                        <Skeleton variant="rectangular" width={150} height={320} />
                        <Skeleton variant="rectangular" width={150} height={240} />
                        <Skeleton variant="rectangular" width={150} height={330} />
                  </Masonry>
               {/*  } */}
                
            </div>
        </div>
          </>
        </div>

        <div
          className={toggleState === 3 ? "content  active-content" : "content"}
        >
          
          {/* <hr /> */}
          <p className="graphic-shapes">
          <div className="sidebar-content">
          {/* <div className="sidebar-tabs-heading">
                <span>Shapes</span>
                <CloseIcon className="close-icon" onClick={handleSidebarClose} />
            </div> */}
            <img className="grid-item-element"  onClick={addSquare} onDragEnd={addSquare}  src={Square}  alt="Square" />
            <img className="grid-item-element"  onClick={addRectangle} onDragEnd={addRectangle}   src={Rectangle}    alt="Circle" />
            <img className="grid-item-element"  onClick={addCircle} onDragEnd={addCircle} src={Circle}alt="Rectangle" />
            <img className="grid-item-element"  onClick={addTriangle} onDragEnd={addTriangle}  src={Triangle}  alt="Triangle" />
          </div>
          
          </p>
        </div>
      </div>
    </div>
     
    )


}

export default Graphics;