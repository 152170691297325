import React, { useEffect, useState } from "react";
import useEyeDropper from 'use-eye-dropper'
import ColorizeIcon from '@mui/icons-material/Colorize';


const Eye = (props) => {

    const{
        setCanvasBgColor,
        editor,
        fabric,
        canvasheight,
        canvaswidth,
    } =props
    

    const { open, close } = useEyeDropper()
    const [eyecolor, setEyecolor] = useState(null)
    const [eyeerror, setEyeerror] = useState()
    // useEyeDropper will reject/cleanup the open() promise on unmount,
    // so setState never fires when the component is unmounted.
    const pickColor = () => {
        open()
            .then(eyecolor => {
                setEyecolor(eyecolor.sRGBHex)
                editor.canvas.backgroundImage = ''
             })
            .catch(e => {
                // console.log(e)
                // Ensures component is still mounted
                // before calling setState
                if (!e.canceled) setEyeerror(e)
            })

            
    }
    useEffect(()=>{
        if(eyecolor != null){
            setCanvasBgColor(eyecolor)
            editor.canvas.backgroundImage = new fabric.Rect({ width: canvaswidth, height: canvasheight,fill:eyecolor });
            editor.canvas.renderAll()
            // editor.canvas.fire('object:modified');
        }
       
    },[eyecolor])
    
    return (
        <>
            <button onClick={pickColor} className="bgeye" style={{ border: 'none', }}>
                <ColorizeIcon className="eye-icon" />
                </button>
            {!!eyeerror && <span>{eyeerror.message}</span>}
        </>
    )
}

export default Eye;
