import React from "react";
import Cookies from "js-cookie";
import Config from "../../config/Config";
import LogoutIcon from '@mui/icons-material/Logout';

const Logout = () => {



    const logoutCanvas = () => {
        Cookies.remove(process.env.REACT_APP_USER_COOKIE_KEY_NAME_SUB, { domain: Config.COOKIE_DOMAIN });
        Cookies.remove(process.env.REACT_APP_USER_COOKIE_KEY_NAME, { domain: Config.COOKIE_DOMAIN });
        Cookies.remove(process.env.REACT_APP_PLAN_COOKIE_KEY_NAME, { domain: Config.COOKIE_DOMAIN });
        Cookies.remove("redirection_domain", { domain: Config.COOKIE_DOMAIN });
        setTimeout(() => {
            window.location.href = process.env.REACT_APP_LOGIN_URL;
        }, 1000);

    }
  
    return (
        <React.Fragment>
            <React.Fragment>
                 <LogoutIcon
                    className="log-out"
                    id="basic-button"
                    onClick={logoutCanvas}
                >
                    Logout
                </LogoutIcon>
            </React.Fragment>
        </React.Fragment>
    );
};

export default Logout;
