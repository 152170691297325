
import React from 'react'
import { SketchPicker } from 'react-color'
import Menu from '@mui/material/Menu';

const SketchExample =(props)=>{
    const {
        objectcolor,
        onChange
    } = props;
  
  // const [color,setColor] = React.useState()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };
    const handleClose = (props) => {
        setAnchorEl(null);
    };

    const style={
        width:'28px',
        height:'28px',
        // background:objectcolor,
        border:'1px solid rgba(0,0,0,0.1)',
        // marginLeft:'-7px',

    }
    return (
      <div>
        <p 
        style={style}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => { handleClick(e)}}>
        </p>
        
        <Menu
            className='sketchmenu'
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
             <SketchPicker color={objectcolor} onChangeComplete={onChange} onChange={onChange}/>
        </Menu>
      </div>
    )
  }


export default SketchExample;