import React, {  useState } from "react";
import Skeleton from '@mui/material/Skeleton';
import SearchIcon from '@mui/icons-material/Search';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import FontFaceObserver from 'fontfaceobserver';


const Text = (props) => {


    const {
        handleSidebarClose, 
        LABS_API, 
        handleAuthTrue, 
        editor,
        fabric,
        generateUID,
        makeid,
        texttemploading,
        texttempfirsthalf,
        texttempSecondhalf,
        textTemplate,
        designid,
        translatedImageid,
        setTranslatedImageid,
        notify,
        scaleMultiplierX,
        canvasheight,
        canvaswidth,
        setManualChecked,
        setCanvasBgColor,
        canvasBgColor,
        handlesize,
        workspaceSwitch,
        save
    } = props

    




    const [leftToptext, setLeftTopText] = useState(100)
    const [TextId, setTextId] = useState(1);
    const [focusOnSearch, setFocusOnSearch] = useState(false)
    const navigate = useNavigate();

    function textWidth(text,size) {
        let div = document.createElement("div");
        div.innerText = text;
        div.style.whiteSpace = 'nowrap';
        div.style.fontSize = size+"px"
        div.style.width = 'fit-content';
        document.querySelector('body').appendChild(div);
        let width = div.clientWidth;
        document.querySelector('body').removeChild(div);
        return width;
      }

    const addText = (e,i) => {
        if(designid === null && translatedImageid !== null && workspaceSwitch !== 'Workspace'){
            notify()
            editor.canvas.isDrawingMode = false
            setTranslatedImageid(null)
            editor.canvas.clear()
            setManualChecked(false)
            navigate('/ailaysa-canvas')
            editor.canvas.renderAll() 
            var size = {name:'togglesidebar',width:canvaswidth,height:canvasheight,fill:canvasBgColor}
            handlesize(size)
        }
        setTextId(TextId + 1)
        setLeftTopText(leftToptext + 10)
        // var path = new fabric.Path('M 0 0 A 20 20 0 0 1 300 0');

        const txt = (new fabric.Textbox(i == 1 ? "Add a text" : i == 2 ? "Add a Subheading" : "Add a Text", {
            name: "Textbox_"+generateUID(8) + makeid(9),
            // path: path,
            globalCompositeOperation: 'source-atop',
            id: TextId,
            // textAlign: "center",
            left: leftToptext,
            top: leftToptext,
            fontFamily: 'Roboto',
            fill: '#111',
            fontSize:  i == 1 ? 32 / editor.canvas.getZoom() : i == 2 ? 28 / editor.canvas.getZoom() :18 / editor.canvas.getZoom(),
            opacity: 1,
            // scaleX: editor.canvas.getZoom() ? 1* editor.canvas.getZoom() : 1,
            // scaleY: editor.canvas.getZoom() ? 1* editor.canvas.getZoom() : 1,
            lockMovementX: false,
            lockMovementY: false,
            lineHeight: 1,
            // width: i == 1 ? 200  : i == 2 ? 225  : 90,
            width: textWidth(i == 1 ? "Add a text" : i == 2 ? "Add a Subheading" : "Add a Text", i == 1 ? 32 / editor.canvas.getZoom() : i == 2 ? 28 / editor.canvas.getZoom() :18 / editor.canvas.getZoom()),
            minScaleLimit: 0.01,
            // perPixelTargetFind: true,
            // minScaleLimit : 0.2,
            breakWords: true,
            uniformScaling: true,
            // splitByGrapheme: true,
            status: 'Unconfirmed',
            textBackgroundColor: 'transparent',
            listType:'bullet',
            listBullet:'',
            listCounter:0,
            isWrapping:false,
            // splitByGrapheme: true
            // objectCaching:false,
            // shadow: 'rgba(0,0,0,0.4) 5px 5px 7px'

            // _wrapLine: true,
            // scalingAffectsSizeProp: true
        }));
        editor.canvas.add(txt).renderAll();

        editor.canvas.getObjects().forEach((e) => {
            if (e.id === TextId) {
                editor.canvas.setActiveObject(e)
                
                // console.log(e.id);
            }
        })
        save()
    }

    const addTextByDragging = (e,i) => {

        if(designid === null && translatedImageid !== null && workspaceSwitch !== 'Workspace'){
            notify()
            editor.canvas.isDrawingMode = false
            setTranslatedImageid(null)
            editor.canvas.clear()
            setManualChecked(false)
            navigate('/ailaysa-canvas')
            var size = {name:'togglesidebar',width:canvaswidth,height:canvasheight,fill:canvasBgColor}
            handlesize(size)
            editor.canvas.renderAll() 
        }
        setTextId(TextId + 1)
        // console.log(TextId);
        setLeftTopText(leftToptext + 10)
        // var path = new fabric.Path('M 0 0 A 20 20 0 0 1 300 0');
        var pointer = editor.canvas.getPointer(e);
        const txt = (new fabric.Textbox(i == 1 ? "Add a text" : i == 2 ? "Add a Subheading" : "Add a Text", {
            name: "Textbox_"+generateUID(8) + makeid(9),
            // path: path,
            globalCompositeOperation: 'source-atop',
            id: TextId,
            // textAlign: "center",
            left:  pointer.x,
                                top:  pointer.y,
            fontFamily: 'Roboto',
            fill: '#111',
            // fontSize:  i == 1 ? '32' / scaleCanvas : i == 2 ? 28 / scaleCanvas :18 / scaleCanvas,
            fontSize:  i == 1 ? 32 /  scaleMultiplierX.current : i == 2 ? 28 / scaleMultiplierX.current :18 / scaleMultiplierX.current,

            scaleX: scaleMultiplierX.current ? 1* scaleMultiplierX.current : 1,
            scaleY: scaleMultiplierX.current ? 1* scaleMultiplierX.current : 1,
            opacity: 1,
            lockMovementX: false,
            lockMovementY: false,
            lineHeight: 1,
            // width: i == 1 ? 200  : i == 2 ? 225  : 90,
            // width: textWidth(i == 1 ? "Add a heading" : i == 2 ? "Add a Subheading" : "Add a Text", i == 1 ? '32' / scaleCanvas : i == 2 ? 28 / scaleCanvas :18 / scaleCanvas),
            width: textWidth(i == 1 ? "Add a text" : i == 2 ? "Add a Subheading" : "Add a Text", i == 1 ? 32 / scaleMultiplierX.current : i == 2 ? 28 / scaleMultiplierX.current :18 / scaleMultiplierX.current),
           
            minScaleLimit: 0.5,
            // perPixelTargetFind: true,
            // minScaleLimit : 0.2,
            breakWords: true,
            uniformScaling: true,
            // splitByGrapheme: true,
            status: 'Unconfirmed',
            textBackgroundColor: 'transparent',
            // _wrapLine: true,
            // scalingAffectsSizeProp: true
        }));
        editor.canvas.add(txt).renderAll();
        

        editor.canvas.getObjects().forEach((e) => {
            if (e.id === TextId) {
                editor.canvas.setActiveObject(e)
                // console.log(e.id);
            }
        })
        save()
    }





const selectall = () => {
    var sel = new fabric.ActiveSelection(editor.canvas.getObjects(), {
        canvas: editor.canvas,
    });
    editor.canvas.setActiveObject(sel);
}

const savetexttemp=()=>{
    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
    // editor.canvas.backgroundColor= 'rgba(0,0,0,0)'
    // editor.canvas.renderAll()
    // var group = editor.canvas.getObjects()
    let currentjosn
    let image

    if(editor.canvas.getActiveObject()?.get('type') === 'textbox'){
        editor.canvas.getObjects().forEach(e => e.set({ opacity : 0}))
        editor.canvas.getActiveObject().set({ opacity : 1})
        const currentText = editor.canvas.getActiveObject()
        currentText.set({
            globalCompositeOperation: 'source-over'
        })
        let dataurl = currentText.toDataURL({
            format: 'png',
            enableRetinaScaling: true,
        })
        currentText.set({
            globalCompositeOperation: 'source-atop'
        })
        editor.canvas.getObjects().forEach(e => e.set({ opacity : 1}))
         currentjosn = JSON.stringify(editor.canvas.getActiveObject().toJSON(['id', 'projectid','subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable', 'name','breakWords','brushtype','textBackgroundColor','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name','oldWidth','oldHeight','oldScaleX','oldScaleY']))
         image = dataURLtoFile(dataurl,'text-template');
        //  console.log('ran');

    }else if (editor.canvas.getActiveObject()?.get('type') === 'activeSelection'){
        var group = editor.canvas.getActiveObject().toGroup()
   
     const currentText = editor.canvas.getActiveObject()
        currentText._objects.forEach((each) => {
            each.set({
                globalCompositeOperation: 'source-over'
            })
           
        })
        let dataurl = currentText.toDataURL({
            format: 'png',
            enableRetinaScaling: true,
        })
        currentText._objects.forEach((each) => {
            each.set({
                globalCompositeOperation: 'source-atop'
            })
           
        })
    //  editor.canvas.backgroundColor='#fff'
      currentjosn = JSON.stringify(group.toJSON(['id','projectid', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable', 'name','breakWords','brushtype','textBackgroundColor','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name','oldWidth','oldHeight','oldScaleX','oldScaleY']))
      image = dataURLtoFile(dataurl,'text-template');
 
    }else{
        selectall()
        var group = editor.canvas.getActiveObject().toGroup()

        const currentText = editor.canvas.getActiveObject()
        currentText._objects.forEach((each) => {
            each.set({
                globalCompositeOperation: 'source-over'
            })
           
        })
        let dataurl = currentText.toDataURL({
            format: 'png',
            enableRetinaScaling: true,
        })
        currentText._objects.forEach((each) => {
            each.set({
                globalCompositeOperation: 'source-atop'
            })
           
        })
     
        // editor.canvas.backgroundColor='#fff'
         currentjosn = JSON.stringify(group.toJSON(['id','projectid', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable', 'name','breakWords','brushtype','textBackgroundColor','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name','oldWidth','oldHeight','oldScaleX','oldScaleY']))
         image = dataURLtoFile(dataurl,'text-template');
    }




    (async function () {
        var formdata = new FormData();
        formdata.append("text_thumbnail", image);
        formdata.append("text_template_json", currentjosn);
        formdata.append("text_keywords", 'sample');
        formdata.append("text_keywords", 'testing');
        formdata.append("text_keywords", 'first');


        var requestOptions = {
            method: 'POST',
            headers: handleAuthTrue(new Headers()),
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(LABS_API + "/imagetranslate/text-template/", requestOptions)
        if (data.status === 200) {
            let response = await data.json()
            // console.log(response);
            textTemplate()
            if(editor.canvas.getActiveObject().type == 'group'){
            editor.canvas.getActiveObject().toActiveSelection()
            }
            editor.canvas.renderAll()
        }
        else {
            console.log('error');
        }
    })()
}

const deleteTextTemplate = (id) => {
    (async function () {


        var requestOptions = {
            method: 'DELETE',
            headers: handleAuthTrue(new Headers()),
            redirect: 'follow'
        };

        let data = await fetch(LABS_API + `/imagetranslate/text-template/${id}`, requestOptions)
        if (data.status === 204) {
            let response = await data
            textTemplate()
        
        }
        else {
            console.log('error');
        }
    })()

}

function loadFabricJSONData(jsonData) {
    // Get all font family names from the JSON data, including nested groups
    const fontFamilies = [];

    function extractFontFamilies(obj) {
        if (obj.fontFamily && !fontFamilies.includes(obj.fontFamily)) {
            fontFamilies.push(obj.fontFamily);
        }
        if (obj.type === 'group' && obj.objects) {
            obj.objects.forEach((groupObj) => extractFontFamilies(groupObj));
        }
    }

    extractFontFamilies(jsonData)
    // jsonData.json.objects.forEach((obj) => extractFontFamilies(obj));

    // Remove duplicate font families
    const uniqueFontFamilies = [...new Set(fontFamilies)];
    // Check if all font families are loaded in the DOM
    uniqueFontFamilies.forEach((fontFamily) => {
        var link = document.createElement('link')
        link.href = `https://fonts.googleapis.com/css?family=${fontFamily}`
        link.rel = 'stylesheet'
        link.setAttribute('name', fontFamily)
        document.querySelector('head').appendChild(link)
    });
    if(uniqueFontFamilies.length == 0){
        // loadDesign(file, jsonData)
        loadTextTemplate(jsonData)

        // document.querySelector('.canvas-space').classList.remove('loading-json-blur');
        // document.querySelector('#loading-wrapper').style.setProperty('display', 'none');
    }
    // If all font families are loaded, load the JSON data in the canvas
    uniqueFontFamilies.forEach((obj, index) => {
        var font = new FontFaceObserver(obj);
        font.load().then(function () {
            console.log('Font is available');
            if (index == (uniqueFontFamilies.length - 1)) {
                setTimeout(() => {
                    // loadDesign(file, jsonData)
                    loadTextTemplate(jsonData)

                }, 500);
            }


        }, function () {
            console.log('Font is not available');
            font.load().then(function () {
                console.log('Font is available');
                setTimeout(() => {
                    // loadDesign(file, jsonData)
                    loadTextTemplate(jsonData)
                }, 500);
            }, function () {
                console.log('Font is not available');
            });
        });
    })
}


const loadTextTemplate = (data) =>{
    var json = [data]

    fabric.util.enlivenObjects(json, function (enlivenedObjects) {
        enlivenedObjects.forEach(function (obj, index) {
            obj.set({
                fontSize : obj.fontSize * editor.canvas.getZoom(),
                ScaleX: 1,
                ScaleY: 1
            })
            editor.canvas.add(obj); 
            editor.canvas.renderAll()
            editor.canvas.setActiveObject(obj)

        });
        if(editor.canvas.getActiveObject().type == 'group'){
            editor.canvas.getActiveObject()?.toActiveSelection()
            // editor.canvas.centerObject(editor.canvas.getActiveObject())
            editor.canvas.getActiveObject().set('top', (canvasheight / 2) - ((editor.canvas.getActiveObject().height * editor.canvas.getActiveObject().scaleY) / 2))
            editor.canvas.getActiveObject().set('left', (canvaswidth / 2) - ((editor.canvas.getActiveObject().width * editor.canvas.getActiveObject().scaleX) / 2))


        }else{
            editor.canvas.getActiveObject().set('top', (canvasheight / 2) - ((editor.canvas.getActiveObject().height * editor.canvas.getActiveObject().scaleY) / 2))
                editor.canvas.getActiveObject().set('left', (canvaswidth / 2) - ((editor.canvas.getActiveObject().width * editor.canvas.getActiveObject().scaleX) / 2))
        }
        editor.canvas.renderAll();
        save()
    });
}


const addtexting=(textTemp,e)=>{
   if(translatedImageid != null && workspaceSwitch !== 'Workspace'){
    notify()
    editor.canvas.isDrawingMode = false
    setTranslatedImageid(null)
    editor.canvas.clear()
    setManualChecked(false)
    navigate('/ailaysa-canvas')
    var size = {name:'togglesidebar',width:canvaswidth,height:canvasheight,fill:canvasBgColor}
    handlesize(size)
    editor.canvas.renderAll()          
   }
   console.log(textTemp)
   
   loadFabricJSONData(textTemp)
   
}


  

    return (
        <div className="sidebar-content">
            <div className="sidebar-tabs-heading">
                <span>Text</span>
                {/* <CloseIcon className="close-icon" onClick={handleSidebarClose} /> */}
                <button className="save-text-template" onClick={savetexttemp}>Save text template</button>
            </div>
            <div className={focusOnSearch ? "main-tab-search active-search-bar" : "main-tab-search"}>
                <SearchIcon className="seacrh-icon" onClick={() => { }} />
                <span className="search-placeholder-text">
                    <input type="text" onKeyDown={() => { }} onClick={() => {
                        setFocusOnSearch(true)
                    }} onBlur={(e) => {
                        setFocusOnSearch(false);
                    }} className="searcher" onChange={() => { }} placeholder="Search..." />
                </span>
            </div>
            
            <div className="add-text">
                <div onClick={(e) => addText(e,1)} data-id="text-1" className='add-heading1' draggable={true} onDragEnd={(e) =>addTextByDragging(e,1)}>
                    Add a text
                </div>
                {/* <div onClick={(e) => addText(e,2)} data-id="text-2" className='add-heading2' draggable={true} onDragEnd={(e) =>addTextByDragging(e,2)}>
                    Add a subheading
                </div>
                <div onClick={(e) => addText(e,3)} data-id="text-3" className='add-heading3' draggable={true} onDragEnd={(e) =>addTextByDragging(e,3)}>
                    Add a text
                </div> */}
            </div>
            {texttemploading && <div className="grid ">
            {Array(10).fill(null).map((value, key) => {
                    return(
                        <Skeleton variant="rectangular" width={130} height={130} />
                    )
                })}
            </div>}

            { !texttemploading && 
                <div className="text-grid">
               <div className="col-first">
               {texttempfirsthalf?.map((temp,index)=>{
                return (<>
                
                    <div key={index} className=" grid-item-text" >
                    <div className="tool-container d-none"  onClick={(e) => {
                            deleteTextTemplate(temp.id)
                        }}>
                   
                    <Tooltip
                        title="Delete" placement="top" arrow>
                        <DeleteForeverIcon className="delete-icon-text" />
                    </Tooltip>
                    
                    </div>
                    <div className="text-design-items-wrapper" onClick={(e) => addtexting(temp.text_template_json)} onDragEnd={(e) => addtexting(temp.text_template_json)} >
                    <img className="images-text" src={LABS_API + temp.text_thumbnail}  />

                    </div>
                    </div>
                </>)
                    })}
               </div>
               <div className="col-first">
               {texttempSecondhalf?.map((temp,index)=>{
                return (<>
                
                    <div key={index} className=" grid-item-text" >
                    <div className="tool-container d-none"  onClick={(e) => {
                            deleteTextTemplate(temp.id)
                        }}>
                   
                    <Tooltip
                        title="Delete" placement="top" arrow>
                        <DeleteForeverIcon className="delete-icon-text" />
                    </Tooltip>
                    
                    </div>
                    <div className="text-design-items-wrapper" onClick={() => addtexting(temp.text_template_json)} onDragEnd={(e) => addtexting(temp.text_template_json)} >
                        <img className="images-text" src={LABS_API + temp.text_thumbnail}  />
                    </div>
                    </div>
                </>)
                    })}
               </div>
                </div>
            }
        </div>

    )


}

export default Text;