import React, { useEffect, useLayoutEffect, useState, useRef, useCallback } from "react";
import Select from 'react-select'
import { langFilter } from "./SideBarData";






const LanguageSelect = (props) => {

    const {
        sourceCavasData,
        languageData,
        srLanguage,
        fabric,
        editor,
        setPageData,
        sourceCavasDataJson,
        setSourceCanvasData,
        pageNo,
        pageData,
        setTargetLanNo,
        setCanvasTranslatedJsonId,
        state,
        handleAuthTrue,
        LABS_API,
        translatedImageid,
        canvaswidth,
        canvasheight,
        setCanvaswidth,
        setCanvasheight,
        totalLangListRef,
        addBackground,
        langList,
        urlPath,
        setLanguageLabel,
        setTargetLangList,
        setTargetLangListInitial,

    } =props

    
    const [options, setOptions] = useState([]);



      const intitialSourceUpdate = (id) => {
        if(id === null){
            (async function () {
                const tempdata = JSON.stringify(editor.canvas.toJSON(['id','projectid', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable','brushtype','brushtype2','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name','oldWidth','oldHeight','oldScaleX','oldScaleY']));
                    var formdata = new FormData();
                      formdata.append("source_canvas_json", tempdata);
            
                    var requestOptions = {
                        method: 'PUT',
                        body: formdata,
                        headers: handleAuthTrue(new Headers()),
                        redirect: 'follow'
                    };
                    let data = await fetch(LABS_API + "/image/imageupload/" + translatedImageid + "/", requestOptions)
                    if (data.status === 200) {
                    let response = await data.json()
                        let targetLangList = []
                       const results = []
                       response.image_inpaint_creation.forEach((value) => {
                         results.push({
                           label: langList[langList.map(function (item) { return item.id; }).indexOf(value.target_language)]?.language,
                           value: value.target_canvas_json,
                           languagNo: value.target_language,
                           boundingBox: value.target_bounding_box,
                           canvasId: value.id
                         });
                         targetLangList.push({id :value.target_language})
                       });
                       setTargetLangList(targetLangList)
                       setTargetLangListInitial(results)
                        setOptions([
                         { label: langList[langList.map(function (item) { return item.id; }).indexOf(srLanguage)]?.language, value: response.source_canvas_json , pageValue : response.source_canvas_json , languagNo: srLanguage, canvasId: null, boundingBox: sourceCavasData.source_bounding_box},
                         ...results
                       ])


                    }
                })();
        }else{
            (async function () {
                var temp = editor.canvas.viewportTransform
            editor.canvas.renderAll()
            editor.canvas.viewportTransform = [1,0,0,1,0,0]
            const thumbail = editor.canvas.toDataURL({
                format: 'jpeg',
                quality: 0.5,
                width: canvaswidth,
                height: canvasheight
            })
            const exportImage = editor.canvas.toDataURL({
                format: 'png',
                width: canvaswidth,
                height: canvasheight
            })
            editor.canvas.viewportTransform = temp
            editor.canvas.renderAll()
    
    
                function dataURLtoFile(dataurl, filename) {
    
                    var arr = dataurl.split(','),
                        mime = arr[0].match(/:(.*?);/)[1],
                        bstr = atob(arr[1]),
                        n = bstr.length,
                        u8arr = new Uint8Array(n);
    
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n);
                    }
    
                    return new File([u8arr], filename, { type: mime });
                }
    
                var file = dataURLtoFile(thumbail, 'thumbnail_'  + translatedImageid + '.jpeg');
                var file2 = dataURLtoFile(exportImage, "export" + ".png");
                const tempdata = JSON.stringify(editor.canvas.toJSON(['id','projectid', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable','brushtype','brushtype2','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name','oldWidth','oldHeight','oldScaleX','oldScaleY']));
                    var formdata = new FormData();
                      formdata.append("target_canvas_json", tempdata);
                      formdata.append("thumbnail", file);
                      formdata.append("export", file2);

                      formdata.append("target_update_id", `${id}`)
            
                    var requestOptions = {
                        method: 'PUT',
                        body: formdata,
                        headers: handleAuthTrue(new Headers()),
                        redirect: 'follow'
                    };
                    let data = await fetch(LABS_API + "/image/imageupload/" + translatedImageid + "/", requestOptions)
                    if (data.status === 200) {
                    let response = await data.json()
                    let targetLangList = []
                       const results = []
                       response.image_inpaint_creation.forEach((value) => {
                         results.push({
                           label: langList[langList.map(function (item) { return item.id; }).indexOf(value.target_language)]?.language,
                           value: value.target_canvas_json,
                           languagNo: value.target_language,
                           boundingBox: value.target_bounding_box,
                           canvasId: value.id,
                           srImg: LABS_API + '/' + sourceCavasData.inpaint_image
                         });
                         targetLangList.push({id :value.target_language})
                       });
                       setTargetLangList(targetLangList)
                       setTargetLangListInitial(results)
                        setOptions([
                         { label: langList[langList.map(function (item) { return item.id; }).indexOf(srLanguage)]?.language, value: sourceCavasDataJson , pageValue : response.source_canvas_json , languagNo: srLanguage, canvasId: null, boundingBox: sourceCavasData.source_bounding_box,srImg: LABS_API + '/' + sourceCavasData.inpaint_image},
                         ...results
                       ])
                    }
                })();
        }
      
   }

    useEffect(() =>{

        if(languageData != null && sourceCavasData != null && srLanguage != null && window.location.pathname === '/ailaysa-canvas-workspace/design/'){
            let targetLangList = []
            const results = []
            languageData.forEach((value) => {
              results.push({
                label: langList[langList?.map(function (item) { return item.id; }).indexOf(value.target_language)]?.language,
                value: value.tranlated_json[pageNo-1]?.json,
                pageValue: value.tranlated_json,
                srPage: sourceCavasDataJson[pageNo-1]?.json,
                languagNo: value.target_language,
                canvasId: value.tranlated_json[0]?.canvas_trans_json,
                srImg: LABS_API + '/' + sourceCavasData.inpaint_image
              });
              targetLangList.push({id :value.target_language})
            });
            setTargetLangList(targetLangList)
            setTargetLangListInitial(results)
            setOptions([
              { label: langList[langList?.map(function (item) { return item.id; }).indexOf(srLanguage)]?.language, value: sourceCavasDataJson[pageNo-1]?.json , pageValue : sourceCavasDataJson , languagNo: srLanguage, canvasId: null,srImg: LABS_API + '/' + sourceCavasData.inpaint_image},
              ...results
            ])
        }
       
        if( languageData != null && sourceCavasData != null && srLanguage != null && (window.location.pathname === '/ailaysa-canvas-workspace/image-translate/' || window.location.pathname === '/ailaysa-canvas-workspace/image-translate-auto/')){
           

            let targetLangList = []
            const results = []
            languageData.forEach((value) => {
              results.push({
                label: langList[langList?.map(function (item) { return item.id; }).indexOf(value.target_language)]?.language,
                value: value.target_canvas_json,
                languagNo: value.target_language,
                boundingBox: value.target_bounding_box,
                canvasId: value.id,
                srImg: LABS_API + '/' + sourceCavasData.inpaint_image
              });
              targetLangList.push({id :value.target_language})
            });
            setTargetLangList(targetLangList)
            setTargetLangListInitial(results)
             setOptions([
              { label: langList[langList?.map(function (item) { return item.id; }).indexOf(srLanguage)]?.language, value: sourceCavasDataJson , pageValue : sourceCavasDataJson , languagNo: srLanguage, canvasId: null, boundingBox: sourceCavasData.source_bounding_box,
              srImg: LABS_API + '/' + sourceCavasData.inpaint_image},
              ...results
            ])
        }



    },[sourceCavasData,pageNo,pageData,langList])

 function generateUID(length)
    {
        return window.btoa(String.fromCharCode(...window.crypto.getRandomValues(new Uint8Array(length * 2)))).replace(/[+/]/g, "").substring(0, length);
    }

    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    
   
    function adjustFontSizeBasedOnFixedWidth(textbox, fixedWidth) {
        const text = textbox.text;
        let fontSize = textbox.fontSize;
      
        // create a temporary Textbox object to calculate the width of the text
        const tempTextbox = new fabric.Textbox(text, {
          fontSize: fontSize,
          fontFamily: textbox.fontFamily,
        });
      
        while (tempTextbox.width > fixedWidth) {
          fontSize--;
          tempTextbox.set('fontSize', fontSize);
        }
      
        // update the font size of the original Textbox object
        textbox.set('fontSize', fontSize);
        textbox.set('width', fixedWidth);
        textbox.setCoords();
      }
 
  function adjustFontSizeBasedOnWidth(textbox) {
        const desiredWidth = 500;
        const text = textbox?.text;
        let fontSize = textbox?.fontSize;
      
        // create a temporary Textbox object to calculate the width of the text
        const tempTextbox = new fabric.Textbox(text, {
          fontSize: fontSize,
          fontFamily: textbox?.fontFamily,
        });
      
        while (tempTextbox.width > desiredWidth) {
          fontSize--;
          tempTextbox.set('fontSize', fontSize);
        }
        // console.log(fontSize*2)
        // update the font size of the original Textbox object
        // textbox.set('fontSize', fontSize);
        // textbox.setCoords();
        return fontSize;
      }

function calculateFontSize(textboxWidth, text) {
    var fontSize = 20; // initial font size
    var textWidth = 0;
  
    // Loop until the text width is greater than or equal to the text box width
    while (textWidth < textboxWidth) {
      var textObject = new fabric.Textbox(text, {
        fontSize: fontSize,
        left: 0,
        top: 0,
      });
  
      textWidth = textObject.getScaledWidth();
  
      // If the text width is less than the text box width, increase the font size and try again
      if (textWidth < textboxWidth) {
        fontSize++;
        // textObject.setFontSize(fontSize);
      }
    }
  
    // The final font size is the one that produced a text width greater than or equal to the text box width
    return fontSize;
  }
    const handleLanguageSelect = (selected) => {
        if(window.location.pathname === '/ailaysa-canvas-workspace/design/'){
            setCanvasTranslatedJsonId(selected.canvasId);
            setPageData(selected.pageValue)
            setTargetLanNo(selected.languagNo)
            setLanguageLabel(selected.canvasId)
            setSourceCanvasData(sourceCavasDataJson[pageNo-1].json)
            editor?.canvas.loadFromJSON(selected.value, function () {
                editor?.canvas.renderAll.bind(editor?.canvas.renderAll());

            })
            editor.canvas.setActiveObject(editor.canvas.getObjects('textbox')[0])
            editor.canvas.renderAll()
            setCanvaswidth(selected.value.backgroundImage.width)
            setCanvasheight(selected.value.backgroundImage.height)

            // selected.value.Object.forEach((each) => {

            // })
            // editor.canvas.getObjects('textbox').forEach((each) => {
            //     console.log(calculateFontSize(each.width,each.text))
            //     each.set({
            //         fontSize: 55,
            //     })

            // })
            editor.canvas.renderAll()
        

            
        }else{
            if(selected.value === null){
                editor.canvas.remove(...editor.canvas.getObjects());
                setCanvasTranslatedJsonId(selected.canvasId);
                setTargetLanNo(selected.languagNo)
                addBackground(selected.srImg)
                Object.entries(selected.boundingBox).map(([k, v]) => {
                    let box = v.bbox,
                        color = v.color1[0],
                        bgcolor = v.color1[1],

                        size = v.fontsize > v.fontsize2 ? v.fontsize + 5 : v.fontsize2 + 5
    
                    var textbox = new fabric.Textbox(v.text?.trim(), {
                        name: "Textbox_"+generateUID(8) + makeid(9),
                        id: 'text-' + k,
                        left: box[0],
                        height:box[3],
                        fontSize:  size,
                        width: box[2] - box[0],
                        top: box[1],
                        fill: 'rgb(' + color + ")",
                        globalCompositeOperation: "source-atop",
                        minScaleLimit: 0.5,
                        fontFamily: 'Roboto',
                        lineHeight: 1,
                        breakWords: true,
                        uniformScaling: true,
                        status: 'Unconfirmed',

                    });
                    editor.canvas.add(textbox)
                    // editor.canvas.setActiveObject(editor.canvas.getObjects()[0])
                    editor.canvas.renderAll()


                }
                
                )
                setTimeout(() => {
                    intitialSourceUpdate(selected.canvasId)
                }, 1000);

            }else{
                setCanvasTranslatedJsonId(selected.canvasId);
                setTargetLanNo(selected.languagNo)
                editor?.canvas.loadFromJSON(selected.value, function () {
                    editor?.canvas.renderAll.bind(editor?.canvas.renderAll());

                })
            }
        }
       
    }

    const customProjectTypeSelectStyles = {
        placeholder: (provided, state) => ({
            ...provided,
            color: "#3C4043",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontWeight: "500",
            lineHeight: "24px",
            zIndex: 7

        }),
        menu: (provided, state) => ({
            ...provided,
            borderBottom: "1px solid #DADADA",
            borderRadius: "0px",
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: "0px solid #CED4DA",
            borderLeft: "2px solid transparent",
            color: state.isSelected ? "#ffffff" : state.isDisabled ? "#cccccc" : "#7E7E7E",
            background: state.isSelected ? "#F4F5F7" : "#ffffff",
            display: "flex",
            marginBottom: "0.2rem",
            padding: "4px 6px",
            color: "#292929",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "24px",
            "&:hover": {
                background: "#F4F5F7",
                borderLeft: "2px solid #0074D3",
                borderRight: "2px solid #0074D3",
                cursor: "pointer",
            },
            zIndex: 7
        }),
        control: (base, state) => ({
            ...base,
            backgroundColor: "#FFFFFF",
            transtion: 0.3,
            color: "#3C4043",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontWeight: "500",
            lineHeight: "24px",
            boxShadow: 0,
            "&:hover": {
                cursor: "pointer",
            },
            zIndex: 6

        }),
    };

    return (
        <div>
            <Select
                onChange={handleLanguageSelect}
                options={options}
                // placeholder={langFilter[langFilter.map(function (item) { return item.value; }).indexOf(srLanguage)]?.label}
                // placeholder={langLabel}
                defaultValue={options[0]}
                styles={customProjectTypeSelectStyles}
                />
        </div>

    )


}

export default LanguageSelect;