import React, { useEffect, useState, useRef } from "react";
import Background from "./sidebarcomponents/Background";
import Graphics from "./sidebarcomponents/Graphics";
import MyDesign from "./sidebarcomponents/MyDesign";
import MyTemplate from "./sidebarcomponents/MyTemplate";
import Photos from "./sidebarcomponents/Photos";
import Template from "./sidebarcomponents/Template";
import Text from "./sidebarcomponents/Text";
import Upload from "./sidebarcomponents/Upload";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import template from '../assets/icons/template.svg'
import text from '../assets/icons/text.svg'
import aiTab from '../assets/icons/ai-image-gen.svg'
import photos from '../assets/icons/photos.svg'
import background from '../assets/icons/background.svg'
import myWork from '../assets/icons/myWork.svg'
import uploads from '../assets/icons/uploads.svg'
import elements from '../assets/icons/elements.svg'
import speechtotextsidebaricon from '../assets/icons/speechtotextsidebaricon.svg'
import Resize from "./sidebarcomponents/Resize";
import keyword from '../assets/icons/keyword.svg'
import FontFamily from "./sidebarcomponents/FontFamily";
import Inpaint from "./sidebarcomponents/Inpaint";
import "rodal/lib/rodal.css";
import Config from "../config/Config";
import AiGeneration from "./sidebarcomponents/AiGeneration";
import { ReactComponent as SideBarToggle } from '../assets/icons/SideBarToggle.svg'
import GraphemeSplitter from 'grapheme-splitter'
import { useNavigate } from "react-router-dom";
import Rodal from 'rodal';
import FontFaceObserver from 'fontfaceobserver';






const Sidebar = (props) => {

    const {
        fabric,
        editor,
        scaleCanvas,
        setCanvasBgColor,
        canvasBgColor,
        setBackgroundEdit,
        setCanvasheight,
        setCanvaswidth,
        setTabIndex,
        tabIndex,
        canvasheight,
        canvaswidth,
        setActiveFontFamilyShow,
        designid,
        setDesignid,
        setName,
        handleAuthTrue,
        LABS_API,
        setAutoSaved,
        autoSaved,
        workspaceSwitch,
        setDesigntemp,
        designtemp,
        setPageData,
        backgroundConfirmation,
        setCropImageSource,
        setAutoChecked,
        autoChecked,
        manualChecked,
        setManualChecked,
        translatedImageid,
        setTranslatedImageid,
        setTranslateimagetemp,
        translateimagetemp,
        imageTranslateId,
        name,
        setImageTransleSource,
        setselected,
        selected,
        updateDesign,
        notify,
        handlesize,
        scaleMultiplierX,
        getMyDesignbyId,
        debounce,
        setCanvasmodified,
        imageTransleSource,
        shadow,
        getProjectData,
        getImageTranslateProjectData,
        langLabel,
        pageData,
        setPageNo,
        handleSave,
        save
    } = props


    // const LABS_API = Config.LABS_API
    const [closeSidebar, setCloseSidebar] = useState(false)
    const [uploadimg, setUpload] = useState([])
    const [uploadimgForImageTranslate, setUploadimgForImageTranslate] = useState([])
    const navigate = useNavigate();
    const [loading,setLoading] = useState(true)
    const [imgId, setImgId] = useState(1);
    const [elementId, setElementId] = useState(1);
    const [jsdata, setJsdata] = useState(null)
    const [photosearchword, setPhotosearchword] = useState('dogs')
    const [elementsearchword, setElementsearchword] = useState('morning')
    const [backgroundsearchword, setBackgroundsearchword] = useState('background')
    const [images, setImages] = useState()
    const [imagesSecondary, setImagesSecondary] = useState()
    const [elementsImages, setElementsImages] = useState()
    const [elementsImagesSecondary, setElementsImagesSecondary] = useState()
    const [bgimages, setBgimages] = useState()
    const [bgimagesSecondary, setBgimagesSecondary] = useState()
    const [translateImage, setTranslatedimage] = useState([])
    const [globalTemplate, setGlobalTemplate] = useState([])
    const canvasimgurl = useRef();
    const [searchingimage, setSearchingimage] = useState(() => { return false })
    const translateimagetabmodel = useRef(false)
    const [texttemp, setTexttemp] = useState(null)
    const [texttempfirsthalf, setTexttempfirsthalf] = useState(null)
    const [texttempSecondhalf, setTexttempsecondhalf] = useState(null)
    const [texttemploading, setTexttemploading] = useState(false)
    const [showSettings, setshowSettings] = useState(false);
    const hideSettingsModal = () => setShowTemplateNotice(false);
    const modaloption = {
        closeMaskOnClick: false,
        width: 400,
        height: 165,
        onClose: hideSettingsModal,
        animation: "fade",
        duration: "0"
    };
    const [showTemplateNotice, setShowTemplateNotice] = useState(false)
    const [templateFrom, settemplateFrom] = useState(false)
    // const hideSettingsModal = () => setshowSettings(false);


    const handleSidebarClose = () => {
        setCloseSidebar(true)
        setTabIndex(16)
        var size = { name: 'togglesidebar', width: canvaswidth, height: canvasheight, fill: canvasBgColor }
        handlesize(size)
    }

    useEffect(() => {
        if (editor) {
            var size = { name: 'togglesidebar', width: canvaswidth, height: canvasheight, fill: canvasBgColor }
            handlesize(size)
        }
    }, [tabIndex])







    function generateUID(length) {
        return window.btoa(String.fromCharCode(...window.crypto.getRandomValues(new Uint8Array(length * 2)))).replace(/[+/]/g, "").substring(0, length);
    }
    function makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    // TempDataCheck-start

    useEffect(() => {
    }, [jsdata])
    const getJsonData = () => {
        const image = editor.canvas.toSVG();
        canvasimgurl.current = "data:image/svg+xml," + encodeURIComponent(image);
        setJsdata(editor.canvas.toJSON(['id', 'projectid','subTargetCheck', 'canvasimg', 'id2', 'status', 'lockMovementX', 'lockMovementY', 'editable', 'name', 'breakWords', 'brushtype', 'textBackgroundColor', 'perPixelTargetFind', 'listType', 'listBullet', 'listCounter', 'isWrapping', 'objectCaching', 'originalwidth', 'originalheight', 'originalleft', 'name','originaltop','oldWidth','oldHeight','oldScaleX','oldScaleY']))
        console.log(jsdata);
    }
    // TempDataCheck-end

    // Uplaod-start


    const getMyUpload = async() => {
        var requestOptions = {
            method: 'GET',
            headers: handleAuthTrue(new Headers()),
            redirect: 'follow'
        };

        let data = await fetch(LABS_API + "/canvas/canvas-user-images/", requestOptions)
        if (data.status === 200) {
            let response = await data.json()
            setUpload(response.reverse())
        }
        else {
            // console.log('error');
        }

    }


    const getMyUploadImageTranslate =async () => {
        var requestOptions = {
            method: 'GET',
            headers: handleAuthTrue(new Headers()),
            redirect: 'follow'
        };

        let data = await fetch(LABS_API + "/image/imageloadlist/", requestOptions)
        if (data.status === 200) {
            let response = await data.json()
            setUploadimgForImageTranslate(response.reverse())
        }
        else {
            // console.log('error');
        }
    }



    const textTemplate = async () => {
        setTexttemploading(true)
        var requestOptions = {
            method: 'GET',
            headers: handleAuthTrue(new Headers()),
            redirect: 'follow'
        };

        let data = await fetch(LABS_API + "/imagetranslate/text-template/", requestOptions)
        if (data.status === 200) {
            let response = await data.json()
            var half = Math.ceil(response?.length / 2)

            setTexttemp(response)
            setTexttempfirsthalf(response.slice(0, half))
            setTexttempsecondhalf(response.slice(half))

            setTexttemploading(false)

        }
        else {
            console.log('error');
            setTexttemploading(false)
        }
    }




    // Uplaod-end


    // Photos-start

    async function fectchPhotos(e) {
        // const response = await fetch(`https://picsum.photos/v2/list?page=2&limit=100`);
        const response = await fetch(`https://pixabay.com/api/?key=${Config.PIXABAY_API}&q=${photosearchword}&image_type=photo&per_page=50&page=1`);
        const responseSecondary = await fetch(`https://pixabay.com/api/?key=${Config.PIXABAY_API}&q=${photosearchword}&image_type=photo&per_page=50&page=2`);
        const jsonifiedResponse = await response.json();
        const jsonifiedResponseSecondary = await responseSecondary.json();
        // console.log(jsonifiedResponse);
        setImages(jsonifiedResponse.hits.sort(() => (Math.random() > 0.5) ? 1 : -1))
        setImagesSecondary(jsonifiedResponseSecondary.hits.sort(() => (Math.random() > 0.5) ? 1 : -1))
    }

    async function fectchBackground(e) {
        // const response = await fetch(`https://picsum.photos/v2/list?page=2&limit=100`);
        const response = await fetch(`https://pixabay.com/api/?key=${Config.PIXABAY_API}&q=${backgroundsearchword}&image_type=photo&category=background&per_page=50&page=1`);
        const responseSecondary = await fetch(`https://pixabay.com/api/?key=${Config.PIXABAY_API}&q=${backgroundsearchword}&image_type=photo&category=background&per_page=50&page=1`);
        const jsonifiedResponse = await response.json();
        const jsonifiedResponseSecondary = await responseSecondary.json();
        // console.log(jsonifiedResponse);
        setBgimages(jsonifiedResponse.hits.sort(() => (Math.random() > 0.5) ? 1 : -1))
        setBgimagesSecondary(jsonifiedResponseSecondary.hits.sort(() => (Math.random() > 0.5) ? 1 : -1))

    }


    // Photos-end

    useEffect(() => {
        const eventinspect = (event) => {
            const key = event.key;
            // console.log(key); // const {key} = event; ES6+
            event = event || window.event // IE support
            var c = event.which
            var ctrlDown = event.ctrlKey || event.metaKey // Mac support

            // Check for Alt+Gr (http://en.wikipedia.org/wiki/AltGr_key)
            if (ctrlDown && event.altKey) {
                return true
            }
            else if (ctrlDown && c == 32) {
                event.preventDefault()
                if (editor.canvas.getActiveObject()) {
                    console.log(editor.canvas.getActiveObject());
                    console.log(editor.canvas.getActiveObject().type);

                }
                else {
                    getJsonData()
                }
            }
        }
        document.addEventListener('keydown', eventinspect, false)
        return (() => {
            document.removeEventListener('keydown', eventinspect)
        })
    })

    // Element-start

    async function fectchVector() {

        let today = new Date();
        let hour = today.getHours()
        if (hour < 12) {
            setElementsearchword('morning')
        }
        else if (hour >= 12) {
            setElementsearchword('afternoon')
        }
        else if (hour >= 15) {
            setElementsearchword('evening')
        }
        else if (hour >= 18) {
            setElementsearchword('night')
        }
        else if (hour >= 5) {
            setElementsearchword('morning')
        }

        // const response = await fetch(`https://picsum.photos/v2/list?page=2&limit=100`);
        const response = await fetch(`https://pixabay.com/api/?key=${Config.PIXABAY_API}&q=${elementsearchword}&image_type=vector&per_page=50&order=latest`);
        // const responseSecondary = await fetch(`https://pixabay.com/api/?key=${Config.PIXABAY_API}&q=${elementsearchword}&image_type=vector&per_page=50&order=latest`);
        const jsonifiedResponse = await response.json();
        // const jsonifiedResponseSecondary = await responseSecondary.json();

        setElementsImages(jsonifiedResponse.hits.filter((e) => { return e.type == 'vector/svg' }))

        // setElementsImagesSecondary(jsonifiedResponseSecondary)
    }

    // Element-end

    // 514b5d39eb4a6865f8fd23e2dd0d55f756b3afcc


    useEffect(() => {
        if (tabIndex == 9) {
            translateimagetabmodel.current = true
            // console.log(translateimagetabmodel.current);
        }
        else {
            translateimagetabmodel.current = false
            // console.log(translateimagetabmodel.current);

        }
    }, [tabIndex])

    const [leftToptext, setLeftTopText] = useState(100)


    const onAddImage = (e, type) => {
        editor.canvas.discardActiveObject().requestRenderAll()

        if (designid === null && translatedImageid !== null && workspaceSwitch !== 'Workspace') {
            notify()
            editor.canvas.isDrawingMode = false
            setTranslatedImageid(null)
            editor.canvas.clear()
            setManualChecked(false)
            navigate('/ailaysa-canvas')
            var size = { name: 'togglesidebar', width: canvaswidth, height: canvasheight, fill: canvasBgColor }
            handlesize(size)
            editor.canvas.renderAll()
        }
        setLeftTopText(leftToptext + 10)

        setImgId(imgId + 1)
        setElementId(elementId + 1)
        const stickers = e.currentTarget.dataset.id === undefined ? e.target.src : e.currentTarget.dataset.id;
        console.log('clicked')
        console.log(type)

        if (type == 'svg') {
            fabric.loadSVGFromURL(stickers, function (objects, options) {
                var loadedObjects = fabric.util.groupSVGElements(objects, options);
                loadedObjects.set({
                    subTargetCheck: true,
                    name: 'Element_' + generateUID(6) + makeid(7),
                    top: leftToptext,
                    left: leftToptext,
                    globalCompositeOperation: 'source-atop',
                })
                loadedObjects.scaleToWidth(editor.canvas.width / 3);
                loadedObjects.scaleToHeight(editor.canvas.height / 3);
                editor.canvas.add(loadedObjects);
                editor.canvas.setActiveObject(loadedObjects);

                editor.canvas.renderAll();
            });
        } else {
            var filterBrightness = new fabric.Image.filters.Brightness({
                brightness: 0,
            });
            var filterContrast = new fabric.Image.filters.Contrast({
                contrast: 0,
            });
            var filterSaturation = new fabric.Image.filters.Saturation({
                saturation: 0,
            });
            var filterBlur = new fabric.Image.filters.Blur({
                blur: 0,
            });
            var filterVibrance = new fabric.Image.filters.Vibrance({
                vibrance: 0,
            });

            var filterNoice = new fabric.Image.filters.Noise({
                noise: 0,
            });

            var filterHue = new fabric.Image.filters.HueRotation({
                rotation: 0,
            });



            fabric.Image.fromURL(stickers, (img) => {
                var oImg = img.set({
                    name: 'Image_' + generateUID(5) + makeid(6),
                    id: 'Image-' + imgId,
                    left: leftToptext,
                    top: leftToptext,
                    globalCompositeOperation: 'source-atop',
                    objectCaching: false,
                    originX: 'center',
                    originY: 'center', 
                    oldWidth : img.width,
                    oldHeight : img.height,
                    oldScaleX : img.scaleX,
                    oldScaleY : img.scaleY,
                })
                img.scaleToWidth(canvaswidth / 3);
                oImg.filters.push(filterBrightness, filterContrast, filterSaturation, filterBlur, filterVibrance, filterNoice, filterHue)

                editor.canvas.add(oImg).sendBackwards();
                editor.canvas.getObjects().forEach((e) => {
                    if (e.id === 'Image-' + imgId) {
                        editor.canvas.setActiveObject(e)
                    }
                })
                editor.canvas.renderAll();
                save()

            }, { crossOrigin: 'anonymous' });
        }

    }


    // function calculateFontSize(textboxWidth, text,initialFontSize) {
    //     var fontSize = 20; // initial font size
    //     var textWidth = 0;
      
    //     // Loop until the text width is greater than or equal to the text box width
    //     while (textWidth < textboxWidth) {
    //       var textObject = new fabric.Textbox(text, {
    //         fontSize: fontSize,
    //         left: 0,
    //         top: 0,
    //       });
      
    //       textWidth = textObject.getScaledWidth();
      
    //       // If the text width is less than the text box width, increase the font size and try again
    //       if (textWidth < textboxWidth) {
    //         fontSize++;
    //         textObject.set({
    //             fontSize: fontSize,
    //         })
    //       }
    //     }
      
    //     // The final font size is the one that produced a text width greater than or equal to the text box width
    //     return fontSize;
    //   }

    // let obj = {
    //     "type": "textbox",
    //     "version": "5.3.0",
    //     "originX": "left",
    //     "originY": "top",
    //     "left": 40.54,
    //     "top": 133.11,
    //     "width": 246.89,
    //     "height": 99.41,
    //     "fill": "rgba(194,63,63,1)",
    //     "stroke": null,
    //     "strokeWidth": 1,
    //     "strokeDashArray": null,
    //     "strokeLineCap": "butt",
    //     "strokeDashOffset": 0,
    //     "strokeLineJoin": "miter",
    //     "strokeUniform": false,
    //     "strokeMiterLimit": 4,
    //     "scaleX": 2.9,
    //     "scaleY": 2.9,
    //     "angle": 0,
    //     "flipX": false,
    //     "flipY": false,
    //     "opacity": 1,
    //     "shadow": null,
    //     "visible": true,
    //     "backgroundColor": "",
    //     "fillRule": "nonzero",
    //     "paintFirst": "fill",
    //     "globalCompositeOperation": "source-atop",
    //     "skewX": 0,
    //     "skewY": 0,
    //     "fontFamily": "Great Vibes",
    //     "fontWeight": "normal",
    //     "fontSize": 87.97250859106529,
    //     "text": "Sweet",
    //     "underline": false,
    //     "overline": false,
    //     "linethrough": false,
    //     "textAlign": "center",
    //     "fontStyle": "normal",
    //     "lineHeight": 1,
    //     "textBackgroundColor": "transparent",
    //     "charSpacing": 0,
    //     "styles": [
    //         {
    //             "start": 0,
    //             "end": 5,
    //             "style": {
    //                 "fill": "rgba(194,63,63,1)"
    //             }
    //         }
    //     ],
    //     "direction": "ltr",
    //     "path": null,
    //     "pathStartOffset": 0,
    //     "pathSide": "left",
    //     "pathAlign": "baseline",
    //     "minWidth": 20,
    //     "splitByGrapheme": false
    // }

    //     console.log(adjustFontSizeBasedOnFixedWidth(obj))
    // adjustFontSizeBasedOnWidth(tb)
    // function adjustFontSizeBasedOnWidth(textbox) {
    //     const desiredWidth = 500;
    //     const text = textbox?.text;
    //     let fontSize = textbox?.fontSize;
      
    //     // create a temporary Textbox object to calculate the width of the text
    //     const tempTextbox = new fabric.Textbox(text, {
    //       fontSize: fontSize,
    //       fontFamily: textbox?.fontFamily,
    //     });
      
    //     while (tempTextbox.width > desiredWidth) {
    //       fontSize--;
    //       tempTextbox.set('fontSize', fontSize);
    //     }
    //     console.log(fontSize*2)
    //     // update the font size of the original Textbox object
    //     // textbox.set('fontSize', fontSize);
    //     // textbox.setCoords();
    //   }

   
    // function checkImage(url) {

    //     var request = new XMLHttpRequest();
    //     request.open("GET", url, true);
    //     request.send();
    //     request.onreadystatechange = function () {
    //         if (this.readyState == this.HEADERS_RECEIVED) {
    //             var contentType = request.getResponseHeader("Content-Type");
    //             if (contentType == 'image/svg+xml') {
    //                 fabric.loadSVGFromURL(stickers, function (objects, options) {
    //                     var loadedObjects = fabric.util.groupSVGElements(objects, options);
    //                     loadedObjects.set({
    //                         subTargetCheck: true,
    //                         name: 'Element_' + generateUID(6) + makeid(7),
    //                         top: leftToptext,
    //                         left: leftToptext,
    //                         globalCompositeOperation: 'source-atop',
    //                     })
    //                     loadedObjects.scaleToWidth(editor.canvas.width / 3);
    //                     loadedObjects.scaleToHeight(editor.canvas.height / 3);
    //                     editor.canvas.add(loadedObjects);
    //                     editor.canvas.setActiveObject(loadedObjects);

    //                     editor.canvas.renderAll();
    //                 });

    //             }
    //             else {
    //                 var filterBrightness = new fabric.Image.filters.Brightness({
    //                     brightness: 0,
    //                 });
    //                 var filterContrast = new fabric.Image.filters.Contrast({
    //                     contrast: 0,
    //                 });
    //                 var filterSaturation = new fabric.Image.filters.Saturation({
    //                     saturation: 0,
    //                 });
    //                 var filterBlur = new fabric.Image.filters.Blur({
    //                     blur: 0,
    //                 });
    //                 var filterVibrance = new fabric.Image.filters.Vibrance({
    //                     vibrance: 0,
    //                 });

    //                 var filterNoice = new fabric.Image.filters.Noise({
    //                     noise: 0,
    //                 });

    //                 var filterHue = new fabric.Image.filters.HueRotation({
    //                     rotation: 0,
    //                 });



    //                 fabric.Image.fromURL(stickers, (img) => {
    //                     img.scaleToWidth(canvaswidth / 3);
    //                     var oImg = img.set({
    //                         name: 'Image_' + generateUID(5) + makeid(6),
    //                         id: 'Image-' + imgId,
    //                         left: leftToptext,
    //                         top: leftToptext,
    //                         globalCompositeOperation: 'source-atop',
    //                         objectCaching: false,
    //                     })
    //                     oImg.filters.push(filterBrightness, filterContrast, filterSaturation, filterBlur, filterVibrance, filterNoice, filterHue)

    //                     editor.canvas.add(oImg).sendBackwards();
    //                     editor.canvas.getObjects().forEach((e) => {
    //                         if (e.id === 'Image-' + imgId) {
    //                             editor.canvas.setActiveObject(e)
    //                         }
    //                     })
    //                     editor.canvas.renderAll();


    //                 }, { crossOrigin: 'anonymous' });
    //             }

    //         }
    //     }
    // }

    // editor.canvas.fire('object:modified');
    // checkImage(stickers)

// }

const onAddImageAi = (image) => {
    editor.canvas.discardActiveObject().requestRenderAll()

    if (designid === null && translatedImageid !== null && workspaceSwitch !== 'Workspace') {
        notify()
        editor.canvas.isDrawingMode = false
        setTranslatedImageid(null)
        editor.canvas.clear()
        setManualChecked(false)
        navigate('/ailaysa-canvas')
        var size = { name: 'togglesidebar', width: canvaswidth, height: canvasheight, fill: canvasBgColor }
        handlesize(size)
        editor.canvas.renderAll()
    }
    setLeftTopText(leftToptext + 10)

    setImgId(imgId + 1)
    setElementId(elementId + 1)



    var filterBrightness = new fabric.Image.filters.Brightness({
        brightness: 0,
    });
    var filterContrast = new fabric.Image.filters.Contrast({
        contrast: 0,
    });
    var filterSaturation = new fabric.Image.filters.Saturation({
        saturation: 0,
    });
    var filterBlur = new fabric.Image.filters.Blur({
        blur: 0,
    });
    var filterVibrance = new fabric.Image.filters.Vibrance({
        vibrance: 0,
    });

    var filterNoice = new fabric.Image.filters.Noise({
        noise: 0,
    });

    var filterHue = new fabric.Image.filters.HueRotation({
        rotation: 0,
    });



    fabric.Image.fromURL(image, (img) => {
        img.scaleToWidth(canvaswidth / 3);
        var oImg = img.set({
            name: 'Image_' + generateUID(5) + makeid(6),
            id: 'Image-' + imgId,
            left: leftToptext,
            top: leftToptext,
            globalCompositeOperation: 'source-atop',
            objectCaching: false,
            originX: 'center',
            originY: 'center', 
            oldWidth : img.width,
            oldHeight : img.height,
            oldScaleX : img.scaleX,
            oldScaleY : img.scaleY,
        })
        oImg.filters.push(filterBrightness, filterContrast, filterSaturation, filterBlur, filterVibrance, filterNoice, filterHue)
        
        editor.canvas.add(oImg).sendBackwards();
        editor.canvas.getObjects().forEach((e) => {
            if (e.id === 'Image-' + imgId) {
                editor.canvas.setActiveObject(e)
            }
        })
        editor.canvas.renderAll();

        save()

    }, { crossOrigin: 'anonymous' });

}



const onDragAddImage = (e) => {
    editor.canvas.discardActiveObject().requestRenderAll()

    if (designid === null && translatedImageid !== null && workspaceSwitch !== 'Workspace') {
        notify()
        editor.canvas.isDrawingMode = false
        setTranslatedImageid(null)
        editor.canvas.clear()
        setManualChecked(false)
        navigate('/ailaysa-canvas')
        var size = { name: 'togglesidebar', width: canvaswidth, height: canvasheight, fill: canvasBgColor }
        handlesize(size)
        editor.canvas.renderAll()
    }
    var pointer = editor.canvas.getPointer(e);

    setImgId(imgId + 1)
    setElementId(elementId + 1)
    const stickers = e.currentTarget.dataset.id === undefined ? e.target.src : e.currentTarget.dataset.id;

    function checkImage(url) {
        var request = new XMLHttpRequest();
        request.open("GET", url, true);
        request.send();
        request.onreadystatechange = function () {
            if (this.readyState == this.HEADERS_RECEIVED) {
                var contentType = request.getResponseHeader("Content-Type");
                // console.log(contentType);
                if (contentType == 'image/svg+xml') {
                    fabric.loadSVGFromURL(stickers, function (objects, options) {
                        var loadedObjects = fabric.util.groupSVGElements(objects, options);
                        loadedObjects.set({
                            subTargetCheck: true
                            , name: 'Element_' + generateUID(6) + makeid(7), left: pointer.x,
                            top: pointer.y,
                            // perPixelTargetFind: true,
                            globalCompositeOperation: 'source-atop',

                        })
                        loadedObjects.scaleToWidth(editor.canvas.width / 3);
                        loadedObjects.scaleToHeight(editor.canvas.height / 3);
                        editor.canvas.add(loadedObjects);
                        editor.canvas.setActiveObject(loadedObjects);

                        editor.canvas.renderAll();
                    });

                }
                else {
                    var filterBrightness = new fabric.Image.filters.Brightness({
                        brightness: 0,
                    });
                    var filterContrast = new fabric.Image.filters.Contrast({
                        contrast: 0,
                    });
                    var filterSaturation = new fabric.Image.filters.Saturation({
                        saturation: 0,
                    });
                    var filterBlur = new fabric.Image.filters.Blur({
                        blur: 0,
                    });
                    var filterVibrance = new fabric.Image.filters.Vibrance({
                        vibrance: 0,
                    });

                    var filterNoice = new fabric.Image.filters.Noise({
                        noise: 0,
                    });

                    var filterHue = new fabric.Image.filters.HueRotation({
                        rotation: 0,
                    });

                    fabric.Image.fromURL(stickers, (img) => {
                        img.scaleToWidth(canvaswidth / 3);
                        var oImg = img.set({
                            name: 'Image_' + generateUID(5) + makeid(6),
                            id: 'Image-' + imgId,
                            left: pointer.x,
                            top: pointer.y,
                            globalCompositeOperation: 'source-atop',
                            objectCaching: false,
                            originX: 'center',
                            originY: 'center', 
                            oldWidth : img.width,
                            oldHeight : img.height,
                            oldScaleX : img.scaleX,
                            oldScaleY : img.scaleY,
                        })

                        oImg.filters.push(filterBrightness, filterContrast, filterSaturation, filterBlur, filterVibrance, filterNoice, filterHue)

                        editor.canvas.add(oImg).sendBackwards();
                        editor.canvas.getObjects().forEach((e) => {
                            if (e.id === 'Image-' + imgId) {
                                editor.canvas.setActiveObject(e)
                            }
                        })
                        editor.canvas.renderAll();
                        save()


                    }, { crossOrigin: 'anonymous' });
                }

            }
        }
    }

    checkImage(stickers)

}


// Background-start

const addBackground = (BGImage) => {
    editor.canvas.discardActiveObject().requestRenderAll()
    if (designid === null && translatedImageid !== null && workspaceSwitch !== 'Workspace') {
        notify()

        editor.canvas.isDrawingMode = false
        setTranslatedImageid(null)
        editor.canvas.clear()
        setManualChecked(false)
        navigate('/ailaysa-canvas')
        var size = { name: 'togglesidebar', width: canvaswidth, height: canvasheight, fill: canvasBgColor }
        handlesize(size)
        editor.canvas.renderAll()
    }
    editor?.canvas?.remove(editor.canvas.getObjects().find(obj => obj.id == 'background'))
    var filterBrightness = new fabric.Image.filters.Brightness({
        brightness: 0,
    });
    var filterContrast = new fabric.Image.filters.Contrast({
        contrast: 0,
    });
    var filterSaturation = new fabric.Image.filters.Saturation({
        saturation: 0,
    });
    var filterBlur = new fabric.Image.filters.Blur({
        blur: 0,
    });
    var filterVibrance = new fabric.Image.filters.Vibrance({
        vibrance: 0,
    });

    var filterNoice = new fabric.Image.filters.Noise({
        noise: 0,
    });

    var filterHue = new fabric.Image.filters.HueRotation({
        rotation: 0,
    });
    // var filterPixalate = new fabric.Image.filters.Pixelate({
    //     blocksize: 0,
    // });



    fabric.Image.fromURL(BGImage, (bg) => {

        var obg = bg.set({

            top: 0,
            leftToptext: 0,
            globalCompositeOperation: 'source-atop',
            objectCaching: false,

        })


        obg.filters.push(filterBrightness, filterContrast, filterSaturation, filterBlur, filterVibrance, filterNoice, filterHue)
        editor.canvas.add(obg)
        editor.canvas.setActiveObject(obg)
        editor.canvas.sendToBack(obg)
        editor.canvas.getActiveObject().set({
            name: 'Background-new',
            id: 'background',
            top: 0,
            left: 0,
            originX: 'left',
            originY: 'top',
            crossOrigin: 'anonymous',
            scaleX: canvaswidth / obg.width,
            scaleY: canvasheight / obg.height,
            globalCompositeOperation: 'source-atop',
            objectCaching: true,
            dirty: true,
        })

        editor.canvas.renderAll();

    }, { crossOrigin: 'anonymous' });

};

//Background-end

// Template
const getGlobalTemplate = async(e) => {

    var requestOptions = {
        method: 'GET',
        headers: handleAuthTrue(new Headers()),
        redirect: 'follow'
    };
    let data = await fetch(LABS_API + `/canvas/template-design/?page=${e}`, requestOptions)
    if (data.status === 200) {
        let response = await data.json()
        // setDesign(response.reverse())
        return {'data':response.results.reverse(),'next':response.next}
    }
    else {
        // console.log('error');
        return false
    }
}


// My Design -start

    const getMyDesign = async (e) => {

        var requestOptions = {
            method: 'GET',
            headers: handleAuthTrue(new Headers()),
            redirect: 'follow'
        };

        let data = await fetch(LABS_API + `/canvas/canvas-design-list/?page=${e ? e : 1}`, requestOptions)
        if (data.status === 200) {
            let response = await data.json()
            return { 'data': response.results, 'next': response.next }
            // setDesign(response.results)
            // console.log(response.results)

        }
        else {
            // console.log('error');
        }

    }

const getMyTranslatedImageproject = async (e) => {
    var requestOptions = {
        method: 'GET',
        headers: handleAuthTrue(new Headers()),
        redirect: 'follow'
    };

    let data = await fetch(LABS_API + `/image/imageupload/?page=${e?e:1}`, requestOptions)
    if (data.status === 200) {
        let response = await data.json()
        return {'data':response.reverse(),'next':response.next}
        setTranslatedimage(response.reverse())
    }
    else {
        // console.log('error');
    }
}

const deleteTranslatedimage = (id,filesource) => {
    (async function () {
        var requestOptions = {
            method: 'DELETE',
            headers: handleAuthTrue(new Headers()),
            redirect: 'follow'
        };
        let data = await fetch(LABS_API + "/image/imageupload/" + id + "/", requestOptions)
        if (data.status === 204) {
            // getMyTranslatedImageproject()
            // navigate('/ailaysa-canvas')
            setTranslatedimage(prevState => prevState.filter(item =>  item !== filesource))
        }
        else {
            console.log('error');
        }
        if (id == translatedImageid) {
            editor.canvas.clear()
            var size = { name: 'new', width: canvaswidth, height: canvasheight, fill: 'rgba(255,255,255,1)' }
            setTranslatedImageid(null)
            navigate('/ailaysa-canvas')
            setTranslateimagetemp(false)
            setName('Untitled Project')
            setCanvasBgColor('rgba(255,255,255,1)')
            handlesize(size)

        }
    })();
}





// useEffect(()=>{
//     if(workspaceSwitch !== 'Workspace'){
//         // getMyDesign()
//         // getMyTranslatedImageproject()
//     }

// },[])

// My Design -end


var splitter = new GraphemeSplitter();

const checksametab = useRef(false)
const _splitTextIntoLines = function (text) {
    var newText = fabric.Text.prototype._splitTextIntoLines.call(this, text),
        graphemeLines = this._wrapText(newText.lines, this.width),
        lines = new Array(graphemeLines.length);
    for (var i = 0; i < graphemeLines.length; i++) {
        lines[i] = graphemeLines[i].join('');
    }
    newText.lines = lines;
    newText.graphemeLines = graphemeLines;
    return newText;
}

fabric.util.object.extend(fabric.Textbox.prototype, {
    _splitTextIntoLines: _splitTextIntoLines,
});


/**
* convert from textarea to grapheme indexes
*/
const fromStringToGraphemeSelection = function (start, end, text) {
    var smallerTextStart = text.slice(0, start),
        graphemeStart = splitter.splitGraphemes(smallerTextStart).length;
    if (start === end) {
        return { selectionStart: graphemeStart, selectionEnd: graphemeStart };
    }
    var smallerTextEnd = text.slice(start, end),
        graphemeEnd = splitter.splitGraphemes(smallerTextEnd).length;
    return { selectionStart: graphemeStart, selectionEnd: graphemeStart + graphemeEnd };
}

fabric.util.object.extend(fabric.Textbox.prototype, {
    fromStringToGraphemeSelection: fromStringToGraphemeSelection,
});



var insertChars = function (text, style, start, end) {
    if (typeof end === 'undefined') {
        end = start;
    }
    if (end > start) {
        this.removeStyleFromTo(start, end);
    }
    var graphemes = splitter.splitGraphemes(text);
    this.insertNewStyleBlock(graphemes, start, style);
    this._text = [].concat(this._text.slice(0, start), graphemes, this._text.slice(end));
    this.text = this._text.join('');
    this.set('dirty', true);
    if (this._shouldClearDimensionCache()) {
        this.initDimensions();
        this.setCoords();
    }
    this._removeExtraneousStyles();
}


const _wrapText = function (lines, desiredWidth) {
    var wrapped = [], i;
    this.isWrapping = true;
    for (i = 0; i < lines.length; i++) {
        wrapped = wrapped.concat(this._wrapLine(lines[i], i, desiredWidth));
    }
    this.isWrapping = false;
    return wrapped;
}


fabric.util.object.extend(fabric.Textbox.prototype, {
    insertChars: insertChars,
});










const _wrapLine = function (_line, lineIndex, desiredWidth, reservedSpace) {
    var lineWidth = 0,
        splitByGrapheme = this.splitByGrapheme,
        graphemeLines = [],
        line = [],
        // spaces in different languages?
        words = splitByGrapheme ? splitter.splitGraphemes(_line) : _line.split(this._wordJoiners),
        word = '',
        offset = 0,
        infix = splitByGrapheme ? '' : ' ',
        wordWidth = 0,
        infixWidth = 0,
        largestWordWidth = 0,
        lineJustStarted = true,
        additionalSpace = this._getWidthOfCharSpacing(),
        reservedSpace = reservedSpace || 0;
    // fix a difference between split and graphemeSplit
    // console.log(splitByGrapheme);
    if (words.length === 0) {
        words.push([]);
    }
    desiredWidth -= reservedSpace;
    for (var i = 0; i < words.length; i++) {
        // if using splitByGrapheme words are already in graphemes.
        word = splitByGrapheme ? words[i] : splitter.splitGraphemes(words[i]);
        wordWidth = this._measureWord(word, lineIndex, offset);
        offset += word.length;

        lineWidth += infixWidth + wordWidth - additionalSpace;
        if (lineWidth > desiredWidth && !lineJustStarted) {
            graphemeLines.push(line);
            line = [];
            lineWidth = wordWidth;
            lineJustStarted = true;
        }
        else {
            lineWidth += additionalSpace;
        }

        if (!lineJustStarted && !splitByGrapheme) {
            line.push(infix);
        }
        line = line.concat(word);

        infixWidth = splitByGrapheme ? 0 : this._measureWord([infix], lineIndex, offset);
        offset++;
        lineJustStarted = false;
        // keep track of largest word
        if (wordWidth > largestWordWidth) {
            largestWordWidth = wordWidth;
        }
    }

    i && graphemeLines.push(line);

    if (largestWordWidth + reservedSpace > this.dynamicMinWidth) {
        this.dynamicMinWidth = largestWordWidth - additionalSpace + reservedSpace;
    }
    return graphemeLines;
}


fabric.util.object.extend(fabric.Textbox.prototype, {
    _wrapLine: _wrapLine,
});

const [templateData, setTemplateData] = useState()
const [templateTotalData, setTemplateTotalData] = useState()

const loadTemplate = (json, id) => {


    if (translatedImageid != null && workspaceSwitch !== 'Workspace') {
        editor.canvas.isDrawingMode = false
        setTranslatedImageid(null)
        editor.canvas.clear()
        notify()
        setManualChecked(false)
        navigate('/ailaysa-canvas')
    }
    // console.log(json)
    // if (workspaceSwitch === 'Workspace') {
    //     editor.canvas.clear()
    //     navigate(`/ailaysa-canvas/template/?id=${id}`, { state: json })


    // }

    editor?.canvas.loadFromJSON(json, function () {
        editor?.canvas.renderAll.bind(editor?.canvas.requestRenderAll());
    })
    var size = { name: 'loadcanvas', width: json.backgroundImage.width, height: json.backgroundImage.height, fill: json.backgroundImage.fill }
    handlesize(size)
    // editor.canvas.backgroundImage = new fabric.Rect({ width: json.backgroundImage.width, height: json.backgroundImage.height, fill: json.backgroundImage.fill });
    setCanvasBgColor(json.backgroundImage.fill)
    setTimeout(() => {
        if(designid == null){
            handleSave()
        }
        document.querySelector('.canvas-space').classList.remove('loading-json-blur');
        document.querySelector('#loading-wrapper').style.setProperty('display', 'none');
       
    }, 500);

}

function loadFabricJSONDataTemplate(json, id) {
    // Get all font family names from the JSON data, including nested groups
    setShowTemplateNotice(false)

    setCanvasBgColor(json.backgroundImage.fill)
    const fontFamilies = [];

    function extractFontFamilies(obj) {
        if (obj.fontFamily && !fontFamilies.includes(obj.fontFamily)) {
            fontFamilies.push(obj.fontFamily);
        }
        if (obj.type === 'group' && obj.objects) {
            obj.objects.forEach((groupObj) => extractFontFamilies(groupObj));
        }
    }
    json.objects.forEach((obj) => extractFontFamilies(obj));

    // Remove duplicate font families
    const uniqueFontFamilies = [...new Set(fontFamilies)];
    console.log(uniqueFontFamilies)
    // Check if all font families are loaded in the DOM
    uniqueFontFamilies.forEach((fontFamily) => {
        var link = document.createElement('link')
        link.href = `https://fonts.googleapis.com/css?family=${fontFamily}`
        link.rel = 'stylesheet'
        link.setAttribute('name', fontFamily)
        document.querySelector('head').appendChild(link)
    });
    if (uniqueFontFamilies.length == 0) {
        // document.querySelector('.canvas-space').classList.remove('loading-json-blur');
        // document.querySelector('#loading-wrapper').style.setProperty('display', 'none');
        loadTemplate(json, id)
    }
    // If all font families are loaded, load the JSON data in the canvas
    uniqueFontFamilies.forEach((obj, index) => {
        var font = new FontFaceObserver(obj);
        font.load().then(function () {
            console.log('Font is available');
            if (index == (uniqueFontFamilies.length - 1)) {
                setTimeout(() => {
                    loadTemplate(json, id)
                }, 500);
            }
        }, function () {
            console.log('Font is not available');
            font.load().then(function () {
                console.log('Font is available');
                setTimeout(() => {
                    loadTemplate(json, id)
                }, 500);
            }, function () {
                console.log('Font is not available');
            });
        });
    })
}


const replaceCurrentPage = () => {
    loadFabricJSONDataTemplate(templateData)
}

const closeTemplateModal = () => {
    document.querySelector('.canvas-space').classList.remove('loading-json-blur');
    document.querySelector('#loading-wrapper').style.setProperty('display', 'none');
    setShowTemplateNotice(false)
}

// const addPage = (templateData,thumbnail,exportFile) => {

//     (async function () {
//         var formdata = new FormData();
//         formdata.append("source_json_file", JSON.stringify(templateData));
//         formdata.append("thumbnail_src", thumbnail)
//         formdata.append("export_img_src", exportFile);
//         formdata.append("src_page", pageData.length + 1)



//         var requestOptions = {
//             method: 'PUT',
//             headers: handleAuthTrue(new Headers()),
//             body: formdata,
//             redirect: 'follow'
//         };

//         let data = await fetch(LABS_API + "/canvas/canvas-designs/" + designid + "/", requestOptions)
//         if (data.status === 200) {
//             let response = await data.json()
//             setPageData(response.source_json)
//             setPageNo(response.source_json[response.source_json.length - 1].page_no)
//             setCanvasBgColor('#fff')
//             editor.canvas.clear()



//         }
//         else {

//             console.log('error');
//         }

//     })();

// }
const createTemplateAsNewPage = () => {

    // addPage(templateData,thumbnail,exportFile)
    // console.log(templateTotalData.id)
    (async function () {
        closeTemplateModal()
        var formdata = new FormData();

        if (templateFrom == 'global') {
            formdata.append("temp_global_design", templateTotalData.id);
        } else {
            formdata.append("my_temp", templateTotalData.id);
        }

        var requestOptions = {
            method: 'PUT',
            headers: handleAuthTrue(new Headers()),
            body: formdata,
            redirect: 'follow'
        };

        let data = await fetch(LABS_API + "/canvas/canvas-designs/" + designid + "/", requestOptions)
        if (data.status === 200) {
            closeTemplateModal()
            let response = await data.json()
            console.log(response)
            setPageData(response.source_json)
        }
        else {
            console.log('error');
        }
    })();

}



return (
    <div >
        <Tabs selectedIndex={tabIndex}
            onSelect={(index) => {
                var temp = tabIndex
                if (temp == index) {
                    checksametab.current = true
                }
                else {
                    checksametab.current = false
                }
                setTabIndex(index)
                if (tabIndex != 9) {
                    setManualChecked(false)

                }
            }

            }
        >
            <TabList >


                    {/* <Tab onClick={(e) => { checksametab.current == false && getMyDesign(); getMyTranslatedImageproject() }} > */}
                    <Tab>
                        <div className="side-bar-item">
                            <img className="my-wrok-icon" alt="my-work" src={myWork}></img>
                            <span className="side-bar-heading" >My works</span>
                        </div>
                    </Tab>
                    {workspaceSwitch !== "Workspace" &&
                        <Tab style={{ display: 'none' }}>
                            <div className="side-bar-item">
                                <img className="my-wrok-icon" src={template} alt="my-template"></img>
                                <span className="side-bar-heading">Template</span>
                            </div>
                        </Tab>}
                    {/* <Tab onClick={() => { (globalTemplate.length == 0 && checksametab.current == false) && getGlobalTemplate(1) }}> */}
                    <Tab >
                        <div className="side-bar-item">
                            <img className="my-wrok-icon" src={template} alt="my-template"></img>
                            <span className="side-bar-heading">Template</span>
                        </div>
                    </Tab>
                {/* <Tab onClick={() => { (globalTemplate.length == 0 && checksametab.current == false) && getGlobalTemplate() }}>
                    <div className="side-bar-item">
                        <img className="my-wrok-icon" src={template} alt="my-template"></img>
                        <span className="side-bar-heading">Template</span>
                    </div>
                </Tab> */}
                <Tab onClick={() => { checksametab.current == false && textTemplate() }}>
                    <div className="side-bar-item">
                        <img className="my-wrok-icon" src={text} alt="text"></img>
                        <span className="side-bar-heading">Text</span>
                    </div>
                </Tab>
                <Tab onClick={() => { }}>
                    <div className="side-bar-item">
                        <img className="my-wrok-icon-ai-icon" src={aiTab} alt="text"></img>
                        <span className="side-bar-heading">Ai</span>
                    </div>
                </Tab>
                <Tab>
                    <div className="side-bar-item" >
                        <img className="my-wrok-icon" src={photos} alt="photos"></img>
                        <span className="side-bar-heading">Photos</span>
                    </div>
                </Tab>
                {/* <Tab onClick={(e) => elementsImages === undefined && checksametab.current == false && fectchVector(e)}> */}
                <Tab>
                    <div className="side-bar-item" >
                        <img className="my-wrok-icon" src={elements} alt="elements"></img>
                        <span className="side-bar-heading">Graphics</span>
                    </div>
                </Tab>
                {/* <Tab onClick={(e) => bgimages === undefined && checksametab.current == false && fectchBackground(e)}> */}
                <Tab >
                    <div className="side-bar-item" >
                        <img className="my-wrok-icon" src={background} alt="background"></img>
                        <span className="side-bar-heading">Background</span>
                    </div>
                </Tab>
                <Tab onClick={() => { checksametab.current == false && getMyUpload() }}>
                    <div className="side-bar-item" >
                        <img src={uploads} alt="uploads" ></img>
                        <span className="side-bar-heading">Upload</span>
                    </div>
                </Tab>
                {/* {workspaceSwitch !== "Workspace" && */}
                <Tab onClick={getMyUploadImageTranslate} >
                    <div className="side-bar-item" >
                        <img className="my-wrok-icon" src={keyword} alt="image-translate"></img>
                        <span className="side-bar-heading">Image translate</span>

                    </div>

                </Tab>
                {/* } */}

                <Tab style={{ display: "none" }}>
                    <div className="side-bar-item" >
                        <span className="side-bar-heading">Resize</span>
                    </div>
                </Tab>
                <Tab style={{ display: "none" }}>
                    <div className="side-bar-item" >
                        <span className="side-bar-heading">FontFamily</span>
                    </div>
                </Tab>
                <Tab style={{ display: 'none' }}>
                    <div className="side-bar-item" >
                        <img src={speechtotextsidebaricon}></img>
                        <span className="side-bar-heading">Speech</span>
                    </div>
                </Tab>
            </TabList>

            <TabPanel >
                <div className="panel-content">
                    <MyDesign
                        handleSidebarClose={handleSidebarClose}
                        handleAuthTrue={handleAuthTrue}
                        LABS_API={LABS_API}
                        // design={design}
                        setTranslatedimage={setTranslatedimage}
                        editor={editor}
                        fabric={fabric}
                        setCanvaswidth={setCanvaswidth}
                        setCanvasheight={setCanvasheight}
                        getMyDesign={getMyDesign}
                        setCanvasBgColor={setCanvasBgColor}
                        designid={designid}
                        setDesignid={setDesignid}
                        setName={setName}
                        setAutoSaved={setAutoSaved}
                        autoSaved={autoSaved}
                        setDesigntemp={setDesigntemp}
                        designtemp={designtemp}
                        setPageData={setPageData}
                        getMyTranslatedImageproject={getMyTranslatedImageproject}
                        translateImage={translateImage}
                        translatedImageid={translatedImageid}
                        setTranslatedImageid={setTranslatedImageid}
                        setTranslateimagetemp={setTranslateimagetemp}
                        translateimagetemp={translateimagetemp}
                        deleteTranslatedimage={deleteTranslatedimage}
                        setManualChecked={setManualChecked}
                        setCropImageSource={setCropImageSource}
                        handlesize={handlesize}
                        getMyDesignbyId={getMyDesignbyId}
                        canvasheight={canvasheight}
                        canvaswidth={canvaswidth}
                        setCanvasmodified={setCanvasmodified}
                        getProjectData={getProjectData}
                        getImageTranslateProjectData={getImageTranslateProjectData}
                        workspaceSwitch={workspaceSwitch}
                        langLabel={langLabel}
                        setShowTemplateNotice={setShowTemplateNotice}
                        setTemplateData={setTemplateData}
                        setTemplateTotalData={setTemplateTotalData}
                        settemplateFrom={settemplateFrom}
                        loadFabricJSONDataTemplate={loadFabricJSONDataTemplate}
                        handleSave={handleSave}
                    />
                    <div className="close-toggle-sidebar" onClick={handleSidebarClose}>
                        <SideBarToggle className="close" />
                    </div>
                </div>
            </TabPanel>
            {workspaceSwitch !== "Workspace" &&

                <TabPanel>
                    <div className="panel-content">
                        <MyTemplate handleSidebarClose={handleSidebarClose} handleAuthTrue={handleAuthTrue} LABS_API={LABS_API} />
                        <div className="close-toggle-sidebar" onClick={handleSidebarClose}>
                            <SideBarToggle className="close" />
                        </div>
                    </div>
                </TabPanel>}
            <TabPanel>
                <div className="panel-content">
                    <Template
                        handleSidebarClose={handleSidebarClose}
                        handleAuthTrue={handleAuthTrue}
                        LABS_API={LABS_API}
                        canvasheight={canvasheight}
                        canvaswidth={canvaswidth}
                        editor={editor}
                        name={name}
                        globalTemplate={globalTemplate}
                        getGlobalTemplate={getGlobalTemplate}
                        handlesize={handlesize}
                        fabric={fabric}
                        setCanvasBgColor={setCanvasBgColor}
                        setManualChecked={setManualChecked}
                        navigate={navigate}
                        setTranslatedImageid={setTranslatedImageid}
                        notify={notify}
                        translatedImageid={translatedImageid}
                        workspaceSwitch={workspaceSwitch}
                        setAutoSaved={setAutoSaved}
                        setName={setName}
                        setDesignid={setDesignid}
                        setDesigntemp={setDesigntemp}
                        setCanvasmodified={setCanvasmodified}
                        setShowTemplateNotice={setShowTemplateNotice}
                        loadTemplate={loadTemplate}
                        loadFabricJSONDataTemplate={loadFabricJSONDataTemplate}
                        setTemplateData={setTemplateData}
                        setTemplateTotalData={setTemplateTotalData}
                        settemplateFrom={settemplateFrom}
                        designid={designid}
                        handleSave={handleSave}
                    />
                    <div className="close-toggle-sidebar" onClick={handleSidebarClose}>
                        <SideBarToggle className="close" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <div className="panel-content">
                    <Text
                        handleSidebarClose={handleSidebarClose}
                        handleAuthTrue={handleAuthTrue}
                        LABS_API={LABS_API}
                        getJsonData={getJsonData}
                        editor={editor}
                        fabric={fabric}
                        scaleCanvas={scaleCanvas}
                        generateUID={generateUID}
                        makeid={makeid}
                        texttemp={texttemp}
                        texttempfirsthalf={texttempfirsthalf}
                        texttempSecondhalf={texttempSecondhalf}
                        texttemploading={texttemploading}
                        textTemplate={textTemplate}
                        designid={designid}
                        translatedImageid={translatedImageid}
                        setTranslatedImageid={setTranslatedImageid}
                        notify={notify}
                        scaleMultiplierX={scaleMultiplierX}
                        canvasheight={canvasheight}
                        canvaswidth={canvaswidth}
                        setManualChecked={setManualChecked}
                        setCanvasBgColor={setCanvasBgColor}
                        canvasBgColor={canvasBgColor}
                        workspaceSwitch={workspaceSwitch}
                        save={save}
                        handlesize={handlesize} />
                </div>
                <div className="close-toggle-sidebar" onClick={handleSidebarClose}>
                    <SideBarToggle className="close" />
                </div>
            </TabPanel>
            <TabPanel>
                <div className="panel-content">
                    <AiGeneration
                        onAddImageAi={onAddImageAi}
                        onAddImage={onAddImage}
                        handleSidebarClose={handleSidebarClose}
                    />
                    <div className="close-toggle-sidebar" onClick={handleSidebarClose}>
                        <SideBarToggle className="close" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <div className="panel-content">
                    <Photos
                        handleSidebarClose={handleSidebarClose}
                        fectchPhotos={fectchPhotos}
                        setPhotosearchword={setPhotosearchword}
                        images={images}
                        imagesSecondary={imagesSecondary}
                        onAddImage={onAddImage}
                        searchingimage={searchingimage}
                        onDragAddImage={onDragAddImage}
                        debounce={debounce}

                    />
                    <div className="close-toggle-sidebar" onClick={handleSidebarClose}>
                        <SideBarToggle className="close" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <div className="panel-content">
                    <Graphics
                        handleSidebarClose={handleSidebarClose}
                        fectchVector={fectchVector}
                        elementsearchword={elementsearchword}
                        setElementsearchword={setElementsearchword}
                        elementsImages={elementsImages}
                        elementsImagesSecondary={elementsImagesSecondary}
                        onAddImage={onAddImage}
                        fabric={fabric}
                        editor={editor}
                        debounce={debounce}
                        generateUID={generateUID}
                        makeid={makeid}
                    />
                    <div className="close-toggle-sidebar" onClick={handleSidebarClose}>
                        <SideBarToggle className="close" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel >
                <div className="panel-content">
                    <Background
                        handleSidebarClose={handleSidebarClose}
                        fectchBackground={fectchBackground}
                        setBackgroundsearchword={setBackgroundsearchword}
                        backgroundsearchword={backgroundsearchword}
                        bgimages={bgimages}
                        addBackground={addBackground}
                        setCanvasBgColor={setCanvasBgColor}
                        canvasBgColor={canvasBgColor}
                        editor={editor}
                        bgimagesSecondary={bgimagesSecondary}
                        setBackgroundEdit={setBackgroundEdit}
                        canvasheight={canvasheight}
                        canvaswidth={canvaswidth}
                        fabric={fabric}
                        debounce={debounce}
                    />
                    <div className="close-toggle-sidebar" onClick={handleSidebarClose}>
                        <SideBarToggle className="close" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel >
                <div className="panel-content">
                    <Upload
                        handleSidebarClose={handleSidebarClose}
                        handleAuthTrue={handleAuthTrue}
                        LABS_API={LABS_API}
                        getMyUpload={getMyUpload}
                        uploadimg={uploadimg}
                        onAddImage={onAddImage} />
                    <div className="close-toggle-sidebar" onClick={handleSidebarClose}>
                        <SideBarToggle className="close" />
                    </div>
                </div>
            </TabPanel>
            {/* {workspaceSwitch !== "Workspace" && */}
            <TabPanel >
                <div className="panel-content">
                    <Inpaint
                        handlesize={handlesize}
                        handleSidebarClose={handleSidebarClose}
                        handleAuthTrue={handleAuthTrue}
                        LABS_API={LABS_API}
                        getMyUploadImageTranslate={getMyUploadImageTranslate}
                        uploadimgForImageTranslate={uploadimgForImageTranslate}
                        setCanvasheight={setCanvasheight}
                        setCanvaswidth={setCanvaswidth}
                        onAddImage={onAddImage}
                        editor={editor}
                        fabric={fabric}
                        setCropImageSource={setCropImageSource}
                        setAutoChecked={setAutoChecked}
                        autoChecked={autoChecked}
                        manualChecked={manualChecked}
                        setManualChecked={setManualChecked}
                        translatedImageid={translatedImageid}
                        setTranslatedImageid={setTranslatedImageid}
                        setDesignid={setDesignid}
                        name={name}
                        setImageTransleSource={setImageTransleSource}
                        setselected={setselected}
                        selected={selected}
                        updateDesign={updateDesign}
                        setPageData={setPageData}
                        designid={designid}
                        setAutoSaved={setAutoSaved}
                        setName={setName}
                        setDesigntemp={setDesigntemp}
                        setCanvasmodified={setCanvasmodified}
                        setCanvasBgColor={setCanvasBgColor}
                        imageTransleSource={imageTransleSource}
                        shadow={shadow}
                        workspaceSwitch={workspaceSwitch}
                    />
                    <div className="close-toggle-sidebar" onClick={handleSidebarClose}>
                        <SideBarToggle className="close" />
                    </div>
                </div>
            </TabPanel>
            {/* } */}
            <TabPanel >
                <div className="panel-content-resize">
                    <Resize
                        handleSidebarClose={handleSidebarClose}
                        setCanvaswidth={setCanvaswidth}
                        setCanvasheight={setCanvasheight}
                        canvaswidth={canvaswidth}
                        canvasheight={canvasheight}
                        handlesize={handlesize}
                        canvasBgColor={canvasBgColor}
                        handleAuthTrue={handleAuthTrue}
                        LABS_API={LABS_API}
                    />
                    <div className="close-toggle-sidebar" onClick={handleSidebarClose}>
                        <SideBarToggle className="close" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel >
                <div className="panel-content-resize">
                    <FontFamily
                        handleSidebarClose={handleSidebarClose}
                        editor={editor}
                        fabric={fabric}
                        setActiveFontFamilyShow={setActiveFontFamilyShow}
                        LABS_API={LABS_API}
                        handleAuthTrue={handleAuthTrue}
                    />
                    <div className="close-toggle-sidebar" onClick={handleSidebarClose}>
                        <SideBarToggle className="close" />
                    </div>
                </div>
            </TabPanel>
        </Tabs>
        <Rodal visible={showTemplateNotice} {...modaloption} onClose={() => {
            closeTemplateModal()
        }} showCloseButton={true} className="">
            <div>
                <span className='modal-lang-wrap-head'>Do you want to?</span>
                <div className="language-select-button-wrap">
                    <button className="canvas-button" onClick={(e) => {
                        replaceCurrentPage()
                    }}>
                        <span>Replace</span>
                    </button>
                    <button className="create-multi-canvas-button" onClick={(e) => {
                        //    creatMultilingualContent()
                        createTemplateAsNewPage()
                    }
                    }>
                        <span>Add as new page</span>
                    </button>

                </div>
            </div>
        </Rodal>
    </div>

)


}

export default Sidebar;