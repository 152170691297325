import React, { useState, useEffect, useRef } from "react";
import TranslateIcon from '@mui/icons-material/Translate';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Rodal from 'rodal';
import Sourcelanguage from './language-modal/Sourcelanguage'
import Targetlanguage from './language-modal/Targetlanguage'
import Config from "../../config/Config";
import "rodal/lib/rodal.css";
import { useNavigate } from "react-router-dom";




const Translate = (props) => {

    const {
        workspaceSwitch,
        editor,
        LABS_API,
        handleAuthTrue,
        name,
        tabIndex,
        designid,
        translatedImageid,
        setCanvasBgColor,
        selected,
        imageTransleSource,
        autoChecked,
        canvaswidth,
        canvasheight,
        fabric,
        setshowTarLangModal,
        showTarLangModal,
        targetLangList,
        setTargetLanguage,
        targetLanguage,
        setTargetLangList,
        setLoading,
        loading,
        setTargetLangListInitial,
        setTranslateLanguages
    } = props;

    const navigate = useNavigate();

    const [showSrcLangModal, setshowSrcLangModal] = useState(false);
    const [sourceLabel, setSourceLabel] = useState("Source language");
    const [languageSelectionModal, setLanguageSelectionModal] = useState(false);
    const [sourceLanguageDisable, setSourceLanguageDisable] = useState(false);
    const [showSettings, setshowSettings] = useState(false);
    const [onFocusWrap, setOnFocusWrap] = useState(false)
    const [searchInput, setSearchInput] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const [sourceLanguageOptions, setSourceLanguageOptions] = useState(null);
    const [sourceLanguage, setSourceLanguage] = useState("");
    const [targetLanguageOptions, setTargetLanguageOptions] = useState(null);
    const [editProjectId, setEditProjectId] = useState(null);
    const deletedJobIds = useRef([]);
    const [editJobs, setEditJobs] = useState([]);

    const searchAreaRef = useRef(null);
    const [maskdata , setMaskData] = useState(null)
    useEffect(() => {

        (async function () {

            var requestOptions = {
                method: 'GET',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/languages", requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                setSourceLanguageOptions(response);
                setTargetLanguageOptions(response);
             
                let lang = []
                response.forEach((each) => {
                    lang.push({
                        label: each.language,
                        value: each.id
                       
                    })
                })
                
                // console.log(lang)
                setTranslateLanguages(lang)
            }
            else {

                console.log('error');
            }
        })();

    }, [])
   
    // if(workspaceSwitch == 'Workspace'){
    //     setTargetLanguage(targetLangList)
    // }
    // console.log(targetLangList)
    
    const handleTargetLangClick = (value, e) => {
        let targetLanguageTemp = targetLanguage != "" ? targetLanguage : [];
        if (e.target.classList.contains("selected")) {
            e.target.classList.remove("selected");
            targetLanguageTemp = Config.removeItemFromArray(targetLanguageTemp, value);
            if (editProjectId != null) {
                let thisJob = editJobs.find((element) => element.target_language == value);
                if (thisJob?.id != null) deletedJobIds.current.push(thisJob?.id);
            }
        } else {
            e.target.classList.add("selected");
            targetLanguageTemp.push(value);
        }
        // console.log(targetLanguageTemp)
        setTargetLangList(targetLanguageTemp)
        setTargetLanguage(targetLanguageTemp);
    };


    /* Handling source language selection */
    const handleSourceLangClick = (value, name, e) => {
        setSourceLanguage(value);
        setshowSrcLangModal(false);
        setSourceLabel(name);
    };



    const updateDesignName = () => {

        (async function () {
            var formdata = new FormData();
            formdata.append("file_name", name)

            var requestOptions = {
                method: 'PUT',
                headers: handleAuthTrue(new Headers()),
                body: formdata,
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/canvas-designs/" + designid + "/", requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                openModal()
            }
            else {

                console.log('error');
            }

        })();


    }

    const openModal = () => {
        setLanguageSelectionModal(true)
    }

    const closeModal = () => {
        setLanguageSelectionModal(false)
    }

    const hideSettingsModal = () => setshowSettings(false);


    const modaloption = {
        closeMaskOnClick: false,
        width: 600,
        height: 315,
        onClose: hideSettingsModal,
        animation: "fade",
        duration: 0
    };

    const sendData = () => {
        (async function () {
          var formdata = new FormData();

          targetLanguage.map(option => {
            formdata.append("canvas_translation_tar_lang", option.id);

          })


          formdata.append("src_lang", sourceLanguage);
          var requestOptions = {
            method: 'PUT',
            headers: handleAuthTrue(new Headers()),
            body: formdata,
            redirect: 'follow'
          };
  
          let data = await fetch(LABS_API + "/canvas/canvas-designs/" + designid + "/", requestOptions)

          if (data.status === 200) {
            let response = await data.json()    
            setLoading(false)
            navigate(`/ailaysa-canvas-workspace/design/?project=${designid}`,{ state : { id: designid, type : "design" }})

          }
          else{

            console.log('error');
          }
        })();
   }





   const ImageTranslate = () => {

    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
    (async function () {
        const tempdata = JSON.stringify(editor.canvas.toJSON(['id','projectid', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable','brushtype','brushtype2','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name','oldWidth','oldHeight','oldScaleX','oldScaleY']));
        var mask = dataURLtoFile(maskdata, 'mask')
            var formdata = new FormData();
              formdata.append("mask_json", tempdata);
              formdata.append("mask", mask);
              formdata.append("project_name", name);
              formdata.append("image_to_translate_id", translatedImageid);
              targetLanguage.map(option => {
                formdata.append("inpaint_creation_target_lang", option.id);
    
              })
              formdata.append("source_language", sourceLanguage);

            var requestOptions = {
                method: 'PUT',
                body: formdata,
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };
            let data = await fetch(LABS_API + "/image/imageupload/" + translatedImageid + "/", requestOptions)
            if (data.status === 200) {
            let response = await data.json()
               navigate(`/ailaysa-canvas-workspace/image-translate/?project=${translatedImageid}`,{ state : { id: translatedImageid, type : "image-translate" }})
               setLoading(false)
            }
        })();
   }


   
   const getJsonData = () => {

    setCanvasBgColor('#000000')
    // let clipPath = new fabric.Rect({ width: canvaswidth, height: canvasheight,fill: 'rgba(0,0,0,1)'});
    editor.canvas.backgroundImage = ''
    editor.canvas.setBackgroundColor('#000000')
    editor.canvas.remove(editor.canvas.getObjects('image')[0])
    editor?.canvas.getObjects('path').forEach(e => {
        if(e.globalCompositeOperation == 'source-over'){
            e.set({stroke:'#fff'})
            e.set({ fill : '#FFF'})
        }
        else{
            e.globalCompositeOperation = 'source-over'
            e.set({stroke:'#000'})
        }
    });
    editor.canvas.backgroundImage =""
    editor.canvas.renderAll()

    var temp = editor.canvas.viewportTransform
    editor.canvas.renderAll()
    editor.canvas.viewportTransform = [1,0,0,1,0,0]
        setTimeout(() => {
           
            const base64 = editor.canvas.toDataURL({
                format: 'png',
                enableRetinaScaling: true,
                width: canvaswidth,
                height: canvasheight
            });
            setMaskData(base64)
            editor.canvas.viewportTransform = temp
            editor.canvas.renderAll()
        }, 2000);
       
}


useEffect(() => {

    if(translatedImageid != null){
        if(maskdata != null) {

            ImageTranslate()
        }
    }

},[maskdata])




const ImageTranslateAuto = () => {
  if(translatedImageid == null){
    (async function () {
        var formdata = new FormData();

  
            formdata.append("image_id", imageTransleSource);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: handleAuthTrue(new Headers()),
            redirect: 'follow'
        };
        let data = await fetch(LABS_API + "/image/imageupload/" , requestOptions)
        if (data.status === 200) {
        let response = await data.json()
           let Id = []
       
           autoTranslateSecondUpdate(response[0].id)
        }
    })();
  }
   
   }


   const autoTranslateSecondUpdate =(Id) =>{
    (async function () {
            var formdata = new FormData();

            formdata.append("project_name", name);
              formdata.append("image_to_translate_id", Id);

              targetLanguage.map(option => {
                formdata.append("inpaint_creation_target_lang", option.id);
    
              })
              formdata.append("source_language", sourceLanguage);

    
           
    
            var requestOptions = {
                method: 'PUT',
                body: formdata,
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };
            let data = await fetch(LABS_API + "/image/imageupload/"+ Id + "/" , requestOptions)
            if (data.status === 200) {
            let response = await data.json()
            navigate(`/ailaysa-canvas-workspace/image-translate-auto/?project=${Id}`,{ state : { id: Id, type : "image-translate-auto" }})
               setLoading(false)
            }
        })();
   }


const creatMultilingualContent = () => {

    if(designid != null && translatedImageid == null){
        sendData()
    setLoading(true)

    }
    if(translatedImageid != null && designid === null){
        getJsonData()
    setLoading(true)


    }

    if(designid === null && translatedImageid == null){
        ImageTranslateAuto()
        setLoading(true)

    }

}

    return (
        <React.Fragment>
            <React.Fragment>
                    {workspaceSwitch !== "Workspace" &&
                     <button 
                     onClick={() => {
                         openModal()
                         
                     }} className="translate" 
                     disabled={(translatedImageid === null && designid === null && autoChecked && imageTransleSource == null) ? true : false} style={{ opacity: (translatedImageid === null && designid === null && autoChecked && imageTransleSource == null)   ? 0.5 : 1, pointerEvents : (translatedImageid === null && designid === null && autoChecked && imageTransleSource == null) ? 'none' : 'auto' }}
                     >
                     <TranslateIcon className="translate-icon" />
                     <span className="translate-text">Translate</span>
                     </button>

                    }
                <Rodal
                  
                    visible={languageSelectionModal} {...modaloption} showCloseButton={false}
                >
                    <div className="modal-lang-wrap-head">
                        Translate Canvas
                    </div>
                    <div className="language-modal-wrapper files-space-align">
                        <div className="form-fields">
                            <div className="form-group">
                                <div
                                    className={
                                        sourceLanguageDisable
                                            ? "ai-lang-select disabled"
                                            : "ai-lang-select"
                                    }
                                    onClick={
                                        sourceLanguageDisable
                                            ? () => setshowSrcLangModal(false)
                                            : () => {
                                                setSearchInput('')
                                                setshowSrcLangModal(true)
                                            }
                                    }
                                >
                                    {sourceLabel === "Source language" ? (
                                        <span className="placeholder">{sourceLabel}</span>
                                    ) : (
                                        <span>{sourceLabel}</span>
                                    )}
                                    <ArrowDropDownIcon className="down-arrow-icon" />
                                </div>
                            </div>
                        </div>
                        <div className="form-fields">
                            <div className="form-group">
                                <div
                                    className="ai-lang-select"
                                    onClick={() => {
                                        setSearchInput('')
                                        setshowTarLangModal(true);
                                    }}
                                >
                                    {targetLanguage == "" ? (
                                        <span className="placeholder">
                                            Target language(s)
                                        </span>
                                    ) : (
                                        <span>
                                            {targetLanguage.length +
                                                " language" +
                                                (targetLanguage.length > 1 ? "s" : "") +
                                                " selected"}
                                        </span>
                                    )}
                                    <ArrowDropDownIcon className="down-arrow-icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="language-select-button-wrap">
                    <button className="canvas-button" onClick={() => {
                            closeModal()
                            }}>
                            <span>Cancel</span>
                            </button>
                        <button className="create-multi-canvas-button" onClick={() => {
                           creatMultilingualContent()
                            
                        }
                        }>
                            <span>Create Multilingual Canvas</span>
                        </button>
                        
                    </div>
                </Rodal>
                    <Rodal visible={showSrcLangModal} {...modaloption} onClose={() =>setshowSrcLangModal(false)} showCloseButton={true} className="ai-lang-select-modal">
                    <Sourcelanguage
                        sourceLanguage={sourceLanguage}
                        setshowSrcLangModal={setshowSrcLangModal}
                        sourceLanguageOptions={sourceLanguageOptions}
                        handleSourceLangClick={handleSourceLangClick}
                        filteredResults={filteredResults} 
                        setFilteredResults={setFilteredResults}
                        searchInput={searchInput} 
                        setSearchInput={setSearchInput}
                        onFocusWrap={onFocusWrap} 
                        setOnFocusWrap={setOnFocusWrap}
                        searchAreaRef={searchAreaRef}
                    />
                </Rodal>
                <Rodal visible={showTarLangModal} {...modaloption}  onClose={() => setshowTarLangModal(false)} showCloseButton={true} className="ai-tar-lang-select-modal">
                    <Targetlanguage
                        targetLanguage={targetLanguage}
                        targetLanguageOptions={targetLanguageOptions}
                        handleTargetLangClick={handleTargetLangClick}
                        setshowTarLangModal={setshowTarLangModal}
                        filteredResults={filteredResults} 
                        setFilteredResults={setFilteredResults}
                        searchInput={searchInput} 
                        setSearchInput={setSearchInput}
                        onFocusWrap={onFocusWrap} 
                        setOnFocusWrap={setOnFocusWrap}
                        searchAreaRef={searchAreaRef}
                    />
                    </Rodal>
                   
            </React.Fragment>
        </React.Fragment>
    );
};

export default Translate;
