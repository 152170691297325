import Draggable from 'react-draggable';
import React, { useEffect, useState, useRef } from "react";
import { Popover } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import vintage from '../../assets/photos/vintage.png'
import poloriod from '../../assets/photos/poloriod.png'
import technicolor from '../../assets/photos/technicolor.png'
import kodachrome from '../../assets/photos/kodachrome.png'
import brownie from '../../assets/photos/brownie.png'
import blackandwhite from '../../assets/photos/blackandwhite.png'
import sepia from '../../assets/photos/sepia.png'
import greyscale from '../../assets/photos/greyscale.png'
import normal from '../../assets/photos/normal.jpg'
import { DragIndicator } from '@mui/icons-material';
import StrokeStyle from './Borderstyle'
import StyleIcon from '@mui/icons-material/Style';
import TuneIcon from '@mui/icons-material/Tune';
import Crop from './Crop';
import Tooltip from '@mui/material/Tooltip';
import CropIcon from '@mui/icons-material/Crop';


const ImageToolbar = (props) => {

    const {
        editor,
        fabric,
        setAsbackgroundImage,
        setCropImageSource,
        handleConfirmBackGround,
        selectedobjectforsvg,
        totalimage,
        totalobject,
        totaltext,
        cropImage,
        callcrop
    } = props;


    //draggable
    const [state, setState] = useState({
        activeDrags: 0,
        deltaPosition: {
            x: 0, y: 0
        },
        controlledPosition: {
            x: -400, y: 200
        }
    });

    const onStart = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags + 1 });
    };
    const onStop = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags - 1 });
    };

    const dragHandlers = { onStart, onStop };

  
    //Image Filter

    const Filter = ({ dragHandlers,editor,fabric }) => {

        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const grayscaleFilter = () => {
            var filter = new fabric.Image.filters.Grayscale();
            editor.canvas.getActiveObject().filters.push(filter);
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();
           
        }

        const removeFilters = () => {

            editor.canvas.getActiveObject().filters.length = 7;
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();


        }

        const sepiaFilter = () => {

            var filter = new fabric.Image.filters.Sepia();
            editor.canvas.getActiveObject().filters.push(filter);
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();
        }

        const blackwhiteFilter = () => {

            var filter = new fabric.Image.filters.BlackWhite();
            editor.canvas.getActiveObject().filters.push(filter);
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();
        }

        const brownieFilter = () => {

            var filter = new fabric.Image.filters.Brownie();
            editor.canvas.getActiveObject().filters.push(filter);
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();
        }

        const vintageFilter = () => {

            var filter = new fabric.Image.filters.Vintage();
            editor.canvas.getActiveObject().filters.push(filter);
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();
        }

        const kodachromeFilter = () => {

            var filter = new fabric.Image.filters.Kodachrome();
            editor.canvas.getActiveObject().filters.push(filter);
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();
        }

        const technicolorFilter = () => {

            var filter = new fabric.Image.filters.Technicolor();
            editor.canvas.getActiveObject().filters.push(filter);
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();
        }

        const polaroidFilter = () => {

            var filter = new fabric.Image.filters.Polaroid();
            editor.canvas.getActiveObject().filters.push(filter);
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();
        }

        const handleCloseAndOpen = (event) => {
            if (anchorEl == null) {
                setAnchorEl(event.currentTarget);
            }
            else {
                setAnchorEl(null);

            }
        }
        


        return (
            <div>
                <Tooltip 
                 componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: '#2A2A2A',
                            '& .MuiTooltip-arrow': {
                                color: '#2A2A2A',
                            },
                        },
                    },
                }}
                title="Filters" placement="top" arrow>
                <StyleIcon className={anchorEl === null ? "image-toolbar-icons" :  "image-toolbar-icons-active"} onClick={handleCloseAndOpen}>
                    Filters
                </StyleIcon>
                </Tooltip>
                <Draggable handle="strong" {...dragHandlers}>

                    <Popover

                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className="filter-div-wrapper box no-cursor">
                            <div className='drag-box-container'>
                                <strong className="drag-me ">
                                    <DragIndicator />
                                </strong>
                                <CloseIcon className="close-icon" onClick={handleClose} />

                            </div>
                            <div className="filter-div">
                                <div className="filter-gride-items" onClick={removeFilters}>
                                    <img className="image-filter-samp" src={normal} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">None</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={grayscaleFilter}>
                                    <img className="image-filter-samp" src={greyscale} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Greyscale</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={sepiaFilter}>
                                    <img className="image-filter-samp" src={sepia} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Sepia</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={blackwhiteFilter}>
                                    <img className="image-filter-samp" src={blackandwhite} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Black/White</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={brownieFilter}>
                                    <img className="image-filter-samp" src={brownie} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Brownie</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={vintageFilter}>
                                    <img className="image-filter-samp" src={vintage} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Vintage</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={kodachromeFilter}>
                                    <img className="image-filter-samp" src={kodachrome} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Kodachrome</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={technicolorFilter}>
                                    <img className="image-filter-samp" src={technicolor} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Technicolor</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={polaroidFilter}>
                                    <img className="image-filter-samp" src={poloriod} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Poloroid</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Popover>
                </Draggable>
            </div>

        )


    }


    const Adjust = ({ dragHandlers }) => {

        const [anchorEl, setAnchorEl] = React.useState(null);


        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const [brightness, setBrightness] = useState(0)
        const [contrast, setContrast] = useState(0)
        const [saturation, setSaturation] = useState(0)
        const [blur, setBlur] = useState(0)
        const [vibrance, setVibrance] = useState(0)
        const [noise, setNoise] = useState(0)
        const [hue, setHue] = useState(0)

        const [pixalate, setPixalate] = useState(0)


          useEffect(() => {
           
            if (editor) {
                if (editor?.canvas?.getActiveObject('image').length === 1) {
                    setBrightness(editor?.canvas?.getActiveObject()?.filters[0]?.brightness * 200)
                    setContrast(editor?.canvas?.getActiveObject()?.filters[1]?.contrast * 100)
                    setSaturation(editor?.canvas?.getActiveObject()?.filters[2]?.saturation * 100)
                    setBlur(editor?.canvas?.getActiveObject()?.filters[3]?.blur * 100)
                    setVibrance(editor?.canvas?.getActiveObject()?.filters[4]?.vibrance * 100)
                    setNoise(editor?.canvas?.getActiveObject()?.filters[5]?.noise / 10)
                    setHue(editor?.canvas?.getActiveObject()?.filters[6]?.rotation * 100)
                    editor.canvas.renderAll()

                }else{
                }
            }
        }, [editor])

     

        const handleBrightness = (e) => {
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type != 'group') {
                    i.filters[0].brightness = e.target.value / 200
                    i.applyFilters();
                }
                else {
                    i._objects.map((e) => {
                        e.filters[0].brightness = e.target.value / 200
                        e.applyFilters();
                    })
                }
            })
            editor.canvas.renderAll()
            setBrightness(e.target.value)

        }


      



        const handleContrast = (e) => {
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type != 'group') {
                    i.filters[1].contrast = e.target.value / 100
                    i.applyFilters();
                }
                else {
                    i._objects.map((e) => {
                        e.filters[1].contrast = e.target.value / 100
                        e.applyFilters();
                    })
                }
            })
            editor.canvas.renderAll()
            setContrast(e.target.value);

        }

    

        const handleSaturation = (e) => {
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type != 'group') {
                    i.filters[2].saturation = e.target.value / 100
                    i.applyFilters();
                }
                else {
                    i._objects.map((e) => {
                        e.filters[2].saturation = e.target.value / 100
                        e.applyFilters();
                    })
                }
            })
            editor.canvas.renderAll()
            setSaturation(e.target.value);

        }

     
        const handleBlur = (e) => {
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type != 'group') {
                    i.filters[3].blur = e.target.value / 100
                    i.applyFilters();
                }
                else {
                    i._objects.map((e) => {
                        e.filters[3].blur = e.target.value / 100
                        e.applyFilters();
                    })
                }
            })
            editor.canvas.renderAll()
            setBlur(e.target.value);

        }


        const handleVibrance = (e) => {
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type != 'group') {
                    i.filters[4].vibrance = e.target.value / 100
                    i.applyFilters();
                }
                else {
                    i._objects.map((e) => {
                        e.filters[4].vibarance = e.target.value / 100
                        e.applyFilters();
                    })
                }
            })
            editor.canvas.renderAll()
            setVibrance(e.target.value);

        }

     

        const handleNoise = (e) => {
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type != 'group') {
                    i.filters[5].noise = e.target.value * 10
                    i.applyFilters();
                }
                else {
                    i._objects.map((e) => {
                        e.filters[5].noise = e.target.value * 10
                        e.applyFilters();
                    })
                }
            })
            editor.canvas.renderAll()
            setNoise(e.target.value);

        }


        const handleHueRotation = (e) => {

            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type != 'group') {
                    i.filters[6].rotation = e.target.value / 100
                    i.applyFilters();
                }
                else {
                    i._objects.map((e) => {
                        e.filters[6].rotation = e.target.value / 100
                        e.applyFilters();
                    })
                }
            })
            editor.canvas.renderAll()
            setHue(e.target.value);

        }

        const handleCloseAndOpen = (event) => {
            if (anchorEl == null) {
                setAnchorEl(event.currentTarget);
            }
            else {
                setAnchorEl(null);

            }
        }

       


        return (

            <div>
                <Tooltip 
                 componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: '#2A2A2A',
                            '& .MuiTooltip-arrow': {
                                color: '#2A2A2A',
                            },
                        },
                    },
                }}
                title="Adjust" placement="top" arrow>
                <TuneIcon  className={anchorEl === null ? "image-toolbar-icons" :  "image-toolbar-icons-active"}  onClick={handleCloseAndOpen} >
                    Adjust
                </TuneIcon>
                </Tooltip>
                <Draggable handle="strong" {...dragHandlers}>
                    <Popover
                     
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >

                        <div className="adjust-div-wrapper box no-cursor">
                            <div className='drag-box-container'>
                                <strong className="drag-me ">
                                    <DragIndicator />
                                </strong>
                                <CloseIcon className="close-icon" onClick={handleClose} />

                            </div>
                            <div className="adjust-div">

                                <div>
                                    <label className="form-label adjust-headers">Brightness</label>
                                    <div className="adjust-range-container">
                                        <input type="range" className="form-range slider-range" value={brightness} min={-100} max={100} onChange={handleBrightness} id="customRange1" />
                                        <input type="text" className="input-value" value={brightness} min={-100} max={100} onChange={handleBrightness} id="customRange1" />
                                    </div>
                                    <label className="form-label adjust-headers">Contrast</label>
                                    <div className="adjust-range-container">
                                        <input type="range" className="form-range slider-range" value={contrast} min={-100} max={100} onChange={handleContrast} id="customRange1" />
                                        <input type="text" className="input-value"  value={contrast} min={-100} max={100} onChange={handleContrast} id="customRange1" />
                                    </div>
                                    <label className="form-label adjust-headers">Saturation</label>
                                    <div className="adjust-range-container">
                                        <input type="range" className="form-range slider-range" value={saturation} min={-100} max={100} onChange={handleSaturation} id="customRange1" />
                                        <input type="text" className="input-value" value={saturation} min={-100} max={100} onChange={handleSaturation} id="customRange1" />
                                    </div>
                                    <label className="form-label adjust-headers">Vibrance</label>
                                    <div className="adjust-range-container">
                                        <input type="range" className="form-range slider-range" value={vibrance} min={0} max={100} onChange={handleVibrance} id="customRange1" />
                                        <input type="text" className="input-value" value={vibrance} min={0} max={100} onChange={handleVibrance} id="customRange1" />
                                    </div>
                                    <label className="form-label adjust-headers">Blur</label>
                                    <div className="adjust-range-container">
                                        <input type="range" className="form-range slider-range" value={blur} min={0} max={100} onChange={handleBlur} id="customRange1" />
                                        <input type="text" className="input-value" value={blur} min={0} max={100} onChange={handleBlur} id="customRange1" />
                                    </div>
                                    <label className="form-label adjust-headers">Noise</label>
                                    <div className="adjust-range-container">
                                        <input type="range" className="form-range slider-range" value={noise} min={0} max={100} onChange={handleNoise} id="customRange1" />
                                        <input type="text" className="input-value" value={noise} min={0} max={100} onChange={handleNoise} id="customRange1" />
                                    </div>
                                    <label className="form-label adjust-headers">Hue</label>
                                    <div className="adjust-range-container">
                                        <input type="range" className="form-range slider-range" value={hue} min={-100} max={100} onChange={handleHueRotation} id="customRange1" />
                                        <input type="text" className="input-value" value={hue} min={-100} max={100} onChange={handleHueRotation} id="customRange1" />
                                    </div>
                                    {/* <label className="form-label adjust-headers">Pixalate</label>
                            <div className="d-flex align-items-center gap-2">
                                <input type="range" className="form-range slider-range" value={pixalate} min={1} max={10} onChange={handlePixalate} id="customRange1" />
                                <span className="input-value">{pixalate}</span>
                            </div> */}
                                </div>
                            </div>
                        </div>

                    </Popover>
                </Draggable>
            </div>

        )
    }



   


    return (
        <> 
       {!(editor.canvas.getActiveObject()?.picArea || editor.canvas.getActiveObject()?.custtype) && <div >
        {editor?.canvas?.getActiveObject()?.id !== 'background' &&
            <>
                <StrokeStyle
                    editor={editor}
                    fabric={fabric}
                    selectedobjectforsvg={selectedobjectforsvg}
                    totalimage={totalimage}
                    totalobject={totalobject}
                    totaltext={totaltext}
                />
                <div className='separator'></div>
            </>
        }
        {editor?.canvas.getObjects('rect').find(Imageobj => Imageobj.id2 === 'crop-box') === undefined &&
            <>
                <Filter
                    editor={editor}
                    fabric={fabric} />
                <Adjust
                    editor={editor}
                    fabric={fabric}
                />


            </>
        }
        {(editor?.canvas.getActiveObject()?.type == 'image' || editor?.canvas.getObjects('rect').find(Imageobj => Imageobj.id2 == 'crop-box')) && editor?.canvas?.getActiveObject('image')?.id !== 'background' &&
            <div>
                <Crop
                    setCropImageSource={setCropImageSource}
                    editor={editor}
                    fabric={fabric} />
            </div>

        }
        {(editor.canvas.getActiveObject().name != 'Background-new' && 'Background-current' && 'Background-static') &&  <>
            <div className='separator'></div>
            {!editor.canvas.getActiveObject().picArea   &&  <div onClick={(e)=>{callcrop(e,'active')}}><div className='image-toolbar-icons'><CropIcon/></div></div>}
            
        </>
        }
        {editor?.canvas.getObjects('rect').find(Imageobj => Imageobj.id2 === 'crop-box') === undefined &&
                    <>
                        <div className='separator'></div>
                        <div onClick={setAsbackgroundImage}>
                            <div className='image-toolbar-icons'>
                                Set as Background
                            </div>
                        </div>
                    </>
                }

        </div>}
        {(editor.canvas.getActiveObject()?.picArea || editor.canvas.getActiveObject()?.custtype)  && <div onClick={(e)=>{cropImage()}}><div className='image-toolbar-icons'>apply</div></div>}
        </>
       
    )
}


export default ImageToolbar;