import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../authentication/Login"
import AilaysaCanvas from "../pages/AilaysaCanvas";
import AilaysaCanvasWorkspace from "../pages/AilaysaCanvasWorkspace";


const AilaysaCanvasRoutes = () => {

   
   
  
    


    return (
            <Routes>
             
                {/* Local authentication page */}

                <Route exact path="/" element={<Login />} />
                <Route exact path="/ailaysa-canvas" element={<AilaysaCanvas  />} />
                <Route exact path="/ailaysa-canvas/:category" element={<AilaysaCanvas />} />
                <Route exact path="/ailaysa-canvas-workspace" element={<AilaysaCanvasWorkspace />} />
                <Route exact path="/ailaysa-canvas-workspace/:category" element={<AilaysaCanvasWorkspace />} />
                {/* <Route path="/404" element={<NotFound />} /> */}
                {/* <Route path="/*" element={<Navigate to="/404" />} /> */}
            </Routes>
    )
}

export default AilaysaCanvasRoutes;
