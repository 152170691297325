import React, { useState, useRef, useEffect } from "react";
import Undo from '../assets/icons/undo.svg'
import Redo from '../assets/icons/redo.svg'
import Tooltip from '@mui/material/Tooltip';

const UndoRedo = (props) => {

    const {
        editor,
        fabric,
        undoStack,
        setUndoStack,
        save,
        setRedoStack,
        redoStack,
    } = props;

  
  
  
    
      const newState = useRef(true)

      useEffect(() => {
        if(editor && newState.current == true){
        const tempdata = editor.canvas.toJSON(['id','projectid', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable','brushtype','brushtype2','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name','oldWidth','oldHeight','oldScaleX','oldScaleY']);
        setUndoStack((stack) => [...stack, tempdata]);
        newState.current = false
        }
      })
     
    useEffect(() => {
        if(editor?.canvas.getActiveObject() == null){
            const eventinspect = (event) => {
                const key = event.key;
                event = event || window.event // IE support
                var c = event.which
                var ctrlDown = event.ctrlKey || event.metaKey
                console.log(ctrlDown)
                if (ctrlDown && c == 90) {
                    // event.preventDefault()
                    undo()
                    console.log('undo')
    
                    // undoChanges()    
                } // z
                else if(ctrlDown && c == 89) {
                    // event.preventDefault()
                    redo()
                    console.log('redo');
    
                } // y
                document.addEventListener('keydown', eventinspect)
                return (() => {
                    document.removeEventListener('keydown', eventinspect)
                })
            }
        }
        
    })

      const undo =() => {
        if(undoStack.length <= 1){
            var item = undoStack[undoStack.length -1]
        }else{
            var item = undoStack[undoStack.length -2]
        }
        if(undoStack.length != 0){
            setRedoStack((stack) => [...stack, item]);
            editor.canvas.loadFromJSON(item, function () {
                editor.canvas.renderAll.bind(editor?.canvas.requestRenderAll());
            })

        setUndoStack(undoStack.slice(0,-1));

             
        }
              
      }
      const redo =() => {
        if(redoStack.length <= 1){
            var item = redoStack[redoStack.length -1]
        }else{
            var item = redoStack[redoStack.length -2]
        }
        if(redoStack.length != 0){
        setUndoStack((stack) => [...stack, item]);
        editor.canvas.loadFromJSON(item, function () {
            editor.canvas.renderAll.bind(editor?.canvas.requestRenderAll());
        })
        }
        setRedoStack(redoStack.slice(0,-1));
      }

      console.log(undoStack)
      console.log(redoStack)

      const imeventtrue = useRef(true)
  
        useEffect(() => {
            if (editor && imeventtrue.current == true) {
                // editor.canvas.__eventListeners["object:modified"] = []
                editor.canvas.on('object:modified', save)
                return () => {editor.canvas.off('object:modified', save)}
            }
        },[editor])





  return (
    <>
      <span className='header-undo-redo-logo'>
                    <Tooltip 
                     componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#2A2A2A',
                                '& .MuiTooltip-arrow': {
                                    color: '#2A2A2A',
                                },
                            },
                        },
                    }}
                    title='Undo'>
                        <span className='undo-icon'  id="undo" onClick={() => {undo()}} style={{ pointerEvents: undoStack?.length <= 1 ? 'none': 'auto',opacity: undoStack?.length <= 1 ? '0.5': '1'}}><img src={Undo} alt="undo_icon" /></span>
                    </Tooltip>
                    <Tooltip 
                     componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#2A2A2A',
                                '& .MuiTooltip-arrow': {
                                    color: '#2A2A2A',
                                },
                            },
                        },
                    }}
                    title='Redo'>
                        <span className='redo-icon' id="redo" onClick={() => {redo()}} style={{ pointerEvents: redoStack?.length  <= 0 ? 'none': 'auto',opacity: redoStack?.length  <= 0 ? '0.5': '1'}}><img src={Redo} alt="redo_icon" /></span>
                    </Tooltip>
                </span>
    </>
  );
};

export default UndoRedo;