import React, { useState, useRef } from "react";
import Skeleton from '@mui/material/Skeleton';
import SearchIcon from '@mui/icons-material/Search';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';
import VirtualScroll from "../VirtualScroll";



const Upload = (props) => {

    const {
        handleSidebarClose,
        LABS_API,
        handleAuthTrue,
        getMyUpload,
        uploadimg,
        onAddImage

    } = props



    const hiddenFileInput = React.useRef(null);
    const typeSearch = useRef()
    const [focusOnSearch, setFocusOnSearch] = useState(false)

    const [inputText, setInputText] = useState("");
    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        setInputText(lowerCase);
    };


    const filteredData = uploadimg.filter((el) => {
        //if no input the return the original
        if (inputText === '') {
            return el;
        }
        //return the item which contains the user input
        else {
            return el.image_name?.toLowerCase().includes(inputText)
        }
    })


    const handleUpload = (event) => {
        hiddenFileInput.current.click();
    };

    const handleFile = (e) => {

        let file = e.target.files;

        for (let i = 0; i < file.length; i++) {
            const fileType = file[i]['type'];
            (async function () {
                var formdata = new FormData();
                formdata.append("image_name", file[i].name);
                formdata.append("image", file[i])

                var requestOptions = {
                    method: 'POST',
                    headers: handleAuthTrue(new Headers()),
                    body: formdata,
                    redirect: 'follow'
                };

                let data = await fetch(LABS_API + "/canvas/canvas-user-images/", requestOptions)
                if (data.status === 200) {
                    let response = await data.json()
                    getMyUpload()
                }
                else {

                    console.log('error');
                }
            })();

        }



    };

    const deleteupload = (id) => {
        (async function () {

            var requestOptions = {
                method: 'DELETE',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/canvas-user-images/" + id + "/", requestOptions)
            if (data.status === 200) {
                let response = await data.json()

                getMyUpload()
            }
            else {

                console.log('error');
            }

        })();
    }

    function sendFunc(file) {

        var num = 1;
        var name = file;
        var ext = name.split('.');
        ext = ext[ext.length - 1];
        return ext?.toLowerCase();
    }

    const UploadList = filteredData.map((file, key) => {
        return (
            <div key={key} className="grid-Inpaint-upload">
                <Tooltip
                    title="Delete" placement="top" arrow>
                    <DeleteForeverIcon className="delete-icon-uploads" onClick={() => {
                        deleteupload(file.id)
                    }} />
                </Tooltip>
                <div className="upoad-image-details">
                    <img className="inpaint-images" src={LABS_API + '' + file.image} onClick={(e) => {
                        onAddImage(e,sendFunc(LABS_API + '' + file.image))}} onDragEnd={(e) => {
                            onAddImage(e,sendFunc(LABS_API + '' + file.image))}} />
                    <div className="image-details">
                        <span className="details"><span className="heading-image">Name: </span><span className="name-image">{file.image_name}</span></span>
                        <span className="details"><span className="heading-image">Type: </span><span className="name-image">{sendFunc(LABS_API + '' + file.image)}</span></span>

                    </div>
                </div>
            </div>
        )
    })

    return (
        <div className="sidebar-content">
            <div className="sidebar-tabs-heading">
                <span>Upload</span>
                {/* <CloseIcon className="close-icon" onClick={handleSidebarClose} /> */}
            </div>
           
            <div className={focusOnSearch ? "main-tab-search active-search-bar" : "main-tab-search"}>
                <SearchIcon className="seacrh-icon" onClick={inputHandler} />
                <span className="search-placeholder-text">
                    <input type="text" onKeyDown={inputHandler} onClick={() => {
                        setFocusOnSearch(true)
                    }} onBlur={(e) => {
                        setFocusOnSearch(false);
                    }} className="searcher" ref={typeSearch} onChange={inputHandler} placeholder="Search..." />
                </span>
            </div>
            <div className="upload-div">
                <button className="upload" onClick={handleUpload}>
                    Upload
                </button>
                <input type="file" onChange={handleFile} style={{ display: 'none' }} ref={hiddenFileInput} name="file[]" multiple />
            </div>
            <div className="grid-inpaint grid-upload-height">
                {uploadimg == '' &&
                    <>
                        { Array(4).fill(null).map((value, key) => {
                    return(
                        <div key={key} className="grid-Inpaint-upload">
                        <div className="upoad-image-details">
                            <Skeleton variant="rectangular" width={150} height={key == 1? 200 : key == 3 ? 150 : 100} />
                            <div className="image-details">
                                <span className="details"><span className="heading-image">
                                <Skeleton variant="text" width={30} height={20} /> </span>
                                    <span className="name-image">
                                    <Skeleton variant="text" width={80} height={20} />
                                        </span>
                                    </span>
                                <span className="details"><span className="heading-image">
                                <Skeleton variant="text" width={30} height={20} /> </span>
                                    <span className="name-image">
                                    <Skeleton variant="text" width={30} height={20} />
                                        </span></span>
        
                            </div>
                        </div>
                    </div>
                        
                    )
                })}
                    </>}

                {(uploadimg != '' && filteredData.length === 0) &&
                    <span className="no-result-found">No Result found</span>
                }
                {filteredData.map((file, key) => {
                            return (
                                <div key={key} className="grid-Inpaint-upload">
                                    <Tooltip
                                        title="Delete" placement="top" arrow>
                                        <DeleteForeverIcon className="delete-icon-uploads" onClick={() => {
                                            deleteupload(file.id)
                                        }} />
                                    </Tooltip>
                                    <div className="upoad-image-details">
                                        <img className="inpaint-images" src={LABS_API + '' + file.image} onClick={(e) => {
                                            onAddImage(e,sendFunc(LABS_API + '' + file.image))}} onDragEnd={(e) => {
                                                onAddImage(e,sendFunc(LABS_API + '' + file.image))}} />
                                        <div className="image-details">
                                            <span className="details"><span className="heading-image">Name: </span><span className="name-image">{file.image_name}</span></span>
                                            <span className="details"><span className="heading-image">Type: </span><span className="name-image">{sendFunc(LABS_API + '' + file.image)}</span></span>

                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    {/* <VirtualScroll
                            height="76vh"
                            totalElements = {filteredData.length}
                            rowHeight={150}
                            items={UploadList}
                            visibleItemsLength={8}
                            >
                            </VirtualScroll> */}
            </div>
        </div>

    )


}

export default Upload;