import React from "react";

import doubleViewVertical from '../assets/icons/doubleViewVertical.svg'
import singleView from '../assets/icons/singleView.svg'



const Footer = (props) => {

    const {
     
        state,
        handleSplitView,
        disableSplitView,

    }
        = props

      
     

        

    return (
        <div className="zoom-container">
             
            {/* <div className="zoom"> */}
                {state?.type === 'image-translate' && 
                <div className="zoom" style={{ display: 'none'}}>
                <img src={doubleViewVertical} alt={'DoubleViewVertical'} onClick={handleSplitView} />
                <img src={singleView} alt={'SingleView'} onClick={disableSplitView} />
                </div>
                }
                {/* <input type="range" min="0.1" max="1" step="0.01" value={splitView? scaleCanvas : scaleCanvas} onChange={(e) => { setScaleCanvas(e.target.value); }} ></input>
                <div className="zoom-range-percentage">
                    <span>{(splitView? scaleCanvas * 100: scaleCanvas * 100).toFixed(0)}%</span>
                </div> */}
            {/* </div> */}
        </div>


    )


}

export default Footer;