import Ailaysalogo from '../assets/icons/ailaysaLogo.svg'
import Undo from '../assets/icons/undo.svg'
import Redo from '../assets/icons/redo.svg'
import React, { useRef, useState } from 'react'
import Tooltip from '@mui/material/Tooltip';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useNavigate } from "react-router-dom";
import Logout from './navbarcomponent/Logout'
import LanguageSelect from '../components/sidebarcomponents/LanguageSelect'
import Select from "react-select";
import Slider from '@mui/material/Slider';
import { saveAs } from 'file-saver'
import { changeDpiDataUrl } from "changedpi";
import DownloadIcon from '@mui/icons-material/Download';
import { Collapse, CardBody, Card ,PopoverBody,UncontrolledPopover } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Translate from './navbarcomponent/Translate'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import AddIcon from '@mui/icons-material/Add';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import UndoRedo from './UndoRedo';
const LoadingMarkup = () => {
    return(
        <div className="py-4 text-center">
            <section className="animation-logo display-flex flex-column">
                <div className="svg-logo"></div>
            </section>
        </div>
    )
};

const HeaderCanvas = (props) => {

    const {
        editor,
        setSwitchWorkspace,
        setTabIndex,
        tabIndex,
        name,
        setName,
        workspaceSwitch,
        switchWorkspace,
        saveAsDesign,
        languageData,
        srLanguage,
        sourceCavasData,
        sourceCavasDataJson,
        setSourceCanvasData,
        setTargetLanNo,
        setCanvasTranslatedJsonId,
        pageNo,
        fabric,
        setPageData,
        pageData,
        undoChanges,
        redoChanges,
        canvasheight,
        canvaswidth,
        handleAuthTrue,
        LABS_API,
        designid,
        translatedImageid,
        setCanvasBgColor,
        selected,
        setSelected,
        state,
        scaleCanvas,
        canvasTranslatedJsonId,
        setImageTransleSource,
        imageTransleSource,
        autoChecked,
        isSaved,
        setCanvasmodified,
        setCanvaswidth,
        setCanvasheight,
        setDesignid,
        setDesigntemp,
        setAutoSaved,
        handlesize,
        totalLangListRef,
        addBackground,
        langList,
        canvasBgColor,
        urlPath,
        setLanguageLabel,
        setTranslateLanguages,
        saveStateToHistory,
        updateMaskImage,
        undoStack,
        setUndoStack,
        save,
        setRedoStack,
        redoStack
    } = props;

    const filename = useRef()
    const navigate = useNavigate();
    const [showTarLangModal, setshowTarLangModal] = useState(false);
    const [targetLangList, setTargetLangList] = useState([]);
    const [targetLangListInitial, setTargetLangListInitial] = useState([]);
    const [targetLanguage, setTargetLanguage] = useState("");
    const [loading,setLoading] = useState(false)

    const removebrtag = () => {
        var var1 = filename.current.querySelectorAll('br');

        for (var i = var1.length; i--;) {
            var1[i].parentNode.removeChild(var1[i]);
        }
    }
    document.querySelector('[contenteditable]')?.addEventListener('paste', function pasteAsPlainText(event) {
        event.preventDefault();
        event.target.innerText = event.clipboardData.getData("text/plain");
        removebrtag()
    });

   

    const executeProposalScroll = () => {
        filename.current.scrollTo(0, 0);
    }
   

    const handleChange = (event) => {
        setSwitchWorkspace(event.target.checked);
    };


    

    const goToHome = () => {
        if (workspaceSwitch === "Workspace") {
            navigate("/ailaysa-canvas")
        } else {
            
        }

    }


    const [si, setSi] = useState(1)
    const getFiletSize = (newValue) => {

        setSi(newValue.target.value)
    }


    const [ dpi , setDpi] = useState(96)
    const getDpiSize = (newValue) => {

        setDpi(newValue.target.value)
    }


    const options = [
        { value: 'jpeg', label: 'jpeg' },
        { value: 'png', label: 'png' },
        { value: 'svg', label: 'svg' },
    ]

    const [fileFormate, setFileFormate] = useState('jpeg')

    const selectFileFormate = (selected) => {
        setFileFormate(selected.value)
    }

 const handleSvgDownload = (w,h) => {
    editor.canvas.setWidth(canvaswidth)
                      editor.canvas.setHeight(canvasheight)
                      editor.canvas.renderAll()
  
                      setTimeout(() => {
                          const svg = editor.canvas.toSVG({
                              embedFonts: true,
                              viewBox: {
                                  x: 0,
                                  y: 0,
                                  width: canvaswidth,
                                  height: canvasheight
                              }
                          });
                          // const base64 = "data:image/svg+xml," + encodeURIComponent(svg);
                          var blob = new Blob([svg], { type: "image/svg+xml" });
                          saveAs(blob, `${name}.${fileFormate}`);
                          editor.canvas.setWidth(w)
                          editor.canvas.setHeight(h)
                          editor.canvas.renderAll()
                      }, 100);
  
 }
  

    const handleDownload = () => {
       
        var temp = editor.canvas.viewportTransform
        let w = editor.canvas.width
        let h = editor.canvas.height
        editor.canvas.renderAll()
        editor.canvas.viewportTransform = [ 1,0, 0, 1, 0,0]
        var clip = editor.canvas.clipPath
        editor.canvas.clipPath = null
        fabric.Object.prototype.objectCaching = false;
        fabric.Object.prototype.noScaleCache = false;
        fabric.Object.prototype.statefullCach = false;
        fabric.Object.prototype.cacheProperties = false;
        fabric.Object.prototype.dirty = false;
       
        editor.canvas.renderAll()
        if (fileFormate === 'svg') {
           
        const fontFamilies = [];

        function extractFontFamilies(obj) {
            if (obj.fontFamily && !fontFamilies.includes(obj.fontFamily)) {
              fontFamilies.push(obj.fontFamily);
            }
            if (obj.type === 'group' && obj.objects) {
              obj.objects.forEach((groupObj) => extractFontFamilies(groupObj));
            }
          }
          let data =  editor.canvas.toJSON()
          data.objects.forEach((obj) => extractFontFamilies(obj));
            // Remove duplicate font families
          const uniqueFontFamilies = [...new Set(fontFamilies)];

            const fontFaceUrls = [];
            let done = false

            if (uniqueFontFamilies.length == 0) {
                done = true;
                handleSvgDownload(w,h)
            }
            uniqueFontFamilies.forEach((each,index) =>{
              fetch(`https://fonts.googleapis.com/css?family=${each}`)
              .then((response) => response.text())
              .then((data) => {
                  const regex = /url\((.*?)\)/g;
                  let match;
                  while (match = regex.exec(data)) {
                  fontFaceUrls.push(
                      {fontFamily: each,
                      fontFaceUrl: match[1]}
  
                      );
                  fontFaceUrls.forEach((each) => {
                      fabric.fontPaths[each.fontFamily] = each.fontFaceUrl;
                  })
  
  
                  if(index == (uniqueFontFamilies.length -1)){
                      done =true;
                  }
                  }
                  if(done){
                    handleSvgDownload(w,h)
                  }
  
              });
            })

         
        } else {
            const base64 = editor.canvas.toDataURL({
                multiplier: si,
                format: fileFormate,
                enableRetinaScaling: true,
                width:canvaswidth,
                height:canvasheight
            });
            var daurl150dpi = changeDpiDataUrl(base64, dpi);
            const link = document.createElement("a");
            link.href = daurl150dpi;
            link.download = `${name}.${fileFormate}`;
            link.click();
            
        }
        editor.canvas.viewportTransform = temp
            editor.canvas.clipPath = clip
            fabric.Object.prototype.objectCaching = true;
            fabric.Object.prototype.noScaleCache = true;
            fabric.Object.prototype.statefullCach = true;
            fabric.Object.prototype.cacheProperties = true;
            fabric.Object.prototype.dirty = false;
            editor.canvas.renderAll()
        // setData1(base64)
    }

 


    const updateDesignName = (namecurrent) => {

        (async function () {
            var formdata = new FormData();
            formdata.append("file_name", namecurrent)

            var requestOptions = {
                method: 'PUT',
                headers: handleAuthTrue(new Headers()),
                body: formdata,
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/canvas-designs/" + designid + "/", requestOptions)
            if (data.status === 200) {
                let response = await data.json()
              
            }
            else {

                console.log('error');
            }

        })();


    }

    const updateMaskImageName = (namecurrent) => {
        // setIsSaved(false)
        (async function () {
            // const tempdata = JSON.stringify(editor.canvas.toJSON(['id','projectid', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable','brushtype','brushtype2','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name']));
            var formdata = new FormData();
            // formdata.append("mask_json", tempdata);
            formdata.append("project_name", namecurrent)
            
            var requestOptions = {
                method: 'PUT',
                body: formdata,
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };
            let data = await fetch(LABS_API + "/image/imageupload/" + translatedImageid + "/", requestOptions)
            if (data.status === 204) {
                let response = await data.json()
               
            }
        })();
    }

    var namecureentref = useRef()
    const checkName = () => {
        namecureentref.current = name
    }
    const savenamechange =(e) => {
        
        executeProposalScroll()
        setName(e.target.innerText)

        if( namecureentref.current != e.target.innerText){
            if(designid != null){
                updateDesignName(e.target.innerText)
            }else if(translatedImageid != null){
                updateMaskImageName(e.target.innerText)
            }
            
        }
      
    }
  
    const newProject = () => {

        editor.canvas.clear()
        navigate('/ailaysa-canvas')
        var size = { name: 'loadcanvas', width:canvaswidth, height: canvasheight,fill:'rgba(255,255,255,1)' }
        setAutoSaved(false)
        setName('Untitled Project')
        setDesignid(null)
        setDesigntemp(false)
        setCanvasmodified(false)
        setCanvasBgColor('rgba(255,255,255,1)')
        // editor.canvas.backgroundImage = new fabric.Rect({ width: canvaswidth, height: canvasheight,fill:'rgba(255,255,255,1)' });
        // handleClose()
        handlesize(size)
        setPageData([])
    }

    const updateTargetLanguage = () =>{
       
        (async function () {
            setLoading(true)
            var formdata = new FormData();
  
            targetLanguage.map(option => {
                // console.log(option.language)
                if(option.language != undefined)
                  formdata.append("canvas_translation_tar_lang", option.id);
                })
  
  
            formdata.append("src_lang", srLanguage);
            var requestOptions = {
              method: 'PUT',
              headers: handleAuthTrue(new Headers()),
              body: formdata,
              redirect: 'follow'
            };
    
            let data = await fetch(LABS_API + "/canvas/canvas-designs/" + designid + "/", requestOptions)
  
            if (data.status === 200) {
              let response = await data.json()    
              setLoading(false)
  
            }
            else{
  
              console.log('error');
            }
          })();
    }

    // console.log(targetLangListInitial)
    // console.log(targetLangList)

  
    return <>
        <div className="header">
            <div className='header-section1'>
                <span className='header-logo'>
                    <img src={Ailaysalogo} alt="Ailaysa_icon" />
                </span>
                <div className='menu-button'>
                    <span className='menu-but' onClick={goToHome}>Home</span>
                    {/* <File
                        editor={editor}
                        setAutoSaved={setAutoSaved}
                        setName={setName}
                        setDesignid={setDesignid}
                        setDesigntemp={setDesigntemp}
                        setCanvasmodified={setCanvasmodified}
                        setCanvasBgColor={setCanvasBgColor}
                        saveAsDesign={saveAsDesign}
                        handlesize={handlesize}
                        canvaswidth={canvaswidth}
                        fabric={fabric}
                        canvasheight={canvasheight}
                         /> */}
                {workspaceSwitch !== 'Workspace' &&
                <span className={tabIndex === 10 ? 'menu-active' : 'menu-but'} onClick={() => { workspaceSwitch === "Workspace" ? setTabIndex(6) : setTabIndex(10) }}>
                    Resize
                    </span>}
                    <span className='new-design-button' onClick={newProject}>
                        <AddIcon className='new-design-button-icon' />
                        <span>New design</span>
                    </span>
                </div>
                {/* <span className='header-undo-redo-logo'>
                    <Tooltip 
                     componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#2A2A2A',
                                '& .MuiTooltip-arrow': {
                                    color: '#2A2A2A',
                                },
                            },
                        },
                    }}
                    title='Undo'>
                        <span className='undo-icon' onClick={undoChanges}><img src={Undo} alt="undo_icon" /></span>
                    </Tooltip>
                    <Tooltip 
                     componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#2A2A2A',
                                '& .MuiTooltip-arrow': {
                                    color: '#2A2A2A',
                                },
                            },
                        },
                    }}
                    title='Redo'>
                        <span className='redo-icon' onClick={redoChanges}><img src={Redo} alt="redo_icon" /></span>
                    </Tooltip>
                </span> */}
                <UndoRedo 
                editor={editor}
                fabric={fabric}
                undoStack={undoStack}
                setUndoStack={setUndoStack}
                save={save}
                setRedoStack={setRedoStack}
                redoStack={redoStack}
                />
            </div>
            <div className='header-section2'>
                <div contentEditable
                    suppressContentEditableWarning={true}
                    ref={filename} 
                    onBlur={savenamechange} 
                    tabIndex={0}
                    onFocus={checkName} 
                    className=' project-box-ai' 
                    onKeyDown={(event) => {
                        // handleNameChange(event)
                        if (event.which == '13') {
                            event.preventDefault();
                            filename.current.blur()
                        }
                    }} 
                    value={name}
                    spellCheck='false'
                    data-placeholder="Untitled project">{name}</div>
                {
                                    isSaved ? (
                                        <Tooltip title="Save" placement="top">
                                            <CloudDoneOutlinedIcon style={{color: '#5f6368', cursor: 'pointer'}} onClick={() => {}} />
                                        </Tooltip>
                                    ) : (
                                        <>
                                            <CachedOutlinedIcon className="rotate" style={{color: '#5f6368'}}/>
                                            <small>Saving</small>
                                        </>
                                    )
                                }
            </div>
            <div className='header-section3 '>
                <button  className='d-none'  onClick={()=>{
                //    editor.canvas.getObjects().forEach((e)=>{
                //     e.set({objectCaching:false})
                // })  
                // editor.canvas.clipPath = null
                // editor.canvas.renderAll()
                    
                console.log(editor.canvas.__eventListeners)
                console.log(editor.canvas.toJSON(['custtype','picArea']))

            }}
                >get events</button>
                {/* <button style={{ display: 'none'}} >remove</button> */}
                <div>
                    {workspaceSwitch === 'Workspace' &&
                    <div className='d-flex align-items-center gap-3'>
                       
                        <LanguageSelect
                            canvaswidth={canvaswidth}
                            canvasheight={canvasheight}
                            setCanvaswidth={setCanvaswidth}
                            setCanvasheight={setCanvasheight}
                            languageData={languageData}
                            srLanguage={srLanguage}
                            sourceCavasData={sourceCavasData}
                            fabric={fabric}
                            editor={editor}
                            setPageData={setPageData}
                            sourceCavasDataJson={sourceCavasDataJson}
                            setSourceCanvasData={setSourceCanvasData}
                            pageNo={pageNo}
                            pageData={pageData}
                            setTargetLanNo={setTargetLanNo}
                            setCanvasTranslatedJsonId={setCanvasTranslatedJsonId}
                            state={state}
                            scaleCanvas={scaleCanvas} 
                            LABS_API={LABS_API}
                            handleAuthTrue={handleAuthTrue}
                            translatedImageid={translatedImageid}
                            canvasTranslatedJsonId={canvasTranslatedJsonId}
                            totalLangListRef={totalLangListRef}
                            addBackground={addBackground}
                            langList={langList}
                            urlPath={urlPath}
                            setLanguageLabel={setLanguageLabel}
                            setTargetLangList={setTargetLangList}
                            setTargetLangListInitial={setTargetLangListInitial}

                           />
                            <ModeOutlinedIcon onClick={() => {
                            // console.log('clicked')
                            setTargetLanguage(targetLangList)
                            setshowTarLangModal(true)
                         }}/>
                           
                           {targetLangList.length > targetLangListInitial.length && <span className='new-design-button' onClick={() => {
                                updateTargetLanguage()
                            }}>
                                <span>Update</span>
                            </span>}
                           </div>
                    }
                </div>
                {(workspaceSwitch === "Workspace" && window.location.pathname === '/ailaysa-canvas-workspace/design/') &&
                    <div className="form-check form-switch switch-style">
                        <FormGroup>
                            <FormControlLabel control={<Switch value={switchWorkspace} onChange={handleChange} />} className="post-edit-view--label" label="Post Editing view " />
                        </FormGroup>
                    </div>
                }
                <DownloadIcon className='log-out' id="PopoverLegacy" />
                    <UncontrolledPopover
                        
                        placement="bottom"
                        target="PopoverLegacy"
                        trigger="legacy"
                    >
                        <PopoverBody className='download-options'>
                            <div className="download-list-flex">
                                <div className="file-size-div">
                                    <label className="heading-list-down-lab">File Type</label>
                                    <Select
                                        classNamePrefix="select-lang"
                                        defaultValue={ { value: 'jpeg', label: 'jpeg' }}
                                        isSearchable={true}
                                        menuPlacement="bottom"
                                        options={options}
                                        onChange={selectFileFormate}
                                    />
                                </div>
                                <Collapse isOpen={fileFormate != 'svg' } className='download-collapse'>
                                <Card className='download-collaspe-card'>
                                    <CardBody className='download-collaspe-body'>
                                        <div className="file-size-div">
                                            <label className="heading-list-down-lab">File Size</label>
                                            <Slider
                                                size="small"
                                                defaultValue={1}
                                                min={1}
                                                max={3}
                                                step={0.1}
                                                aria-label="Small"
                                                onChange={getFiletSize}
                                                valueLabelDisplay="auto"
                                                className="slider-file"
                                            />



                                            <div className="innerText">
                                                <span>{(canvaswidth * si)?.toFixed(0)} X {(canvasheight * si)?.toFixed(0)}</span>
                                            </div>
                                        </div>
                                        <div className="file-size-div">
                                            <label className="heading-list-down-lab">Dpi</label>
                                            <Slider
                                                size="small"
                                                defaultValue={96}
                                                min={72}
                                                max={300}
                                                step={1}
                                                aria-label="Small"
                                                onChange={getDpiSize}
                                                valueLabelDisplay="auto"
                                                className="slider-file"
                                            />

                                            <div className="innerText">
                                                <span>{dpi} dpi</span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Collapse>
                            <button className="innter-down" onClick={handleDownload}>Download</button>
                        </div>
                        </PopoverBody>
                    </UncontrolledPopover>
               
               <Translate
                 editor={editor}
                 canvaswidth={canvaswidth}
                 canvasheight={canvasheight}
                  fabric={fabric}
                   handleAuthTrue={handleAuthTrue}
                    LABS_API={LABS_API}
                    name={name}
                    tabIndex={tabIndex} 
                    designid={designid} 
                    translatedImageid={translatedImageid}
                    setCanvasBgColor={setCanvasBgColor}
                    selected={selected}
                    setSelected={setSelected}
                    setImageTransleSource={setImageTransleSource}
                    imageTransleSource={imageTransleSource}
                    autoChecked={autoChecked}
                    showTarLangModal={showTarLangModal}
                    setshowTarLangModal={setshowTarLangModal}
                    workspaceSwitch={workspaceSwitch}
                    setTargetLangList={setTargetLangList}
                    targetLangList={targetLangList}
                    targetLanguage={targetLanguage}
                    setTargetLanguage={setTargetLanguage}
                    setLoading={setLoading}
                    loading={loading}
                    setTranslateLanguages={setTranslateLanguages}
                    />
                    
                <Logout />
            </div>
        </div>
        {
            loading && <LoadingMarkup />
        }
    </>
}


export default HeaderCanvas;