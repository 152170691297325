import React, { useEffect, useState, useRef, useCallback } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { GooglePicker } from 'react-color'
import Eye from "./Eye";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import useFetch from "./backgroundfetch";
import Skeleton from '@mui/material/Skeleton';
import Slide from '@mui/material/Slide';
const Background = (props) => {


    const {
        handleSidebarClose,
        fectchBackground,
        setBackgroundsearchword,
        backgroundsearchword,
        addBackground,
        editor,
        setBackgroundEdit,
        canvasBgColor,
        setCanvasBgColor,
        canvasheight,
        canvaswidth,
        fabric,
        debounce

    } = props

    const [displayPicker, setDisplayPicker] = useState(false)
    const [focusOnSearch, setFocusOnSearch] = useState(false)


    const photosearchinputer = useRef()

    const fetchImageOnSearch = (e) => {
        if (e.key === 'Enter') {
            if ((e.target.value.length > 0) && (e.target.value != ' ')) {
                fectchBackground()
            }
            else {
                setBackgroundsearchword('background')
            }
        }
    }

    const handleSearchChange = (e) => {
        if (e.target.value.length > 0 && e.target.value != ' ') {
            setBackgroundsearchword(e.target.value)
        }
        else {
            setBackgroundsearchword('background')
        }
    }

    const [backgroundColor, setBackgroundColor] = useState(null)
    const handleSetColor = (file) => {
        // setCanvasBgColor(color)
        setBackgroundColor(file)
        // editor.canvas.fire('object:modified');
        // editor?.canvas.setBackgroundColor(color).renderAll()
        // setCanvasBgColor("rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")")
        // editor?.canvas.setBackgroundColor("rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")").renderAll()
        editor.canvas.backgroundImage = ''
        editor.canvas.backgroundImage = new fabric.Rect({ width: canvaswidth, height: canvasheight, fill: file });
        setBackgroundEdit(false)
        editor.canvas.renderAll();
    }

    const colors = ["#000000", "#FFFFFF", "#D9EEE1", "#FCB900", "#8ED1FC", "#9900EF", "#DCE775"];
    const [backgroundcolor, setBackgroundcolor] = useState()

    const handleOpenColorPicker = () => {
        if (!displayPicker) {
            setDisplayPicker(true)

        } else {
            setDisplayPicker(false)

        }
    }

    const handleColorChangeViaPicker = (color) => {
    }

    // console.log(backgroundColor);


    useEffect(() => {
        if (backgroundColor != null) {
            setCanvasBgColor(backgroundColor)
        }
    }, [backgroundColor])




    const [oddpagephotos, setoddpagephotos] = useState(1);
    const [evenpagephotos, setEvenpagephotos] = useState(2);
    // const [photopage, setphotopage] = useState(1);
    const { loading, error, list, firstoddhalf, secondevenhalf } = useFetch(backgroundsearchword, oddpagephotos, evenpagephotos);
    const loader1 = useRef(null);
    const loader2 = useRef(null);

    // const handleChange = (e) => {
    //   setQuery(e.target.value);
    // };
    useEffect(() => {
        setoddpagephotos(1);
        setEvenpagephotos(2)
    }, [backgroundsearchword])

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setoddpagephotos((prev) => prev + 2);
            setEvenpagephotos((prev) => prev + 2)
        }
    }, []);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader1.current) observer.observe(loader1.current);
        if (loader2.current) observer.observe(loader2.current);
    }, [handleObserver]);






    return (
        <div className="sidebar-content">
            <div className="sidebar-tabs-heading">
                <span>Background</span>
                {/* <CloseIcon className="close-icon" onClick={handleSidebarClose} /> */}
            </div>
            {/* <div className="sidebar-search">
                <SearchIcon className="seacrh-icon" onClick={fectchBackground} />
                <span className="search-placeholder-text">
                    <input type="text" onKeyDown={(e) => { fetchImageOnSearch(e) }} className="searcher" ref={photosearchinputer} onChange={(e) => { handleSearchChange(e) }} placeholder="Search..." />
                </span>
            </div> */}
            <div className={focusOnSearch ? "main-tab-search active-search-bar" : "main-tab-search"}>
                <SearchIcon className="seacrh-icon"  />
                <span className="search-placeholder-text">
                    <input type="text" 
                    onKeyDown={(e) => { fetchImageOnSearch(e) }} 
                    onClick={(e) => {
                        setFocusOnSearch(true)
                    }} 
                    onBlur={(e) => {
                        setFocusOnSearch(false);
                    }} 
                    className="searcher"
                    ref={photosearchinputer} 
                    onChange={(e) => { debounce(handleSearchChange,e) }} 
                    placeholder="Search..." />
                </span>
            </div>
            <div className="sidebar-background-color">
                {colors?.map((file, key) => {
                    return (
                        <div key={key + file} className="bg-colors" style={{ background: file }} onClick={(e) => handleSetColor(file)} >

                        </div>
                    )
                })}
                <Eye
                    setCanvasBgColor={setCanvasBgColor}
                    canvasBgColor={canvasBgColor}
                    editor={editor}
                    fabric={fabric}
                    canvaswidth={canvaswidth}
                    canvasheight={canvasheight}
                />
                <div className="add-colors">
                    {displayPicker ? (
                        <ArrowDropUpIcon className="add-icon" onClick={handleOpenColorPicker} />
                    ) : (
                        <ArrowDropDownIcon className="add-icon" onClick={handleOpenColorPicker} />)}
                </div>

            </div>
            {displayPicker &&
                <div>
                    <GooglePicker className="colorPicker" color={canvasBgColor} onChangeComplete={() => { editor.canvas.fire('object:modified'); }} onChange={(color) => {
                        setCanvasBgColor("rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")")
                        editor.canvas.backgroundImage = new fabric.Rect({ width: canvaswidth, height: canvasheight, fill: "rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")" });
                        editor.canvas.renderAll();
                    }}

                    />
                </div>
            }

            <div className={`grid-photos api-photos api-bg-photos ${+ displayPicker && 'bg-container-height'}`}>

                <div className="odd-photos">
                    {firstoddhalf?.map((item, i) => (
                        <img className="images-section" src={item.previewURL} onClick={(e) => addBackground(item.fullHDURL)} onDragEnd={(e) => addBackground(item.fullHDURL)} alt="" />
                    ))}
                    <div ref={loader1} />
                        <Skeleton variant="rectangular" width={160} height={150} />
                        <Skeleton variant="rectangular" width={160} height={200} />
                        <Skeleton variant="rectangular" width={160} height={150} />
                        <Skeleton variant="rectangular" width={160} height={200} />
                        <Skeleton variant="rectangular" width={160} height={150} />
                        <Skeleton variant="rectangular" width={160} height={200} />
                    {error && <p>No result!</p>}

                </div>


                <div className="even-photos">
                    {secondevenhalf?.map((item, i) => (
                         <Slide direction="up" in={true} mountOnEnter unmountOnExit><img className="images-section" src={item.previewURL} onClick={(e) => addBackground(item.fullHDURL)} onDragEnd={(e) => addBackground(item.fullHDURL)} alt="" /></Slide>
                        ))}
                    
                    <div ref={loader2} /> 
                    
                        <Skeleton variant="rectangular" width={160} height={200} />
                        <Skeleton variant="rectangular" width={160} height={150} />
                        <Skeleton variant="rectangular" width={160} height={200} />
                        <Skeleton variant="rectangular" width={160} height={150} />
                        <Skeleton variant="rectangular" width={160} height={200} />
                        <Skeleton variant="rectangular" width={160} height={150} />
                    {error && <p>No result!</p>}

                </div>
            </div>
            {firstoddhalf.length == 0 && secondevenhalf.length == 0 && !loading ? <> no result
            </> : ''}
        </div>

    )


}

export default Background;