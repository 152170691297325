import { useState, useEffect, useCallback } from "react";

import Config from "../../config/Config";
import axios from "axios";

function useFetch(query, oddpagephotos,evenpagephotos) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);
  const [firstoddhalf,setFirstoddhalf] = useState([])
  const [secondevenhalf,setSecondevenhalf] = useState([])
  let tempstore = []


  useEffect(()=>{
    setFirstoddhalf([])
    setSecondevenhalf([])
  },[query])

  const sendQuery = useCallback(async () => {
    try {
      await setLoading(true);
      await setError(false);
      const oddphotos = await axios.get(`${Config.PIXABAY_API2}&page=${oddpagephotos}&q=${query}`)
      const evenphotos = await axios.get(`${Config.PIXABAY_API2}&page=${evenpagephotos}&q=${query}`)
      // await setList((prev) => [...prev, ...res.data.hits]);
      await setFirstoddhalf((prev) => [...prev, ...oddphotos.data.hits])
      await setSecondevenhalf((prev) => [...prev, ...evenphotos.data.hits])
      setLoading(false);
    } catch (err) { 
      setLoading(false);
      setError(err);
      return false
    }
  }, [query, oddpagephotos,evenpagephotos]);


  useEffect(() => {
    sendQuery(query);
  }, [query, sendQuery, oddpagephotos,evenpagephotos]);

  return { loading, error, list ,firstoddhalf ,secondevenhalf };
}

export default useFetch;