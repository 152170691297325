import React, { useEffect, useState, useRef } from "react";
import SearchIcon from '@mui/icons-material/Search';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Checkbox from '@mui/material/Checkbox';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";
import VirtualScroll from "../VirtualScroll";
import Skeleton from '@mui/material/Skeleton';

const   Inpaint = (props) => {

    const {
        handleSidebarClose,
        LABS_API,
        handleAuthTrue,
        uploadimgForImageTranslate,
        getMyUploadImageTranslate,
        setCanvasheight,
        setCanvaswidth,
        editor,
        fabric,
        setCropImageSource,
        setAutoChecked,
        autoChecked,
        manualChecked,
        setManualChecked,
        translatedImageid,
        setDesignid,
        setImageTransleSource,
        setselected,
        selected,
        updateDesign,
        designid,
        handlesize,
        canvaswidth,
        canvasheight,
        setCanvasBgColor,
        setCanvasmodified,
        setName,
        setAutoSaved,
        setDesigntemp,
        setTranslatedImageid,
        imageTransleSource,
        setIsSaved,
        shadow,
        workspaceSwitch   

    } = props

    const notify = () => toast.info('Previous design is saved. Space is setup now for Image Translation. ', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        
        });

    const [files, setFiles] = useState([])
    const [manual, setManual] = useState([])


    const hiddenFileInput = React.useRef(null);
    const typeSearch = useRef()
    const [focusOnSearch, setFocusOnSearch] = useState(false)

    const [inputText, setInputText] = useState("");
    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        setInputText(lowerCase);
    };
    const navigate = useNavigate();

    const filteredData = uploadimgForImageTranslate.filter((el) => {
        //if no input the return the original
        if (inputText === '') {
            return el;
        }
        //return the item which contains the user input
        else {
            return el.image_name?.toLowerCase().includes(inputText)
        }
    })

    const handleUpload = (event) => {
        hiddenFileInput.current.click();
    };



    const handleFile = (e) => {

        let file = e.target.files;
        for (let i = 0; i < file.length; i++) {
            const fileType = file[i]['type'];
            (async function () {
                var formdata = new FormData();
                formdata.append("image_name", file[i].name);
                formdata.append("image", file[i])

                var requestOptions = {
                    method: 'POST',
                    headers: handleAuthTrue(new Headers()),
                    body: formdata,
                    redirect: 'follow'
                };

                let data = await fetch(LABS_API + "/image/imageloadlist/", requestOptions)
                if (data.status === 200) {
                    let response = await data.json()
                    setDesignid(null)
                    getMyUploadImageTranslate()
                }
                else {
                    
                    console.log('error');
                }
            })();
        }
    };

   


    const deleteupload = (id) => {
        (async function () {

            var requestOptions = {
                method: 'DELETE',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/image/imageloadlist/" + id + "/", requestOptions)
            if ( data.status === 200 || data.status === 204 ) {
                // let response = await data.json()
                getMyUploadImageTranslate()
                // console.log('sampled');

            }
            else {

                console.log('error');
            }

        })();
    }



  
   


  
    const addImageOcr = (id,e) => {

        if(workspaceSwitch == 'Workspace'){
            navigate(`/ailaysa-canvas/image-translate/?image-translate=${id}`, {state: e.target.src} )

        }else{
            editor.canvas.clear()
            try {
                document.querySelector('.canvas-space').classList.add('loading-json-blur');
            document.querySelector('#loading-wrapper').style.setProperty('display', 'grid');
                if(translatedImageid === null && designid !== null && workspaceSwitch !== 'Workspace'){
                    notify()
                }
        
                if(designid != null){
                    editor.canvas.discardActiveObject()
                    updateDesign()
                    setDesignid(null)
                    navigate('/ailaysa-canvas')
                    var size = { name: 'loadcanvas', width:canvaswidth, height: canvasheight }
                    setAutoSaved(false)
                    setName('Untitled Project')
                    setDesignid(null)
                    setDesigntemp(false)
                    setCanvasmodified(false)
                    setCanvasBgColor('rgba(255,255,255,1)')
                    // editor.canvas.backgroundImage = new fabric.Rect({ width: canvaswidth, height: canvasheight,fill:'rgba(255,255,255,1)' });
                }
               
                const img = new Image();
                img.src = e.target.src;
    
                img.onload = function() {
                  
                    let  clipPath = new fabric.Rect({ width: this.height, height: this.width, dirty: false,fill:'rgba(255,255,255,1)' ,objectCaching:false,shadow:shadow});
                    editor.canvas.backgroundImage = clipPath
                    setCanvaswidth(this.width);
                     setCanvasheight(this.height) 
                     editor.canvas.backgroundImage.width = this.width
                     editor.canvas.backgroundImage.height = this.height
                        var size =  size={name:'loadcanvas' ,width:this.width,height:this.height} 
        
                        handlesize(size)
                  }
                editor.canvas.renderAll()
                
                setImageTransleSource(id);
                setCropImageSource(e.target.src)
                var filterBrightness = new fabric.Image.filters.Brightness({
                    brightness: 0,
                });
                var filterContrast = new fabric.Image.filters.Contrast({
                    contrast: 0,
                });
                var filterSaturation = new fabric.Image.filters.Saturation({
                    saturation: 0,
                });
                var filterBlur = new fabric.Image.filters.Blur({
                    blur: 0,
                });
                var filterVibrance = new fabric.Image.filters.Vibrance({
                    vibrance: 0,
                });
        
                var filterNoice = new fabric.Image.filters.Noise({
                    noise: 0,
                });
        
                var filterHue = new fabric.Image.filters.HueRotation({
                    rotation: 0,
                });
                // var filterPixalate = new fabric.Image.filters.Pixelate({
                //     blocksize: 0,
                // });
                var filterBlackandWhite = new fabric.Image.filters.BlackWhite();
        
        
                fabric.Image.fromURL(e.target.src, (bg) => {
        
                    var obg = bg.set({
        
                        top: 0,
                        left: 0,
                        id: 'background',
                        name: 'Background-static',
                        lockMovementX: true,
                        lockMovementY: true,
                        editable: false,
                        selectable: false,
                        evented: false,
                        globalCompositeOperation: 'source-atop',
                        objectCaching:false,
        
                    })
        
        
                    obg.filters.push(filterBrightness, filterContrast, filterSaturation, filterBlur, filterVibrance, filterNoice, filterHue)
                    editor.canvas.sendToBack(obg)
                    editor.canvas.renderAll();
                    
        
                }, { crossOrigin: 'anonymous' });
                document.querySelector('.canvas-space').classList.remove('loading-json-blur');
                document.querySelector('#loading-wrapper').style.setProperty('display', 'none');
                setManualChecked(true)
                
            } catch (error) {
                console.log(error);
            }
        }


      

        
       
    }




 


    
    function sendFunc(file) {
          var num =  1;
          var name = file;
          var ext = name.split('.');
          ext = ext[ext.length - 1];
          return ext?.toLowerCase();
      }


      







      const checkselectedfile=(e)=>{
        if(selected.includes(e)){
            setselected(selected.filter(i=>{return i!=e }))
        }
        else{
            setselected(old=>[...old,e])
          
        }
      }

      useEffect(()=>{
    },[selected])

      
      const handleCheckedAuto = (e) => {
        setManualChecked(manualChecked => {return false})
        setAutoChecked(autoChecked => {return true})
      }


      const handleCheckedManual = (e) => {
        setAutoChecked(autoChecked => {return false})
        setManualChecked(manualChecked => {return true})
        // postImageTranslateProject()
      }

      useEffect(() => {
       
      },[manualChecked,autoChecked])



const imageTranslateUploadList = filteredData?.map((file, key) => {
    return (
      
            <div className="checkbox-translate-image">
            <div key={key} className="grid-Inpaint-upload">
            <Tooltip 
                title="Delete" placement="top" arrow>
                <DeleteForeverIcon className="delete-icon-uploads" onClick={() => {
                    deleteupload(file.id)
                }} />
                </Tooltip>
                <div className="upoad-image-details">
                    <img className="inpaint-images" src={LABS_API + '' + file.image} onClick={(e) => addImageOcr(file.id,e)} name={file.id} onDragEnd={(e) => manualChecked && addImageOcr(file.id,e)}  style={{ cursor: autoChecked && "auto"}}/>
                    <div className="image-details" style={{ cursor: autoChecked && "auto"}}>
                        <span className="details">
                            <span className="heading-image">Name: </span>
                            <span className="name-image">{file.file_name}</span>
                        </span>
                        <span className="details"><span className="heading-image">Width: </span><span className="name-image">{file.width}</span></span>
                        <span className="details"><span className="heading-image">Height: </span><span className="name-image">{file.height}</span></span>
                        <span className="details"><span className="heading-image">Type: </span><span className="name-image">{sendFunc(LABS_API + '' + file.image)}</span></span>
                    </div>
                </div>
                </div>
           
        </div>
       
    )
})
     
    return (
        <div className="sidebar-content">
            <div className="sidebar-tabs-heading">
                <span>Image Translate</span>
                {/* <CloseIcon className="close-icon" onClick={handleSidebarClose} /> */}
            </div>
             <div className={focusOnSearch ? "main-tab-search active-search-bar" : "main-tab-search"}>
                <SearchIcon className="seacrh-icon" onClick={inputHandler} />
                <span className="search-placeholder-text">
                    <input type="text" onKeyDown={inputHandler} onClick={() => {
                        setFocusOnSearch(true)
                    }} onBlur={(e) => {
                        setFocusOnSearch(false);
                    }} className="searcher" ref={typeSearch} onChange={inputHandler} placeholder="Search..." />
                </span>
            </div>
            <div className="upload-div">
                <button className="upload" onClick={handleUpload}>
                    Upload
                </button>
                <input type="file" onChange={handleFile} style={{ display: 'none' }} ref={hiddenFileInput} name="file[]" multiple />
            </div>
            <div className="switch-container d-none">
                    <FormControlLabel  control={<Checkbox     checked={!manualChecked && autoChecked} onChange={handleCheckedAuto}  />}  label="Auto" />
                    <FormControlLabel  control={<Checkbox   checked={!autoChecked && manualChecked} onChange={handleCheckedManual}  />} label="Manual" />
            </div>
            
               
            <div className="grid-inpaint grid-inpaint-height">
            {uploadimgForImageTranslate == '' &&
                    <>
                        { Array(4).fill(null).map((value, key) => {
                    return(
                        <div key={key} className="grid-Inpaint-upload">
                        <div className="upoad-image-details">
                            <Skeleton variant="rectangular" width={150} height={key == 1? 200 : key == 3 ? 150 : 100} />
                            <div className="image-details">
                                <span className="details"><span className="heading-image">
                                <Skeleton variant="text" width={30} height={20} /> </span>
                                    <span className="name-image">
                                    <Skeleton variant="text" width={80} height={20} />
                                        </span>
                                    </span>
                                <span className="details"><span className="heading-image">
                                <Skeleton variant="text" width={30} height={20} /> </span>
                                
                                    <span className="name-image">
                                    <Skeleton variant="text" width={30} height={20} />
                                        </span></span>
                                        <span className="details"><span className="heading-image">
                                <Skeleton variant="text" width={30} height={20} /> </span>
                                
                                    <span className="name-image">
                                    <Skeleton variant="text" width={30} height={20} />
                                        </span></span>
                                        <span className="details"><span className="heading-image">
                                <Skeleton variant="text" width={30} height={20} /> </span>
                                
                                    <span className="name-image">
                                    <Skeleton variant="text" width={30} height={20} />
                                        </span></span>
        
                            </div>
                        </div>
                    </div>
                        
                    )
                })}
                    </>}

                
                {( uploadimgForImageTranslate != ''&& filteredData?.length === 0) &&
                    <span className="no-result-found">No Result found</span>
                }
                {/* {filteredData?.map((file, key) => {
                    return (
                      
                            <div className="checkbox-translate-image">
                            <div key={key} className="grid-Inpaint-upload">
                            <Tooltip 
                                title="Delete" placement="top" arrow>
                                <DeleteForeverIcon className="delete-icon" onClick={() => {
                                    deleteupload(file.id)
                                }} />
                                </Tooltip>
                                <div className="upoad-image-details">
                                    <img className="inpaint-images" src={LABS_API + '' + file.image} onClick={(e) => addImageOcr(file.id,e)} name={file.id} onDragEnd={(e) => manualChecked && addImageOcr(file.id,e)}  style={{ cursor: autoChecked && "auto"}}/>
                                    <div className="image-details" style={{ cursor: autoChecked && "auto"}}>
                                        <span className="details">
                                            <span className="heading-image">Name: </span>
                                            <span className="name-image">{file.file_name}</span>
                                        </span>
                                        <span className="details"><span className="heading-image">Width: </span><span className="name-image">{file.width}</span></span>
                                        <span className="details"><span className="heading-image">Height: </span><span className="name-image">{file.height}</span></span>
                                        <span className="details"><span className="heading-image">Type: </span><span className="name-image">{sendFunc(LABS_API + '' + file.image)}</span></span>
                                    </div>
                                </div>
                                </div>
                           
                        </div>
                       
                    )
                })} */}
                <VirtualScroll
                    height="76vh"
                    totalElements = {filteredData.length}
                    rowHeight={150}
                    items={imageTranslateUploadList}
                    visibleItemsLength={8}
                    >
                    </VirtualScroll>
            </div>
            <ToastContainer  
            position="bottom-right"
            autoClose={2000}
            limit={1}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"/>
        </div>

    )


}

export default Inpaint;