import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as Transliteration } from '../assets/icons/Transliteration.svg'
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ReactTransliterate } from "react-transliterate";
import CircleIcon from '@mui/icons-material/Circle';
import { ReactComponent as Unconfirmed } from '../assets/icons/Unconfirmed.svg'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DragIndicator } from '@mui/icons-material';
import autosize from 'autosize'; // assuming this dependency has been installed and imported

const PostEditingSideBar = (props) => {

    const {
        editor,
        targetlanNo,
        srLanguage,
        sourceCavasData,
        totalLangListRef
    } = props

    const [transliteratebooleanstate, setTransliteratebooleanstate] = useState(false)
    const [transliterate, setTransliterate] = useState('hi')
    const [getActiveObjectText, setGetActiveObjectText] = useState('')

    const transliterateboolean = () => {
        if (transliteratebooleanstate) {
            setTransliteratebooleanstate(false)

        }
        else {
            setTransliteratebooleanstate(true)

        }
    }


    const selectObject = (v, k) => {

        var objs = editor.canvas.getObjects('textbox');
        objs.forEach(function (obj) {
            if (obj && objs.indexOf(obj) == k) {
                editor.canvas.setActiveObject(obj);
                editor.canvas.renderAll();
            }


        });
    }

    const updateActiveObjectText = (e) => {
        setGetActiveObjectText(e.target.value)
        editor.canvas.getActiveObject().set("text", e.target.value)
        editor.canvas.renderAll()
        // updateDesignfromapi2()



    }

    const confirmPostText = (e) => {


        if (e.currentTarget.dataset.id == 'boo') {
            editor.canvas.getActiveObject('textbox').set({
                lockMovementX: false,
                lockMovementY: false,
                // lockScalingX : true,
                // lockScalingY: true,
                editable: true,
                status: 'Unconfirmed'
            })
            editor.canvas.discardActiveObject()
            editor.canvas.renderAll()
        }
    }

    const unConfirmPostText = (e) => {
       
        if (e.currentTarget.dataset.id == 'foo') {
            editor.canvas.getActiveObject('textbox').set({
                lockMovementX: true,
                lockMovementY: true,
                editable: false,
                status: 'Confirmed'

            })
            editor.canvas.discardActiveObject()
            editor.canvas.renderAll()

        }
    }

    useEffect(() => {

        editor?.canvas.getObjects('textbox')

    }, [getActiveObjectText])


    
    const onDragEnd = (e) => {
        // dropped outside the list
        
        editor.canvas.getObjects()[e.source.index].moveTo(e.destination.index)
        editor.canvas.renderAll()
    }



    return (
        <div className="post-editing-sidebar">
            <div className="posteditor-header-container" >
                <div className="posteditor-language-container">
                    <span className="source-target-lang">{totalLangListRef.current[totalLangListRef.current?.map(function (item) { return item.id; }).indexOf(srLanguage)]?.language}</span>
                    <ArrowRightAltIcon className="arrow-right-icon" />
                    <span className="source-target-lang">{totalLangListRef.current[totalLangListRef.current?.map(function (item) { return item.id; }).indexOf(targetlanNo)]?.language}</span>
                </div>
                <div className="posteditor-icon-container">
                    <Transliteration className="posteditor-icons" onClick={transliterateboolean} />
                    <FormatSizeIcon className="posteditor-icons" />
                    <SpellcheckIcon className="posteditor-icons" />
                    <FilterAltOutlinedIcon className="posteditor-icons" />
                </div>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div className="workspace-input-itrate" {...provided.droppableProps}
                            ref={provided.innerRef}>
                            {editor &&
                                Object.entries(editor.canvas.getObjects('textbox')).map(([k, v]) => {
                                    return (
                                        <Draggable key={v.id} draggableId={v.name} index={parseInt(k)}>
                                            {(provided, snapshot) => (
                                                <div className="ailaysa-canvas-text-postediting" ref={provided.innerRef}
                                                    {...provided.draggableProps}>
                                                    {/* <div className="drag-indicator-post-edit"><div {...provided.dragHandleProps}><DragIndicator  /></div> </div> */}
                                                    <div className="postediting-head-text ">
                                                        {sourceCavasData &&
                                                            <div className="source-text">
                                                                        {sourceCavasData?.objects?.filter(function (el) {
                                                                    return el.name == v.name
                                                                })[0].text}
                                                            </div>
                                                        }
                                                        <div className="postediting-tags">
                                                            <span className="tag">{parseInt(k) + 1}</span>
                                                            <HelpOutlineIcon className="posteditor-icons" />
                                                        </div>
                                                    </div>
                                                    <div className="translated-postediting-canvas-area">
                                                        <textarea rows={5} onClick={() => selectObject(v, k)} id={`${parseInt(v.id)}`} value={v.text} placeholder={v.text} onChange={(e) => updateActiveObjectText(e)} className={!transliteratebooleanstate ? 'ailaysa-canvas-textarea' : 'ailaysa-canvas-textarea display-none'}></textarea>
                                                        <ReactTransliterate

                                                            renderComponent={(props) => <textarea rows={5} {...props} />}
                                                            value={v.text}
                                                            className={transliteratebooleanstate ? 'ailaysa-canvas-textarea' : 'ailaysa-canvas-textarea display-none'}
                                                            onChangeText={(v) => {
                                                                editor.canvas.getActiveObject().set({ text: v })
                                                            }}
                                                            onChange={(e) => updateActiveObjectText(e)}
                                                            onClick={() => selectObject(v, k)}
                                                            lang={totalLangListRef.current && totalLangListRef.current[totalLangListRef.current?.map(function (item) { return item.id; }).indexOf(targetlanNo)]?.code }
                                                        />
                                                        <div className="post-editing-confirmation">
                                                            <div id={`confirms1${k}`} className={`${(v.status?.toLowerCase())}-div`}>
                                                                <CircleIcon id={`confirms2${k}`} className={`${v.status?.toLowerCase()}-icon`} />
                                                                <span id={`confirm${k}`} className={`${v.status?.toLowerCase()}-text`}>{v.status}</span>
                                                            </div>
                                                            <div className="post-editing-toolbar">

                                                                <CheckCircleOutlineOutlinedIcon id={`${k}`} data-id="foo" name={`${v.status}`} className="posteditor-icons-sec" style={{ display: v.status == 'Unconfirmed' ? 'block' : 'none' }} onClick={(e) =>
                                                                    unConfirmPostText(e)} />
                                                                <Unconfirmed id={`${k}`} name={`${v.status}`} data-id="boo" className="posteditor-icons-sec" style={{ display: v.status == 'Confirmed' ? 'block' : 'none' }} onClick={(e) =>
                                                                    confirmPostText(e)} />
                                                                <ChatBubbleOutlineIcon className="posteditor-icons-sec" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                            {provided.placeholder}

                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>

    )


}

export default PostEditingSideBar;