import React, { useEffect, useState, useRef } from "react";
// import 'draft-js/dist/Draft.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Rodal from 'rodal';
import Sourcelanguage from '../../../layouts/navbarcomponent/language-modal/Sourcelanguage'
import VoiceEditor from "./VoiceEditor";
import { webSpeechLangFullList, webSpeechLangMap } from "../../../components/sidebarcomponents/speech-dictation/WebSpeechLang";
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const DraftEditor = (props) => {

  let {
    editor,
    fabric,
    scaleCanvas,
  } = props

  const [showSrcLangModal, setshowSrcLangModal] = useState(false);
  const [showSettings, setshowSettings] = useState(false);
  const [sourceLanguage, setSourceLanguage] = useState(17);
  const [sourceLanguageOptions, setSourceLanguageOptions] = useState(null);
  const searchAreaRef = useRef(null);
  const [onFocusWrap, setOnFocusWrap] = useState(false)
  const [searchInput, setSearchInput] = useState('');
  const [sourceLabel, setSourceLabel] = useState("English");
  const [filteredResults, setFilteredResults] = useState([]);
  const [selectedSourceLang, setSelectedSourceLang] = useState("en")

  const handleSourceLangClick = (value, name,code, e) => {
    /* let elements = document.getElementsByClassName('list selected')
    for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove('selected')
    }
    e.target.classList.add("selected") */
    setSelectedSourceLang(code)
    setSourceLanguage(value);
    setshowSrcLangModal(false);
    setSourceLabel(name);
  };


  const dictatFilteredSourceLang = useRef(null)
  const dictatedFilterListWithAccent = useRef(null)




  useEffect(() => {
    let langList = []
    webSpeechLangMap.map(each => langList.push({
      id: each.language_id,
      language: each.language,
      languageKey: each.code
    }))


    // let dictateFilterLang = []
    // //  = langList?.filter(each => webSpeechLang?.some(obj => each.id === obj.value));
    // let temp = webSpeechLangFullList?.filter(each => langList?.some(obj => obj.value === each.value))
    // temp?.map(each => {
    //     dictateFilterLang.push({
    //         id: each.value,
    //         language: each.name,
    //         code: each.code,
    //         code_name: each.code_name
    //     })
    // })
    // dictatedFilterListWithAccent.current = dictateFilterLang
    // dictatFilteredSourceLang.current = [...new Map(dictateFilterLang.map(item => [item['id'], item])).values()]
    // // console.log(dictatFilteredSourceLang.current)
    // console.log(dictatFilteredSourceLang.current)
    setSourceLanguageOptions(langList)

  }, [])







  const [isListening, setIsListening] = useState(false);



  const hideSettingsModal = () => setshowSettings(false);


  const modaloption = {
    closeMaskOnClick: false,
    width: 600,
    height: 315,
    onClose: hideSettingsModal,
    animation: "fade",
    duration: "0"
  };




  return (
    <div className='ts-dictation' >
      <VoiceEditor className="voice-button"
        editor={editor}
        fabric={fabric}
        scaleCanvas={scaleCanvas}
        isListening={isListening}
        setIsListening={setIsListening}
        sourceLanguage={sourceLanguage}
        selectedSourceLang={selectedSourceLang}
      />
      <ArrowDropDownIcon className="down-arrow-icon" onClick={() =>
         { document.querySelector('.ailaysa-canvas-working-col-wrapper').style.zIndex = 4;
         document.querySelector('.text-tools').style.zIndex = 2
          setshowSrcLangModal(true)}} />
      {/* <UncontrolledPopover
        placement="right"
        target="PopoverClick2"
        trigger="hover"
        className="speech-pop"
       

      > */}
        {/* <div className="labguage-select-pop" onClick={() =>
         { document.querySelector('.ailaysa-canvas-working-col-wrapper').style.zIndex = 4;
          setshowSrcLangModal(true)}} >
          {sourceLabel}
          <ArrowDropDownIcon className="down-arrow-icon" />
        </div> */}

      {/* </UncontrolledPopover> */}
      <Rodal visible={showSrcLangModal} {...modaloption} onClose={() =>{
        document.querySelector('.ailaysa-canvas-working-col-wrapper').style.zIndex = 0
        document.querySelector('.text-tools').style.zIndex = 2
        setshowSrcLangModal(false)}} showCloseButton={true} className="ai-lang-select-modal">
        <Sourcelanguage
          sourceLanguage={sourceLanguage}
          setshowSrcLangModal={setshowSrcLangModal}
          sourceLanguageOptions={sourceLanguageOptions}
          handleSourceLangClick={handleSourceLangClick}
          filteredResults={filteredResults}
          setFilteredResults={setFilteredResults}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          onFocusWrap={onFocusWrap}
          setOnFocusWrap={setOnFocusWrap}
          searchAreaRef={searchAreaRef}
        />
      </Rodal>

    </div>
  );
};

export default DraftEditor;