export const webSpeechLangFullList = [
    {value: 1,name: 'Afrikaans', code: 'af-ZA'},
    {value: 0,name: 'Amharic', code: 'am-ET'},
    {value: 0,name: 'Azerbaijani', code: 'az-AZ'},
    {value: 91,name: 'Bengali', code: 'bn-BD', code_name: 'বাংলাদেশ'},
    {value: 91,name: 'Bengali', code: 'bn-IN', code_name: 'ভারত'},
    {value: 36,name: 'Indonesian', code: 'id-ID'},
    {value: 52,name: 'Malay', code: 'ms-MY'},
    {value: 11,name: 'Catalan', code: 'ca-ES'},
    {value: 14,name: 'Czech ', code: 'cs-CZ'},
    {value: 15,name: 'Danish', code: 'da-DK'},
    {value: 26,name: 'German', code: 'de-DE'},
    {value: 17,name: 'English', code: 'en-AU', code_name: 'Australia'},
    {value: 17,name: 'English', code: 'en-CA', code_name: 'Canada'},
    {value: 17,name: 'English', code: 'en-IN', code_name: 'India'},
    {value: 17,name: 'English', code: 'en-KE', code_name: 'Kenya'},
    {value: 17,name: 'English', code: 'en-TZ', code_name: 'Tanzania'},
    {value: 17,name: 'English', code: 'en-GH', code_name: 'Ghana'},
    {value: 17,name: 'English', code: 'en-NZ', code_name: 'New Zealand'},
    {value: 17,name: 'English', code: 'en-NG', code_name: 'Nigeria'},
    {value: 17,name: 'English', code: 'en-ZA', code_name: 'South Africa'},
    {value: 17,name: 'English', code: 'en-PH', code_name: 'Philippines'},
    {value: 17,name: 'English', code: 'en-GB', code_name: 'United Kingdom'},
    {value: 17,name: 'English', code: 'en-US', code_name: 'United States'},
    {value: 73,name: 'Spanish', code: 'es-AR', code_name: 'Argentina'},
    {value: 73,name: 'Spanish', code: 'es-BO', code_name: 'Bolivia'},
    {value: 73,name: 'Spanish', code: 'es-CL', code_name: 'Chile'},
    {value: 73,name: 'Spanish', code: 'es-CO', code_name: 'Colombia'},
    {value: 73,name: 'Spanish', code: 'es-CR', code_name: 'Costa Rica'},
    {value: 73,name: 'Spanish', code: 'es-EC', code_name: 'Ecuador'},
    {value: 73,name: 'Spanish', code: 'es-SV', code_name: 'El Salvador'},
    {value: 73,name: 'Spanish', code: 'es-ES', code_name: 'España'},
    {value: 73,name: 'Spanish', code: 'es-US', code_name: 'Estados Unidos'},
    {value: 73,name: 'Spanish', code: 'es-GT', code_name: 'Guatemala'},
    {value: 73,name: 'Spanish', code: 'es-HN', code_name: 'Honduras'},
    {value: 73,name: 'Spanish', code: 'es-MX', code_name: 'México'},
    {value: 73,name: 'Spanish', code: 'es-NI', code_name: 'Nicaragua'},
    {value: 73,name: 'Spanish', code: 'es-PA', code_name: 'Panamá'},
    {value: 73,name: 'Spanish', code: 'es-PY', code_name: 'Paraguay'},
    {value: 73,name: 'Spanish', code: 'es-PE', code_name: 'Perú'},
    {value: 73,name: 'Spanish', code: 'es-PR', code_name: 'Puerto Rico'},
    {value: 73,name: 'Spanish', code: 'es-DO', code_name: 'República Dominicana'},
    {value: 73,name: 'Spanish', code: 'es-UY', code_name: 'Uruguay'},
    {value: 73,name: 'Spanish', code: 'es-VE', code_name: 'Venezuela'},
    {value: 0,name: 'Basque ', code: 'eu-ES'},
    {value: 20,name: 'Filipino', code: 'fil-PH'},
    {value: 22,name: 'French', code: 'fr-FR'},
    {value: 0,name: 'Javanese', code: 'jv-ID'},
    {value: 0,name: 'Galician', code: 'gl-ES'},
    {value: 28,name: 'Gujarati', code: 'gu-IN'},
    {value: 0,name: 'Croatian', code: 'hr-HR'},
    {value: 0,name: 'Zulu', code: 'zu-ZA'},
    {value: 34,name: 'Icelandic', code: 'is-IS'},
    {value: 38,name: 'Italian ', code: 'it-IT', code_name: 'Italia'},
    {value: 38,name: 'Italian ', code: 'it-CH', code_name: 'Svizzera'},
    {value: 40,name: 'Kannada', code: 'kn-IN'},
    {value: 0,name: 'Khmer', code: 'km-KH'},
    {value: 47,name: 'Latvian', code: 'lv-LV'},
    {value: 0,name: 'Lithuanian ', code: 'lt-LT'},
    {value: 53,name: 'Malayalam', code: 'ml-IN'},
    {value: 0,name: 'Marathi', code: 'mr-IN'},
    {value: 33,name: 'Hungarian', code: 'hu-HU'},
    {value: 0,name: 'Laos', code: 'lo-LA'},
    {value: 16,name: 'Dutch', code: 'nl-NL'},
    {value: 0,name: 'Nepali', code: 'ne-NP'},
    {value: 0,name: 'Bokmål', code: 'pl-PL'},
    {value: 62,name: 'Polish', code: ''},
    {value: 63,name: 'Portuguese', code: 'pt-BR', code_name: 'Brasil'},
    {value: 63,name: 'Portuguese', code: 'pt-PT', code_name: 'Portugal'},
    {value: 65,name: 'Romanian', code: 'ro-RO'},
    {value: 0,name: 'Sinhala', code: 'si-LK'},
    {value: 0,name: 'Slovenian', code: 'sl-SI'},
    {value: 75,name: 'Swedish', code: 'sv-SE'},
    {value: 0,name: 'Swahili', code: 'sw-TZ', code_name: 'Tanzania'},
    {value: 0,name: 'Swahili', code: 'sw-KE', code_name: 'Kenya'},
    {value: 0,name: 'Georgian', code: 'ka-GE'},
    {value: 0,name: 'Armenian', code: 'hy-AM'},
    {value: 77,name: 'Tamil', code: 'ta-IN', code_name: 'இந்தியா'},
    {value: 77,name: 'Tamil', code: 'ta-SG', code_name: 'சிங்கப்பூர்'},
    {value: 77,name: 'Tamil', code: 'ta-LK', code_name: 'இலங்கை'},
    {value: 77,name: 'Tamil', code: 'ta-MY', code_name: 'மலேசியா'},
    {value: 79,name: 'Telugu', code: 'te-IN'},
    {value: 86,name: 'Vietnamese', code: 'vi-VN'},
    {value: 81,name: 'Turkish', code: 'tr-TR'},
    {value: 0,name: 'Urdu', code: 'ur-PK', code_name: 'پاکستان'},
    {value: 0,name: 'Urdu', code: 'ur-IN', code_name: 'بھارت'},
    {value: 27,name: 'Greek', code: 'el-GR'},
    {value: 10, name: 'Bulgarian', code: 'bg-BG'},
    {value: 66,name: 'Russian', code: 'ru-RU'},
    {value: 67,name: 'Serbian', code: 'sr-RS'},
    {value: 82,name: 'Ukrainian', code: 'uk-UA'},
    {value: 44,name: 'Korean', code: 'ko-KR'},
    {value: 0,name: 'Chinese', code: 'cmn-Hans-CN', code_name: '普通话 (中国大陆)'},
    {value: 0,name: 'Chinese', code: 'cmn-Hans-HK', code_name: '普通话 (香港)'},
    {value: 0,name: 'Chinese', code: 'cmn-Hant-TW', code_name: '中文 (台灣)'},
    {value: 0,name: 'Chinese', code: 'yue-Hant-HK', code_name: '粵語 (香港)'},
    {value: 39,name: 'Japanese', code: 'ja-JP'},
    {value: 32,name: 'Hindi', code: 'hi-IN'},
    {value: 80,name: 'Thai', code: 'th-TH'},
]


export const webSpeechLangMap = [
    {
        "language": "Bulgarian",
        "language_id": 10,
        "code": "bg"
    },
    {
        "language": "Catalan",
        "language_id": 11,
        "code": "ca"
    },
    {
        "language": "Czech",
        "language_id": 14,
        "code": "cs"
    },
    {
        "language": "Danish",
        "language_id": 15,
        "code": "da"
    },
    {
        "language": "Dutch",
        "language_id": 16,
        "code": "nl"
    },
    {
        "language": "English",
        "language_id": 17,
        "code": "en"
    },
    {
        "language": "Filipino",
        "language_id": 20,
        "code": "fil"
    },
    {
        "language": "Finnish",
        "language_id": 21,
        "code": "fi",
    },
    {
        "language": "French",
        "language_id": 22,
        "code": "fr",
    },
    {
        "language": "German",
        "language_id": 26,
        "code": "de",
    },
    {
        "language": "Greek",
        "language_id": 27,
        "code": "el",
    },
    {
        "language": "Gujarati",
        "language_id": 28,
        "code": "gu",
    },
    {
        "language": "Hindi",
        "language_id": 32,
        "code": "hi",
    },
    {
        "language": "Hungarian",
        "language_id": 33,
        "code": "hu",
    },
    {
        "language": "Icelandic",
        "language_id": 34,
        "code": "is",
    },
    {
        "language": "Indonesian",
        "language_id": 36,
        "code": "id",   
    },
    {
        "language": "Italian",
        "language_id": 38,
        "code": "it",   
    },
    {
        "language": "Japanese",
        "language_id": 39,
        "code": "ja",
    },
    {
        "language": "Kannada",
        "language_id": 40,
        "code": "kn",
    },
    {
        "language": "Korean",
        "language_id": 44,
        "code": "ko",
    },
    {
        "language": "Latvian",
        "language_id": 47,
        "code": "lv",
    },
    {
        "language": "Malay",
        "language_id": 52,
        "code": "ms",
    },
    {
        "language": "Malayalam",
        "language_id": 53,
        "code": "ml",
    },
    {
        "language": "Polish",
        "language_id": 62,
        "code": "pl",
    },
    {
        "language": "Portuguese",
        "language_id": 63,
        "code": "pt",
    },
    {
        "language": "Punjabi",
        "language_id": 64,
        "code": "pa",
    },
    {
        "language": "Romanian",
        "language_id": 65,
        "code": "ro",
    },
    {
        "language": "Russian",
        "language_id": 66,
        "code": "ru",
    },
    {
        "language": "Serbian",
        "language_id": 67,
        "code": "sr",
    },
    {
        "language": "Slovak",
        "language_id": 70,
        "code": "sk",
    },
    {
        "language": "Spanish",
        "language_id": 73,
        "code": "es",
    },
    {
        "language": "Swedish",
        "language_id": 75,
        "code": "sv",
    },
    {
        "language": "Tamil",
        "language_id": 77,
        "code": "ta",
    },
    {
        "language": "Telugu",
        "language_id": 79,
        "code": "te",
    },
    {
        "language": "Thai",
        "language_id": 80,
        "code": "th",
    },
    {
        "language": "Turkish",
        "language_id": 81,
        "code": "tr",
    },
    {
        "language": "Ukrainian",
        "language_id": 82,
        "code": "uk",
    },
    {
        "language": "Vietnamese",
        "language_id": 86,
        "code": "vi",
    },
    {
        "language": "Bengali",
        "language_id": 91,
        "code": "bn",
    },
    {
        "language": "Norwegian",
        "language_id": 99,
        "code": "no",
    },
    {
        "language": "Chinese (Traditional)",
        "language_id": 107,
        "code": "zh-TW",
    },
    {
        "language": "Chinese (Simplified)",
        "language_id": 108,
        "code": "zh-CN",
    },
    {
        "language": "Arabic",
        "language_id": 4,
        "code": "ar",
    },
    {
        "language": "Afrikaans",
        "language_id": 1,
        "code": "af",
    }
]