import React, { useState, useRef, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { FontFamilyArray} from './SideBarData'
import FontFaceObserver from 'fontfaceobserver';
import Select, { components } from "react-select";
import VirtualScroll from "../VirtualScroll";

const FontFamily = (props) => {

    const {
        handleSidebarClose,
        editor,
        setActiveFontFamilyShow,
        handleAuthTrue,
        LABS_API

    } = props
    const [activeFontFamily, setActiveFontFamily] = useState()
    // const [type, setType] = useState()
    const typeSearch = useRef()
    const [inputText, setInputText] = useState("");
    const [focusOnSearch, setFocusOnSearch] = useState(false)
    const [fontFamilyLangList, setFontFamilyLangList] = useState([])
    const [ totalFontFamilyList, setTotalFontFamilyList] = useState([])

    let inputHandler = (e) => {
        var lowerCase = e.target.value.toLowerCase();
        setInputText(lowerCase);
    };

    const filteredData = totalFontFamilyList.filter((el) => {
        if (inputText === '') {
            return el;
        }
        else {
            return el.toLowerCase().includes(inputText)
        }
    })

    const removeDuplicateLink = () => {
        // Get all link tags with a href attribute containing "fonts.googleapis.com"
        const fontLinks = document.querySelectorAll('link[href*="fonts.googleapis.com"]');

        // Create an array to store the unique href values
        const uniqueLinks = [];

        // Loop through the fontLinks and check if the href value is already in the uniqueLinks array
        fontLinks.forEach(link => {
            const href = link.getAttribute('href');
            if (!uniqueLinks.includes(href)) {
                uniqueLinks.push(href);
            } else {
                link.remove(); // Remove duplicate link tag
            }
        });
    }

    const fontFamilySet = (fontFam) => {

        if(fontFam != 'Coda Caption' && fontFam != 'Sunflower' && fontFam != 'Buda' && fontFam != 'UnifrakturCook'){
            var link = document.createElement('link')
            link.href = `https://fonts.googleapis.com/css?family=${fontFam}`
            link.rel = 'stylesheet'
            link.setAttribute('name', fontFam)
            document.querySelector('head').appendChild(link)
        }
       
        var font = new FontFaceObserver(fontFam);
        font.load().then(function () {
            console.log('Font is available');
            editor.canvas.getActiveObjects().forEach((e) => {
                e.set({ fontFamily: fontFam })
            })
            editor.canvas.renderAll();
            setActiveFontFamily(editor?.canvas.getActiveObject('textbox')?.fontFamily)
            setActiveFontFamilyShow(editor?.canvas.getActiveObject('textbox')?.fontFamily)
            removeDuplicateLink()
        }, function () {
            console.log('Font is not available');

        });

        // editor.canvas.getActiveObject().set({    
        //     fontFamily: fontFam
        // })

    }

    const customProjectTypeSelectStyles = {
        placeholder: (provided, state) => ({
            ...provided,
            color: "#3C4043",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontWeight: "500",
            lineHeight: "24px",
        }),
        menu: (provided, state) => ({
            ...provided,
            padding: "6px 0px 0px 0px",
            boxShadow: "0px 3px 6px #00000029",
            border: "1px solid #DADADA",
            borderRadius: "4px",
            
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: "0px solid #CED4DA",
            borderLeft: "2px solid transparent",
            color: state.isSelected ? "#ffffff" : state.isDisabled ? "#cccccc" : "#7E7E7E",
            background: state.isSelected ? "#F4F5F7" : "#ffffff",
            display: "flex",
            marginBottom: "0.2rem",
            padding: "4px 6px",
            color: "#292929",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "24px",
            "&:hover": {
                background: "#F4F5F7",
                borderLeft: "2px solid #0074D3",
                cursor: "pointer",
            },
        }),
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? "0px solid #0074D3" : "0px solid #DBDBDB",
            backgroundColor: "#e9ebee",
            borderRadius: 4,
            transtion: 0.3,
            color: "#3C4043",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontWeight: "500",
            lineHeight: "24px",
            boxShadow: 0,
            padding: "0px 10px",
            width: 150,
            height: 40,
            "&:hover": {
                cursor: "pointer",
            },
        }),
    };

    const getFontFamilyLang = () => {

        (async function () {

            var requestOptions = {
                method: 'GET',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/font-language/", requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                console.log(response)
                let langTypes = response
                let langAr = [{value: 0 ,label:'All Languages'}]
                langTypes.forEach((lang) => {
                    if(lang.id != 29){
                        langAr.push({
                            value:lang.id,
                            label:lang.name
                        })
                    }
                  
                })
                setFontFamilyLangList(langAr)

          }
            else {
                // console.log('error');
            }
        })();
    }

    useEffect(() => {
        if(fontFamilyLangList.length == 0){

            getFontFamilyLang()
        } 
        if(totalFontFamilyList.length == 0){
            getFontFamily()

        }
    },[])

       // Font family list

       const getFontFamily = () => {

        (async function () {

            var requestOptions = {
                method: 'GET',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/font-family/", requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                setTotalFontFamilyList(response.font_family_name)
            }
            else {
                // console.log('error');
            }
        })();
    }

         // Font family list

         const getFontFamilyEachLang = (id) => {

            (async function () {
    
                var requestOptions = {
                    method: 'GET',
                    headers: handleAuthTrue(new Headers()),
                    redirect: 'follow'
                };
    
                let data = await fetch(LABS_API + `/canvas/font-data/?font_lang=${id}`, requestOptions)
                if (data.status === 200) {
                    let response = await data.json()
                    setTotalFontFamilyList(response.font_list)
                }
                else {
                    // console.log('error');
                }
            })();
        }
    






    // ]
    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                {props.selectProps.menuIsOpen ? <span id="triangle-up"></span> : <span id="triangle-down"></span>}
                
            </components.DropdownIndicator>
        );
    };

    const handleStatusFilter = (selected) => {
        if(selected.value == 0){
            getFontFamily()
        }else{
            getFontFamilyEachLang(selected.value)
        }
    }

    // console.log(activeFontFamily);

    const fontFamilyRenderList = filteredData.map((list) => {
        return (
            <>
            <div className="size-type-ff" style={{ fontFamily: list }} value={list} onClick={() => fontFamilySet(list)} >
                <img className="font-family-thumbnail" src={LABS_API + "/canvas/font-thumnail/"+list?.split(' ')?.join('-')+ '.png'} alt={list} />
                </div>
            </>
        )
    })

    return (
        <div className="sidebar-content">
            <div className="sidebar-tabs-heading">
                <span>Font Family</span>
                {/* <CloseIcon className="close-icon" onClick={handleSidebarClose} /> */}
            </div>
            <div className={focusOnSearch ? "main-tab-search active-search-bar" : "main-tab-search"}>
                <SearchIcon className="seacrh-icon" onClick={inputHandler} />
                <span className="search-placeholder-text">
                    <input type="text" onKeyDown={inputHandler} onClick={() => {
                        setFocusOnSearch(true)
                    }} onBlur={(e) => {
                        setFocusOnSearch(false);
                    }} className="searcher" ref={typeSearch} onChange={inputHandler} placeholder="Search..." />
                </span>
            </div>
            <div className="lang-select-bar-font-fam">
                <Select  
                    options={fontFamilyLangList}
                    isSearchable={true}
                    styles={customProjectTypeSelectStyles}
                    classNamePrefix="project-type-list"
                    placeholder={'All Languages'}
                    components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                    onChange={handleStatusFilter}
                />
            
            </div>

            <div className="fontFamily-container">
                <VirtualScroll
                height="76vh"
                totalElements = {filteredData.length}
                rowHeight={40}
                items={fontFamilyRenderList}
                visibleItemsLength={25}
                 >
                </VirtualScroll>
            </div>
        </div>

    )


}

export default FontFamily;