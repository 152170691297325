import React, { useState,useRef ,useEffect,useCallback} from "react";
import SearchIcon from '@mui/icons-material/Search';
import FontFaceObserver from 'fontfaceobserver';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Masonry from '@mui/lab/Masonry';
import Grow from '@mui/material/Grow';
import Skeleton from '@mui/material/Skeleton';


const MyTemplate = (props) => {

    const {
        editor,
        canvaswidth,
        canvasheight,
        LABS_API,
        handleAuthTrue,
        name,
        pageNo,
        globalTemplate,
        myTemplate,
        setMyTemplate,
        getGlobalTemplate,
        handlesize,
        setCanvasBgColor,
        setManualChecked,
        setTranslatedImageid,
        translatedImageid,
        navigate,
        notify,
        workspaceSwitch,
        getMyTemplate,
        setShowTemplateNotice,
        setTemplateData,
        setTemplateTotalData,
        settemplateFrom,
        toggleState,
        designid,
        loadFabricJSONDataTemplate,
        handleSave
    } = props;

    const [focusOnSearch, setFocusOnSearch] = useState(false)
    const typeSearch = useRef()
    const nextpage = useRef()
    const loadingMytemplate = useRef(false)

    const [inputText, setInputText] = useState("");
    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        setInputText(lowerCase);
    };


    const filteredData = myTemplate?.filter((el) => {
        //if no input the return the original
        if (inputText === '') {
            return el;
        }
        //return the item which contains the user input
        else {
            return el.file_name?.toLowerCase().includes(inputText)
        }
    })


    // var half = Math.ceil(filteredData?.length / 2)
    // var firstHalf = filteredData?.slice(0, half)
    // var secondHalf = filteredData?.slice(half)

    
    // function loadFabricJSONData(json,id) {
    //     // Get all font family names from the JSON data, including nested groups
       
    //     setCanvasBgColor(json.backgroundImage.fill)
    //     const fontFamilies = [];

    //     function extractFontFamilies(obj) {
    //         if (obj.fontFamily && !fontFamilies.includes(obj.fontFamily)) {
    //             fontFamilies.push(obj.fontFamily);
    //         }
    //         if (obj.type === 'group' && obj.objects) {
    //             obj.objects.forEach((groupObj) => extractFontFamilies(groupObj));
    //         }
    //     }
    //     json.objects.forEach((obj) => extractFontFamilies(obj));

    //     // Remove duplicate font families
    //     const uniqueFontFamilies = [...new Set(fontFamilies)];
    //     console.log(uniqueFontFamilies)
    //     // Check if all font families are loaded in the DOM
    //     uniqueFontFamilies.forEach((fontFamily) => {
    //         var link = document.createElement('link')
    //         link.href = `https://fonts.googleapis.com/css?family=${fontFamily}`
    //         link.rel = 'stylesheet'
    //         link.setAttribute('name', fontFamily)
    //         document.querySelector('head').appendChild(link)
    //     });
    //     if (uniqueFontFamilies.length == 0) {
    //         // document.querySelector('.canvas-space').classList.remove('loading-json-blur');
    //         // document.querySelector('#loading-wrapper').style.setProperty('display', 'none');
    //         loadTemplate(json,id)
    //     }
    //     // If all font families are loaded, load the JSON data in the canvas
    //     uniqueFontFamilies.forEach((obj, index) => {
    //         var font = new FontFaceObserver(obj);
    //         font.load().then(function () {
    //             console.log('Font is available');
    //             if (index == (uniqueFontFamilies.length - 1)) {
    //                 setTimeout(() => {
    //                     loadTemplate(json,id)
    //                 }, 500);
    //             }
    //         }, function () {
    //             console.log('Font is not available');
    //             font.load().then(function () {
    //                 console.log('Font is available');
    //                 setTimeout(() => {
    //                     loadTemplate(json,id)
    //                 }, 500);
    //             }, function () {
    //                 console.log('Font is not available');
    //             });
    //         });
    //     })
    // }


    const fetchTemplate= (id) =>{
       
        (async function () {
            document.querySelector('.canvas-space').classList.add('loading-json-blur');
            document.querySelector('#loading-wrapper').style.setProperty('display', 'grid');
            settemplateFrom('my')
            var requestOptions = {
                method: 'GET',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + `/canvas/mytemplate-design-get/${id}`, requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                // console.log(response.my_template_page[0].my_template_json);
                // console.log(response)
                // getGlobalTemplate()
                // loadFabricJSONData(response.my_template_page[0].my_template_json)setTemplateData(response.template_globl_pag[0].json_page)
                setTemplateData(response.my_template_page[0].my_template_json)
                setTemplateTotalData(response)
                if(designid != null){
                    setShowTemplateNotice(true)
                }else{
                    loadFabricJSONDataTemplate(response.my_template_page[0].my_template_json)
                }
            
            }
            else {
                // setIsSaved(true)
                console.log('error');
                document.querySelector('.canvas-space').classList.remove('loading-json-blur');
                document.querySelector('#loading-wrapper').style.setProperty('display', 'none');
            }
        })();


      
    }

    // const loadTemplate = (json,id) => {
           
        
    //     if(translatedImageid != null && workspaceSwitch !== 'Workspace'){
    //         editor.canvas.isDrawingMode = false
    //         setTranslatedImageid(null)
    //         editor.canvas.clear()
    //         notify()
    //         setManualChecked(false)
    //         navigate('/ailaysa-canvas')
    //     }
    //     console.log(json)
    //     if(workspaceSwitch === 'Workspace'){
    //         editor.canvas.clear()
    //         navigate(`/ailaysa-canvas/template/?id=${id}`, {state: json})
    //         // var size = { name: 'loadcanvas', width:canvaswidth, height: canvasheight,fill:'rgba(255,255,255,1)' }
    //         // setAutoSaved(false)
    //         // setName('Untitled Project')
    //         // setDesignid(null)
    //         // setDesigntemp(false)
    //         // setCanvasmodified(false)
    //         // setCanvasBgColor('rgba(255,255,255,1)')
    //         // // editor.canvas.backgroundImage = new fabric.Rect({ width: canvaswidth, height: canvasheight,fill:'rgba(255,255,255,1)' });
    //         // // handleClose()
    //         // handlesize(size)

    //     }
        
    //     editor?.canvas.loadFromJSON(json, function () {
    //         editor?.canvas.renderAll.bind(editor?.canvas.requestRenderAll());
    //     })
    //     var size = { name: 'loadcanvas', width: json.backgroundImage.width, height: json.backgroundImage.height ,fill:json.backgroundImage.fill}
    //     handlesize(size)
    //     // editor.canvas.backgroundImage = new fabric.Rect({ width: json.backgroundImage.width, height: json.backgroundImage.height, fill: json.backgroundImage.fill });
    //     setCanvasBgColor(json.backgroundImage.fill)
    //     setTimeout(() => {
    //         document.querySelector('.canvas-space').classList.remove('loading-json-blur');
    //         document.querySelector('#loading-wrapper').style.setProperty('display', 'none');
    //     }, 500);

    // }

    const deleteTemplate= (id,filesource) =>{
        (async function () {
            var requestOptions = {
                method: 'DELETE',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + `/canvas/mytemplate-design/${id}`, requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                // getMyTemplate()
                setMyTemplate(prevState => prevState.filter(item =>  item !== filesource))
            }
            else {
                // setIsSaved(true)
                console.log('error');
               
            }
        })();


      
    }





        
    const getTempdata = async(e)=>{
        loadingMytemplate.current = true
        var data = await getMyTemplate(e?e:1)
        setMyTemplate(prev=>[...prev,...data.data])
        loadingMytemplate.current = false
        if (data.next != null) {
            nextpage.current = true
        } else {
            nextpage.current = false
        }
    }

    useEffect(()=>{
        if(toggleState == 4){
            page.current = 1
            getTempdata(page.current)
        }
        else{
            setMyTemplate([])
        }
        // if(toggleState == 3){
        //     page.current = 1
        //     setTranslatedimage([])
        //     // getMyTranslatedImageproject()
        // }
    },[toggleState])



    const page = useRef(1)
    const observer = useRef(); // ref to store observer
    const lastElementRef = useCallback((element) => {
        //element is the react element being referenced
        // disconnect observer set on previous last element
        if (observer.current) observer.current.disconnect();
        // if there's no more data to be fetched, don't set new observer
        if (!nextpage.current) return;
        // set new observer
        observer.current = new IntersectionObserver(async (entries) => {
            // increase page number when element enters (is intersecting with) viewport.
            // This triggers the pagination hook to fetch more items in the new page
            if (entries[0].isIntersecting && nextpage.current) {
                page.current += 1
                getTempdata(page.current)
                // getphotodata(page.current)
                // var data = await  getGlobalTemplate(page.current)
                // setglobalTemplate( prev => [...prev,...data.data])
                // if (data.next != null) {
                //     nextpage.current = true
                // } else { 
                //     nextpage.current = false
                // }
            }
            else {
                // no more templates
                console.log('nomore');
            }
        });

        // observe/monitor last element
        if (element) observer.current.observe(element);
    });





    return (
        <>
        <div className={focusOnSearch ? "main-tab-search active-search-bar" : "main-tab-search"}>
            <SearchIcon className="seacrh-icon" onClick={inputHandler} />
            <span className="search-placeholder-text">
                <input type="text" onKeyDown={inputHandler} onClick={() => {
                    setFocusOnSearch(true)
                }} onBlur={(e) => {
                    setFocusOnSearch(false);
                }} className="searcher" ref={typeSearch} onChange={inputHandler} placeholder="Search..." />
            </span>
        </div>
                {!loadingMytemplate.current && filteredData.length == 0 && <>No Result found</>}
        <div className="grid-design-tab grid-photos">

                <Masonry columns={2} spacing={0.8}>
                    {filteredData.length != 0 && filteredData?.map((value, key) => {
                        return (
                            <Grow ref={key === filteredData.length - 1 ? lastElementRef : undefined}
                                in={true}
                                style={{ transformOrigin: '0 0 0' }}
                                {...(true ? { timeout: 1000 } : {})}>
                                <div className="template-global" onClick={(e) => {
                                    fetchTemplate(value.id)
                                }}>
                                    <div className="tool-container">
                                        <Tooltip

                                            title="Delete" placement="top" arrow>
                                            <DeleteForeverIcon className="delete-icon" onClick={(e) => {
                                                e.stopPropagation()
                                                deleteTemplate(value.id,value)

                                            }} />
                                        </Tooltip>

                                    </div>
                                    <img className="main-bar-image-temp" src={LABS_API + value.my_template_thumbnail} />
                                </div>
                            </Grow>
                        )

                    })
                    }
                    {loadingMytemplate.current && <>
                        <Skeleton variant="rectangular" width={150} height={300} />
                        <Skeleton variant="rectangular" width={150} height={100} />
                        <Skeleton variant="rectangular" width={150} height={320} />
                        <Skeleton variant="rectangular" width={150} height={200} />
                        <Skeleton variant="rectangular" width={150} height={320} />
                        <Skeleton variant="rectangular" width={150} height={120} />
                        <Skeleton variant="rectangular" width={150} height={220} />
                        <Skeleton variant="rectangular" width={150} height={170} />
                        <Skeleton variant="rectangular" width={150} height={180} />
                    </>

                    }
                </Masonry>
            {/* <div className="template-half">
                {firstHalf?.map((value, key) => {
                    return (
                        <div>
                            <div className="template-global" onClick={(e) => {
                                fetchTemplate(value.id)
                            }}>
                                         <div className="tool-container">
                                    <Tooltip

                                        title="Delete" placement="top" arrow>
                                        <DeleteForeverIcon className="delete-icon" onClick={(e) => {
                                            e.stopPropagation()
                                            deleteTemplate(value.id)

                                        }} />
                                    </Tooltip>
                                   
                                </div>
                                <img className="main-bar-image-temp" src={LABS_API +  value.my_template_thumbnail} />
                            </div>
                        </div>
                    )

                })
                }
            </div>
            <div className="template-half">
                {secondHalf?.map((value, key) => {
                    return (
                        <div>
                            <div className="template-global" onClick={(e) => {
                                fetchTemplate(value.id)
                            }}>
                                  <div className="tool-container">
                                    <Tooltip

                                        title="Delete" placement="top" arrow>
                                        <DeleteForeverIcon className="delete-icon" onClick={(e) => {
                                            e.stopPropagation()
                                            deleteTemplate(value.id)
                                        }} />
                                    </Tooltip>
                                   
                                </div>
                                <img className="main-bar-image-temp" src={LABS_API  + value.my_template_thumbnail} />
                            </div>
                        </div>
                    )

                })
                }
            </div> */}

        </div>
    </>
    )


}

export default MyTemplate;