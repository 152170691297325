// import { ButtonBase } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Label, FormGroup } from "reactstrap";
import Cookies from "js-cookie";
import Config from "../config/Config";
import axios from "axios";


export const refreshBackgroundImage = () => {

    var loginImages = [
        "login-page-bg.jpg",
        "banner1.jpg",
        "banner2.jpg",
        "banner3.jpg",
        "banner4.jpg",
        "banner5.jpg",
        "banner6.jpg",
        // "banner7.jpg",
        "banner8.jpg",
        "banner9.jpg",
    ];
    document.getElementsByClassName("login-left-side-wrapper")[0].style.backgroundImage =
        "url(" + window.location.origin + "/media/images/auth/login/" + loginImages[Math.floor(Math.random() * loginImages.length)] + ")";
};



const Login = (props) => {


    
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [pwdVisibility, setPwdVisibility] = useState(false);
    const [pwdSignVisibility, setPwdSignVisibility] = useState(false);

    const [showLoginLoader, setShowLoginLoader] = useState(false);

    useEffect(() => {
        refreshBackgroundImage();
        if (Config.userState != null) {
            window.location.href = "/ailaysa-canvas";
        }
    }, []);

    const handleSubmit = async(e) => {
        e.preventDefault()
        var requestOptions = {
            method: 'POST',
            body: handleFormData(new FormData()),
            redirect: 'follow'
        };
        setShowLoginLoader(true)
        let data = await fetch(Config.LABS_API + "/auth/dj-rest-auth/login/", requestOptions)
        if(data.status === 200){
            let response = await data.json()
            let userData = {
                id: response?.user?.pk,
                firstName: response.user.first_name,
                lastName: response.user.last_name,
                userName: response.user.username,
                email: response.user.email,
                token: response.access_token,
                refresh_token: response.refresh_token,
            };
            Cookies.set(process.env.REACT_APP_USER_COOKIE_KEY_NAME, JSON.stringify(userData), { domain: Config.COOKIE_DOMAIN });
            // setTimeout(() => {
            //     setShowLoginLoader(false)
            //     window.location.href = "/ailaysa-canvas";
            // }, 500);
        }
    };


    const handleSubmitSub = (e) => {
        // Actual login submission to the backend
        e.preventDefault();
        let formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);
        // setShowLoginLoader(true);
        // setShowAccessDenied(false);
        axios
            .post(Config.BASE_URL + "/auth/dj-rest-auth/login/", formData, { "Access-Control-Allow-Origin": "*" /*, withCredentials: true*/ })
            .then((response) => {
                let userDataSub = {
                    id: response.data?.user?.pk,
                    name: response.data.user.fullname,
                    email: response.data.user.email,
                    token: response.data.access_token,
                    refresh_token: response.data.refresh_token,
                    user_status: response.data.deactivate ? false : true,
                    is_internal_member: response.data?.user?.is_internal_member,
                    internal_member_team_detail: response.data?.user?.internal_member_team_detail,
                };
                axios
                    .get(Config.BASE_URL + "/auth/profile-images", { headers: { "Access-Control-Allow-Origin": "*", Authorization: `Bearer ${userDataSub.token}` } })
                    .then((profileResponse) => {
                        userDataSub.image_url = profileResponse?.data?.avatar;
                        /* Removing the old cookie and set a new one */
                        Cookies.remove(process.env.REACT_APP_USER_COOKIE_KEY_NAME_SUB, { domain: Config.COOKIE_DOMAIN });
                        Cookies.set(process.env.REACT_APP_USER_COOKIE_KEY_NAME_SUB, JSON.stringify(userDataSub), { domain: Config.COOKIE_DOMAIN });
                    });
                    setTimeout(() => {
                        setShowLoginLoader(false)
                        window.location.href = "/ailaysa-canvas";
                    }, 500);
            })
            .catch((error) => {
                if (error?.response?.data != null) Config.toast(error?.response?.data?.detail, "error");
            })
            .finally();
    };

    const handleFormData = (formData) => {
        formData.append("email", email);
        formData.append("password", password);
        return formData;
    };

    const handlePwdVisibilityChange = () => {
        setPwdVisibility(!pwdVisibility);
    };

    const handlePwdSignVisibilityChange = () => {
        setPwdSignVisibility(!pwdSignVisibility);
    };

    return (
        <React.Fragment>
            <section className="login-bg">
                <div className="login-left-side-wrapper">
                    <div className="login-left-side">
                        <div className="top-area">
                            <div className="logo">
                                <img src={window.location.origin + "/media/images/auth/white-ai-logo.svg"} alt="login-logo" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="login-form">
                       
                        <div className="form-area-wrapper img-swap-active-anim">
                          
                            <h1 className="title">Log in</h1>
                            <Form>
                                <FormGroup className="ai-login-form-group">
                                    <Label htmlFor="email-id">Email *</Label>
                                    <Input 
                                        type="email" 
                                        id="email-id" 
                                        className="ai-textbox" 
                                        value={email} 
                                        onChange={(e) => setEmail(e.target.value)} 
                                    />
                                </FormGroup>
                                <FormGroup className="ai-login-form-group">
                                    <div className="login-forget-pwd">
                                        <Label htmlFor="password">Password *</Label>
                                    </div>
                                    <Input
                                        type={pwdVisibility ? "text" : "password"}
                                        id="password"
                                        className="ai-textbox"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <span className="pwd-visibility-btn" onClick={handlePwdVisibilityChange}>
                                        <img
                                            src={
                                                pwdVisibility
                                                    ? window.location.origin + "/media/images/auth/pwd_visibility.svg"
                                                    : window.location.origin + "/media/images/auth/pwd_visibility_off.svg"
                                            }
                                            alt="pwdvisibility"
                                        />
                                    </span>
                                </FormGroup>
                                <div className="ai-mt-2">
                                    <button
                                        type="submit" 
                                        onClick={(e) => {
                                            handleSubmitSub(e)
                                            handleSubmit(e)}}
                                        disabled={showLoginLoader? true: false}
                                        className="ai-login-submit-btn-base ai-login-submit-btn"
                                    >
                                        <span className="ai-login-submit-btn">
                                            {showLoginLoader && (
                                                <div className="save-btn-spinner">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            )}
                                            {showLoginLoader ? "Logging in" : "Log in"}
                                        </span>
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
            </section>
        </React.Fragment>
    )
}

export default Login;