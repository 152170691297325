import React, { useState,useEffect } from "react";
import MicOutlinedIcon from '@mui/icons-material/MicOutlined';
import SquareIcon from '@mui/icons-material/Square';
import GraphemeSplitter from 'grapheme-splitter'

const SpeechRecognition = window.SpeechRecognition
      || window.webkitSpeechRecognition
      || window.mozSpeechRecognition
      || window.msSpeechRecognition
      || window.oSpeechRecognition;

var SpeechGrammarList = SpeechGrammarList || window.webkitSpeechGrammarList;

  
const recognition = SpeechRecognition ? new SpeechRecognition() : {};
const speechRecognitionList = new SpeechGrammarList();
recognition.grammars = speechRecognitionList;
recognition.continuous = true;


export default function VoiceEditor(props) {
  let{speechSourceLanguageOption,selectedSourceLang,sourceLanguage,selectedAccent,isListening,setIsListening,editor,fabric} = props

  recognition.lang = selectedSourceLang;


  const handleListening = () => {
    if (!isListening) {

      try{
      recognition.start();
    
      setIsListening(true);
      }
      catch{
        recognition.stop();
        // editor.canvas.discardActiveObject()
        // editor.canvas.renderAll()
        setIsListening(false);
      }
    } else {
      recognition.stop();
      // editor.canvas.discardActiveObject()
      // editor.canvas.renderAll()
      setIsListening(false);
    }
  };


   
  var splitter = new GraphemeSplitter();

  recognition.onresult = (e) => {
    const current = e.resultIndex;
    const transcript = e.results[current][0].transcript;
    var activeObj = editor?.canvas.getActiveObject();
    var caretPositionStart = activeObj.selectionStart;
    var caretPositionEnd = activeObj.selectionEnd;
    activeObj.enterEditing();
    activeObj.insertChars(transcript, null, caretPositionStart, caretPositionEnd);
    activeObj.selectionStart = caretPositionStart + splitter.countGraphemes(transcript);
    activeObj.selectionEnd = caretPositionStart + splitter.countGraphemes(transcript) + 1;
    activeObj.exitEditing();
    editor.canvas.renderAll();
    activeObj.enterEditing();


  };







  return (

    <div className="text-center start-listening-btn" >
      <button className={"listen-button " + (speechSourceLanguageOption !== null ? '' : 'record-action-disable')} onClick={handleListening} id="PopoverClick2"
    type="button">
        <div className="mic-icon">
        <span className={isListening ? "mic-icon" : "mic-icon" }>
          {isListening ? <SquareIcon style={{color: '#E74C3C', padding: '4px'}} /> : <MicOutlinedIcon style={{color: '#666666'}} />}
        </span>
        </div>
      </button>
    </div>
  );
}
