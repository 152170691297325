import { useState,useRef ,useEffect} from 'react';
import Popover from '@mui/material/Popover';
import { DragIndicator } from '@mui/icons-material';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import Slider from '@mui/material/Slider';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import ImagesearchRollerIcon from '@mui/icons-material/ImagesearchRoller';
import ImagesearchRollerOutlinedIcon from '@mui/icons-material/ImagesearchRollerOutlined';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import OpacityIcon from '@mui/icons-material/Opacity';
import ThreeSixtySharpIcon from '@mui/icons-material/ThreeSixtySharp';
import CallMergeSharpIcon from '@mui/icons-material/CallMergeSharp';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import Tooltip from '@mui/material/Tooltip';



import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';



const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));




 

const Moretools=(props)=>{
    const {
        editor,
        duplicate,
        handledelete,
        canvasheight,
        canvaswidth,
        back,
        front,
        forward,
        backward,
        generateUID,
        makeid
    }=props


    

    //draggable
    const [state, setState] = useState({
        activeDrags: 0,
        deltaPosition: {
            x: 0, y: 0
        },
        controlledPosition: {
            x: -400, y: 200
        }
    });

    const onStart = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags + 1 });
    };
    const onStop = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags - 1 });
    };

    const dragHandlers = { onStart, onStop };


    const [lock, setlock] = useState(false)

    const [textcompletestyleiconchange,setCompletestyleiconchange] = useState()
    const textcompletestyle = useRef([])
    const imagecompletestyle = useRef(null)
    const objectcompletestyle = useRef([])


    useEffect(()=>{
        setCompletestyleiconchange(false)
    },[])
    

   

    const gettextstyle=()=>{
        editor.canvas.defaultCursor = 'pointer' 
        editor.canvas.hoverCursor = 'pointer'
        editor.canvas.moveCursor = 'pointer'
        editor.canvas.renderAll()

        const setdefaultcursors =()=>{
            editor.canvas.defaultCursor = 'default' 
            editor.canvas.hoverCursor = 'move'
            editor.canvas.moveCursor = 'move'
            editor.canvas.renderAll()

        }

        if(editor?.canvas?.getActiveObject().type =='textbox'){
            if(textcompletestyle.current.length == 0 ){

                textcompletestyle.current = {
                    styles:JSON.parse(JSON.stringify(editor.canvas.getActiveObject().styles)),
                    strokeWidth:editor.canvas.getActiveObject().strokeWidth,
                    stroke:editor.canvas.getActiveObject().stroke,
                    strokeDashArray:editor.canvas.getActiveObject().strokeDashArray,
                    strokeLineCap:editor.canvas.getActiveObject().strokeLineCap,
                    strokeMiterLimit:editor.canvas.getActiveObject().strokeMiterLimit,
                    strokeDashOffset:editor.canvas.getActiveObject().strokeDashOffset,
                    strokeLineJoin:editor.canvas.getActiveObject().strokeLineJoin,
                    strokeUniform:editor.canvas.getActiveObject().strokeUniform,
                    fontFamily:editor.canvas.getActiveObject().fontFamily,
                    charSpacing:editor.canvas.getActiveObject().charSpacing,
                    fontSize:editor.canvas.getActiveObject().fontSize,
                    // width:editor.canvas.getActiveObject().getBoundingRect().width,
                    // height:editor.canvas.getActiveObject().getBoundingRect().height,
                    // lineHeight:editor.canvas.getActiveObject().lineHeight,
                    lineHeight:editor.canvas.getActiveObject().lineHeight,
                    opacity:editor.canvas.getActiveObject().opacity,
                    scaleX:editor.canvas.getActiveObject().scaleX,
                    scaleY:editor.canvas.getActiveObject().scaleY,
                    shadow:editor.canvas.getActiveObject().shadow,
                    textAlign:editor.canvas.getActiveObject().textAlign,
                    flipX:editor.canvas.getActiveObject().flipX,
                    flipY:editor.canvas.getActiveObject().flipY,
                    listBullet:editor.canvas.getActiveObject().flipY,
                    listCounter:editor.canvas.getActiveObject().flipY,
                    objectCaching:editor.canvas.getActiveObject().flipY,
                    listType:editor.canvas.getActiveObject().flipY,
                    isWrapping:editor.canvas.getActiveObject().flipY,
                    // zoomX:editor.canvas.getActiveObject().zoomX,
                    // zoomY:editor.canvas.getActiveObject().zoomY,
                }

                
               
                setCompletestyleiconchange(true)
                const applystyle = (e)=>{

                    if(textcompletestyle.current?.length  != 0 && e?.target?.type =='textbox' ){

                        // editor.canvas.__eventListeners['mouse:down'] = {}
                        editor?.canvas?.getActiveObject()?.set(textcompletestyle.current)
                        // editor?.canvas?.getActiveObject()?.setZoom(2)
                        textcompletestyle.current = [] 
                        setCompletestyleiconchange(false)
                        remove()
                        setdefaultcursors()
                        editor?.canvas?.renderAll()
                    }
                    else{
                        // editor.canvas.__eventListeners['mouse:down'] = {}
                        textcompletestyle.current = [] 
                        setCompletestyleiconchange(false)
                        remove()
                        setdefaultcursors()
                        editor?.canvas?.requestRenderAll()

                    }
                }
                
                editor.canvas.__eventListeners["mouse:down"]=[]
                editor.canvas.on('mouse:down',applystyle)
                // return ()=>{editor.canvas.off('mouse:down',applystyle)}
                
            }
        }
        else if(editor?.canvas?.getActiveObject().type  != 'textbox' &&  editor?.canvas?.getActiveObject().type  != 'image' && editor?.canvas?.getActiveObject().type  != 'group' ){
            if(objectcompletestyle.current.length == 0){
                objectcompletestyle.current  = { 
                    strokeWidth:editor.canvas.getActiveObject().strokeWidth,
                    stroke:editor.canvas.getActiveObject().stroke,
                    strokeDashArray:editor.canvas.getActiveObject().strokeDashArray,
                    strokeLineCap:editor.canvas.getActiveObject().strokeLineCap,
                    strokeMiterLimit:editor.canvas.getActiveObject().strokeMiterLimit,
                    strokeDashOffset:editor.canvas.getActiveObject().strokeDashOffset,
                    strokeLineJoin:editor.canvas.getActiveObject().strokeLineJoin,
                    strokeUniform:editor.canvas.getActiveObject().strokeUniform,
                    width:editor.canvas.getActiveObject().width,
                    height:editor.canvas.getActiveObject().height,
                    opacity:editor.canvas.getActiveObject().opacity,
                    shadow:editor.canvas.getActiveObject().shadow,
                    flipX:editor.canvas.getActiveObject().flipX,
                    flipY:editor.canvas.getActiveObject().flipY,
                    fill:editor.canvas.getActiveObject().fill,
                    // zoomX:editor.canvas.getActiveObject().zoomX,
                    // zoomY:editor.canvas.getActiveObject().zoomY,
                    scaleX:editor.canvas.getActiveObject().scaleX,
                    scaleY:editor.canvas.getActiveObject().scaleY,
                }
                setCompletestyleiconchange(true)
                const applystyle = (e)=>{

                    if(objectcompletestyle.current?.length  != 0 && e?.target?.type !='textbox' &&  editor?.canvas?.getActiveObject().type  != 'image' && editor?.canvas?.getActiveObject().type  != 'group'  ){
                       
                        editor.canvas.__eventListeners['mouse:down'] = {}
                        editor?.canvas?.getActiveObject()?.set(objectcompletestyle.current)
                        // editor?.canvas?.getActiveObject()?.setZoom(2)
                        objectcompletestyle.current = [] 
                        setCompletestyleiconchange(false)
                        remove()
                        setdefaultcursors()
                        editor?.canvas?.renderAll()
                    }
                    else{
                        editor.canvas.__eventListeners['mouse:down'] = {}
                        objectcompletestyle.current = [] 
                        setCompletestyleiconchange(false)
                        remove()
                        setdefaultcursors()
                        editor?.canvas?.renderAll()

                    }
                }

                editor.canvas.__eventListeners["mouse:down"]=[]
                editor.canvas.on('mouse:down',applystyle)
                // return ()=>{editor.canvas.off('mouse:down',applystyle)}
            }
        }
        else{
            if(editor?.canvas?.getActiveObject().type == 'image'){

                if(imagecompletestyle.current == null){
                    imagecompletestyle.current = editor.canvas.getActiveObject()?.filters
                    setCompletestyleiconchange(true)
                    
                    editor.canvas.__eventListeners["mouse:down"]=[]
                    editor.canvas.on('mouse:down',function(e){
                        
                        if(imagecompletestyle.current != null && e.target.type == 'image' ){
                            
                            editor.canvas.__eventListeners['mouse:down'] = {}
                            editor?.canvas?.getActiveObject()?.set({filters:imagecompletestyle.current})
                            editor.canvas.getActiveObject().applyFilters()
                            editor?.canvas?.renderAll()
                            imagecompletestyle.current = null
                            setCompletestyleiconchange(false)  
                            remove()
                            setdefaultcursors()
                            editor.canvas.off('mouse:down')
                           }
                        else{
                            imagecompletestyle.current = null
                            setCompletestyleiconchange(false)  
                            remove()
                            editor.canvas.off('mouse:down')
                            setdefaultcursors()
                            editor?.canvas?.renderAll()

                           }
                       })
                }
            }
            
        }
    }


    useEffect(() => {
        if (editor?.canvas?.getActiveObjects().length > 0) {
            let lockcount = 0
            let word = 0
            editor?.canvas?.getActiveObjects().forEach((e) => {
                word++
                if (e.lockMovementX) {
                    lockcount++
                }

            })
            if (lockcount == word) {
                setlock(true)
            }
            else {
                setlock(false)
            }

        }
        else {
            setlock(false)
        }
    }, [editor])
   
      
    const positionmenu = [
        { position: 'Top', icon: <AlignVerticalTopIcon /> },
        { position: 'Bottom', icon: <AlignVerticalBottomIcon /> },
        { position: 'Left', icon: <AlignHorizontalLeftIcon /> },
        { position: 'Right', icon: <AlignHorizontalRightIcon /> },
        { position: 'V-Center', icon: <AlignHorizontalCenterIcon /> },
        { position: 'H-Center', icon: <AlignVerticalCenterIcon /> },
        { position: 'Page-center', icon: <VerticalAlignCenterIcon /> },
    ]
    const alignpositionleft = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.getActiveObject().set('left', 0)
                editor.canvas.renderAll()
            }
        }
    }
    const alignpositionright = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.getActiveObject().set('left', canvaswidth - (editor.canvas.getActiveObject().width * editor.canvas.getActiveObject().scaleX))
                editor.canvas.renderAll()
            }
        }
    }
    const alignpositiontop = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.getActiveObject().set('top', 0)
                editor.canvas.renderAll()
            }
        }
    }
    const alignpositionbottom = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.getActiveObject().set('top', canvasheight - (editor.canvas.getActiveObject().height * editor.canvas.getActiveObject().scaleY))
                editor.canvas.renderAll()
            }
        }
    }
    const alignpositionhorizontal = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.getActiveObject().set('left', (canvaswidth / 2) - ((editor.canvas.getActiveObject().width * editor.canvas.getActiveObject().scaleX) / 2))
                editor.canvas.renderAll()
            }
        }
    }
    const alignpositionvertical = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.getActiveObject().set('top', (canvasheight / 2) - ((editor.canvas.getActiveObject().height * editor.canvas.getActiveObject().scaleY) / 2))
                editor.canvas.renderAll()
            }

        }
    }
    const alignpositionmiddle = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.getActiveObject().set('top', (canvasheight / 2) - ((editor.canvas.getActiveObject().height * editor.canvas.getActiveObject().scaleY) / 2))
                editor.canvas.getActiveObject().set('left', (canvaswidth / 2) - ((editor.canvas.getActiveObject().width * editor.canvas.getActiveObject().scaleX) / 2))
                editor.canvas.renderAll()
            }
        }
    }
    const callbackposition = (e) => {
        e == 'Top' ? alignpositiontop() : e == 'Bottom' ? alignpositionbottom() : e == 'Left' ? alignpositionleft() : e == 'Right' ? alignpositionright() : e == 'V-Center' ? alignpositionvertical() : e == 'Page-center' ? alignpositionmiddle() : alignpositionhorizontal()
    }
    const forwardandbackward = [
        { name: 'Front', icon: <ShortcutIcon className="postition-icon" style={{ transform: 'rotate(270deg)' }} /> },
        { name: 'Forward', icon: <ShortcutIcon className="postition-icon" style={{ transform: 'rotate(270deg)' }} /> },
        { name: 'Backward', icon: <ShortcutIcon className="postition-icon" style={{ transform: 'rotate(90deg)' }} /> },
        { name: 'Back', icon: <ShortcutIcon className="postition-icon" style={{ transform: 'rotate(90deg)' }} /> },
    ]
    const callbackfrontback = (e) => {
        e == 'Front' ? front() : e == 'Back' ? back() : e == 'Forward' ? forward() : backward();
    }
    const handlelock = () => {

        editor?.canvas?.getActiveObjects().forEach((e) => {
            if (e.lockMovementX === true) {
                e.set('lockMovementX', false);
                e.set('lockMovementY', false);
                e.set('lockScalingX', false);
                e.set('lockScalingY', false);
                e.set('hasControls', true);
                e.set('editable', true);
                // e.set('hasBorders', false);
                setlock(false)
                editor.canvas.renderAll();
            }
            else {
                e.set('lockMovementX', true);
                e.set('lockMovementY', true);
                e.set('lockScalingX', true);
                e.set('lockScalingY', true);
                e.set('hasControls', false);
                e.set('editable', false);
                e.set('hasBorders', true);
                setlock(true)
                editor.canvas.renderAll();
            }
        })

    }
    const handleflipX = () => {
        if (editor) {
            editor.canvas.getActiveObjects().forEach((i) => {
                i.set({ flipX: !i.flipX })
            })
            editor.canvas.renderAll()
        }
    }
    const handleflipY = () => {
        if (editor) {
            editor.canvas.getActiveObjects().forEach((i) => {
                i.set({ flipY: !i.flipY })
            })
            editor.canvas.renderAll()
        }
    }
    const group = () => {
        if (!editor.canvas.getActiveObject()) {
            return;
        }
        if (editor.canvas.getActiveObject().type !== 'activeSelection') {
            return;
        }
        // console.log(editor.canvas.getActiveObject())
        editor.canvas.getActiveObject().toGroup();
        editor.canvas.getActiveObject().set({
            id: 'Group' + generateUID(9) + makeid(4),
            name: 'Group' + generateUID(9) + makeid(4),
            globalCompositeOperation: 'source-atop',
        })
        editor.canvas.getActiveObject()._objects.forEach((each) =>{
            each.set({
                globalCompositeOperation: 'source-over',
            })
        })
        editor.canvas.renderAll();
    }
    const ungroup = () => {
        if (!editor.canvas.getActiveObject()) {
            return;
        }
        if (editor.canvas.getActiveObject().type !== 'group') {
            return;
        }
        editor.canvas.getActiveObject()._objects.forEach((each) =>{
            each.set({
                globalCompositeOperation: 'source-atop',
            })
        })
        editor.canvas.getActiveObject().toActiveSelection();
        editor.canvas.renderAll();
    }  
    const Placement = ({ dragHandlers }) => {
     
        const handleCloseAndOpen = (event) => {
            if (anchorEl == null) {
                setAnchorEl(event.currentTarget);
            }
            else {
                setAnchorEl(null);
    
            }
        }
       
        const [anchorEl, setAnchorEl] = useState(null);

      

        const handleClose = () => {
            setAnchorEl(null);
        };
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;




        const [expanded, setExpanded] = useState('panel1');

        const handleChange = (panel) => (event, newExpanded) => {
            setExpanded(newExpanded ? panel : false);
        };



        return (<>
            <Tooltip 
             componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: '#2A2A2A',
                        '& .MuiTooltip-arrow': {
                            color: '#2A2A2A',
                        },
                    },
                },
            }}
            title="Position" placement="top" arrow><span onClick={handleCloseAndOpen}><PictureInPictureIcon className="toolbar-icons" /></span></Tooltip>
            <Draggable handle="strong" {...dragHandlers}>
                <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>

                    <div className='position'>
                        <div className='drag-box-container'>
                            <strong className="drag-me ">
                                <DragIndicator />
                            </strong>
                            <CloseIcon className="close-icon" onClick={handleClose} />
                        </div>
                        <p className='moretools-icons'>
                            <span  onClick={()=>{handleflipX()}}><ThreeSixtySharpIcon /> Horizontal</span>
                            <span  onClick={()=>{handleflipY()}}><ThreeSixtySharpIcon style={{ transform: 'rotate(90deg)' }} /> Vertical</span>
                            {editor?.canvas.getActiveObject().id !== 'background' &&<>
                            <span style={{pointerEvents:editor.canvas.getActiveObjects().length > 1 ? null : 'none' ,opacity:editor.canvas.getActiveObjects().length > 1 ? 1 : 0.5}} onClick={()=>{group()}}><CallMergeSharpIcon /> Group</span>
                            <span  style={{pointerEvents:editor.canvas.getActiveObject().type == 'group'  ? null : 'none' ,opacity:editor.canvas.getActiveObject().type == 'group' ? 1 : 0.5 }}  onClick={()=>{ungroup()}}><CallSplitIcon /> Ungroup</span>
                            </>}
                        </p>
                        {editor?.canvas.getActiveObject().id !== 'background' &&
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <Typography component={'div'}>Position</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component={'div'}>
                                    <div className='positionnmenu'>
                                        {forwardandbackward.map((e) => {
                                            return (
                                                <span key={e} className='positionbtn' onClick={() => callbackfrontback(e.name)}>{e.icon} {e.name}</span>
                                            )
                                        })}
                                        {positionmenu.map((e) => {
                                            return (
                                                <span key={e} className='positionbtn' onClick={() => { callbackposition(e.position) }}>{e.icon} &nbsp; {e.position}</span>
                                            )
                                        })}
                                    </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>}

                    </div>
                </Popover>
            </Draggable>

        </>)
    }
   
  

    const Others = ({ dragHandlers }) => {

        const [opacity, setOpacity] = useState(100)
        const opacityinputer = useRef(null)


        const changeopacityvalue = (e) => {
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type != 'group') {
                    i.set({ opacity: e.target.value/100 })
                }
                else {
                    i._objects.map((e) => {
                        e.set({ opacity: e.target.value/100 })
                        
                    })
                }
              
            })
         
            editor.canvas.renderAll()
            setOpacity(e.target.value)
        }

        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleOpacity=(e)=>{
            setOpacity(e.target.value)
        }


        
        const [skewx, setSkewx] = useState(0)
        const skewxinputer = useRef(null)





        useEffect(() => {

            if (editor) {

                if (!editor.canvas.getActiveObjects()) {
                    return;
                }
                else {

                    if (editor.canvas.getActiveObject()) {
                        setSkewx(editor.canvas.getActiveObject().skewX)
                        setOpacity(editor.canvas.getActiveObject().opacity*100)
                        editor.canvas.renderAll()
                    }
                }
            }
        }, [editor])



        const changeskewxvalue = (e) => {
            setSkewx(e.target.value)
        }


        const skewxtemp = useRef(true)
        useEffect(() => {
            if (skewxtemp.current) {
                skewxtemp.current = false
                return;
            }
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    
                    try {
                        editor.canvas.getActiveObject().set({ skewX: skewx })
                        editor.canvas.renderAll()
                       
                    }
                    catch {
                    }
                }
            }
        })
        // ##################################skewy#############################################

        const [skewy, setSkewy] = useState(0)
        const skewyinputer = useRef(null)

        useEffect(() => {

            if (editor) {

                if (!editor.canvas.getActiveObjects()) {
                    return;
                }
                else {
                    if (editor.canvas.getActiveObject()) {
                        setSkewy(editor.canvas.getActiveObject().skewY)
                        editor.canvas.renderAll()
                    }
                }
            }
        }, [editor])


        const changeskewyvalue = (e) => {
            setSkewy(e.target.value)
        }


        const skewytemp = useRef(true)
        useEffect(() => {
            if (skewytemp.current) {
                skewytemp.current = false
                return;
            }
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                   
                    try {
                        editor.canvas.getActiveObject().set({ skewY: skewy })
                        editor.canvas.renderAll()
                      
                    }
                    catch {
                    }
                }
            }
        })






        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (<>
            <Tooltip 
             componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: '#2A2A2A',
                        '& .MuiTooltip-arrow': {
                            color: '#2A2A2A',
                        },
                    },
                },
            }}
            title="Opacity" placement="top" arrow><span  onClick={handleClick} ><OpacityIcon className="toolbar-icons" />
            </span></Tooltip>
           
            <Draggable handle="strong" {...dragHandlers} >
                <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>

                    <div className='others'>
                        <div className='drag-box-container'>
                            <strong className="drag-me ">
                                <DragIndicator />
                            </strong>
                            <CloseIcon className="close-icon" onClick={handleClose} />
                        </div>
                        <div className='othersmenu'>
                            <span>
                                <label htmlFor="customRange1">Opacity</label>
                               <Slider size="small" aria-label="Small" value={opacity}  min={0} max={100}  ref={opacityinputer}  onChange={(e)=>{
                                    changeopacityvalue(e)
                                }} id="customRange1" valueLabelDisplay="auto" />
                            </span>
                            <span>
                            <label htmlFor="customRange2">SkewX</label>

                               <Slider size="small" aria-label="Small" className="form-range" value={skewx} min={-25} max={25} ref={skewxinputer} onChange={(e) => {
                                changeskewxvalue(e);
                            }} id="customRange2"valueLabelDisplay="auto"  />
                            </span>
                            <span>
                            <label htmlFor="customRange3">SkewY</label>
                               <Slider size="small" aria-label="Small" className="form-range" value={skewy} min={-25} max={25} ref={skewyinputer} onChange={(e) => {
                                changeskewyvalue(e);
                            }} id="customRange3" valueLabelDisplay="auto"   />
                            </span>
                        </div>
                    </div>
                </Popover>
            </Draggable>

        </>)
    }
    
    function remove(){
        editor.canvas.off('mouse:down');
       }



   
   

    
    return(<>
        { editor?.canvas.getActiveObject().id !== 'background' && 
         <Tooltip 
         componentsProps={{
            tooltip: {
                sx: {
                    bgcolor: '#2A2A2A',
                    '& .MuiTooltip-arrow': {
                        color: '#2A2A2A',
                    },
                },
            },
        }}
         title="Duplicate" placement="top" arrow><span className="toolbar-icons" style={{ display: 'flex', alignItems: 'center' }} onClick={duplicate}><DifferenceOutlinedIcon /></span></Tooltip>
        }
        {editor?.canvas.getActiveObject().id !== 'background' && 
         <Tooltip 
         componentsProps={{
            tooltip: {
                sx: {
                    bgcolor: '#2A2A2A',
                    '& .MuiTooltip-arrow': {
                        color: '#2A2A2A',
                    },
                },
            },
        }}
         title="Formate Painter" placement="top" arrow><span style={{pointerEvents:editor.canvas.getActiveObjects().length > 1 || editor.canvas.getActiveObject().type == 'group' ? 'none' : null, opacity:editor.canvas.getActiveObjects().length > 1 || editor.canvas.getActiveObject().type == 'group'   ? 0.5 : 1 }} onClick={()=>{gettextstyle()}}>{textcompletestyleiconchange == true ? <ImagesearchRollerIcon className="toolbar-icons" />  : <ImagesearchRollerOutlinedIcon className="toolbar-icons" />}</span></Tooltip>
         }
                <Placement/>
                <Others/>
                  {editor?.canvas.getActiveObject().id !== 'background' &&
                  <Tooltip 
                  componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: '#2A2A2A',
                            '& .MuiTooltip-arrow': {
                                color: '#2A2A2A',
                            },
                        },
                    },
                }}
                  title="Lock" placement="top" arrow>

                    <span className={lock ? 'active' : ''} onClick={handlelock}>{lock ? <LockOutlinedIcon className="toolbar-icons" /> : <LockOpenIcon className="toolbar-icons" />}</span>
                    </Tooltip>
                  
                   }
                    <Tooltip 
                     componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: '#2A2A2A',
                                '& .MuiTooltip-arrow': {
                                    color: '#2A2A2A',
                                },
                            },
                        },
                    }}
                    title="Delete" placement="top" arrow>
                <span onClick={handledelete}><DeleteOutlineIcon className="toolbar-icons" /></span>
                </Tooltip>

    </>)
}

export default Moretools;