
import React, { useEffect, useState} from "react";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CropIcon from '@mui/icons-material/Crop';
import Tooltip from '@mui/material/Tooltip';


const Crop = (props) => {

    const{
        editor,
        fabric,
        setCropImageSource
    } = props

    const [displayCrop, setDisplayCrop] = useState(true)
    const [displayCropProps, setDisplayCropProps] = useState(false)
    const [imageCrop, setImageCrop] = useState()
    const [previousImgValue, setPreviousImgValue] = useState({
        scaleX: 0,
        scaleY: 0,
        top: 0,
        left: 0,
        cropX: 0,
        cropY: 0,
        height: 0,
        width: 0,
        angle: 0,
        rectScaleX:0,
        rectScaleY:0
    })

    const [previousState, setPreviousState] = useState(null)
   


    const addInvisibleRect = () => {
        var initial
        if(displayCropProps === false){
            var initial = editor.canvas.getActiveObject()
        }
        setPreviousState(initial)

        setDisplayCropProps(true)
        // var pattern = new fabric.Pattern({source: editor.canvas.getActiveObject(), offsetX: -20, offsetY: -50});
        // editor.canvas.getActiveObject().set({opacity: 0.5
        //     })

        let objid = editor.canvas.getActiveObject().id;
        let Imageobj = editor.canvas.getObjects().find(Imageobj => Imageobj.id == objid);
        setPreviousImgValue({ scaleX: Imageobj.scaleX , scaleY: Imageobj.scaleY ,top: Imageobj.top , left: Imageobj.left,cropX: Imageobj.cropX, cropY: Imageobj.cropY, height: Imageobj.height, width: Imageobj.width,angle: Imageobj.angle});
        setImageCrop(objid)
        editor.canvas.getObjects().forEach(object => object.set({ selectable: false, opacity: 0.3, evented:false}))
        Imageobj.set({
            opacity:0.5,
            cropX: 0,
            cropY: 0,
            angle:0,

        })
        Imageobj._resetWidthHeight()
        editor.canvas.bringToFront(Imageobj)

        fetch(editor.canvas.toDataURL('image/png'))
            .then(res => res.blob())
            .then(blob => {
            var url = window.URL.createObjectURL(blob);
            setCropImageSource(url)
            });

        Imageobj.set({
            cropX: 0,
            cropY: 0,
            opacity: 1,
            angle:0,
            // originX: 'left',
            // originY: 'top',
            // top: 0,
            // left: 0,
            // scaleX: editor.canvas.getWidth() / editor.canvas.getActiveObject().width,
            // scaleY: editor.canvas.getHeight() / editor.canvas.getActiveObject().height,
            // scaleX: 1,
            // scaleY: 1
        })

        // editor.canvas.setWidth(editor.canvas.getActiveObject().width)
        // editor.canvas.setHeight(editor.canvas.getActiveObject().height)
        

        var slot1 = new fabric.Rect({
            originX: 'left',
            originY: 'top',
            left: Imageobj.left,
            top: Imageobj.top,
            width: Imageobj.width * Imageobj.scaleX,
            height: Imageobj.height * Imageobj.scaleY,
            // angle: Imageobj.angle,
            // scaleY: editor.canvas.getActiveObject().scaleY,
            // scaleX: editor.canvas.getActiveObject().scaleX,
            // fill: 'transparent',
            stroke: 'white',
            strokeWidth: 0,
            selectable: true,
            id: objid,
            id2: 'crop-box',
            opacity: 1,
            // cornerStrokeColor: 'red'
            // minScaleLimit: 0.2,
            // maxScaleLimit: 1,
            lockMovementX: true,
            lockMovementY: true,
            // stroke: '#BD8AF5',
            // strokeWidth: 1
            globalCompositeOperation: 'destination-in',

        });
        editor.canvas.add(slot1)
        editor.canvas.setActiveObject(slot1)

        let objrect = editor.canvas.getObjects('rect').find(obj => obj.id == editor.canvas.getActiveObject().id);
        objrect.setControlsVisibility({
            tr: false,
            tl: false,
            br: false,
            bl: false
        })


        setDisplayCrop(false)
        // setDisableLi(true)
        //   editor.canvas.setOverlayColor("rgba(255, 255, 255,0.5)")
        editor.canvas.renderAll();



    }

    // if(editor){
    //     if((editor.canvas.getActiveObject()) == (editor.canvas.getObjects('rect').find(Imageobj => Imageobj.id2 == 'crop-box'))){
    //         let ob = editor.canvas.getObjects('rect').find(Imageobj => Imageobj.id2 == 'crop-box')

    //     }

    // }


    var maxScaleX = 1;
    var maxScaleY = 1;

    //     useEffect(() => {
    //         if(editor){
    //             let ob =editor?.canvas.getObjects('rect').find(Imageobj => Imageobj.id2 == 'crop-box')
    //             let w = ob?.width
    //             let h = ob?.height

    //             if((editor.canvas.getActiveObject()) == (editor.canvas.getObjects('rect').find(Imageobj => Imageobj.id2 == 'crop-box'))){

    //                 console.log('yes');
    //                 editor.canvas.on('object:scaling', function(e) {
    //                 editor.canvas.getActiveObject().set({
    //                     scaleX : editor.canvas.getActiveObject().scaleX > maxScaleX? maxScaleX : editor.canvas.getActiveObject().scaleX,
    //                     scaleY : editor.canvas.getActiveObject().scaleY > maxScaleY? maxScaleY : editor.canvas.getActiveObject().scaleY,
    //                     width : editor.canvas.getActiveObject().width >  w? w : editor.canvas.getActiveObject().width,
    //                     height : editor.canvas.getActiveObject().height > h? h : editor.canvas.getActiveObject().height,
    //                 })
    //                editor.canvas.renderAll();

    //             })


    //         }
    //     }

    // },[editor])

    // const cropImage = () => {

    //     editor.canvas.getActiveObject().set({
    //         cropX : 100,
    //         cropY : 100,
    //         scaleX : 0.5,
    //         scaleY : 0.7,
    //         opacity: 1
    //     })
    //    editor.canvas.renderAll();

    // }

    let nowClip = {
        x: 0,
        y: 0
    }

    useEffect(() => {

        if (imageCrop != '') {
            // console.log(imageCrop);
        }

    }, [imageCrop,displayCropProps])


    // canvas.remove(obj);
    function clipImage() {
        let obact = editor?.canvas.getObjects('rect').find(Imageobj => Imageobj.id2 == 'crop-box')
        editor.canvas.setActiveObject(obact)
        // console.log('clicked');
        let obj2 = editor.canvas.getObjects('image').find(obj => obj.id == editor.canvas.getActiveObject().id);
        //   console.log(obj);
        let obj = editor.canvas.getObjects('rect').find(obj => obj.id == editor.canvas.getActiveObject().id);
        // console.log(obj2);
        // console.log('clip!')

        // obj.set({
        //     angle: 0,
        // })
        // obj2.set({
        //     angle: 0,
        // })
        const newImgCrop = obj.getBoundingRect()
        // let result = []

        // editor.canvas.renderAll()
       
        // obj.set({  
        //     left: obj2.left,
        //     top: obj2.top,
        //     width: obj2.width * obj2.scaleX,
        //     height: obj2.height * obj2.scaleY,
        //     scaleX: 1,
        //     scaleY: 1,

        //   })
        // editor.canvas.renderAll()

        // console.log(newImgCrop);
        // // console.log(newImgCrop)
        // // editor.canvas.setWidth(newImgCrop.width)
        // // editor.canvas.setHeight(newImgCrop.height)
    
        obj2.set({
            cropX:( newImgCrop.left - obj2.left )/ obj2.scaleX,
            cropY: (newImgCrop.top -  obj2.top)/ obj2.scaleY,
            width: newImgCrop.width / obj2.scaleX,
            height: newImgCrop.height / obj2.scaleY,
            
            //   scaleY:  obj2.scaleY,
            // scaleX:  obj2.scaleX,
            opacity: 1,
        })
        // 校正位置
        nowClip.x += newImgCrop.left
        nowClip.y += newImgCrop.top


        obj2.set({
            left: obj.left,
            top: obj.top,
            scaleX: obj2.scaleX,
            scaleY: obj2.scaleY,
            angle: previousImgValue.angle,


        })
        obj2.setCoords()
        editor.canvas.renderAll()
        // console.log(obj);
        editor.canvas.remove(obj)
        editor.canvas.getObjects().forEach(object => object.set({ selectable: true, opacity: 1,evented:true }))
        editor.canvas.renderAll()
        setDisplayCrop(true)
        // setDisableLi(false)
        setDisplayCropProps(false)
        setCropImageSource(null)

    }

    const cancelCrop = () => {
        let obj2 = editor.canvas.getObjects('image').find(obj => obj.id == editor.canvas.getActiveObject().id);
        let obj = editor.canvas.getObjects('rect').find(obj => obj.id == editor.canvas.getActiveObject().id);

        obj2.set({
            top: previousImgValue.top,
            left: previousImgValue.left,
            scaleX: previousImgValue.scaleX,
            scaleY: previousImgValue.scaleY,
            cropX: previousImgValue.cropX,
            cropY: previousImgValue.cropY,
            height: previousImgValue.height,
            width: previousImgValue.width

        })
        obj2.setCoords()

        // editor.canvas.remove(obj2)
        editor.canvas.renderAll()
        editor.canvas.remove(obj)
        // editor.canvas.add(previousState)
        editor.canvas.getObjects().forEach(object => object.set({ selectable: true, opacity: 1,evented:true }))
        editor.canvas.renderAll()
        setDisplayCrop(true)
        // setDisableLi(false)
        setDisplayCropProps(false)
        setCropImageSource(null)
    }
 

    // useEffect(() => {
    // },[previousImgValue])


    let ob2 = editor?.canvas.getObjects('rect').find(Imageobj => Imageobj.id2 == 'crop-box')
    let ob3 = editor?.canvas.getObjects('image').find(Imageobj => Imageobj.type == 'image')

        // if(ob2 && displayCropProps){
            
        //     editor.canvas.on("selection:cleared",function () {editor.canvas.setActiveObject(ob2)
        //     return editor?.canvas?.off('selection:cleared')})
        // }
     
    const unCrop = () => {
        if(editor.canvas.getActiveObject().type == 'image' && (editor.canvas.getActiveObject().cropX != 0  ||  editor.canvas.getActiveObject().cropY != 0)){
            editor.canvas.getActiveObject().set({
                cropX:0,
                cropY:0
            })
            editor.canvas.getActiveObject()._resetWidthHeight()
            editor.canvas.renderAll()
            setPreviousImgValue({
                scaleX: 0,
                scaleY: 0,
                top: 0,
                left: 0,
                cropX: 0,
                cropY: 0,
                height: 0,
                width: 0})
        }
    }

    return (
        <>
            {(ob2 != editor.canvas.getActiveObject() && displayCropProps == false  )&& 
            <>
            <Tooltip 
             componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: '#2A2A2A',
                        '& .MuiTooltip-arrow': {
                            color: '#2A2A2A',
                        },
                    },
                },
            }}
            title="Crop" placement="top" arrow>
                <CropIcon className='image-toolbar-icons d-none'  onClick={addInvisibleRect}>crop</CropIcon>
                </Tooltip>
                 {/* {((editor.canvas.getActiveObject().cropX != 0) || (editor.canvas.getActiveObject().cropY != 0) ) && <div className='image-toolbar-icons' onClick={unCrop}>UnCrop</div>} */}
         
            </>
            }
            {/* {ob2 && <div button className='image-toolbar-icons' onClick={clipImage}>Confirm</div>} */}
           {ob2 && <div className="toolbar-icons background-confirmation-container">
                <div className='set-as-background-but' onClick={clipImage} >
                    <CheckIcon className="confirm-ic" />
                    <span>Confirm</span>
                </div>
                <div className='separator'></div>
                <div className='set-as-background-but' onClick={cancelCrop} >
                    <CloseIcon className="close-ic" />
                    <span>Cancel</span>
                </div>

            </div>}

        </>
    )
}


export default Crop;