import { useState,useRef ,useEffect} from 'react';
import useEyeDropper from 'use-eye-dropper'
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import { SketchPicker} from 'react-color';
import { DragIndicator } from '@mui/icons-material';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import ColorizeIcon from '@mui/icons-material/Colorize';
import SubscriptIcon from '@mui/icons-material/Subscript';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import Slider from '@mui/material/Slider';
import BlockIcon from '@mui/icons-material/Block';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import GraphemeSplitter from 'grapheme-splitter'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FormatOverlineSharpIcon from '@mui/icons-material/FormatOverlineSharp';
import FormatStrikethroughSharpIcon from '@mui/icons-material/FormatStrikethroughSharp';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import DraftEditor from '../sidebarcomponents/speech-dictation/DraftEditor';
import StrokeStyle from './Borderstyle'
import Shadow from './shadow'
import Tooltip from '@mui/material/Tooltip';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';






const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));


const Texttools = (props) => {
    const {
        editor,
        fabric,
        tabIndex,
        setTabIndex,
        fontFamilyobj,
        workspaceSwitch,
        scaleCanvas,
        setFontFamilyobj,
        textalignicon,
        totaltext,
        selectedobjectforsvg,
        totalimage,
        totalobject,
        ruleonoff
        

    } = props


// const classes = useStyles();
//draggable
const [state, setState] = useState({
    activeDrags: 0,
    deltaPosition: {
        x: 0, y: 0
    },
    controlledPosition: {
        x: -400, y: 200
    }
});

const onStart = () => {
    const { activeDrags } = state;
    setState({ ...state, activeDrags: activeDrags + 1 });
};
const onStop = () => {
    const { activeDrags } = state;
    setState({ ...state, activeDrags: activeDrags - 1 });
};

const dragHandlers = { onStart, onStop };


// declaration
const [bold, setBold] = useState(false)
const [italic, setItalic] = useState(false)
const [underline, setUnderline] = useState(false)
const [fontSize, setFontsize] = useState(21)
const textcolorgradient = useRef([])
const textbackgroundgradient = useRef([])
const validateselectedtext = useRef(false)
const textcount = useRef(false)
const typingTimeout = useRef(0);
const typing = useRef(false);


const FontSizemenu = [6, 8, , 10, 12, 14, 16, 21, 28, 32, 36, 42, 48, 56, 64, 72, 88, 96, 104, 120, 144]

const debounce = (callback, e) => {
    if (typingTimeout.current) clearTimeout(typingTimeout.current);
    typing.current = false;
    typingTimeout.current = setTimeout(() => {
        // projectSearchTerm?.length && callback();
        callback(e);
    }, 500);
};

// initial calculation font pro
useEffect(() => {

    let total = editor.canvas.getActiveObjects().length
    let totaltextcount = 0;
    textcount.current = 0
    editor.canvas.getActiveObjects().map((e)=>{
        if (e.type == 'textbox') {
            totaltextcount++
            textcount.current ++
        }
    })

    if(totaltextcount == total){
        validateselectedtext.current = true 
    }
    else{
        validateselectedtext.current  = false
    }

    if (validateselectedtext.current == true) {
        setFontsize(editor.canvas.getActiveObject().scaleX * editor.canvas.getActiveObject().fontSize)

        textcolorgradient.current = []
        textbackgroundgradient.current = []
            if(editor?.canvas?.getActiveObjects()?.length === 1 && Object?.values(editor?.canvas?.getActiveObject()?.styles).length == 0 ){
                textcolorgradient.current.push('#5F6368')
                textbackgroundgradient.current.push('#5F6368')
            }else{
                editor?.canvas?.getActiveObjects().map((e) => {
                    Object.values(e.styles).map((e) => {
                        Object.values(e).map((e) => {
                            // text color
                            if (e?.fill) {
                                if (!textcolorgradient.current.includes(e?.fill)) {
                                    textcolorgradient.current.push(e?.fill)
                                }
                            }
                            else {
                                if (!textcolorgradient.current.includes('#5F6368')) {
                                    textcolorgradient.current.push('#5F6368')
                                }
                            }
    
                            // backgroundcolor
                            if (e?.textBackgroundColor) {
                                if(e?.textBackgroundColor.replace(/^.*,(.+)\)/,'$1')+'' == 0){
                                }else{
                                    if (!textbackgroundgradient.current.includes(e?.textBackgroundColor)) {
                                        textbackgroundgradient.current.push(e?.textBackgroundColor)
                                    }
                                }
                               
                            }
                            else {
                                if (!textbackgroundgradient.current.includes('#5F6368')) {
                                    textbackgroundgradient.current.push('#5F6368')
                                }
                            }
                          
                        })
                    })
                    
                })

            }
         
       
        }
    
    if (editor?.canvas?.getActiveObjects().length > 0){
        setFontFamilyobj(editor?.canvas?.getActiveObjects()[0].fontFamily)
    }

    editor.canvas.renderAll()

},[editor])


// events in font
const imeventtrue = useRef(true)
useEffect(()=>{
    const handletextsizeupdate =(event)=>{
        editor.canvas.__eventListeners['object:scaling'] = {}
        if(event.target.type == 'textbox'){
            const initialScale = editor.canvas.getActiveObject().scaleX;
            const initialFontSize = editor.canvas.getActiveObject().fontSize;
            const absoluteFontSize = initialFontSize / initialScale;
            
            setFontsize(editor.canvas.getActiveObject().scaleX *  editor.canvas.getActiveObject().fontSize)
        }
    }
    const settextsize =()=>{
        editor?.canvas.getActiveObjects().forEach((i) => {
            if (i.type == 'textbox') {
                // i.setSelectionStyles({ fontSize: i.fontSize - 1 })
                // i.set({  fontSize: i.fontSize - 1 })
                i.set({  fontSize: i.fontSize * i.scaleX  })
                i.set({  scaleX: 1 })
                i.set({  scaleY: 1 })
                editor.canvas.renderAll()
            }
        })
    }
    if(editor && imeventtrue.current == true ){
        editor.canvas.__eventListeners["object:scaling"]=[]
        editor.canvas.on('object:scaling',(e)=>{
            handletextsizeupdate(e);
            // debounce(settextsize)
        })
        imeventtrue.current = false
        // return ()=> editor.canvas.off('object:scaling',handletextsizeupdate)
    }
})

// decrese fontsize
const fontdecrease = () => {
    editor?.canvas.getActiveObjects().forEach((i) => {
        if (i.type == 'textbox') {
            // i.setSelectionStyles({ fontSize: i.fontSize - 1 })
            // i.set({  fontSize: i.fontSize - 1 })
            i.set({  fontSize: i.fontSize * i.scaleX  })
            i.set({  scaleX: 1 })
            i.set({  scaleY: 1 })
            i.set({  fontSize: i.fontSize - 1 })
        }
    })
    editor.canvas.renderAll()
}
const fontincrease = () => {
    editor?.canvas.getActiveObjects().forEach((i) => {
        if (i.type == 'textbox') {
            // i.setSelectionStyles({ fontSize: i.fontSize + 1 })
            // i.set({ width: i.width + 5})
            
            // setFontsize(i.fontSize)
            
            i.set({  fontSize: i.fontSize * i.scaleX  })
            i.set({  scaleX: 1 })
            i.set({  scaleY: 1 })
            i.set({  fontSize: i.fontSize + 1 })
        }
    })
    editor.canvas.renderAll()
}
const Fontsizecomp = () => {
  
    return (<>
        <span >
            <Divider orientation="vertical" flexItem />
            {editor.canvas.getActiveObjects().length == 1 ? parseInt(fontSize)?.toFixed() : '--' }

            <Divider orientation="vertical" flexItem />
        </span>
        
    </>)
}



// fontformate
var splitter = new GraphemeSplitter();

const handleBold = () => {

    if (editor.canvas.getActiveObjects().length > 1) {
        let bold = 0
        let word = 0
        editor.canvas.getActiveObjects().forEach((i) => {
            if (i.type == 'textbox') {
                word++
                if (i.fontWeight == 'bold') {
                    bold++
                }
            }
        })
        editor.canvas.getActiveObjects().forEach((i) => {
            if (i.type == 'textbox' ) {
                if (bold == word && bold > 0) {
                    let temp = i.get('text').length
                    i.setSelectionStyles(({ fontWeight: 'normal' }), 0, temp)
                    i.set({ fontWeight: 'normal' })
                }
                else {
                    let temp = i.get('text').length
                    i.setSelectionStyles(({ fontWeight: 'bold' }), 0, temp)
                    i.set({ fontWeight: 'bold' })
                }
            }
        })
        editor.canvas.renderAll()
    }
    else {
        let active = editor.canvas
        if (editor.canvas?.getActiveObjects()?.length === 1 && editor?.canvas?.getActiveObject().getSelectedText()) {
            let boldcount = 0
            for (let i = 0; i < splitter.countGraphemes(editor?.canvas?.getActiveObject().getSelectedText()); i++) {
                if (active.getActiveObject().getSelectionStyles()[i]?.fontWeight === 'bold') {
                    boldcount++
                }
            }

            let temp = editor.canvas.getActiveObject().get('text').length

            if (boldcount === (splitter.countGraphemes(editor?.canvas?.getActiveObject().getSelectedText()))) {
                editor.canvas.getActiveObject()?.setSelectionStyles({ fontWeight: 'normal' })
                editor.canvas.getActiveObject().set({fontWeight:'normal'})
                editor.canvas.renderAll()

            }
            else if (boldcount === 0) {
                editor.canvas.getActiveObject().setSelectionStyles({ fontWeight: 'bold' })
                editor.canvas.renderAll()
            }
            else if (boldcount < splitter.countGraphemes(editor?.canvas?.getActiveObject().getSelectedText()) / 2) {
                editor.canvas.getActiveObject().setSelectionStyles({ fontWeight: 'bold' })
            }
           

        }
        else {
            let bold = 0
            let word = 0
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type == 'textbox') {
                    word++
                    if (i.fontWeight == 'bold') {
                        bold++
                    }
                }
            })
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type == 'textbox') {
                    if (bold == word) {
                        let temp = i.get('text').length
                        i.setSelectionStyles(({ fontWeight: 'normal' }), 0, temp)
                        i.set({ fontWeight: 'normal' })
                    }
                    else {
                        let temp = i.get('text').length
                        i.setSelectionStyles(({ fontWeight: 'bold' }), 0, temp)
                        i.set({ fontWeight: 'bold' })
                    }
                }
            })
            editor.canvas.renderAll()
        }
    }

}
const handleItalic = () => {

    if (editor.canvas.getActiveObjects().length > 1) {
        let italic = 0
        let word = 0
        editor.canvas.getActiveObjects().forEach((i) => {
            if (i.type == 'textbox') {
                word++
                if (i.fontStyle == 'italic') {
                    italic++
                }
            }
        })
        editor.canvas.getActiveObjects().forEach((i) => {
            if (i.type == 'textbox') {
                if (italic == word) {
                    let temp = i.get('text').length
                    i.setSelectionStyles(({ fontStyle: 'normal' }), 0, temp)
                    i.set({ fontStyle: 'normal' })
                }
                else {
                    let temp = i.get('text').length
                    i.setSelectionStyles(({ fontStyle: 'italic' }), 0, temp)
                    i.set({ fontStyle: 'italic' })
                }
            }
        })
        editor.canvas.renderAll()
    }
    else {
        const active = editor.canvas
        if (editor.canvas.getActiveObject().getSelectedText() && editor.canvas?.getActiveObjects()?.length === 1) {
            let italiccount = 0
            for (let i = 0; i <  splitter.countGraphemes(editor?.canvas?.getActiveObject().getSelectedText()); i++) {
                if (active.getActiveObject().getSelectionStyles()[i].fontStyle === 'italic') {
                    // console.log('yes im true');
                    italiccount++
                }
                else {
                    // console.log('yes im false');
                }
            }
            // console.log('italic-count', italiccount);
            let temp = active.getActiveObject().get('text').length
            if (italiccount === (splitter.countGraphemes(editor?.canvas?.getActiveObject().getSelectedText()))) {
                // console.log('unitalic')
                // console.log(active.getActiveObject().getSelectedText().length / 2)
                active.getActiveObject().setSelectionStyles({ fontStyle: 'normal' })
                if (italiccount === temp) {
                    active.getActiveObject().set({ fontStyle: 'normal' })
                    setItalic(false)
                }
                setItalic(false)
                active.renderAll()
            }
            else if (italiccount === 0) {
                // console.log('italic');
                active.getActiveObject().setSelectionStyles({ fontStyle: 'italic' })
                if (italiccount === temp) {
                    setItalic(true)
                    active.getActiveObject().set({ fontStyle: 'italic' })
                }
                setItalic(false)
            }
            else if (italiccount < splitter.countGraphemes(editor?.canvas?.getActiveObject().getSelectedText()) / 2) {
                // console.log('italic')
                active.getActiveObject().setSelectionStyles({ fontStyle: 'italic' })
                setItalic(false)
                active.getActiveObject().set({ fontStyle: 'normal' })
            }
            
            editor.canvas.renderAll()

        }
        else {
            let italic = 0
            let word = 0
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type == 'textbox') {
                    word++
                    if (i.fontStyle == 'italic') {
                        italic++
                    }
                }
            })
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type == 'textbox') {
                    if (italic == word) {
                        let temp = i.get('text').length
                        i.setSelectionStyles(({ fontStyle: 'normal' }), 0, temp)
                        i.set({ fontStyle: 'normal' })
                    }
                    else {
                        let temp = i.get('text').length
                        i.setSelectionStyles(({ fontStyle: 'italic' }), 0, temp)
                        i.set({ fontStyle: 'italic' })
                    }
                }
            })
            editor.canvas.renderAll()
        }
    }

}
const handleunderline = () => {
    if (editor.canvas.getActiveObjects().length > 1) {
        let underline = 0
        let word = 0
        editor.canvas.getActiveObjects().forEach((i) => {
            if (i.type == 'textbox') {
                word++
                if (i.underline == true) {
                    underline++
                }
            }
        })
        editor.canvas.getActiveObjects().forEach((i) => {
            if (i.type == 'textbox') {
                if (underline == word) {
                    let temp = i.get('text').length
                    i.setSelectionStyles(({ underline: false }), 0, temp)
                    i.set({ underline: false })
                }
                else {
                    let temp = i.get('text').length
                    i.setSelectionStyles(({ underline: true }), 0, temp)
                    i.set({ underline: true })
                }
            }
        })
        editor.canvas.renderAll()
    }
    else {
        const active = editor.canvas
        if (editor.canvas.getActiveObject().getSelectedText() && editor.canvas.getActiveObjects()?.length === 1) {
            let Underlinecount = 0
            for (let i = 0; i <  splitter.countGraphemes(editor?.canvas?.getActiveObject().getSelectedText()); i++) {
                if (active.getActiveObject().getSelectionStyles()[i].underline === true) {
                    // console.log('yes im true');
                    Underlinecount++
                }
                else {
                    // console.log('yes im false');
                }
            }
            // console.log('Underlinecount', Underlinecount);
            let temp = active.getActiveObject().get('text').length
            if (Underlinecount === (splitter.countGraphemes(editor?.canvas?.getActiveObject().getSelectedText()))) {
                // console.log('un-Underline')
                // console.log(active.getActiveObject().getSelectedText().length / 2)
                active.getActiveObject().setSelectionStyles({ underline: false })
                if (Underlinecount === temp) {
                    active.getActiveObject().set({ underline: false })
                    // setUnderline(false)
                }
                // setUnderline(false)
                active.renderAll()
            }
            else if (Underlinecount === 0) {
                // console.log('underline');
                active.getActiveObject().setSelectionStyles({ underline: true })
                if (Underlinecount === temp) {
                    // setUnderline(true)
                    active.getActiveObject().set({ underline: true })
                }
                // setUnderline(false)
            }
            else if (Underlinecount < splitter.countGraphemes(editor?.canvas?.getActiveObject().getSelectedText()) / 2) {
                // console.log('underline')
                active.getActiveObject().setSelectionStyles({ underline: true })
                // setUnderline(false)
                active.getActiveObject().set({ underline: false })
            }
            editor.canvas.renderAll()

        }
        else {

            let underline = 0
            let word = 0
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type == 'textbox') {
                    word++
                    if (i.underline == true) {
                        underline++
                    }
                }
            })
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type == 'textbox') {
                    if (underline == word) {
                        let temp = i.get('text').length
                        i.setSelectionStyles(({ underline: false }), 0, temp)
                        i.set({ underline: false })
                    }
                    else {
                        let temp = i.get('text').length
                        i.setSelectionStyles(({ underline: true }), 0, temp)
                        i.set({ underline: true })
                    }
                }
            })
            editor.canvas.renderAll()
        }
    }
    editor.canvas.renderAll()

}
const handlinethrough = () => {
    if (editor.canvas.getActiveObjects().length > 1) {
        let linethroughcount = 0
        let word = 0
        editor.canvas.getActiveObjects().forEach((i) => {
            if (i.type == 'textbox') {
                word++
                if (i.linethrough == true) {
                    linethroughcount++
                }
            }
        })
        editor.canvas.getActiveObjects().forEach((i) => {
            if (i.type == 'textbox') {
                if (linethroughcount == word) {
                    let temp = i.get('text').length
                    i.setSelectionStyles(({ linethrough: false }), 0, temp)
                    i.set({ linethrough: false })
                }
                else {
                    let temp = i.get('text').length
                    i.setSelectionStyles(({ linethrough: true }), 0, temp)
                    i.set({ linethrough: true })
                }
            }
        })
        editor.canvas.renderAll()
    }
    else {
        const active = editor.canvas
        if (editor.canvas.getActiveObject().getSelectedText() && editor.canvas.getActiveObjects()?.length === 1) {
            let linethroughcount = 0
            for (let i = 0; i <splitter.countGraphemes(editor?.canvas?.getActiveObject().getSelectedText()); i++) {
                if (active.getActiveObject().getSelectionStyles()[i].linethrough === true) {
                    // console.log('yes im true');
                    linethroughcount++
                }
                else {
                    // console.log('yes im false');
                }
            }
            // console.log('linethroughcount', linethroughcount);
            let temp = active.getActiveObject().get('text').length
            if (linethroughcount === (splitter.countGraphemes(editor?.canvas?.getActiveObject().getSelectedText()))) {
                // console.log('un-Underline')
                // console.log(active.getActiveObject().getSelectedText().length / 2)
                active.getActiveObject().setSelectionStyles({ linethrough: false })
                if (linethroughcount === temp) {
                    active.getActiveObject().set({ linethrough: false })
                    // setUnderline(false)
                }
                // setUnderline(false)
                active.renderAll()
            }
            else if (linethroughcount === 0) {
                // console.log('linethrough');
                active.getActiveObject().setSelectionStyles({ linethrough: true })
                if (linethroughcount === temp) {
                    // setUnderline(true)
                    active.getActiveObject().set({ linethrough: true })
                }
                // setUnderline(false)
            }
            else if (linethroughcount < splitter.countGraphemes(editor?.canvas?.getActiveObject().getSelectedText()) / 2) {
                // console.log('linethrough')
                active.getActiveObject().setSelectionStyles({ linethrough: true })
                // setUnderline(false)
                active.getActiveObject().set({ linethrough: false })
            }
            editor.canvas.renderAll()

        }
        else {

            let linethrough = 0
            let word = 0
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type == 'textbox') {
                    word++
                    if (i.linethrough == true) {
                        linethrough++
                    }
                }
            })
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type == 'textbox') {
                    if (linethrough == word) {
                        let temp = i.get('text').length
                        i.setSelectionStyles(({ linethrough: false }), 0, temp)
                        i.set({ linethrough: false })
                    }
                    else {
                        let temp = i.get('text').length
                        i.setSelectionStyles(({ linethrough: true }), 0, temp)
                        i.set({ linethrough: true })
                    }
                }
            })
            editor.canvas.renderAll()
        }
    }
    editor.canvas.renderAll()
    // editor.canvas.fire('object:modified');

}
const handleoverline = () => {
    if (editor.canvas.getActiveObjects().length > 1) {
        let overlinecount = 0
        let word = 0
        editor.canvas.getActiveObjects().forEach((i) => {
            if (i.type == 'textbox') {
                word++
                if (i.overline == true) {
                    overlinecount++
                }
            }
        })
        editor.canvas.getActiveObjects().forEach((i) => {
            if (i.type == 'textbox') {
                if (overlinecount == word) {
                    let temp = i.get('text').length
                    i.setSelectionStyles(({ overline: false }), 0, temp)
                    i.set({ overline: false })
                }
                else {
                    let temp = i.get('text').length
                    i.setSelectionStyles(({ overline: true }), 0, temp)
                    i.set({ overline: true })
                }
            }
        })
        editor.canvas.renderAll()
    }
    else {
        const active = editor.canvas
        if (editor.canvas.getActiveObject().getSelectedText() && editor.canvas.getActiveObjects()?.length === 1) {
            let overlinecount = 0
            for (let i = 0; i < splitter.countGraphemes(editor?.canvas?.getActiveObject().getSelectedText()); i++) {
                if (active.getActiveObject().getSelectionStyles()[i].overline === true) {
                    // console.log('yes im true');
                    overlinecount++
                }
                else {
                    // console.log('yes im false');
                }
            }
            // console.log('overlinecount', overlinecount);
            let temp = active.getActiveObject().get('text').length
            if (overlinecount === (splitter.countGraphemes(editor?.canvas?.getActiveObject().getSelectedText()))) {
                // console.log('un-overlinecount')
                // console.log(active.getActiveObject().getSelectedText().length / 2)
                active.getActiveObject().setSelectionStyles({ overline: false })
                if (overlinecount === temp) {
                    active.getActiveObject().set({ overline: false })
                    // setUnderline(false)
                }
                // setUnderline(false)
                active.renderAll()
            }
            else if (overlinecount === 0) {
                // console.log('overline');
                active.getActiveObject().setSelectionStyles({ overline: true })
                if (overlinecount === temp) {
                    // setUnderline(true)
                    active.getActiveObject().set({ overline: true })
                }
                // setUnderline(false)
            }
            else if (overlinecount < splitter.countGraphemes(editor?.canvas?.getActiveObject().getSelectedText()) / 2) {
                // console.log('overline')
                active.getActiveObject().setSelectionStyles({ overline: true })
                // setUnderline(false)
                active.getActiveObject().set({ overline: false })
            }
           
            editor.canvas.renderAll()
        }
        else {

            let overline = 0
            let word = 0
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type == 'textbox') {
                    word++
                    if (i.overline == true) {
                        overline++
                    }
                }
            })
            editor.canvas.getActiveObjects().forEach((i) => {
                if (i.type == 'textbox') {
                    if (overline == word) {
                        let temp = i.get('text').length
                        i.setSelectionStyles(({ overline: false }), 0, temp)
                        i.set({ overline: false })
                    }
                    else {
                        let temp = i.get('text').length
                        i.setSelectionStyles(({ overline: true }), 0, temp)
                        i.set({ overline: true })
                    }
                }
            })
            editor.canvas.renderAll()
        }
    }
    editor.canvas.renderAll()

}

const Fontcolor = ({ dragHandlers }) => {
    
    const [fontcolor, setFontcolor] = useState(textcolorgradient.current[0])
    
    
    const handlefontcolor = (e) => {
        setFontcolor(e)
        if (editor?.canvas?.getActiveObjects().length > 1) {
            editor?.canvas?.getActiveObjects().forEach((i) => {
                let temp = i.get('text').length
                i.setSelectionStyles(({ fill: e }), 0, temp) 
                i.set({fill:e})
            })
            editor.canvas.renderAll()
        }
        else {
            if (editor?.canvas?.getActiveObject()?.getSelectedText() && editor?.canvas?.getActiveObjects().length == 1) {
                if (editor.canvas.getActiveObject().getSelectedText()) {
                    editor.canvas.getActiveObject().setSelectionStyles({ fill: e })
                    editor.canvas.renderAll()
                }
            }
            else {
                let temp = editor?.canvas?.getActiveObject()?.get('text').length
                editor?.canvas?.getActiveObject().setSelectionStyles(({ fill: e }), 0, temp)
                editor?.canvas?.getActiveObject().set({fill:e})
                editor?.canvas?.renderAll()
            }
        }
        textcolorgradient.current = []

        editor?.canvas?.getActiveObjects().map((e)=>{
            Object.values(e?.styles).map((e)=>{
                Object.values(e).map((e)=>{
                    if(e?.fill){
                        if(!textcolorgradient.current.includes(e?.fill)){
                            textcolorgradient.current.push(e?.fill)
                        }
                    }
                    else{
                        if(!textcolorgradient.current.includes('#5F6368')){
                            textcolorgradient.current.push('#5F6368')
                        }
                    }
                })
            })
        })
    }
    

    const Eye = () => {
        const { open, close } = useEyeDropper()
        const [eyecolor, setEyecolor] = useState(fontcolor)
        const [eyeerror, setEyeerror] = useState()
        // useEyeDropper will reject/cleanup the open() promise on unmount,
        // so setState never fires when the component is unmounted.
        const pickColor = () => {
            open()
                .then(eyecolor => {
                    setEyecolor(eyecolor.sRGBHex)
                    setFontcolor(eyecolor.sRGBHex)
                    handlefontcolor(eyecolor.sRGBHex)
                   

                })
                .catch(e => {
                    // Ensures component is still mounted
                    // before calling setState
                    if (!e.canceled) setEyeerror(e)
                })
        }
        
        return (
            <>
                <button onClick={pickColor} className="eyecplacer" style={{ border: 'none', }}><ColorizeIcon /></button>
                {!!eyeerror && <span>{eyeerror.message}</span>}
            </>
        )
    }

        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (<>
            <span style={{  paddingTop: '4px'}} onClick={handleClick} >
            <Tooltip 
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: '#2A2A2A',
                        '& .MuiTooltip-arrow': {
                            color: '#2A2A2A',
                        },
                    },
                },
            }}
            title="Text color" placement="top" arrow>
                    <svg id="Group_1086" data-name="Group 1086" xmlns="http://www.w3.org/2000/svg" width="18.435" height="20.397" viewBox="0 0 18.435 20.397">
                        <defs>
                            <linearGradient id="text-color-gradient" >
                                {textcolorgradient.current.map((e, i) => {
                                    return <stop key={e} offset={((i+1)*(100/textcolorgradient.current.length)).toFixed(0)+"%"} stop-color={e} />
                                })}
                            </linearGradient>
                        </defs>
                        <rect id="Rectangle_2350" data-name="Rectangle 2350" width="18.435" height="4.136" transform="translate(0 16.261)" fill='url(#text-color-gradient)' />
                        <path id="Path_2683" data-name="Path 2683" d="M11.861,2.2h.094L14.2,8.178H9.591ZM10.7,0,5.1,13.236H7.654L8.93,9.974h5.909l1.252,3.262h2.718L13.35,0Z" transform="translate(-2.69)" fill="#5f6368" />
                    </svg>
                </Tooltip>
            </span>
            <Draggable handle="strong" {...dragHandlers} 
           
                >
                <Popover
                id={id}
               
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>

                    <div className='Fontcolormenu'>
                        <div className='drag-box-container'>
                            <strong className="drag-me ">
                                <DragIndicator />
                            </strong>
                            <CloseIcon className="close-icon" onClick={handleClose} />
                        </div>
                        <SketchPicker color={fontcolor} onChange={(color) => {
                            handlefontcolor("rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")");
                            
                        }} />
                        <Eye />
                    </div>
                </Popover>
            </Draggable>

        </>)
    }
// textalign

const textalignmenu = [
    { name: 'Left', icon: <FormatAlignLeftIcon /> },
    { name: 'Center', icon: <FormatAlignCenterIcon /> },
    { name: 'Right', icon: <FormatAlignRightIcon /> },
    { name: 'Justify', icon: <FormatAlignJustifyIcon /> },
]
const Fontaligncomp = () => {
    
    const handletextalign =(e)=> {
        if (editor.canvas.getActiveObjects()) {
            editor?.canvas?.getActiveObjects().forEach((i) => {
                i.set({ textAlign: e.toLowerCase() });
                editor?.canvas.renderAll();
            });
        }
    }
    

   
    const [anchorEl, setAnchorEl] = useState(null);

    const handleCloseAndOpen = (event) => {
        if (anchorEl == null) {
            setAnchorEl(event.currentTarget);
        }
        else {
            setAnchorEl(null);

        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (<>
        <Tooltip 
        componentsProps={{
            tooltip: {
                sx: {
                    bgcolor: '#2A2A2A',
                    '& .MuiTooltip-arrow': {
                        color: '#2A2A2A',
                    },
                },
            },
        }}
        title="Text align" placement="top" arrow>
        <span onClick={handleCloseAndOpen} >
            {textalignicon}
        </span></Tooltip>
       
            <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleCloseAndOpen}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}>
                <div className='Fontalign'>
                    <div className='Fontalignmenu'>
                    {textalignmenu.map((e) => {
                    return (
                        <span key={e} className='sizebtn' onClick={() => { handletextalign(e.name) }}>{e.icon}{e.name}</span>
                    )
                    })}
                    </div>
                </div>
            </Popover>
    </>)
}

// textEffects
const TextEffects = ({ dragHandlers }) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // shadow blur
    const [shadowBlur, setShadowBlur] = useState(0)

    const handleShadowBlur = (e) => {
        setShadowBlur(e.target.value);

    }
    
    useEffect(() => {
    
        if (editor) {
            if (editor.canvas.getActiveObject().shadow) {
                setShadowBlur(editor.canvas.getActiveObject().shadow.blur)
                editor.canvas.renderAll()
            }
        }
    }, [editor])



    const [offsetY, setOffsetY] = useState(0)

    const handleOffY = (e) => {
        setOffsetY(e.target.value);

    }

    const offYTemp = useRef(true)

    useEffect(() => {
        // if(opacitytemp.current){
        //     opacitytemp.current = false
        //     return;
        // }
        if (editor) {
            if (editor.canvas.getActiveObject().shadow) {
                setOffsetY(editor.canvas.getActiveObject().shadow.offsetY * 10)
                editor.canvas.renderAll()

            }
        }
    }, [editor])

    const [offsetX, setOffsetX] = useState(0)

    const handleOffX = (e) => {
        setOffsetX(e.target.value);

    }


    useEffect(() => {
        // if(opacitytemp.current){
        //     opacitytemp.current = false
        //     return;
        // }
        if (editor) {
            if (editor.canvas.getActiveObject().shadow) {
                setOffsetX(editor.canvas.getActiveObject().shadow.offsetX * 10)
                editor.canvas.renderAll()

            }
        }
    }, [editor])

    const [shadowColor, setShadowColor] = useState('#000')

    const handleShadowColor = (e) => {
        setShadowColor(e.target.value);

    }


    useEffect(() => {
    
        if (editor) {
            if (editor.canvas.getActiveObject().shadow) {
                setShadowColor(editor.canvas.getActiveObject().shadow.color)
                editor.canvas.renderAll()

            }
        }
    }, [editor])




    useEffect(() => {

        if (offYTemp.current) {
            offYTemp.current = false;
            return;
        }

        // if (editor.canvas.getActiveObjects().type == 'textbox') {

        editor?.canvas.getActiveObjects().forEach((i) => {
            if (i.type == 'textbox') {
                i.set('shadow', new fabric.Shadow({
                    blur: shadowBlur,
                    color: shadowColor,
                    offsetX: offsetX / 10,
                    offsetY: offsetY / 10,

                }
                ))
            }
        })
        editor.canvas.renderAll();
        // }

    }, [offsetY, shadowBlur, offsetX, shadowColor])


// backgroundcolor

    const [textBackgroundColors, setTextBackgroundColors] = useState(textbackgroundgradient.current[0])
  
    const Eye = () => {
        const { open, close } = useEyeDropper()
        const [eyecolor, setEyecolor] = useState()
        const [eyeerror, setEyeerror] = useState()
        // useEyeDropper will reject/cleanup the open() promise on unmount,
        // so setState never fires when the component is unmounted.
        const pickColor = () => {
            open()
                .then(eyecolor => {
                    setEyecolor(eyecolor.sRGBHex)
                    setTextBackgroundColors(eyecolor.sRGBHex)
                    handleTextBackgroundColor(eyecolor.sRGBHex)
                })
                .catch(e => {
                    // console.log(e)
                    // Ensures component is still mounted
                    // before calling setState
                    if (!e.canceled) setEyeerror(e)
                })
        }
       
        // handlefontcolor(eyecolor);
        return (
            <>
                <button onClick={pickColor} className="eyecplacer" style={{ border: 'none', }}><ColorizeIcon /></button>
                {!!eyeerror && <span>{eyeerror.message}</span>}
            </>
        )
    }


    const handleTextBackgroundColor = (e) => {
        setTextBackgroundColors(e)
        if (editor?.canvas?.getActiveObjects().length > 1) {
            editor?.canvas?.getActiveObjects().forEach((i) => {
                let temp = i.get('text').length
                // i.setSelectionStyles(({ textBackgroundColor: e }), 0, temp)  
                i.set({ backgroundColor: e })  
                // i.setSelectionStyles(({ fill: '' }), 0, temp)  
                // i.set({ fill: e })
            })
            editor.canvas.renderAll()
        }
        else {
            if (editor?.canvas?.getActiveObject()?.getSelectedText() && editor?.canvas?.getActiveObjects().length == 1) {
                if (editor.canvas.getActiveObject().getSelectedText()) {
                    editor.canvas.getActiveObject().setSelectionStyles({ textBackgroundColor: e })
                    editor.canvas.renderAll()
                    
                }
            }
            else {
                let temp = editor?.canvas?.getActiveObject()?.get('text').length
                // editor?.canvas?.getActiveObject().setSelectionStyles(({ textBackgroundColor: e }), 0, temp)
                editor?.canvas?.getActiveObject().set({ backgroundColor: e })  
                editor?.canvas?.renderAll()
            }
        }
        textbackgroundgradient.current = []

        editor?.canvas?.getActiveObjects().map((e) => {
            Object.values(e?.styles).map((e) => {
                Object.values(e).map((e) => {
                    if (e?.textBackgroundColor) {
                        if(e?.textBackgroundColor.replace(/^.*,(.+)\)/,'$1')+'' == 0){
                        }else{
                            if (!textbackgroundgradient.current.includes(e?.textBackgroundColor)) {
                                textbackgroundgradient.current.push(e?.textBackgroundColor)
                            }
                        }
                       
                    }
                    else {
                        if (!textbackgroundgradient.current.includes('#111')) {
                            textbackgroundgradient.current.push('#111')
                        }
                    }
                })
            })
        })
    }

    useEffect(() => {
       
        if (editor) {
            if (editor.canvas.getActiveObject().type == 'textbox') {
                setTextBackgroundColors(editor.canvas.getActiveObject().backgroundColor)
                editor.canvas.renderAll()
            }
        }
    }, [editor])


// stroke
    const [strokeColor, setStrokeColor] = useState('#000')
    const [strokeWidth, setStrokeWidth] = useState(0)

    const handleStrokeColor = (e) => {
        setStrokeColor(e.target.value)
    }

    const handleStrokeWidth = (e) => {
        setStrokeWidth(e.target.value)
    }

    useEffect(() => {
        if (editor) {
            if (editor.canvas.getActiveObject().type == 'textbox') {
                setStrokeColor(editor.canvas.getActiveObject().stroke)
                editor.canvas.renderAll()
            }
        }
    }, [editor])

    useEffect(() => {
        if (editor) {
            if (editor.canvas.getActiveObject().type == 'textbox') {
                setStrokeWidth(editor.canvas.getActiveObject().strokeWidth * 50)
                editor.canvas.renderAll()
            }
        }
    }, [editor])


    const stcolorTemp = useRef(true)
    useEffect(() => {

        if (stcolorTemp.current) {
            stcolorTemp.current = false;
            return;
        }

        // if (editor.canvas.getActiveObject()?.type == 'textbox') {

        editor?.canvas.getActiveObjects().forEach((i) => {
            if (i.type == 'textbox') {
                i.set({
                    stroke: strokeColor,
                    strokeWidth: strokeWidth / 50,

                })
            }
        })

        editor.canvas.renderAll();
        // }

    }, [strokeColor, strokeWidth])






    const [shadowProperty, setShadowProperty] = useState(false)
    const [blurProperty, setBlurProperty] = useState(false)
    const [strokeProperty, setStrokeProperty] = useState(false)
    // const [hallowProperty, setHallowProperty] = useState(false)



   
    const handleCloseAndOpen = (event) => {
        if (anchorEl == null) {
            setAnchorEl(event.currentTarget);
        }
        else {
            setAnchorEl(null);
        }
    }




    return (

        <div className="text-effects">
             <Tooltip 
             componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: '#2A2A2A',
                        '& .MuiTooltip-arrow': {
                            color: '#2A2A2A',
                        },
                    },
                },
            }}
             title="Text Background" placement="top" arrow><span aria-describedby={id} className={anchorEl ? "toolbar-icons-active filters-button " : "toolbar-icons filters-button"} onClick={handleCloseAndOpen}>
                    <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" width='23' height='23' xmlns="http://www.w3.org/2000/svg" data-testid="FontDownloadIcon">
                        <linearGradient id="text-background-gradient" >
                            {textbackgroundgradient.current.map((e, i) => {
                                return <stop key={e} offset={((i + 1) * (100 / textbackgroundgradient.current.length)).toFixed(0) + "%"} stop-color={e} />
                            })}
                            {/* {textbackgroundgradient.current.length == 1 &&  <stop offset={"100%"} stop-color={'#5F6368'} />} */}
                        </linearGradient>
                        <path d="M9.93 13.5h4.14L12 7.98zM20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-4.05 16.5-1.14-3H9.17l-1.12 3H5.96l5.11-13h1.86l5.11 13h-2.09z" stroke="#5F6368" stroke-width="0.5" fill='url(#text-background-gradient)'></path>
                    </svg>
            </span>
            </Tooltip>
            <Draggable handle="strong" {...dragHandlers}>
                <Popover
                   
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}

                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="text-effects-wrapper box ">
                        <div className='drag-box-container'>
                            <strong className="drag-me ">
                                <DragIndicator />
                            </strong>
                            <CloseIcon className="close-icon" onClick={handleClose} />

                        </div>
                       
                        {shadowProperty &&
                            <><div className="text-effects-head-adjust">
                                <span>Shadow</span>
                            </div>

                                <div className="text-effects-adjust">
                                    <div className="text-effect-range-container">
                                        <label className="form-label text-effect-input-value">Blur</label>
                                        <Slider size="small" aria-label="Small" value={shadowBlur} min={0} max={100} onChange={handleShadowBlur} valueLabelDisplay="auto" />
                                    </div>
                                    <div className="text-effect-range-container">
                                        <label className="form-label text-effect-input-value">Offset-X</label>
                                        <Slider size="small" aria-label="Small" value={offsetX} min={-100} max={100} onChange={handleOffX} valueLabelDisplay="auto" />
                                    </div>
                                    <div className="text-effect-range-container">
                                        <label className="form-label text-effect-input-value">Offset-Y</label>
                                        <Slider size="small" aria-label="Small" value={offsetY} min={-100} max={100} onChange={handleOffY} valueLabelDisplay="auto" />

                                    </div>
                                    <div className="text-effect-range-container-color">
                                        <span className="text-effect-input-value-color">Color</span>
                                        <SketchPicker color={shadowColor} onChange={(e) => {
                                            setShadowColor("rgba(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")");
                                        }} />
                                    </div>
                                </div>
                            </>
                        }
                     
                            <><div className="text-effects-head-adjust">

                                <span>Background</span>
                                </div><div className="text-effects-adjust">
                                    <div className="text-effect-range-container-color">
                                        <SketchPicker color={textBackgroundColors} onChange={(e) => {
                                            handleTextBackgroundColor("rgba(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")");
                                        }} />
                                        <Eye/>
                                    </div>
                                </div>
                            </>


                        
                        {strokeProperty &&
                            <> <div className="text-effects-head-adjust">

                                <span>Stroke</span>
                            </div>
                                <div className="text-effects-adjust">
                                    <label className="form-label text-effect-input-value">Stoke width</label>
                                    <div className="text-effect-range-container">
                                        <Slider size="small" aria-label="Small" value={strokeWidth} min={0} max={100} onChange={handleStrokeWidth} valueLabelDisplay="auto" />

                                    </div>
                                    <div className="text-effect-range-container-color">
                                        <span className="text-effect-input-value">Stroke color</span>
                                        <input type="color" className="form-range text-effects-color-range" value={strokeColor} onChange={handleStrokeColor} />
                                    </div>
                                </div>
                            </>

                        }

                      
                    </div>




                </Popover>
            </Draggable>
        </div>

    )


}

const Spacingtoggler = ({ dragHandlers }) => {

    //  letter spacing
        const [linespacing, setLinespacing] = useState(100)
        const linespacinginputer = useRef(null)
    
    
        useEffect(() => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    setLinespacing((editor.canvas.getActiveObject().lineHeight * 100))
                    editor.canvas.renderAll()
                }
            }
        }, [editor])
    
        const changeLinespacingvalue = (e) => {
            setLinespacing(e.target.value);
    
        }
        const linespacingtemp = useRef(true)
        useEffect(() => {
            if (linespacingtemp.current) {
                linespacingtemp.current = false
                return;
            }
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    
    
                    try {
                        editor.canvas.getActiveObject().set({ lineHeight: linespacing / 100 })
                        editor.canvas.renderAll()
        
                    }
                    catch {
                    }
                }
            }
        }, [linespacing])
    
        // character spacing
    
        const [charspacing, setCharspacing] = useState(100)
        const charspacinginputer = useRef(null)
    
    
        useEffect(() => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    setCharspacing((editor.canvas.getActiveObject().charSpacing))
                    editor.canvas.renderAll()
                }
            }
        }, [editor])
    
        const changeCharspacingvalue = (e) => {
            setCharspacing(e.target.value * 10)
    
        }
        const charspacingtemp = useRef(true)
        useEffect(() => {
            if (charspacingtemp.current) {
                charspacingtemp.current = false
                return;
            }
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    try {
                        editor.canvas.getActiveObject().set({ charSpacing: charspacing })
                        editor.canvas.renderAll()
                    }
                    catch {
                    }
                }
            }
        }, [charspacing])
    
    
    
    
        const [anchorEl, setAnchorEl] = useState(null);
    
        const handleCloseAndOpen = (event) => {
            if (anchorEl == null) {
                setAnchorEl(event.currentTarget);
            }
            else {
                setAnchorEl(null);
    
            }
        }
    
        const handleClose = () => {
            setAnchorEl(null);
        };
    
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;



        const [expanded, setExpanded] = useState('panel1');

        const handleChange = (panel) => (event, newExpanded) => {
            setExpanded(newExpanded ? panel : false);
        };



        // script 
        const textscriptmenu = [
            { name: 'none', icon: <BlockIcon /> },
            { name: 'Superscript', icon: <SuperscriptIcon /> },
            { name: 'Subscript', icon: <SubscriptIcon /> }
        ]
        const superscript = () => {
            var active = editor.canvas.getActiveObject();
            if (!active) return;
            active.setSelectionStyles({
                fontSize: undefined,
                deltaY: undefined,
            });
            active.setSuperscript();
            editor.canvas.requestRenderAll();
        }
        const subscript = () => {
            var active = editor.canvas.getActiveObject();
            if (!active) return;
            active.setSelectionStyles({
                fontSize: undefined,
                deltaY: undefined,
            });
            active.setSubscript();
            editor.canvas.requestRenderAll();
        }
        const nonescript = () => {
            var active = editor.canvas.getActiveObject();
            if (!active) return;
            active.setSelectionStyles({
                fontSize: undefined,
                deltaY: undefined,
            });
            editor.canvas.requestRenderAll();
        }
        const callbackscript = (e) => {
            e == 'none' ? nonescript() : e == 'Superscript' ? superscript() : subscript();
        }

        const onuppercase = () => {
            editor.canvas.getActiveObjects().forEach((e) => {
                e.set('uppercase', true)
                e.set('text', e.text.toUpperCase())
            })
            editor.canvas.renderAll()
        }

        const capitalization = () => {
            editor.canvas.getActiveObjects().forEach(e=>{
                e.set('uppercase', true)
                e.set('text', e.text.charAt(0).toUpperCase() + e.text.slice(1).toLowerCase())
            })
            editor.canvas.renderAll()
            
        }

        const onlowercase = () => {
            editor.canvas.getActiveObjects().forEach((e)=>{
                e.set('uppercase', false)
                e.set('text', e.text.toLowerCase())
            })
            editor.canvas.renderAll()
        }
       
                
        return (
            <div className="spacecontainer"  >
                <Tooltip 
                componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: '#2A2A2A',
                            '& .MuiTooltip-arrow': {
                                color: '#2A2A2A',
                            },
                        },
                    },
                }}
                title="More" placement="top" arrow>
                <span  onClick={handleCloseAndOpen} >
                    <MoreVertIcon className='textmore-icon' />
                </span>
                </Tooltip>
                <Draggable handle="strong" {...dragHandlers}>
                    <Popover
                        id={id}
                        
                        open={open}
                        onClose={handleClose}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className='accordion-texttool'>
                            <div className='drag-box-container'>
                                <strong className="drag-me ">
                                    <DragIndicator />
                                </strong>
                                <CloseIcon className="close-icon" onClick={handleClose} />

                            </div>
                            <p className='p-font-case'>
                                <span>Cases </span>
                                <span className='btns font-case' onClick={()=>{onuppercase()}}>AA</span>
                                <span className='btns font-case' onClick={()=>{onlowercase()}}>aa</span>
                                <span className='btns font-case' onClick={()=>{capitalization()}}>Aa</span>
                            </p>
                            <p>
                                <span>Scripts  </span>{textscriptmenu.map((e) => {
                                    return (
                                        <span key={e} className='btns' onClick={() => { callbackscript(e.name) }}>{e.icon}</span>
                                    )
                                })}</p>
                            
                            <p className='other-styles'> 
                                <span>Styles  </span>
                                <span className='btns' onClick={handlinethrough}><FormatStrikethroughSharpIcon /></span>
                                <span className='btns' onClick={handleoverline}><FormatOverlineSharpIcon /></span>
                            </p>
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                    <Typography  component={'div'}>Spacing</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography component={'div'}>
                                        <div className="div-spacesection" style={{pointerEvents: textcount.current > 1 ? 'none' : 'null',opacity:textcount.current > 1 ? '0.5' : '1'  }}>
                                            <div className="linespacing">
                                                <label className="adjust-headers m-0">LineSpacing</label>
                                                <div className="linespacing-inputflex">
                                                    <Slider size="small" aria-label="Small" value={linespacing} min={1} max={250} ref={linespacinginputer} onChange={changeLinespacingvalue} id="linespacing" valueLabelDisplay="auto" />
                                                </div>
                                            </div>

                                            <div className="charspacing">
                                                <label className="adjust-headers m-0">LetterSpacing</label>
                                                <div className="charspacing-inputflex">
                                                    <Slider size="small" aria-label="Small" value={charspacing / 10} min={1} max={100} ref={charspacinginputer} onChange={changeCharspacingvalue} id="charspacing" valueLabelDisplay="auto" />
                                                </div>
                                            </div>
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                           
                        </div>





                        
                    </Popover>
                </Draggable>
    
            </div>
        );
    }

const Bullets = () => {
        
        const [bullettab,setbullettab] = useState(true)

        const [anchorEl, setAnchorEl] = useState(null);
    
        const handleCloseAndOpen = (event) => {
            if (anchorEl == null) {
                setAnchorEl(event.currentTarget);
            }
            else {
                setAnchorEl(null);
    
            }
        }
    
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;
    
    const [currentlist,setCurrentlist] = useState([editor.canvas.getActiveObject().listType,editor.canvas.getActiveObject().listBullet])
        
    const setlist =(e,sym)=>{
        editor.canvas.getActiveObject().set({listBullet : sym})
        editor.canvas.getActiveObject().set({listCounter :  0})
        editor.canvas.getActiveObject().set({isWrapping : false})
        editor.canvas.getActiveObject().set({objectCaching: false})
        editor.canvas.getActiveObject().set({listType : e })
        setCurrentlist([e,sym])
        editor.canvas.renderAll()
    }

    const bullets =[
        {'bullets':""},
        {'bullets':'•'},
        {'bullets':'✓'},
        {'bullets':'★'},
        {'bullets':'◈'},
    ]

    
        return (<>
            <Tooltip 
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: '#2A2A2A',
                        '& .MuiTooltip-arrow': {
                            color: '#2A2A2A',
                        },
                    },
                },
            }}
            title="Bullets" placement="top" arrow>
            <span onClick={handleCloseAndOpen} >
                <FormatListBulletedIcon />
            </span></Tooltip>
                <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseAndOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                    <div className='bullets'>
                        <p className='text-bullet-list-tab-header'>
                            <span onClick={()=>{setbullettab(current => true)}} style={{background:bullettab == true ? '#e8f0fe' : null}}>bullets</span>
                            <span onClick={()=>{setbullettab(current => false)}} style={{background:bullettab == false ? '#e8f0fe' : null}}>lists</span>
                        </p>
                        {bullettab == true &&
                            <p className='bullet-tab-item-list symbols'>
                               { bullets.map((e)=>{
                                    return <span style={{background:currentlist[1] == e.bullets && currentlist[0] == 'bullet' && '#e8f0fe'}} onClick={()=>{setlist('bullet',e.bullets);setCurrentlist(['bullet',e.bullets])}}>{e.bullets == "" ? <DoNotDisturbIcon /> : e.bullets }</span> 
                                })}
                                
                            </p>
                        }
                        {bullettab == false &&  <p className='bullet-tab-item-list'>
                            <span style={{background:currentlist[0] === 'number' && '#e8f0fe'}}  onClick={()=>{setlist('number')}}><FormatListNumberedIcon/></span>
                            <span style={{background:currentlist[0] === 'Alpha' && '#e8f0fe'}}  onClick={()=>{setlist('Alpha')}}>Alpha</span>
                            <span style={{background:currentlist[0] === 'alpha' && '#e8f0fe'}}  onClick={()=>{setlist('alpha')}}>alpha</span>
                            <span style={{background:currentlist[0] ===  'romen' && '#e8f0fe'}}  onClick={()=>{setlist('romen')}}>romen</span>
                            </ p>
                        }
                        
                       
                    </div>
                </Popover>
        </>)
    }

    
    


    return (<>
        <div className='text-tools' style={{top:ruleonoff ? '40px' : '10px',left:ruleonoff ? '40px' : '10px',zIndex:ruleonoff ? 9999 : 1}}>
            <div className={tabIndex === 11 ? 'font-family active' : 'font-family'} style={{ fontFamily: editor?.canvas.getActiveObject('textbox')?.fontFamily }} onClick={() => { workspaceSwitch === "Workspace" ? setTabIndex(10) : setTabIndex(11) }} >
                {fontFamilyobj}
            </div>
            <div className='font-size'>
                <span onClick={fontdecrease}><RemoveIcon className="toolbar-icons" /></span>
                <Fontsizecomp />
                <span onClick={fontincrease}><AddIcon className="toolbar-icons" /></span>
            </div>
            <div className="text-styles">
                <Tooltip 
                componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: '#2A2A2A',
                            '& .MuiTooltip-arrow': {
                                color: '#2A2A2A',
                            },
                        },
                    },
                }}
                title="Bold" placement="top" arrow>
                    <span onClick={handleBold} className={bold ? 'active' : null}>
                        <FormatBoldIcon className="toolbar-icons" />
                    </span>
                </Tooltip>
                <Tooltip 
                componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: '#2A2A2A',
                            '& .MuiTooltip-arrow': {
                                color: '#2A2A2A',
                            },
                        },
                    },
                }}
                title="Italic" placement="top" arrow>
                    <span onClick={handleItalic} className={italic ? 'active' : null}>
                        <FormatItalicIcon className="toolbar-icons" />
                    </span>
                </Tooltip>
                <Tooltip 
                componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: '#2A2A2A',
                            '& .MuiTooltip-arrow': {
                                color: '#2A2A2A',
                            },
                        },
                    },
                }}
                title="Underline" placement="top" arrow>
                    <span onClick={handleunderline} className={underline ? 'active' : null}>
                        <FormatUnderlinedIcon className="toolbar-icons" />
                    </span>
                </Tooltip>
                <Fontcolor/>
                <Divider orientation="vertical" flexItem />
                <Fontaligncomp/>
                <span>
                    <TextEffects />
                </span>
                {totaltext && <span>
                <StrokeStyle
                    editor={editor}
                    fabric={fabric}
                    selectedobjectforsvg={selectedobjectforsvg}
                    totalimage={totalimage}
                    totalobject={totalobject}
                    totaltext={totaltext}
                   
                    />
                </span>}
                <span>
                    <Shadow
                    editor={editor}
                    fabric={fabric}/>
                </span>
                <DraftEditor
                    editor={editor}
                    fabric={fabric}
            
                    setTabIndex={setTabIndex}
                    tabIndex={tabIndex}
                    workspaceSwitch={workspaceSwitch}
                    fontFamilyobj={fontFamilyobj}
                    scaleCanvas={scaleCanvas}
                    textalignicon={textalignicon}
                    setFontFamilyobj={setFontFamilyobj}

                    />
                <span className='spacing-span' style={{padding:'0'}}><Spacingtoggler/></span>     
                {/* <span className='spacing-span' style={{padding:'0'}}><Bullets/></span>      */}
            </div>
        </div>
       
    </>)
}

export default Texttools;