import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as SideBarToggle } from '../../assets/icons/SideBarToggle.svg'

const Layers = (props) => {

    const {
        editor,
        handlePageSideBar

    } = props



    const selectObject = (name, index) => {
        var objs = editor.canvas.getObjects()
        objs?.reverse()?.forEach(function (obj) {
           

            if (obj && objs.indexOf(obj) == index) {
                editor.canvas.setActiveObject(obj);
                editor.canvas.requestRenderAll();
            }


        });
    }



    const onDragEnd = (e) => {
      
        let objs = editor.canvas.getObjects()?.reverse()
        objs[e.source.index].moveTo((objs?.length - 1) - e.destination.index)
        editor.canvas.requestRenderAll()
    }
    let obact = editor?.canvas.getObjects('rect').find(Imageobj => Imageobj?.custtype == 'picArea')

    return (
    
        <div className="layer-sidebar">
            <div className="close-toggle-sidebar-right" onClick={() => {handlePageSideBar()}}>
                <SideBarToggle className="close"/>
            </div>
            <div className="sidebar-tabs-heading">
                <span className="layer-heading">Layers</span>
            </div>
         
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div className="layer-container" {...provided.droppableProps}
                            ref={provided.innerRef}>
                            {editor?.canvas.getObjects()?.reverse()?.map((list, index) => {

                                    list.set({
                                        globalCompositeOperation: 'source-over'
                                    })
                                    let dataurl = list.toDataURL({
                                        format: 'png',
                                        enableRetinaScaling: true,

                                    })
                                    list.set({
                                        globalCompositeOperation: 'source-atop'
                                    })
                                return (
                                    <>
                                    {(list !== obact) &&
                                    <Draggable key={list.id} draggableId={list.name} index={index}
                                    isDragDisabled={list.id === 'background'}
                                    >
                                        {(provided, snapshot) => (
                                            <div className="layer-inner-wrap" ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps} onClick={(e) => selectObject(list.name, index)}>
                                                <div className={editor?.canvas.getObjects()?.reverse()?.indexOf(editor?.canvas.getActiveObject()) === index ? "layer-inner-thumbs-container-active" : "layer-inner-thumbs-container"}>
                                                    <img className="layer-inner-thumbs" src={dataurl} />
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>}
                                    </>
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>

    )


}

export default Layers;