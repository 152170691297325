import { useState, useEffect, useCallback } from "react";

import Config from "../../config/Config";
import axios from "axios";

function useFetch(query, oddpagephotos,evenpagephotos) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);
  const [firstoddhalf,setFirstoddhalf] = useState([])
  const [secondevenhalf,setSecondevenhalf] = useState([])
  let tempstore = []

  // https://pixabay.com/api/?key=${Config.PIXABAY_API}&q=${photosearchword}&image_type=photo&per_page=50&page=1


  // const sendQuery = useCallback(async () => {
  //   try {
  //     await setLoading(true);
  //     await setError(false);
  //     // const res = await axios.get(`${Config.PIXABAY_API2}&${page}&q=${query}`);
  //     const response = await fetch(`${Config.PIXABAY_API2}&${page}&q=${query}`);
  //     const jsonifiedResponse = await response.json();
  //     // console.log(res);
  //     console.log(jsonifiedResponse);
  //     // tempstore = tempstore.concat(res.data.hits) 
  //     // console.log(tempstore)
      

  //     setList(jsonifiedResponse.hits)

  //     // setLoading(false);
  //   } catch (err) {
  //     setError(err);
  //   }
  // }, [query, page]);
  useEffect(()=>{
    setFirstoddhalf([])
    setSecondevenhalf([])
  },[query])

  const sendQuery = useCallback(async () => {
    try {
      await setLoading(true);
      await setError(false);
      const oddphotos = await axios.get(`${Config.PIXABAY_API2}&page=${oddpagephotos}&q=${query}`)
      const evenphotos = await axios.get(`${Config.PIXABAY_API2}&page=${evenpagephotos}&q=${query}`)
      // await setList((prev) => [...prev, ...res.data.hits]);
      await setFirstoddhalf((prev) => [...prev, ...oddphotos.data.hits])
      await setSecondevenhalf((prev) => [...prev, ...evenphotos.data.hits])



      //unsplash api
      // const oddphotos = await axios.get(`${Config.UNSPLASH_API}&page=${oddpagephotos}&query=${query}&per_page=10`)
      // const evenphotos = await axios.get(`${Config.UNSPLASH_API}&page=${evenpagephotos}&query=${query}&per_page=10`)
      // // await setList((prev) => [...prev, ...res.data.hits]);
      // // console.log(oddphotos)
      // await setFirstoddhalf((prev) => [...prev, ...oddphotos.data.results])
      // await setSecondevenhalf((prev) => [...prev, ...evenphotos.data.results])




      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
      return false
    }
  }, [query, oddpagephotos,evenpagephotos]);


  useEffect(() => {
    sendQuery(query);
  }, [query, sendQuery, oddpagephotos,evenpagephotos]);

  return { loading, error, list ,firstoddhalf ,secondevenhalf };
}

export default useFetch;