import React, { useState, useRef, useEffect,useCallback} from "react";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ImagetranslationProject from './myworktab/ImagetranslationPoject';
import Allprojects from './myworktab/Allprojects';
import MyTemplate from './myworktab/MyTemplate';
import Tooltip from '@mui/material/Tooltip';
import FontFaceObserver from 'fontfaceobserver';
import InfoIcon from '@mui/icons-material/Info';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Masonry from '@mui/lab/Masonry';
import Grow from '@mui/material/Grow';
import Skeleton from '@mui/material/Skeleton';

const MyDesign = (props) => {


    const {
        handleSidebarClose,
        LABS_API,
        // design,
        editor,
        setCanvasheight,
        setCanvaswidth,
        getMyDesign,
        handleAuthTrue,
        setCanvasBgColor,
        designid,
        setDesignid,
        setName,
        setDesigntemp,
        setPageData,
        getMyTranslatedImageproject,
        translateImage,
        translatedImageid,
        setTranslatedImageid,
        translateimagetemp,
        setTranslateimagetemp,
        deleteTranslatedimage,
        setManualChecked,
        setCropImageSource,
        handlesize,
        canvasheight,
        canvaswidth,
        setAutoSaved,
        setCanvasmodified,
        fabric,
        getProjectData,
        getImageTranslateProjectData,
        workspaceSwitch,
        langLabel,
        setShowTemplateNotice,
        setTemplateData,
        setTemplateTotalData,
        settemplateFrom,
        setTranslatedimage,
        replaceCurrentPage,
        loadFabricJSONDataTemplate,
        handleSave
    } = props

    // const [designid, setDesignid] = useState(null)
    const [design, setDesign] = useState([])
    const [pageNo, setPageNo] = useState(1)
    const navigate = useNavigate();
    const URL_SEARCH_PARAMS = new URLSearchParams(window.location.search);
    const [myTemplate, setMyTemplate] = useState([])
    const nextpage = useRef(true)
    const loadingdesign = useRef(false)
    const designtypeSearch = useRef()


    
    useEffect(()=>{
        toggleTab(2)
    },[])



    const loadTranslatedImage = (e) => {
        setDesignid(null)
        if (e.image_inpaint_creation.length > 0) {

        }
        else {
            // console.log('ran')

            navigate(`/ailaysa-canvas/image-translate/?project=${e.id}`)
            setName(e.project_name)
            setTranslatedImageid(e.id)
            setManualChecked(true)
            setCanvasheight(e.height)
            setCanvaswidth(e.width)
            setCropImageSource(LABS_API + '/' + e.image)
            editor.canvas.loadFromJSON(e.mask_json, function () {
                editor.canvas.renderAll.bind(editor.canvas.renderAll());

            })
            var size = size = { name: 'loadcanvas', width: e.width, height: e.height, fill: 'rgba(255,255,255,1)' }
            handlesize(size)
        }

    }

    const [designinputText, setDesigninputText] = useState("");
    const [focusOnSearch, setFocusOnSearch] = useState(false)

    let inputDesignhandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        setDesigninputText(lowerCase);

    };

    const designFilteredData = design.filter((el) => {
        //if no input the return the original
        if (designinputText === '') {
            return el;
        }
        //return the item which contains the user input
        else {
            return el.file_name?.toLowerCase().includes(designinputText)
        }
    })
    // console.log(designFilteredData)

    function loadFabricJSONData(file, jsonData) {
        navigate(`/ailaysa-canvas/design/?project=${jsonData.canvas_design}`)

        // Get all font family names from the JSON data, including nested groups
        document.querySelector('.canvas-space').classList.add('loading-json-blur');
        document.querySelector('#loading-wrapper').style.setProperty('display', 'grid');



        const fontFamilies = [];

        function extractFontFamilies(obj) {
            if (obj.fontFamily && !fontFamilies.includes(obj.fontFamily)) {
                fontFamilies.push(obj.fontFamily);
            }
            if (obj.type === 'group' && obj.objects) {
                obj.objects.forEach((groupObj) => extractFontFamilies(groupObj));
            }
        }

        jsonData.json.objects.forEach((obj) => extractFontFamilies(obj));

        // Remove duplicate font families
        const uniqueFontFamilies = [...new Set(fontFamilies)];
        // Check if all font families are loaded in the DOM
        uniqueFontFamilies.forEach((fontFamily) => {
            // document.querySelectorAll('head link[name]')
            var link = document.createElement('link')
            link.href = `https://fonts.googleapis.com/css?family=${fontFamily}`
            link.rel = 'stylesheet'
            link.setAttribute('name', fontFamily)
            document.querySelector('head').appendChild(link)

        });

        // Get all link tags with a href attribute containing "fonts.googleapis.com"
        const fontLinks = document.querySelectorAll('link[href*="fonts.googleapis.com"]');

        // Create an array to store the unique href values
        const uniqueLinks = [];



        // Loop through the fontLinks and check if the href value is already in the uniqueLinks array
        fontLinks.forEach(link => {
            const href = link.getAttribute('href');
            if (!uniqueLinks.includes(href)) {
                uniqueLinks.push(href);
            } else {
                link.remove(); // Remove duplicate link tag
            }
        });

        if (uniqueFontFamilies.length == 0) {
            loadDesign(file, jsonData)
            // document.querySelector('.canvas-space').classList.remove('loading-json-blur');
            // document.querySelector('#loading-wrapper').style.setProperty('display', 'none');

        }

        // If all font families are loaded, load the JSON data in the canvas
        uniqueFontFamilies.forEach((obj, index) => {
            var font = new FontFaceObserver(obj);
            font.load().then(function () {
                console.log('Font is available');
                if (index == (uniqueFontFamilies.length - 1)) {
                    setTimeout(() => {
                        loadDesign(file, jsonData)

                    }, 1000);
                }


            }, function () {
                console.log('Font is not available');
                font.load().then(function () {
                    console.log('Font is available');
                    setTimeout(() => {
                        loadDesign(file, jsonData)

                    }, 1000);
                }, function () {
                    console.log('Font is not available');
                });
            });
        })
    }

    const loadDesign = (file, filesource) => {
        setManualChecked(false)
        editor.canvas.isDrawingMode = false

        props.setDesigntemp(true)
        if (file.canvas_translation.length > 0) {
            console.log('Translated Redirecting to workspace')

        }
        else {
            getMyDesign()
            setName(file.file_name)
            setDesignid(filesource.canvas_design)
            setTranslatedImageid(null)
            setCanvaswidth(file.width)
            setCanvasheight(file.height)
            setPageNo(filesource.page_no)
            setPageData(file.source_json)
            // if (typeof (filesource.json) == 'string') {
            //     setCanvasBgColor(JSON.parse(filesource.json).background)
            // }
            // else {
            //     setCanvasBgColor(filesource.json.background)
            // }
            setCanvasBgColor(file.source_json[0].json.backgroundImage.fill)
            editor.canvas.loadFromJSON(filesource.json, function () {
                editor.canvas.renderAll.bind(editor.canvas.renderAll());
            })
            var size = { name: 'loadcanvas', width: file.source_json[0].json.backgroundImage.width, height: file.source_json[0].json.backgroundImage.height, fill: file.source_json[0].json.backgroundImage.fill }
            handlesize(size)
            // editor.canvas.backgroundImage = new fabric.Rect({ width: file.source_json[0].json.backgroundImage.width, height: file.source_json[0].json.backgroundImage.height,fill:file.source_json[0].json.backgroundImage.fill });

            navigate(`/ailaysa-canvas/design/?project=${filesource.canvas_design}`)
            document.querySelector('.canvas-space').classList.remove('loading-json-blur');
            document.querySelector('#loading-wrapper').style.setProperty('display', 'none');


        }


    }

    const deleteDesign = (id,filesource) => {
        (async function () {

            var requestOptions = {
                method: 'DELETE',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/canvas-designs/" + id + "/", requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                console.log('sucessfully deleted');
                // if(design.indexOf(filesource)>-1){
                //     setDesign(design.splice(design.indexOf(filesource), 1))
                // }
                // if (designFilteredData.indexOf(filesource)>-1) { // only splice array when item is found
                //     designFilteredData.splice(designFilteredData.indexOf(filesource), 1); // 2nd parameter means remove one item only
                //   }
                setDesign(prevState => prevState.filter(item =>  item !== filesource))
            }
            else {
                console.log('error');
            }
            if (id == designid) {
                editor.canvas.clear()
                var size = { name: 'new', width: canvaswidth, height: canvasheight, fill: 'rgba(255,255,255,1)' }
                navigate('/ailaysa-canvas')
                setDesignid(null)
                setDesigntemp(false)
                setAutoSaved(false)
                setName('Untitled Project')
                setCanvasmodified(false)
                setCanvasBgColor('rgba(255,255,255,1)')
                handlesize(size)
                setPageData([])
            }
        })();
    }

    const setupWorkspace = (filesource) => {
        // console.log(filesource)
        navigate(`/ailaysa-canvas-workspace/design/?project=${filesource.id}`)
        // loadFabricJSONData()
        getProjectData(filesource.id)
    }

    const setuPImagetranslationWorkspace = (file) => {
        // console.log(file)

        navigate(`/ailaysa-canvas-workspace/image-translate/?project=${file.id}`)
        getImageTranslateProjectData(file.id)
    }

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };


    // var half = Math.ceil(designFilteredData?.length / 2)
    // var firstHalf = designFilteredData.slice(0, half)
    // var secondHalf = designFilteredData.slice(half)

    const saveAsTemplate = (id, translatedId) => {
        console.log(translatedId)
        // setIsSaved(false)
        if (workspaceSwitch != 'Workspace') {
            (async function () {
                var formdata = new FormData();
                formdata.append("canvas_design_id", id);
                var requestOptions = {
                    method: 'POST',
                    headers: handleAuthTrue(new Headers()),
                    body: formdata,
                    redirect: 'follow'
                };

                let data = await fetch(LABS_API + "/canvas/mytemplate-design/", requestOptions)
                if (data.status === 200) {
                    let response = await data.json()
                    // console.log(response);

                }
                else {
                    // setIsSaved(true)
                    console.log('error');
                }
            })();

        } else {
            (async function () {
                var formdata = new FormData();
                formdata.append("canvas_design_id", id);
                if (langLabel != null || langLabel != undefined) {
                    formdata.append("canvas_trans_id", langLabel);

                }

                var requestOptions = {
                    method: 'POST',
                    headers: handleAuthTrue(new Headers()),
                    body: formdata,
                    redirect: 'follow'
                };

                let data = await fetch(LABS_API + "/canvas/mytemplate-design/", requestOptions)
                if (data.status === 200) {
                    let response = await data.json()
                    // console.log(response);

                }
                else {
                    // setIsSaved(true)
                    console.log('error');
                }
            })();
        }


    }


    const getMyTemplate = async(e) => {
        var requestOptions = {
            method: 'GET',
            headers: handleAuthTrue(new Headers()),
            redirect: 'follow'
        };

        let data = await fetch(LABS_API + `/canvas/mytemplate-design/?page=${e}`, requestOptions)
        if (data.status === 200) {
            let response = await data.json()
            // console.log(response);
            return {'data':response.results,'next':response.next}
            // setMyTemplate(response.results)
        }
        else {
            // setIsSaved(true)
            console.log('error');
        }

    }

    const fetchMyDesign = async(id) => {
            
            document.querySelector('.canvas-space').classList.add('loading-json-blur');
            document.querySelector('#loading-wrapper').style.setProperty('display', 'grid');
            var requestOptions = {
                method: 'GET',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + `/canvas/canvas-designs/${id}`, requestOptions)
            if (data.status === 200) {  
                let response = await data.json()
                // console.log(response);
                loadFabricJSONData(response,response.source_json[0])
            }
            else {
                // setIsSaved(true)
                console.log('error');
                // document.querySelector('.canvas-space').classList.remove('loading-json-blur');
                // document.querySelector('#loading-wrapper').style.setProperty('display', 'none');
            }

           
        // var datastorage = storedata()
        // await db.put('AIstore',datastorage , 'AilaysaCanvas');
}



const getdesigndata = async(e)=>{
    loadingdesign.current = true
    if(nextpage.current == true){
        var data = await getMyDesign(e?e:1)
        setDesign(prev=>[...prev,...data?.data])
    }
  
    loadingdesign.current = false
    if (data?.next != null) {
        nextpage.current = true
    } else {
        nextpage.current = false
    }
}

useEffect(()=>{
    if(toggleState == 2){
        page.current = 1
        getdesigndata()
    }
    else{
        setDesign([])
    }
    // if(toggleState == 3){
    //     page.current = 1
    //     setTranslatedimage([])
    //     // getMyTranslatedImageproject()
    // }
},[toggleState])



    const page = useRef(1)
    const observer = useRef(); // ref to store observer
    const lastElementRef = useCallback((element) => {
        //element is the react element being referenced
        // disconnect observer set on previous last element
        if (observer.current) observer.current.disconnect();
        // if there's no more data to be fetched, don't set new observer
        if (!nextpage.current) return;
        // set new observer
        observer.current = new IntersectionObserver(async (entries) => {
            // increase page number when element enters (is intersecting with) viewport.
            // This triggers the pagination hook to fetch more items in the new page
            if (entries[0].isIntersecting && nextpage.current) {
                page.current += 1
                getdesigndata(page.current)
                // getphotodata(page.current)
                // var data = await  getGlobalTemplate(page.current)
                // setglobalTemplate( prev => [...prev,...data.data])
                // if (data.next != null) {
                //     nextpage.current = true
                // } else { 
                //     nextpage.current = false
                // }
            }
            else {
                // no more templates
                console.log('nomore');
            }
        });

        // observe/monitor last element
        if (element) observer.current.observe(element);
    });





    return (
        <div className="container graphictab">
            <div className="bloc-tabs tab-heading">
                {/* <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"} onClick={() => { toggleTab(1) }}>
                    All
                </button> */}
                <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"} onClick={() => { toggleTab(2);  }}  >
                    Designs
                </button>
                <button className={toggleState === 3 ? "tabs active-tabs" : "tabs"} onClick={() => { toggleTab(3); }}>
                    Image translation
                </button>
                <button className={toggleState === 4 ? "tabs active-tabs" : "tabs"} onClick={() => { toggleTab(4);}}>
                    Templates
                </button>

            </div>
            <div className="main-tabs">
                <div className={toggleState === 2 ? "content main-tab-content active-content" : "main-tab-content content"}>
                    <>
                        {/* <div className="main-tabs-heading">
                            <span>My Design</span>
                            <CloseIcon className="close-icon" onClick={handleSidebarClose} />
                        </div> */}

                        <div className={focusOnSearch ? "main-tab-search active-search-bar" : "main-tab-search"}>
                            <SearchIcon className="seacrh-icon" onClick={inputDesignhandler} />
                            <span className="search-placeholder-text">
                                <input type="text" onKeyDown={inputDesignhandler} onClick={(e) => {
                                    e.stopPropagation()
                                    setFocusOnSearch(true)
                                }} onBlur={(e) => {
                                    setFocusOnSearch(false);
                                }} className="searcher" ref={designtypeSearch} onChange={inputDesignhandler} placeholder="Search..." />
                            </span>
                        </div>
                        {!loadingdesign.current && designFilteredData.length == 0 && <>No Result found</>}
                        <div className="grid-design-tab grid-photos">
                            <Masonry columns={2} spacing={0.8}>
                                {designFilteredData.length != 0 && 
                                    designFilteredData.map((filesource, keysoure) => {
                                        return (
                                            <Grow ref={keysoure === designFilteredData.length - 1 ? lastElementRef : undefined}
                                                in={true}
                                                style={{ transformOrigin: '0 0 0' }}
                                                {...(true ? { timeout: 1000 } : {})}
                                            >
                                                <div className="main-tab-grid">
                                                    <div key={JSON.stringify(filesource) + keysoure}>
                                                        <>
                                                            <div className="tool-container">
                                                                <Tooltip

                                                                    title="Delete" placement="top" arrow>
                                                                    <DeleteForeverIcon className="delete-icon" onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        deleteDesign(filesource.id, filesource)
                                                                    }} />

                                                                </Tooltip>
                                                                <Tooltip
                                                                    title="Add to my template" placement="top" arrow>
                                                                    <LibraryAddIcon className="delete-icon" onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        saveAsTemplate(filesource.id)
                                                                    }} />

                                                                </Tooltip>
                                                                <Tooltip
                                                                    title={<div dangerouslySetInnerHTML={{
                                                                        __html: `<span>
                                                                    <div clas="tool-info-heading">Design</div>
                                                                    <span class="writter-tooltip-design-head">
                                                                       ${filesource.file_name} - <span class="writter-tooltip-design-subhead">${filesource.width} x ${filesource.height}</span>
                                                                    </span>
                                                                    <br/>
                                                                    <span class="writter-tooltip-design-subhead" >
                                                                    (${filesource?.translate_available ? 'Translated' : 'Not yet Translated'})
                                                                    </span>
                                                                    </span>`}} />} placement="bottom-end" arrow>
                                                                    <InfoIcon className="delete-icon" onClick={() => {
                                                                    }} />

                                                                </Tooltip>
                                                            </div>
                                                            <div className={designid == filesource.id ? "design-thumb-image-details-active" : "design-thumb-image-details"} onClick={() => { filesource?.translate_available ? setupWorkspace(filesource) : fetchMyDesign(filesource.id) }}>
                                                                {/* <div className={designid == filesource.id ? "design-thumb-image-details-active" : "design-thumb-image-details"} onClick={(e) => {
                                                            console.log(designFilteredData.splice(1, 1));
                                                            }}> */}

                                                                <img className="main-bar-image" src={LABS_API + '/' + filesource.thumbnail_src}

                                                                />
                                                            </div>
                                                        </>
                                                    </div>
                                                </div>
                                            </Grow>
                                        )
                                    })
                                }
                                {loadingdesign.current && <>
                                    <Skeleton variant="rectangular" width={150} height={300} />
                                    <Skeleton variant="rectangular" width={150} height={100} />
                                    <Skeleton variant="rectangular" width={150} height={320} />
                                    <Skeleton variant="rectangular" width={150} height={200} />
                                    <Skeleton variant="rectangular" width={150} height={320} />
                                    <Skeleton variant="rectangular" width={150} height={120} />
                                    <Skeleton variant="rectangular" width={150} height={220} />
                                    <Skeleton variant="rectangular" width={150} height={170} />
                                    <Skeleton variant="rectangular" width={150} height={180} />
                                </>}
                            </Masonry>
{/*     
                            <div className="template-half">
                                
                                        
                                            {
                                                firstHalf.map((filesource, keysoure) => {
                                                    // console.log(firstHalf)
                                                    return (
                                                        <div className="main-tab-grid">
                                                        <div key={JSON.stringify(filesource) + keysoure}>
                                                                <>
                                                                    <div className="tool-container">
                                                                        <Tooltip

                                                                            title="Delete" placement="top" arrow>
                                                                            <DeleteForeverIcon className="delete-icon" onClick={() => {
                                                                                deleteDesign(filesource.id)
                                                                            }} />

                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title="Add to my template" placement="top" arrow>
                                                                            <LibraryAddIcon className="delete-icon" onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                saveAsTemplate(filesource.id)
                                                                            }} />

                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title={<div dangerouslySetInnerHTML={{
                                                                                __html: `<span>
                                                                    <div clas="tool-info-heading">Design</div>
                                                                    <span class="writter-tooltip-design-head">
                                                                       ${filesource.file_name} - <span class="writter-tooltip-design-subhead">${filesource.width} x ${filesource.height}</span>
                                                                    </span>
                                                                    <br/>
                                                                    <span class="writter-tooltip-design-subhead" >
                                                                    (${filesource?.translate_available ? 'Translated' : 'Not yet Translated'})
                                                                    </span>
                                                                    </span>`}} />} placement="bottom-end" arrow>
                                                                            <InfoIcon className="delete-icon" onClick={() => {
                                                                            }} />

                                                                        </Tooltip>
                                                                    </div>
                                                                    <div className={designid == filesource.id ? "design-thumb-image-details-active" : "design-thumb-image-details"} onClick={() => { filesource?.translate_available ? setupWorkspace(filesource) : fetchMyDesign( filesource.id) }}>

                                                                        <img className="main-bar-image" src={LABS_API + '/' + filesource.thumbnail_src}

                                                                        />
                                                                    </div>
                                                                </>
                                                        </div>
                                                           </div>
                                                    )
                                                })
                                            }
                            </div>
                            <div className="template-half">
                                
                                            {
                                                secondHalf.map((filesource, keysoure) => {

                                                    return (
                                                        <div className="main-tab-grid">
                                                        <div key={JSON.stringify(filesource) + keysoure}>
                                                                <>
                                                                    <div className="tool-container">
                                                                        <Tooltip

                                                                            title="Delete" placement="top" arrow>
                                                                            <DeleteForeverIcon className="delete-icon" onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                deleteDesign(filesource.id)
                                                                            }} />

                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title="Add to my template" placement="top" arrow>
                                                                            <LibraryAddIcon className="delete-icon" onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                saveAsTemplate(filesource.id)
                                                                            }} />

                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title={<div dangerouslySetInnerHTML={{
                                                                                __html: `<span>
                                                                    <div clas="tool-info-heading">Design</div>
                                                                    <span class="writter-tooltip-design-head">
                                                                       ${filesource.file_name} - <span class="writter-tooltip-design-subhead">${filesource.width} x ${filesource.height}</span>
                                                                    </span>
                                                                    <br/>
                                                                    <span class="writter-tooltip-design-subhead" >
                                                                    (${filesource?.translate_available ? 'Translated' : 'Not yet Translated'})
                                                                    </span>
                                                                    </span>`}} />} placement="bottom-end" arrow>
                                                                            <InfoIcon className="delete-icon" onClick={() => {
                                                                            }} />

                                                                        </Tooltip>
                                                                    </div>
                                                                    <div className={designid == filesource.id ? "design-thumb-image-details-active" : "design-thumb-image-details"} onClick={() => { filesource?.translate_available ? setupWorkspace(filesource) : fetchMyDesign( filesource.id) }}>

                                                                        <img className="main-bar-image" src={LABS_API + '/' + filesource.thumbnail_src}

                                                                        />
                                                                    </div>
                                                                </>
                                                        </div>
                                                    </div>

                                                    )
                                                })
                                            }
                                    
                            </div> */}
                        </div>
                    </>
                </div>
                <div className={toggleState === 3 ? "content main-tab-content active-content" : "main-tab-content content"}>
                    <>
                        <ImagetranslationProject
                            setTranslatedimage={setTranslatedimage}
                            translateImage={translateImage}
                            translatedImageid={translatedImageid}
                            setTranslatedImageid={setTranslatedImageid}
                            translateimagetemp={translateimagetemp}
                            setTranslateimagetemp={setTranslateimagetemp}
                            LABS_API={LABS_API}
                            toggleState={toggleState}
                            handleAuthTrue={handleAuthTrue}
                            setName={setName}
                            getMyTranslatedImageproject={getMyTranslatedImageproject}
                            editor={editor}
                            handleSidebarClose={handleSidebarClose}
                            deleteTranslatedimage={deleteTranslatedimage}
                            loadTranslatedImage={loadTranslatedImage}
                            setuPImagetranslationWorkspace={setuPImagetranslationWorkspace}
                        />

                    </>
                </div>
                {/* <div className={toggleState === 1 ? "content main-tab-content active-content" : "main-tab-content content"}>
                    <>
                        <Allprojects
                            translateImage={translateImage}
                            translatedImageid={translatedImageid}
                            setTranslatedImageid={setTranslatedImageid}
                            translateimagetemp={translateimagetemp}
                            setTranslateimagetemp={setTranslateimagetemp}
                            setName={setName}
                            getMyTranslatedImageproject={getMyTranslatedImageproject}
                            editor={editor}
                            handleSidebarClose={handleSidebarClose}
                            designid={designid}
                            setDesignid={setDesignid}
                            getMyDesign={getMyDesign}
                            design={design}
                            LABS_API={LABS_API}
                            setupWorkspace={setupWorkspace}
                            deleteDesign={deleteDesign}
                            loadDesign={loadDesign}
                            loadFabricJSONData={loadFabricJSONData}
                            deleteTranslatedimage={deleteTranslatedimage}
                            loadTranslatedImage={loadTranslatedImage}
                            setuPImagetranslationWorkspace={setuPImagetranslationWorkspace}
                            handlesize={handlesize}
                            handleAuthTrue={handleAuthTrue}
                            saveAsTemplate={saveAsTemplate}
                            fetchMyDesign={fetchMyDesign}

                        />
                    </>
                </div> */}
                <div className={toggleState === 4 ? "content main-tab-content active-content" : "main-tab-content content"}>
                    <>
                        <MyTemplate
                            translateImage={translateImage}
                            translatedImageid={translatedImageid}
                            setTranslatedImageid={setTranslatedImageid}
                            translateimagetemp={translateimagetemp}
                            setTranslateimagetemp={setTranslateimagetemp}
                            setName={setName}
                            getMyTranslatedImageproject={getMyTranslatedImageproject}
                            editor={editor}
                            handleSidebarClose={handleSidebarClose}
                            designid={designid}
                            setDesignid={setDesignid}
                            getMyDesign={getMyDesign}
                            design={design}
                            LABS_API={LABS_API}
                            setupWorkspace={setupWorkspace}
                            deleteDesign={deleteDesign}
                            loadDesign={loadDesign}
                            loadFabricJSONDataTemplate={loadFabricJSONDataTemplate}
                            deleteTranslatedimage={deleteTranslatedimage}
                            loadTranslatedImage={loadTranslatedImage}
                            setuPImagetranslationWorkspace={setuPImagetranslationWorkspace}
                            handlesize={handlesize}
                            handleAuthTrue={handleAuthTrue}
                            myTemplate={myTemplate}
                            setMyTemplate={setMyTemplate}
                            canvasheight={canvasheight}
                            canvaswidth={canvaswidth}
                            fabric={fabric}
                            setCanvasBgColor={setCanvasBgColor}
                            setManualChecked={setManualChecked}
                            navigate={navigate}
                            setAutoSaved={setAutoSaved}
                            setDesigntemp={setDesigntemp}
                            setCanvasmodified={setCanvasmodified}
                            getMyTemplate={getMyTemplate}
                            setShowTemplateNotice={setShowTemplateNotice}
                            setTemplateData={setTemplateData}
                            setTemplateTotalData={setTemplateTotalData}
                            settemplateFrom={settemplateFrom}
                            toggleState={toggleState}
                            handleSave={handleSave}
                        />
                    </>
                </div>

            </div>
        </div>
    )


}

export default MyDesign;