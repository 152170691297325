import BlurOnIcon from '@mui/icons-material/BlurOn';
import { useState, useRef, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import Slider from '@mui/material/Slider';
import { DragIndicator } from '@mui/icons-material';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import useEyeDropper from 'use-eye-dropper'
import ColorizeIcon from '@mui/icons-material/Colorize';
import Tooltip from '@mui/material/Tooltip';


const Shadow = (props) => {

    const {
        editor,
        fabric
    } = props


    //draggable
    const [state, setState] = useState({
        activeDrags: 0,
        deltaPosition: {
            x: 0, y: 0
        },
        controlledPosition: {
            x: -400, y: 200
        }
    });

    const onStart = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags + 1 });
    };
    const onStop = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags - 1 });
    };

    const dragHandlers = { onStart, onStop };

    const [shadowBlur, setShadowBlur] = useState(0)

    const handleShadowBlur = (e) => {
        setShadowBlur(e.target.value);

    }


    useEffect(() => {

        if (editor) {
            if (editor.canvas.getActiveObject().shadow) {
                setShadowBlur(editor.canvas.getActiveObject().shadow.blur)
                editor.canvas.renderAll()
            }
        }
    }, [editor])



    const [offsetY, setOffsetY] = useState(0)

    const handleOffY = (e) => {
        setOffsetY(e.target.value);

    }

    const offYTemp = useRef(true)

    useEffect(() => {

        if (editor) {
            if (editor.canvas.getActiveObject().shadow) {
                setOffsetY(editor.canvas.getActiveObject().shadow.offsetY * 10)
                editor.canvas.renderAll()

            }
        }
    }, [editor])

    const [offsetX, setOffsetX] = useState(0)

    const handleOffX = (e) => {
        setOffsetX(e.target.value);

    }


    useEffect(() => {

        if (editor) {
            if (editor.canvas.getActiveObject().shadow) {
                setOffsetX(editor.canvas.getActiveObject().shadow.offsetX * 10)
                editor.canvas.renderAll()

            }
        }
    }, [editor])

    const [shadowColor, setShadowColor] = useState('#000')




    useEffect(() => {

        if (editor) {
            if (editor.canvas.getActiveObject().shadow) {
                setShadowColor(editor.canvas.getActiveObject().shadow.color)
                editor.canvas.renderAll()

            }
        }
    }, [editor])

    const Eye = () => {
        const { open, close } = useEyeDropper()
        const [eyecolor, setEyecolor] = useState(shadowColor)
        const [eyeerror, setEyeerror] = useState()
        // useEyeDropper will reject/cleanup the open() promise on unmount,
        // so setState never fires when the component is unmounted.
        const pickColor = () => {
            open()
                .then(eyecolor => { setEyecolor(eyecolor.sRGBHex); setShadowColor(eyecolor.sRGBHex) })
                .catch(e => {
                    // Ensures component is still mounted
                    // before calling setState
                    if (!e.canceled) setEyeerror(e)
                })
        }

        return (
            <>
                <button onClick={pickColor} className="eyecplacer" style={{ border: 'none', }}><ColorizeIcon /></button>
                {!!eyeerror && <span>{eyeerror.message}</span>}
            </>
        )
    }



    useEffect(() => {

        if (offYTemp.current) {
            offYTemp.current = false;
            return;
        }


        editor?.canvas.getActiveObjects().forEach((i) => {
            if (i.type == 'textbox') {
                i.set('shadow', new fabric.Shadow({
                    blur: shadowBlur,
                    color: shadowColor,
                    offsetX: offsetX / 10,
                    offsetY: offsetY / 10,

                }
                ))
            }
        })
        editor.canvas.renderAll();

    }, [offsetY, shadowBlur, offsetX, shadowColor])


    const [anchorEl, setAnchorEl] = useState(null);

    const handleCloseAndOpen = (event) => {
        if (anchorEl == null) {
            setAnchorEl(event.currentTarget);
        }
        else {
            setAnchorEl(null);
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (<>
        <div className='borderstyle-container'>
            <Tooltip
                componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: '#2A2A2A',
                            '& .MuiTooltip-arrow': {
                                color: '#2A2A2A',
                            },
                        },
                    },
                }}
                title="Shadow" placement="top" arrow>
                <span onClick={handleCloseAndOpen}>
                    <BlurOnIcon />
                </span>
            </Tooltip>
            <Draggable handle="strong" {...dragHandlers}>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}

                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="text-effects-wrapper box ">
                        <div className='drag-box-container'>
                            <strong className="drag-me ">
                                <DragIndicator />
                            </strong>
                            <CloseIcon className="close-icon" onClick={handleClose} />

                        </div>


                        <div className="text-effects-head-adjust">
                            <span>Shadow</span>
                        </div>

                        <div className="text-effects-adjust">
                            <div className="text-effect-range-container">
                                <label className="form-label text-effect-input-value">Blur</label>
                                <Slider size="small" aria-label="Small" value={shadowBlur} min={0} max={100} onChange={handleShadowBlur} valueLabelDisplay="auto" />
                            </div>
                            <div className="text-effect-range-container">
                                <label className="form-label text-effect-input-value">Offset-X</label>
                                <Slider size="small" aria-label="Small" value={offsetX} min={-100} max={100} onChange={handleOffX} valueLabelDisplay="auto" />
                            </div>
                            <div className="text-effect-range-container">
                                <label className="form-label text-effect-input-value">Offset-Y</label>
                                <Slider size="small" aria-label="Small" value={offsetY} min={-100} max={100} onChange={handleOffY} valueLabelDisplay="auto" />

                            </div>
                            <div className="text-effect-range-container-color">
                                <span className="text-effect-input-value-color">Color</span>
                                <SketchPicker color={shadowColor} onChange={(e) => {
                                    setShadowColor("rgba(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")");
                                }} />
                                <Eye />
                            </div>
                        </div>
                    </div>
                </Popover>
            </Draggable>
        </div>
    </>)
}


export default Shadow;