import Cookies from "js-cookie";
import { toast,Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";


const userState = JSON.parse(
  typeof Cookies.get(process.env.REACT_APP_USER_COOKIE_KEY_NAME) != "undefined" ? Cookies.get(process.env.REACT_APP_USER_COOKIE_KEY_NAME) : null
);

const userStateSub = JSON.parse(
  typeof Cookies.get(process.env.REACT_APP_USER_COOKIE_KEY_NAME_SUB) != "undefined" ? Cookies.get(process.env.REACT_APP_USER_COOKIE_KEY_NAME_SUB) : null
);
const Config = {
  userState: userState,
  userStateSub:userStateSub,
  HOST_URL: "/",
  LABS_API: process.env.REACT_APP_LABS_API,
  TRANSEDITOR_DOMAIN_URL: process.env.REACT_APP_TRANSEDITOR_URL, //TransEditor domain url
  PIXABAY_API: process.env.REACT_APP_PIXABAY_API,
  COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  toast: function (alertText = "", type = "success") {
    //Toast alert
    toast.configure();
    let AlertContent = () => (
      <div className="toast-align">
        <span className="toast-img">
          <img src={Config.HOST_URL + "assets/images/new-ui-icons/check_circle_success.svg"} />
        </span>
        <div className="classWithFontStyleOrWhatever">{alertText}</div>
      </div>
    );
    switch (type) {
      case "warning": {
        // To show warnings
        AlertContent = () => (
          <div className="toast-align">
            <span className="toast-img">
              <img src={Config.HOST_URL + "assets/images/new-ui-icons/error_black_warn.svg"} />
            </span>
            <div className="classWithFontStyleOrWhatever">{alertText}</div>
          </div>
        );
        return toast.warn(<AlertContent />, {
          transition: Slide,
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      case "info": {
        //To show information
        AlertContent = () => (
          <div className="toast-align">
            <span className="toast-img">
              <img src={Config.HOST_URL + "assets/images/new-ui-icons/error_black_warn.svg"} />
            </span>
            <div className="classWithFontStyleOrWhatever">{alertText}</div>
          </div>
        );
        return toast.info(<AlertContent />, {
          transition: Slide,
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      case "error": {
        //To show error messages
        AlertContent = () => (
          <div className="toast-align">
            <span className="toast-img">
              <img src={Config.HOST_URL + "assets/images/new-ui-icons/remove_circle_red.svg"} />
            </span>
            <div className="classWithFontStyleOrWhatever">{alertText}</div>
          </div>
        );
        return toast.error(<AlertContent />, {
          transition: Slide,
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          limit: 1,
        });
      }
      default: {
        // To show success alert
        return toast.success(<AlertContent />, {
          transition: Slide,
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  },
  // axios: (params) => {
  //   //Common axios handling
  //   let userCacheData = JSON.parse(
  //     typeof Cookies.get(process.env.REACT_APP_USER_COOKIE_KEY_NAME) != "undefined" ? Cookies.get(process.env.REACT_APP_USER_COOKIE_KEY_NAME) : null
  //   );
  //   // refresh the page if the access token does not match
  //   if (userState?.token !== userCacheData?.token) {
  //     window.location.reload()
  //   }
  //   let token = userCacheData != null ? userCacheData?.token : "";
  //   let headers = {
  //     "Access-Control-Allow-Origin": "*",
  //     Accept: "application/json",
  //   };
  //   if (params.headers != null) headers = params.headers; //Setting headers
  //   if (params.auth != null) {
  //     //If auth needs
  //     if (params.auth === true) headers["Authorization"] = `Bearer ${token}`;
  //   }
  //   let axiosObj = {
  //     method: params.method,
  //     url: params.url,
  //     headers: headers,
  //   };
  //   if (params.method == null)
  //     //Define method
  //     params["method"] = "GET";
  //   if (params?.data != null || params?.formData != null)
  //     //If data, set the data
  //     axiosObj["data"] = params?.data != null ? params.data : params?.formData != null && params.formData;
  //   if (params?.timeout != null)
  //     // If timeout need
  //     params["timeout"] = params?.timeout;
  //   axios(axiosObj)
  //     .then((response) => {
  //       if (params?.success != null) params.success(response); //Callback to the param's success function
  //     })
  //     .catch((error) => {
  //       /*Create Refresh Token if access_token expires - start*/
  //       if (error.response?.data?.messages !== undefined && error.response?.data?.messages.length !== 0 && error.response?.data?.messages[0]?.token_class === "AccessToken") {
  //         let refreshToken = userState != null ? userState.refresh_token : "";
  //         let formData = new FormData();
  //         formData.append("refresh", refreshToken);
  //         Config.axios({
  //           url: Config.BASE_URL + "/auth/dj-rest-auth/token/refresh/",
  //           method: "POST",
  //           data: formData,
  //           auth: true,
  //           success: (response) => {
  //             // console.log(response.data)
  //             let userData = Config.userState;
  //             userData.token = response.data.access;
  //             Cookies.remove(process.env.REACT_APP_USER_COOKIE_KEY_NAME, { domain: Config.COOKIE_DOMAIN });
  //             Cookies.set(process.env.REACT_APP_USER_COOKIE_KEY_NAME, JSON.stringify(userData), { domain: Config.COOKIE_DOMAIN });
  //             setTimeout(() => {
  //               window.location.reload(false);
  //             }, 500);
  //             Config.axios(params);
  //           },
  //           error: (error) => {
  //             // console.log(error.response.status);
  //             if (Config.userState !== null) Cookies.remove(process.env.REACT_APP_USER_COOKIE_KEY_NAME, { domain: Config.COOKIE_DOMAIN });
  //             window.location.href = process.env.REACT_APP_LOGIN_REDIRECT_URL;
  //           },
  //         });
  //       } else if (error.response?.data?.msg === "Unauthorised" || error.response?.data?.code === "bad_authorization_header") {
  //         //Logout if unauthorized response came
  //         Config.logout();
  //       } else if (params.error != null) params.error(error);
  //       else {
  //         Config.log(params.url);
  //         Config.log(error);
  //         Config.toast(Config.DEFAULT_ERROR_MESSAGE, "error");
  //       }
  //     })
  //     .finally(() => {
  //       if (params.finally != null) params.finally();
  //     });
  // },
  PIXABAY_API2: `https://pixabay.com/api/?key=${process.env.REACT_APP_PIXABAY_API}&image_type=photo&per_page=10&safesearch=true`,
  PIXABAY_API3: `https://pixabay.com/api/?key=${process.env.REACT_APP_PIXABAY_API}&image_type=vector&per_page=15&safesearch=true`,
  UNSPLASH_API: `https://api.unsplash.com/search/photos/?client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`,
  removeItemFromArray: (arr, item) => {
    //To remove an item the :arr by value
    return arr.filter((f) => f !== item);
  },
};
export default Config;