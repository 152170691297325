import React, { useState, useRef ,useEffect,useCallback} from "react";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Masonry from '@mui/lab/Masonry';
import Grow from '@mui/material/Grow';
import Skeleton from '@mui/material/Skeleton';


const Imagetranslation = (props) => {


    const {
        LABS_API,
        translateImage,
        translatedImageid,
        handleSidebarClose,
        deleteTranslatedimage,
        loadTranslatedImage,
        setuPImagetranslationWorkspace,
        toggleState,
        setTranslatedimage,
        getMyTranslatedImageproject
    } = props
    const URL_SEARCH_PARAMS = new URLSearchParams(window.location.search);
    const [translatedimageinputText, setTranslatedimageinputText] = useState("");
    const translatedimagetypeSearch = useRef()
    const nextpage = useRef()
    const loadingimagetranslate = useRef(false)


    let inputTranslatedimageHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        setTranslatedimageinputText(lowerCase);
    };


    const translatedFilteredData = translateImage.filter((el) => {
        //if no input the return the original
        if (translatedimageinputText === '') {
            return el;
        }
        //return the item which contains the user input
        else {
            return el.project_name?.toLowerCase().includes(translatedimageinputText)
        }
    })


    const [focusOnSearch, setFocusOnSearch] = useState(false)

    // var half = Math.ceil(translatedFilteredData?.length / 2)
    // var firstHalf = translatedFilteredData.slice(0, half)
    // var secondHalf = translatedFilteredData.slice(half)

    const getTranslatedImageproject = async(e)=>{
        loadingimagetranslate.current = true
        var data = await getMyTranslatedImageproject(e?e:1)
        setTranslatedimage(prev=>[...prev,...data.data])
        loadingimagetranslate.current = false
        if (data.next != null) {
            nextpage.current = true
        } else {
            nextpage.current = false
        }
    }

    useEffect(()=>{
        if(toggleState == 3){
            page.current = 1
            setTranslatedimage([])
            getTranslatedImageproject()
        }
        else{
            setTranslatedimage([])

        }
    },[toggleState])



    const page = useRef(1)
    const observer = useRef(); // ref to store observer
    const lastElementRef = useCallback((element) => {
        //element is the react element being referenced
        // disconnect observer set on previous last element
        if (observer.current) observer.current.disconnect();
        // if there's no more data to be fetched, don't set new observer
        if (!nextpage.current) return;
        // set new observer
        observer.current = new IntersectionObserver(async (entries) => {
            // increase page number when element enters (is intersecting with) viewport.
            // This triggers the pagination hook to fetch more items in the new page
            if (entries[0].isIntersecting && nextpage.current) {
                page.current += 1
                getTranslatedImageproject(page.current)
                // getphotodata(page.current)
                // var data = await  getGlobalTemplate(page.current)
                // setglobalTemplate( prev => [...prev,...data.data])
                // if (data.next != null) {
                //     nextpage.current = true
                // } else { 
                //     nextpage.current = false
                // }
            }
            else {
                // no more templates
                console.log('nomore');
            }
        });

        // observe/monitor last element
        if (element) observer.current.observe(element);
    });




    return (
        <>
            {/* <div className="main-tabs-heading">
                <span>My translated image</span>
                <CloseIcon className="close-icon" onClick={handleSidebarClose} />
            </div> */}
            <div className={focusOnSearch ? "main-tab-search active-search-bar" : "main-tab-search"}>
                <SearchIcon className="seacrh-icon" onClick={inputTranslatedimageHandler} />
                <span className="search-placeholder-text">
                    <input type="text" onKeyDown={inputTranslatedimageHandler} onClick={() => {
                        setFocusOnSearch(true)
                    }} onBlur={(e) => {
                        setFocusOnSearch(false);
                    }} className="searcher" ref={translatedimagetypeSearch} onChange={inputTranslatedimageHandler} placeholder="Search..." />
                </span>
            </div>
            {!loadingimagetranslate.current && translatedFilteredData.length == 0 && <>No Result found</>}
            <div className="grid-design-tab grid-photos">

           <Masonry columns={2} spacing={0.8}>
                    {  translatedFilteredData.length != 0 &&  translatedFilteredData.map((file, key) => {
                        return (
                            <Grow ref={key === translatedFilteredData.length - 1 ? lastElementRef : undefined}
                                in={true}
                                style={{ transformOrigin: '0 0 0' }}
                                {...(true ? { timeout: 1000 } : {})}
                            >
                                <div className="main-tab-grid"
                                    key={key}>
                                    <div>
                                        <div className="tool-container">
                                            <Tooltip

                                                title="Delete" placement="top" arrow>
                                                <DeleteForeverIcon className="delete-icon" onClick={(e) => {
                                                    e.stopPropagation()
                                                    deleteTranslatedimage(file.id,file)
                                                }} />
                                            </Tooltip>
                                            <Tooltip

                                                title={<div dangerouslySetInnerHTML={{
                                                    __html: `<span>
                                            <div clas="tool-info-heading">Image translate</div>
                                                                    <span class="writter-tooltip-design-head">
                                                                       ${file.file_name} - <span class="writter-tooltip-design-subhead">${file.width} x ${file.height}</span>
                                                                    </span>
                                                                    <br/>
                                                                    <span class="writter-tooltip-design-subhead" >
                                                                    (${file?.canvas_translation?.length !== 0 ? 'Translated' : 'Not yet Translated'})
                                                                    </span>
                                                                    </span>`}} />} placement="bottom-end" arrow>
                                                <InfoIcon className="delete-icon" onClick={() => {
                                                }} />

                                            </Tooltip>
                                        </div>
                                        <div className={translatedImageid == file.id ? "design-thumb-image-details-active" : "design-thumb-image-details"} onClick={() => { file?.image_inpaint_creation?.length !== 0 ? setuPImagetranslationWorkspace(file) : loadTranslatedImage(file) }}>

                                            <img className="main-bar-image" src={LABS_API + '/' + file.image}

                                            />

                                        </div>
                                    </div>

                                </div>
                            </Grow>
                        )

                    })}
                     {loadingimagetranslate.current && <>
                                    <Skeleton variant="rectangular" width={150} height={300} />
                                    <Skeleton variant="rectangular" width={150} height={100} />
                                    <Skeleton variant="rectangular" width={150} height={320} />
                                    <Skeleton variant="rectangular" width={150} height={200} />
                                    <Skeleton variant="rectangular" width={150} height={320} />
                                    <Skeleton variant="rectangular" width={150} height={120} />
                                    <Skeleton variant="rectangular" width={150} height={220} />
                                    <Skeleton variant="rectangular" width={150} height={170} />
                                    <Skeleton variant="rectangular" width={150} height={180} />
                                </>}
            </Masonry>
                {/* <div className="template-half">
                {firstHalf.map((file, key) => {
                    return (
                        <div className="main-tab-grid"
                            key={key}>
                            <div>
                                <div className="tool-container">
                                    <Tooltip

                                        title="Delete" placement="top" arrow>
                                        <DeleteForeverIcon className="delete-icon" onClick={(e) => {
                                             e.stopPropagation()
                                            deleteTranslatedimage(file.id)
                                        }} />
                                    </Tooltip>
                                    <Tooltip

                                        title={<div dangerouslySetInnerHTML={{
                                            __html: `<span>
                                            <div clas="tool-info-heading">Image translate</div>
                                                                    <span class="writter-tooltip-design-head">
                                                                       ${file.file_name} - <span class="writter-tooltip-design-subhead">${file.width} x ${file.height}</span>
                                                                    </span>
                                                                    <br/>
                                                                    <span class="writter-tooltip-design-subhead" >
                                                                    (${file?.canvas_translation?.length !== 0 ? 'Translated' : 'Not yet Translated'})
                                                                    </span>
                                                                    </span>`}} />} placement="bottom-end" arrow>
                                        <InfoIcon className="delete-icon" onClick={() => {
                                        }} />

                                    </Tooltip>
                                </div>
                                <div className={translatedImageid == file.id ? "design-thumb-image-details-active" : "design-thumb-image-details"} onClick={() => {file?.image_inpaint_creation?.length !== 0 ? setuPImagetranslationWorkspace(file) : loadTranslatedImage(file) }}>

                                    <img className="main-bar-image" src={LABS_API + '/' + file.image}

                                    />
                                   
                                </div>
                            </div>

                        </div>
                    )

                })}
                </div> */}
                {/* <div className="template-half">
                {secondHalf.map((file, key) => {
                                    return (
                                        <div className="main-tab-grid"
                                            key={key}>
                                            <div>
                                                <div className="tool-container">

                                                  
                                                    <Tooltip

                                                        title="Delete" placement="top" arrow>
                                                        <DeleteForeverIcon className="delete-icon" onClick={(e) => {
                                                             e.stopPropagation()
                                                            deleteTranslatedimage(file.id)
                                                        }} />
                                                    </Tooltip>
                                                    <Tooltip

                                                        title={<div dangerouslySetInnerHTML={{
                                                            __html: `<span>
                                                            <div clas="tool-info-heading">Image translate</div>
                                                                                    <span class="writter-tooltip-design-head">
                                                                                    ${file.file_name} - <span class="writter-tooltip-design-subhead">${file.width} x ${file.height}</span>
                                                                                    </span>
                                                                                    <br/>
                                                                                    <span class="writter-tooltip-design-subhead" >
                                                                                    (${file?.canvas_translation?.length !== 0 ? 'Translated' : 'Not yet Translated'})
                                                                                    </span>
                                                                                    </span>`}} />} placement="bottom-end" arrow>
                                                        <InfoIcon className="delete-icon" onClick={() => {
                                                        }} />

                                                    </Tooltip>
                                                </div>
                                                <div className={translatedImageid == file.id ? "design-thumb-image-details-active" : "design-thumb-image-details"} onClick={() => {file?.image_inpaint_creation?.length !== 0 ? setuPImagetranslationWorkspace(file) : loadTranslatedImage(file) }}>

                                                    <img className="main-bar-image" src={LABS_API + '/' + file.image}

                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    )

                                })}
                </div> */}
                
            </div>
        </>
    )
}

export default Imagetranslation;