import React, {  useState, useRef, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import {sizeData} from './SideBarData'


const Resize = (props) => {

    const{
        canvasheight,
        canvaswidth,
        setCanvasheight,
        setCanvaswidth,
        handlesize,
        canvasBgColor,
        LABS_API,
        handleAuthTrue
    } =props

    const[type, setType] = useState()
    const typeSearch = useRef()
    const [focusOnSearch, setFocusOnSearch] = useState(false)
    const [size, setSize] = useState([])
    const [inputText, setInputText] = useState("");
    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        setInputText(lowerCase);
    };


        const filteredData = size.filter((el) => {
            //if no input the return the original
            if (inputText === '') {
                return el;
            }
            //return the item which contains the user input
            else {
                return el.social_media_name.toLowerCase().includes(inputText)
            }
        })

    const handleSetCanvasHeight = (e) => {
        var size = {name:'custom',width:canvaswidth,height:e.target.value,resize:true,fill:canvasBgColor}
        handlesize(size)
    }

    const handleSetCanvasWidth = (e) => {
        var size = {name:'custom',width:e.target.value,height:canvasheight,resize:true,fill:canvasBgColor}
        handlesize(size)
    }

    const setCanvasSize = (size) => {
        setType(size.social_media_name)
        handlesize(size)
        setCanvaswidth(size.width)
        setCanvasheight(size.height)

    }

    var parent = document.querySelector(".canvas-editor-space"); 

    const setFullView =() =>{
 
        setCanvasheight(parent?.offsetHeight)
        setCanvaswidth(parent?.offsetWidth)
    }


    const getSize = async() => {
       

            var requestOptions = {
                method: 'GET',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/social-media-size", requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                // let lang = []
                // response.forEach((each) => {
                //     lang.push({
                //         label: each.language,
                //         value: each.id
                       
                //     })
                // })
                
                // console.log(lang)
                setSize(response)
            }
            else {

                console.log('error');
            }
    }

    useEffect(() =>{
        getSize()
    },[])


    return (
        <div className="sidebar-content">
            <div className="sidebar-tabs-heading">
                <span>Resize</span>
                {/* <CloseIcon className="close-icon" onClick={handleSidebarClose} /> */}
            </div>
            <div className={focusOnSearch ? "main-tab-search active-search-bar" : "main-tab-search"}>
                <SearchIcon className="seacrh-icon" onClick={inputHandler} />
                <span className="search-placeholder-text">
                    <input type="text" onKeyDown={inputHandler} onClick={() => {
                        setFocusOnSearch(true)
                    }} onBlur={(e) => {
                        setFocusOnSearch(false);
                    }} className="searcher" ref={typeSearch} onChange={inputHandler} placeholder="Search..." />
                </span>
            </div>
            <div className="grid-resize">
                <h5>Custom Size</h5>
                <div className="resize-custom-input-box">
                    <span className="custom-size-category">Width</span>
                    <Input 
                    type="number" 
                    defaultValue={canvaswidth} 
                    value={canvaswidth} 
                    onBlur={(e) => handleSetCanvasWidth(e)}
                    onInput={(e)=>{e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4);setCanvaswidth(e.target.value)}}  />
                    <span className="custom-size-category">Height</span>
                    <Input 
                    type="number" 
                    defaultValue={canvasheight} 
                    value={canvasheight} 
                    onBlur={(e) => handleSetCanvasHeight(e)}
                    onInput={(e)=>{e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0,4);setCanvasheight(e.target.value)}}  />
                </div>
                <h5>Other Size</h5>
                <div className="size-container">
                    {/* <div className="size-type" onClick={setFullView} >Full view</div> */}
                    {filteredData.length === 0 && <span className="no-result-found">No Result found</span>}
                    {filteredData.map((size) => {
                        return(
                            <div className={type === size.social_media_name? "size-type-active " :"size-type "} onClick={() => setCanvasSize(size)} ><span>{size.social_media_name} </span><span className="size-on-hover">{size.width}x{size.height}</span></div>
                        )
                    })}
                </div>
            </div>
        </div>

    )


}

export default Resize;