import React, { useEffect} from "react";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Checkblue from '../../../assets/icons/checkblue.svg';
import Noeditorfound from '../../../assets/icons/noeditorfound.svg';

function SourceLanguage(props) {

    const {
        filteredResults,
        setFilteredResults,
        searchInput,
        setSearchInput,
        onFocusWrap,
        setOnFocusWrap,
        searchAreaRef,
        sourceLanguageOptions
    } = props;



    useEffect(() => {
        if (searchInput !== '') {
            let re = new RegExp('^'+searchInput, 'i')
            let filteredData = sourceLanguageOptions?.filter((item) => {
              return(
                re.test(item.language)
              )
            })
            setFilteredResults(filteredData)
        }
        else{
            setFilteredResults(sourceLanguageOptions)
        }
    }, [searchInput])

    const handleOnclick = (e) => {
        searchAreaRef.current.focus();
        setOnFocusWrap(true)
      }

    return (
        <React.Fragment>
            <div className="ai-source-language-cont">
                <div className={"lang-modal-search-area " + (onFocusWrap ? "focused" : null)}>
                    <input
                        value={searchInput}
                        ref={searchAreaRef}
                        onChange={(e) => setSearchInput(e.target.value)}
                        onClick={(e) => handleOnclick(e)}
                        type="text"
                        placeholder="Search source language"
                        className="search-input"
                    />
                    <div className={onFocusWrap ? "icon-wrap" : "search-icon"}>
                        {
                            onFocusWrap ?
                            <CloseOutlinedIcon onClick={(e) => {setSearchInput(""); setOnFocusWrap(false)}} className="icon"/>
                            :
                            <SearchOutlinedIcon className="icon"/>
                        }
                    </div>
                </div>
                <div className="ai-src-language-part">
                    <ul className="ai-source-langs-list">
                        { searchInput?.length >= 1 ?
                            (filteredResults?.length == 0 ?
                                (
                                    <div className="search-not-found-wrapper">
                                        <img src={Noeditorfound} alt="no-results"/>
                                        <h1>No results</h1>
                                    </div>
                                )
                                :
                                (filteredResults?.map((value) => (
                                    <li
                                        key={value.id}
                                        onClick={(e) => props.handleSourceLangClick(value.id, value.language, value.languageKey, e)}
                                        className={value.id == props.sourceLanguage ? "list selected" : "list"}
                                    >
                                        <img className="checked-icon" src={Checkblue} alt="check_blue" />{" "}
                                        {value.language}
                                    </li>
                                )))
                            )
                            :
                            (props.sourceLanguageOptions != null &&
                                props.sourceLanguageOptions.map((value) => (
                                    <li
                                        key={value.id}
                                        onClick={(e) => {props.handleSourceLangClick(value.id, value.language, value.languageKey, e)}}
                                        className={value.id == props.sourceLanguage ? "list selected" : "list"}
                                    >
                                        <img className="checked-icon" src={Checkblue} alt="check_blue" />{" "}
                                        {value.language}
                                    </li>
                                ))
                            )
                        }
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SourceLanguage;
