import AilaysaCanvasEditor from './AilaysaCanvasEditor'
import React, { useEffect, useState, useRef } from "react";


const AilaysaCanvas = () => {
   

    const [tabSwitch, setTabSwitch] = useState('Ailaysacanvas')
    const [tab, setTab] = useState()
    const [jsdata, setJsdata] = useState(null)

    const [translatedImageid, setTranslatedImageid] = useState(null)
  

    return (
        <React.Fragment>
                {tabSwitch === "Ailaysacanvas" && <AilaysaCanvasEditor translatedImageid={translatedImageid} setTranslatedImageid={setTranslatedImageid}   setTabSwitch={setTabSwitch} setTab={setTab} setJsdata={setJsdata} /> }
                {/* {tabSwitch === "AilaysaLanguageSelectPage" && <AilaysaCanvasLanguageSelection setTranslatedImageid={setTranslatedImageid} translatedImageid={translatedImageid} setTabSwitch={setTabSwitch} jsdata={jsdata} tab={tab}  /> } */}
        </React.Fragment>
    )
}
export default AilaysaCanvas;