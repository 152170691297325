import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as Eraser } from '../../assets/icons/eraser.svg'
import BrushIcon from '@mui/icons-material/Brush';
import { ReactComponent as Lassoicon } from '../../assets/icons/lasso_icon_137448.svg'
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Config from "../../config/Config";
import { useNavigate } from "react-router-dom";

const Inpainttools=(props)=>{

    const {

        editor,
        tabIndex,
        setCropImageSource,
        setCanvasBgColor,
        translatedImageid,
        setTranslatedImageid,
        designid,
        imageTransleSource,
        updateMaskImage,
        setIsSaved,
        name,


    } = props


    const navigate = useNavigate();

    const handleAuthTrue = (authentication) => {
        let token = Config.userState != null ? Config.userState.token : "";
        authentication.append("Authorization", `Bearer ${token}`)
        return authentication;
    }
    const LABS_API = Config.LABS_API

    const brushsizeref = useRef(null)
    const [draw,setDraw]  = useState(null)
    const [lassoTrue,setLassoTrue]  = useState(null)
    const drawingmode = useRef(false)
    const [Brushsize, setBrushSize] = useState(90)

    
 if (translatedImageid !== null) {
    editor?.canvas.getObjects('path').forEach(e => {
        e.set({ selectable: false })
    })
} else {
    editor?.canvas.getObjects('path').forEach(e => {
        e.set({ selectable: true })
    })
}
const brushColor = ' #ff0000';
const getDrawCursor = () => {
    const circle = `
        <svg
            height="${ Brushsize }"
            fill="${ brushColor }"
            opacity="${0.5}"
            viewBox="0 0 ${ Brushsize * 2 } ${ Brushsize * 2 }"
            width="${ Brushsize }"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="50%"
                cy="50%"
                r="${ Brushsize }" 
            />
        </svg>
    `;
    
    return `data:image/svg+xml;base64,${ window.btoa(circle) }`;
};
// if(editor){
//     editor.canvas.freeDrawingCursor = `url(${ getDrawCursor() }) ${ Brushsize / 2 } ${ Brushsize / 2 }, crosshair`
// }

useEffect(()=>{
  
    setBrushSize(90)
    setDraw(false)
},[])


    const drawing=()=>{
        if(translatedImageid == null){
            postImageTranslateProject()

        }
        editor.canvas.isDrawingMode = true
        editor.canvas.freeDrawingBrush.color = 'rgba(255, 0, 0, 0.3)'
        editor.canvas.freeDrawingCursor = `url(${ getDrawCursor() }) ${ Brushsize / 2 } ${ Brushsize / 2 }, crosshair`
        editor.canvas.freeDrawingBrush.width = Brushsize

        drawingmode.current = true
        setDraw(true)
        setLassoTrue(false)
    }

    const lassodrawing=()=>{
        if(translatedImageid == null){
            postImageTranslateProject()

        }
        editor.canvas.isDrawingMode = true
        editor.canvas.freeDrawingBrush.color = 'rgba(255, 0, 0, 0.3)'
        editor.canvas.freeDrawingCursor = 'default'
        editor.canvas.freeDrawingBrush.width = 5

        drawingmode.current = true
        setDraw(true)
        setLassoTrue(true)
    }
    useEffect(() => {

    },[lassoTrue])

    useEffect(()=>{
        if(editor){
            editor.canvas.freeDrawingBrush.width = Brushsize
        }

    },[Brushsize])
   
    const eraser=()=>{
        editor.canvas.freeDrawingBrush.color = 'rgba(255, 0, 0, 1)'
        editor.canvas.freeDrawingBrush.width = Brushsize

        // canvas.getContext().globalCompositeOperation = 'destination-out'
        setDraw(false)
            
    }


    const postImageTranslateProject = () => {
            if(translatedImageid === null){
                // setIsSaved(false)
                (async function () {
                
                    const tempdata = JSON.stringify(editor.canvas.toJSON(['id','projectid', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable','brushtype','brushtype2','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name','oldWidth','oldHeight','oldScaleX','oldScaleY']));
                    var formdata = new FormData();
                    //   formdata.append("mask_json", tempdata);
                        formdata.append("image_id", imageTransleSource)
                        formdata.append("project_name", name)
        
        
                    //   formdata.append("mask_json", tempdata);
        
            
                    // tLanguages.map(option => {
                    //   formdata.append("canvas_translation_tar_lang", option.value);
              
                    // })
            
                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        headers: handleAuthTrue(new Headers()),
                        redirect: 'follow'
                    };
                    let data = await fetch(LABS_API + "/image/imageupload/", requestOptions)
                    if (data.status === 200) {
                    let response = await data.json()
                    // console.log(response.id);
                    navigate(`/ailaysa-canvas/image-translate/?project=${response[0].id}`)
                    setTranslatedImageid(response[0].id)
                    // console.log(response[0].image);
                    setCropImageSource(LABS_API + "/" +response[0].image)
                    setTimeout(() => {
                        setIsSaved(true)
                    }, 1800);
                    }
                })();
            }
    }

    
   

if(editor?.canvas?.isDrawingMode || tabIndex == 9){
    editor.canvas?.on('object:added', (e)=>{
        if(e.target.type == 'path'){
           
            if(draw){
                e.target.globalCompositeOperation = 'source-over'
                e.target.selectable = false
                e.target.evented = false
                e.target.brushtype = 'brush'
                if(lassoTrue){
                    e.target.fill = 'rgba(255, 0, 0, 0.3)'
                }
                else{
                    e.target.fill = 'rgba(255, 0, 0, 0)'
                }
            }
            else{
                e.target.globalCompositeOperation = 'destination-out' 
                e.target.evented = false
                e.target.selectable = false
                e.target.brushtype = 'erase'
                // e.target.fill = 'rgba(255, 0, 0, 0)'
            }
            editor.canvas.requestRenderAll()
            if(designid == null ){
                if(translatedImageid != null){
                    updateMaskImage()
                }
            }
        }
        
        editor.canvas.__eventListeners = {} 
    })
}

     

  


    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const changebrushsize=(e)=>{
        setBrushSize(e.target.value)
      
    }

    // const seimg =()=> {
    //     editor.canvas.getObjects().forEach(e=>{
    //         e.target.globalCompositeOperation = 'source-over' ? e.target.stroke = '#fff' : e.target.stroke = '#000'
    //     })
    //     editor.canvas.backgroundColor = '#000'
    //     editor.canvas.renderAll()
    //     let image = editor.canvas.dataURl({
    //         type: "png"
    //     })
    //     console.log(image);
    // //    editor?.canvas?.getObjects('path').forEach(each => 
    // //         console.log(each)
    // //         ); 
    // }

    const getJsonData = () => {
        editor.canvas.backgroundImage  = ''
        setCanvasBgColor('#000000')
        editor.canvas.getObjects('path').forEach(e => {
            if(e.globalCompositeOperation == 'source-over'){
                // console.log(e)
                e.set({stroke:'#fff'})
                if(lassoTrue){
                    e.set({ fill : '#FFF'})
                }
            }
            else{
                e.globalCompositeOperation = 'source-over'
                // console.log(e)
                e.set({stroke:'#000'})
                // editor.canvas.remove(e)
            }
        });
        editor.canvas.renderAll()


            setTimeout(() => {
                const base64 = editor.canvas.toDataURL({
                    format: 'png',
                    enableRetinaScaling: true
                });
                const link = document.createElement("a");
                link.href = base64;
                link.download = 'sample.png';
                // setJsdata(base64)
                link.click();
                // setTabSwitch("AilaysaLanguageSelectPage")
            }, 2000);

       
        // setJsdata(editor.canvas.toJSON(['id', 'height', 'width', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable', 'name','breakWords']))
       
    }
    

    return (<>
        <div className='inpaintbar' >
                    <span className={draw && drawingmode.current && !lassoTrue ? 'active' : ''} onClick={()=>{drawing()}}><BrushIcon className="toolbar-icons" /></span>
                    <span className={draw && drawingmode.current && lassoTrue ? 'active' : ''} onClick={()=>{lassodrawing()}}><Lassoicon className="toolbar-icons" /></span>
                    {/* <span  onClick={erasingmode}><Eraser/></span> */}
                    <span className={!draw && drawingmode.current ? 'active' : ''} onClick={()=>{eraser()}}><Eraser className="toolbar-icons" /></span>
                   {!lassoTrue && <span aria-describedby={id} type="button" className="brushsize" onClick={handleClick}>
                        Size:  <Box width={150}>
                             <Slider
                                size="small"
                                defaultValue={90}
                                aria-label="Small"
                                value={Brushsize}
                                onChange={(e)=>{changebrushsize(e)}}
                                valueLabelDisplay="auto"
                                /></Box>
                    </span>}
        </div>
    </>)
}


export default Inpainttools;