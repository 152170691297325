

import { useEffect, useState, useRef } from 'react';
import React from 'react';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import SketchExample from './ColorPicker'
import StrokeStyle from './toolbar/Borderstyle'
import ImageToolbar from "./toolbar/ImageToolbar";
import Texttool from './toolbar/Texttools'
import Moretool from './toolbar/Moretools'
import Inpainttools from './toolbar/Inpainttools'





const Toolbar = (props) => {
    const {
        editor,
        fabric,
        setTabIndex,
        tabIndex,
        canvaswidth,
        canvasheight,
        scaleCanvas,
        workspaceSwitch,
        redoChanges,
        saveAsDesign,
        updateDesign,
        designid,
        setCropImageSource,
        setBackgroundEdit,
        setCanvasBgColor,
        manualChecked,
        back,
        front,
        forward,
        backward,
        handledelete,
        duplicate,
        translatedImageid,
        setTranslatedImageid,
        setImageTransleSource,
        imageTransleSource,
        name,
        updateMaskImage,
        setIsSaved,
        generateUID,
        undoChanges,
        makeid,
        ruleonoff,
        cropImage,
        callcrop,
        setManualChecked,
        handleConfirmBackGround
    }
        = props

    const totaltext = useRef(false)
    const [totaltextalignCentercount, setTotaltextalignCentercount] = useState(false)
    const [totaltextalignLeftcount, setTotaltextalignLeftcount] = useState(false)
    const [totaltextalignRightcount, setTotaltextalignRightcount] = useState(false)
    const [objectcolor, setObjectcolor] = useState()
    const totalimage= useRef(false)
    const totalobject= useRef(false)
    const validategroup = useRef(false)
    const [textalignicon,setTextalignicon] = useState(null)
    const [fontfamily, setFontfamily] = useState('Roboto');
    
    const [scalewidth, setscalewidth] = useState(null)
 
    const [fontFamilyobj, setFontFamilyobj] = useState()
    
   

    const [lineheight, setLineheight] = useState(1)
    
    const selectedobjectforsvg = useRef(false)


    const handleChange = (event) => {
        setFontfamily(event.target.value);
    };


    useEffect(() => {
        //  check selection object type
        // console.log(ruleonoff)
        totaltext.current = false
        totalimage.current = false 
        totalobject.current = false
        selectedobjectforsvg.current = false
        if (editor?.canvas.getActiveObjects().length >= 1) {
            let total = editor.canvas.getActiveObjects().length
            let totaltextcount = 0;
            let totalimagecount = 0;
            let totalobjectcount = 0;
            let totalgroupobject = 0;
            let totalgrouptext = 0;
            let totalgroupimage = 0;
            let totalgroupotherobjects = 0;
            let textalignleftcount = 0
            let textaligncentercount = 0
            let textalignrightcount = 0
            let textalignjustifycount = 0
            validategroup.current = false

            editor?.canvas.getActiveObjects().forEach((e) => {
                if (e.type == 'textbox') {
                    totaltextcount++
                    if (e.textAlign == 'center') {
                        textaligncentercount++
                    }
                    else if (e.textAlign == 'left') {
                        textalignleftcount++
                    }
                    else if(e.textAlign == 'justify'){
                        textalignjustifycount++
                    }
                    else {
                        textalignrightcount++
                    }
                }
                else if (e.type == 'image') {
                    totalimagecount++
                }
                else if ((e.type != 'textbox') && (e.type != 'image') && (e.type != 'group')) {
                    totalobjectcount++
                }
                else if (e.type == 'group') {
                    validategroup.current = true
                    e._objects.map(i => {
                        totalgroupobject++
                        if (i.type == 'textbox') {
                            totalgrouptext++
                        }
                        else if (i.type == 'image') {
                            totalgroupimage ++
                        }
                        else if ((i.type != 'textbox') && (i.type != 'image') && (i.type != 'group')) {
                            totalgroupotherobjects ++
                        }
                    })
                }
            })

            textaligncentercount == totaltextcount ? setTotaltextalignCentercount(true) : setTotaltextalignCentercount(false)
            textalignleftcount == totaltextcount ? setTotaltextalignLeftcount(true) : setTotaltextalignLeftcount(false)
            textalignrightcount == totaltextcount ? setTotaltextalignRightcount(true) : setTotaltextalignRightcount(false)
            selectedobjectforsvg.current =  totalgroupobject == totalgroupotherobjects && validategroup.current && total == 1 ? true : false

            if(totaltextcount == total){
                totaltext.current = true
                totalimage.current = false 
                totalobject.current = false
                handleConfirmBackGround()

                
            }
            else if(totalimagecount == total){
                let backGround =  editor.canvas.getObjects().find(obj => obj.name == 'Background-new')
                totaltext.current = false
                totalimage.current = true
                totalobject.current = false
                if(editor.canvas.getActiveObject() != backGround){
                    handleConfirmBackGround()

                }



            }
            else if(totalobjectcount == total ){
                totaltext.current = false
                totalimage.current = false
                totalobject.current = true
                handleConfirmBackGround()
               
            }
            else{
                totaltext.current = false
                totalimage.current = false
                totalobject.current = false
                handleConfirmBackGround()
                
            }



            if(textaligncentercount == totaltextcount){
                setTextalignicon(<FormatAlignCenterIcon className="toolbar-icons" />) // center icon
            }
            else if(textalignleftcount == totaltextcount){
                setTextalignicon(<FormatAlignLeftIcon className="toolbar-icons" />) // left icon
            }
            else if(textalignrightcount == totaltextcount){
                setTextalignicon(<FormatAlignRightIcon className="toolbar-icons" />) // right icon
            }
            else if(textalignjustifycount == totaltextcount){
                setTextalignicon(<FormatAlignJustifyIcon className="toolbar-icons" />) // jsutify icon
            }
            }else{
                setTextalignicon(<FormatAlignLeftIcon className="toolbar-icons" />) // left icon
            }
        if (!editor?.canvas.getActiveObjects()) {
            totaltext.current = false
            totalimage.current = false
            totalobject.current = false
        }
    }, [editor])  
    
    
    // const handleConfirmBackGround = () => {
    //     let backGround =  editor.canvas.getObjects().find(obj => obj.name == 'Background-new')
    //     // console.log(Math.abs(backGround?.left));
    //     // console.log(Math.abs(backGround?.top));
    //     // console.log(editor.canvas.backgroundImage);
    //     var rectclip = new fabric.Rect({ width: Math.floor(canvaswidth), height: Math.floor(canvasheight),absolutePositioned:true});
    //     if(backGround){
    //         backGround.set({
    //             id: 'background',
    //             name: 'Background-current',
    //             lockMovementX: true,
    //             lockMovementY: true,
    //             editable: false,
    //             selectable: false,
    //             evented: false,
    //             originalwidth:backGround.width,
    //             originalheight:backGround.height,
    //             originalleft:backGround.left,
    //             originaltop:backGround.top,
    //             // cropX:Math.abs(editor.canvas.getActiveObject().left),
    //             // cropY:Math.abs(editor.canvas.getActiveObject().top),
    //             // top:0,
    //             // left:0,
    //             clipPath:rectclip,
    //         })
    //         backGround.setCoords();
    //         editor.canvas.background = ""
    //         editor.canvas.requestRenderAll()
    //     }
    // }


    if (editor?.canvas.getActiveObject()?.type == 'textbox') {
        editor?.canvas?.getActiveObject()?.setControlsVisibility({
            mt: false, // middle top disable
            mb: false, // midle bottom
        })
        editor.canvas.renderAll()
    }

    const selectall = () => {
        var sel = new fabric.ActiveSelection(editor.canvas.getObjects(), {
            canvas: editor.canvas,
        });
        editor.canvas.setActiveObject(sel);
    }

    useEffect(() => {
        
        if(editor?.canvas.getObjects('rect').find(Imageobj => Imageobj.id2 == 'crop-box') != editor?.canvas.getActiveObject() || editor?.canvas.getActiveObject() == undefined ){
            const eventinspect = (event) => {

                const key = event.key;
                // console.log(key); // const {key} = event; ES6+
                if (key === "Delete" ) {
                    
                    if(editor.canvas.getActiveObject()){
                        if(editor.canvas.getActiveObject().isEditing){
                            return true
                        }
                        else{
                            if (document.activeElement.tagName.toLowerCase() === 'input' || document.activeElement.tagName.toLowerCase() === 'div' || document.activeElement.tagName.toLowerCase() === 'textarea') {
                                // code to execute if the element is an input
                              }
                            else{
                            event.preventDefault()
                            return handledelete()
                            }
                        }
                      }

                    // if(editor.canvas.getActiveObject() && editor.canvas.getActiveObject().isEditing){
                    // }
                    // else{
                    //     event.preventDefault()
                    //     handledelete()
                    // }
                }
                event = event || window.event // IE support
                var c = event.which
                var ctrlDown = event.ctrlKey || event.metaKey // Mac support
    
                // Check for Alt+Gr (http://en.wikipedia.org/wiki/AltGr_key)
                if (ctrlDown && event.altKey) {
                    return true
                }
                // Check for ctrl+c, v and x
                else if (ctrlDown && c == 67) {
                    if(editor.canvas.getActiveObject()){
                        if(editor.canvas.getActiveObject().isEditing){
                            // console.log('editing');
                        } 
                        else{
                            // event.preventDefault()
                            // Copy()
                            if (document.activeElement.tagName.toLowerCase() === 'input' || document.activeElement.tagName.toLowerCase() === 'div' || document.activeElement.tagName.toLowerCase() === 'textarea') {
                                // code to execute if the element is an input
                              }
                              else{
                                event.preventDefault()
                                return Copy()
                              } 
                        }
                    }
                } // c
                else if (ctrlDown && c == 86) {
                    // event.preventDefault()
                    // Paste()
                  if(editor.canvas.getActiveObject()){
                    if(editor.canvas.getActiveObject().isEditing){
                        return true
                    }
                    else{
                        if (document.activeElement.tagName.toLowerCase() === 'input' || document.activeElement.tagName.toLowerCase() === 'div' || document.activeElement.tagName.toLowerCase() === 'textarea') {
                            // code to execute if the element is an input
                          }
                        else{
                        event.preventDefault()
                        return Paste()
                        }
                    }
                  }
                  else{
                    if (document.activeElement.tagName.toLowerCase() === 'input' || document.activeElement.tagName.toLowerCase() === 'div' || document.activeElement.tagName.toLowerCase() === 'textarea') {
                        // code to execute if the element is an input
                      }
                      else{
                          Paste()
                      }
                  }
                } // v
                else if (ctrlDown && c == 88) {

                    if(editor.canvas.getActiveObject()){
                        if(editor.canvas.getActiveObject().isEditing){
                            // console.log('editing');
                            // console.log('call2');
                        } 
                        else{
                            // event.preventDefault()
                            if (document.activeElement.tagName.toLowerCase() === 'input' || document.activeElement.tagName.toLowerCase() === 'div' || document.activeElement.tagName.toLowerCase() === 'textarea') {
                                // code to execute if the element is an input
                                // console.log('call3');
                              }
                            else{
                                // console.log('call4');
                                event.preventDefault()
                                Copy();
                                handledelete()
                              } 
                        }
                    }
                } // x
                // else if (ctrlDown && c == 90) {
                //     // event.preventDefault()
                //     console.log('undo');

                //     if(editor.canvas.getActiveObject()){
                //         if(editor.canvas.getActiveObject().isEditing){
                //             // console.log('editing');
                //         } 
                //         else{
                //             // event.preventDefault()
                //             if (document.activeElement.tagName.toLowerCase() === 'input' || document.activeElement.tagName.toLowerCase() === 'div' || document.activeElement.tagName.toLowerCase() === 'textarea') {
                //                 // code to execute if the element is an input
                //               }
                //               else{
                //                 // event.preventDefault()
                //                 // return undoChanges()
                //               } 
                //         }
                //     }
                //     // undoChanges()
                // } // z
                // else if (ctrlDown && c == 89) {
                //     console.log('redo');
                //     if(editor.canvas.getActiveObject()){
                //         if(editor.canvas.getActiveObject().isEditing){
                //             // console.log('editing');
                //         } 
                //         else{
                //             // event.preventDefault()
                //             if (document.activeElement.tagName.toLowerCase() === 'input' || document.activeElement.tagName.toLowerCase() === 'div' || document.activeElement.tagName.toLowerCase() === 'textarea') {
                //                 // code to execute if the element is an input
                //               }
                //               else{
                //                 // event.preventDefault()
                //                 // return redoChanges()
                //                 console.log('undoooooo');
                //               } 
                //         }
                //     }
                    
                // } // y
                else if (ctrlDown && c == 65) {
                    if(editor.canvas.getActiveObject()){
                        if(editor.canvas.getActiveObject().isEditing){
                            // console.log('editing');
                        } 
                        else{
                            // event.preventDefault()
                            if (document.activeElement.tagName.toLowerCase() === 'input' || document.activeElement.tagName.toLowerCase() === 'div' || document.activeElement.tagName.toLowerCase() === 'textarea') {
                                // code to execute if the element is an input
                              }
                              else{
                                event.preventDefault()
                                return selectall()
                              } 
                        }
                    }
                    else{
                        if (document.activeElement.tagName.toLowerCase() === 'input' || document.activeElement.tagName.toLowerCase() === 'div' || document.activeElement.tagName.toLowerCase() === 'textarea'){
                            
                        }
                        else{
                            event.preventDefault()
                            return selectall()
                        }
                    }
                    
                } // a
                else if(c == 38){
                    if (document.activeElement.tagName.toLowerCase() === 'input' || document.activeElement.tagName.toLowerCase() === 'div' || document.activeElement.tagName.toLowerCase() === 'textarea') {
                        // code to execute if the element is an input
                      }
                      else{
                        event.preventDefault()
                        return moving('top')
                      }
                    
                } //up
                else if(c == 37){
                    if (document.activeElement.tagName.toLowerCase() === 'input' || document.activeElement.tagName.toLowerCase() === 'div' || document.activeElement.tagName.toLowerCase() === 'textarea') {
                        // code to execute if the element is an input
                      }
                      else{
                        event.preventDefault()
                        return moving('left')
                      }
                    
                } //left
                else if(c == 39){
                    if (document.activeElement.tagName.toLowerCase() === 'input' || document.activeElement.tagName.toLowerCase() === 'div' || document.activeElement.tagName.toLowerCase() === 'textarea') {
                        // code to execute if the element is an input
                      }
                      else{
                        event.preventDefault()
                        return moving('right')
                      }
                    
                } //right
                else if(c == 40){
                    if (document.activeElement.tagName.toLowerCase() === 'input' || document.activeElement.tagName.toLowerCase() === 'div' || document.activeElement.tagName.toLowerCase() === 'textarea') {
                        // code to execute if the element is an input
                      }
                      else{
                        event.preventDefault()
                        return moving('down')
                      }
                    
                } //down
                else if (ctrlDown && c == 83) {
                    event.preventDefault()
                    if (designid == null) {
                        saveAsDesign()
                    }
                    else {
                        updateDesign()
                    }
                } // s
                else if (ctrlDown && c == 68) {
                    event.preventDefault()
                    if(editor.canvas.getActiveObject()){
                        duplicate()
                    }
                } // d
            }
            document.addEventListener('keydown', eventinspect, false)
            return (() => {
                document.removeEventListener('keydown', eventinspect)
            })
        }

        if(editor?.canvas.getActiveObject() == null ){
            const eventinspect = (event) => {
                const key = event.key;
                event = event || window.event // IE support
                var c = event.which
                var ctrlDown = event.ctrlKey || event.metaKey // Mac support
    
                // Check for Alt+Gr (http://en.wikipedia.org/wiki/AltGr_key)
                if (ctrlDown && event.altKey) {
                    return true
                }
                else if (ctrlDown && c == 86) {
                    event.preventDefault()
                    // Paste()
                    Paste() 
                } }
            document.addEventListener('keydown', eventinspect, false)
            return (() => {
                document.removeEventListener('keydown', eventinspect)
            })
        }
    })
    const moving=(e)=>{
        // moving top,left 
        var steps = 1
    
        if(e == 'right'){
            // editor.canvas.getActiveObjects().forEach((i) => {
            //     // if(i.left < canvaswidth - i.width){
            //         i.left = i.left + steps  
            //         editor.canvas.renderAll()
            //     // }
            //     // else{
            //     //     return false
            //     // }
            // })
            editor.canvas.getActiveObject().left =editor.canvas.getActiveObject().left + steps
            editor.canvas.requestRenderAll()
        }
        else if(e == 'down'){
            // editor.canvas.getActiveObjects().forEach((i) => {
            //     // if(i.top < canvasheight - i.height){
            //         i.top = i.top + steps  
            //         editor.canvas.renderAll()
            //     // }
            //     // else{
            //     //     return false
            //     // }
            // })
            editor.canvas.getActiveObject().top =editor.canvas.getActiveObject().top + steps
            editor.canvas.requestRenderAll()
        }
        else if(e == 'top'){
            // editor.canvas.getActiveObjects().forEach((i) => {
            //     // if(i.top > 0){
            //         i.top = i.top - steps  
            //         editor.canvas.renderAll()
            //     // }
            //     // else{
            //     //     return false
            //     // }
            // })
            editor.canvas.getActiveObject().top = editor.canvas.getActiveObject().top - steps
            editor.canvas.requestRenderAll()
        }
        else{
            // editor.canvas.getActiveObjects().forEach((i) => {
            //     // if(i.left > 0){
            //         i.left = i.left - steps  
            //         editor.canvas.renderAll()
            //     // }
            //     // else{
            //     //     return false
            //     // }
            // })
            editor.canvas.getActiveObject().left = editor.canvas.getActiveObject().left - steps
            editor.canvas.requestRenderAll()
        }
    }

    const elementscolor = []
    if (editor) {
        if (editor?.canvas.getActiveObject()?.type == 'group') {
            for (let i = 0; i < editor.canvas.getActiveObject()._objects.length; i++) {
                if (editor.canvas.getActiveObject()._objects[i].fill != '') {
                    // dummy.push({color:editor.canvas.getActiveObject()._objects[i].fill,paths:[i]})
                    if (elementscolor.length === 0) {
                        elementscolor.push({ color: editor.canvas.getActiveObject()._objects[i].fill, paths: [i] })

                    }
                    else {
                        if (elementscolor.find(o => o.color === editor.canvas.getActiveObject()._objects[i].fill)) {
                            let currentindexcolor = elementscolor.findIndex(x => x.color === editor.canvas.getActiveObject()._objects[i].fill)
                            elementscolor[currentindexcolor].paths.push(i)
                        }
                        else {
                            elementscolor.push({ color: editor.canvas.getActiveObject()._objects[i].fill, paths: [i] })
                        }
                    }
                }
            }
        }
    }
   

    // texteffect
    
    // set Image as background
    const setAsbackgroundImage = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                let backGround =  editor.canvas.getObjects().find(obj => obj.name == 'Background-current')
                editor.canvas.getActiveObject().set({
                    top: 0,
                    left: 0,
                    originX: 'left',
                    originY: 'top',
                    scaleX: canvaswidth / editor.canvas.getActiveObject().width,
                    scaleY: canvasheight / editor.canvas.getActiveObject().height,
                    crossOrigin: 'anonymous',
                    id: 'background',
                    name: 'Background-new'

                });
                editor.canvas.sendToBack(editor.canvas.getActiveObject())
                editor.canvas.remove(backGround)
                editor.canvas.renderAll();
            }
        }

    }

const imeventtrue = useRef(true)

    useEffect(()=>{
        if (editor && imeventtrue.current == true) {
            
            if (editor.canvas.getActiveObject()?.type == 'group') {
                const selectindividulobj = (e) => {
                    if (editor.canvas.getActiveObject()?.type == 'group') {
                        // editor.canvas.perPixelTargetFind  = true
                        editor.canvas.getActiveObject().set({subTargetCheck: true})
                        editor.canvas.renderAll()
                        editor?.canvas?.setActiveObject(e?.subTargets[0]);
                        editor.canvas.getActiveObject().hasControls = false
                        editor.canvas.renderAll()
                    }
                }
                editor.canvas.__eventListeners["mouse:dblclick"]=[]
                editor.canvas.on('mouse:dblclick', selectindividulobj);
                // return ()=>{
                //     editor.canvas.off('mouse:dblclick', selectindividulobj);
                // }

            }
            // else{
            //     editor.canvas.perPixelTargetFind  = false
            // }
            
        }
    })

    // useEffect(() =>{
    //     if (editor) {
            
    //         if (editor.canvas.getActiveObject()?.type == 'group') {
                  
    //             editor.canvas.perPixelTargetFind  = true
    //         }
    //         else if(editor.canvas.getActiveObject() == 'undefined'){
    //             editor.canvas.perPixelTargetFind  = false
    //         }else{
    //             editor.canvas.perPixelTargetFind  = false

    //         }
            
    //     }
    // })
 

    
    const copyobj = useRef(null)


    
    const Copy = () => {
        editor.canvas.getActiveObject().clone(function (cloned) {
            copyobj.current = cloned;
        });
    }

    
    const Paste = () => {
        // clone again, so you can do multiple copies.
        // let center = editor.canvas.getCenter()
       
        if(copyobj.current !=null){
            copyobj.current.clone(function (clonedObj) {
                editor.canvas.discardActiveObject();
                clonedObj.set({
                    left: clonedObj.left + 10,
                    top: clonedObj.top + 10,
                    evented: true,
                    name: "Duplicate"+generateUID(8) + makeid(9),
                    id: "Duplicate"+generateUID(8) + makeid(9)
                });
                if (clonedObj.type === 'activeSelection') {
                    // active selection needs a reference to the canvas.
                    clonedObj.canvas = editor.canvas;
                    clonedObj.forEachObject(function (obj) {
                        editor.canvas.add(obj);
                    });
                    // this should solve the unselectability
                    // clonedObj.setCoords();
                } else {
                    editor.canvas.add(clonedObj);
                }
                // copyobj.current.top += 10;
                // copyobj.current.left += 10;
                editor.canvas.setActiveObject(clonedObj);
                // editor.canvas.fire('object:modified');
                editor.canvas.requestRenderAll();
            });
        }
    }

   
    const removeBackground = () => {

        editor.canvas.setBackgroundImage(null, editor.canvas.renderAll.bind(editor.canvas));
        setCanvasBgColor("#fff")
        setBackgroundEdit(false)

        editor.canvas.renderAll();
    }

    

    return <>
            {/*text tool*/}
            {totaltext.current && editor?.canvas?.getActiveObject() && tabIndex != 9 && 
             <Texttool
             editor={editor}
             fabric={fabric}
             setTabIndex={setTabIndex}
             tabIndex={tabIndex}
             workspaceSwitch={workspaceSwitch}
             fontFamilyobj={fontFamilyobj}
             scaleCanvas={scaleCanvas}
             textalignicon={textalignicon}
             setFontFamilyobj={setFontFamilyobj}
             totaltext={totaltext.current}
             ruleonoff={ruleonoff}

             />}
            {/* img tool*/}
            {((editor?.canvas?.getActiveObject()?.type == 'image') || editor?.canvas.getObjects('rect').find(Imageobj => Imageobj.custtype == 'picArea')) && tabIndex != 9 &&
                <div className='img-toolbar' style={{top:ruleonoff ? '40px' : '10px',left:ruleonoff ? '40px' : '10px'}}>
                    <ImageToolbar
                        setAsbackgroundImage={setAsbackgroundImage}
                        setCropImageSource={setCropImageSource}
                        editor={editor}
                        fabric={fabric}
                        handleConfirmBackGround={handleConfirmBackGround}
                        totaltext={totaltext.current}
                        selectedobjectforsvg={selectedobjectforsvg.current}
                        totalimage={totalimage.current}
                        totalobject={totalobject.current}
                        cropImage={cropImage}
                        callcrop={callcrop}
                         />
                </div>}
            
            {/*show svg  and object tool*/}
            {(selectedobjectforsvg.current || totalobject.current)  &&  editor?.canvas.getObjects('rect').find(Imageobj => Imageobj.custtype ) != editor?.canvas.getActiveObject() && tabIndex != 9 &&
                <div className='img-toolbar element-toolbar' style={{top:ruleonoff ? '40px' : '10px',left:ruleonoff ? '40px' : '10px',zIndex:ruleonoff ? 9999 : 1}}>
                    {selectedobjectforsvg.current &&
                        <div className='colorbar' style={{ display: 'flex', gap: '10px', padding: '5px,10px' }}>
                            {/* <span><ElementsColor editor={editor} fabric={fabric}/></span> */}
                            {elementscolor.filter(e => { return typeof (e.color) != 'object' }).map((c, i) => {
                                const style = {
                                    width: '28px',
                                    height: '28px',
                                    background: c.color,
                                    border: '1px solid rgba(0,0,0,0.1)'
                                }
                                return (
                                    <p className={`hasPicked${i}`} onClick={() => { setObjectcolor(c.color) }} style={style} >
                                        <SketchExample
                                            objectcolor={objectcolor}
                                            setObjectcolor={setObjectcolor}
                                            onChange={(color) => {
                                                c.color = "rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")"
                                                setObjectcolor("rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")")
                                                for (let j = 0; j < c.paths.length; j++) {
                                                    document.querySelector(`.hasPicked${i}`).style.backgroundColor = c.color
                                                    editor.canvas.getActiveObject()._objects[c.paths[j]].set({ fill: "rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")" });
                                                }
                                                editor.canvas.renderAll()
                                            }}
                                        /></p>
                                )
                            })}
                        </div>}
                    {/* no visible in group */}
                    {!selectedobjectforsvg.current && <StrokeStyle
                        editor={editor}
                        fabric={fabric}
                        totaltext={totaltext.current}
                        selectedobjectforsvg={selectedobjectforsvg.current}
                        totalimage={totalimage.current}
                        totalobject={totalobject.current}
                    />}
                </div>}
            {/*inpaint tool*/}
            {((translatedImageid !== null || (manualChecked && tabIndex == 9 )) && workspaceSwitch != "Workspace"  )&& 
            // <div className='inpainttoolbar' style={{ display: 'flex' }}>
                <Inpainttools
                updateMaskImage={updateMaskImage}
                name={name}
                editor={editor}
                fabric={fabric}
                tabIndex={tabIndex}
                translatedImageid={translatedImageid}
                setTranslatedImageid={setTranslatedImageid}
                designid={designid}
                setCropImageSource={setCropImageSource}
                setCanvasBgColor={setCanvasBgColor}
                setImageTransleSource={setImageTransleSource}
                imageTransleSource={imageTransleSource}
                setIsSaved={setIsSaved}
                />
            // </div>
            }
            {/*more tool*/}
        {editor?.canvas?.getActiveObject() && (editor?.canvas.getObjects().find(Imageobj => Imageobj.custtype == 'picArea') != editor?.canvas.getActiveObject()) && (editor?.canvas.getObjects().find(Imageobj => Imageobj.picArea)   != editor?.canvas.getActiveObject()) && tabIndex != 10 && <div className='more-tools' style={{top:ruleonoff ? '40px' : '10px',zIndex:ruleonoff ? 9999: 1 }}>
                <Moretool
                fabric={fabric}
                editor={editor}
                duplicate={duplicate}
                handledelete={handledelete}
                canvaswidth={canvaswidth}
                canvasheight={canvasheight}
                forward={forward}
                backward={backward}
                front={front}
                back={back}
                generateUID={generateUID}
                makeid={makeid}
                />
            </div>}
    </>
}

export default Toolbar