import React, { useEffect } from "react";
import { Skeleton } from "@mui/material";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



const ProjectBar = (props) => {

    const {
        fabric,
        editor,
        LABS_API,
        pageData,
        sourceCavasData,
        setSourceCanvasData,
        setCanvasTranslatedJsonId,
        setPageSwitch,
        pageSwitch,
        translatedImageid,
        state,
        makeid,
        generateUID,
        setTranslatedImageid,
        addBackground,
        setLanguageData,
        setsourceCavasDataJson,
        urlPath,
        handlesize
    } = props


    useEffect(() => {

    }, [sourceCavasData])
    const loadPage = (file, key) => {

        if (window.location.pathname === '/ailaysa-canvas-workspace/image-translate-auto/') {
            setTranslatedImageid(file.id)
            setCanvasTranslatedJsonId(file.image_inpaint_creation[0].id)
            setLanguageData(file.image_inpaint_creation)
            setSourceCanvasData(file?.source_canvas_json)
                setsourceCavasDataJson(file)

        }
        if(file.source_canvas_json === null){
            editor?.canvas.clear();
            addBackground(LABS_API + '/' + file.inpaint_image)
            Object.entries(file.source_bounding_box).map(([k, v]) => {
                let box = v.bbox,
                    color = v.color1[0],
                    size = (v.fontsize+v.fontsize2)/2
                    // size = v.fontsize > v.fontsize2 ? v.fontsize + 5 : v.fontsize2 + 5

                var textbox = new fabric.Textbox(v.text, {
                    name: "Textbox_"+generateUID(8) + makeid(9),
                    id: 'text-' + k,
                    left: box[0],
                    height:box[3],
                    fontSize:  size,
                    width: box[2] - box[0],
                    globalCompositeOperation: "source-atop",
                    top: box[1],
                    fill: 'rgb(' + color + ")",
                    // editable: true,
                    minScaleLimit: 0.5,
                    fontFamily: 'Roboto',
                    // perPixelTargetFind: true,
                    lineHeight: 1,
                    breakWords: true,
                    uniformScaling: true,
                    status: 'Unconfirmed',

                });
                editor.canvas.add(textbox)
                // firstSourceCanvasSave(file.id)

                // editor.canvas.getActiveObject()?.set({ editable: false })
              
                editor.canvas.renderAll()


            }
            
            )
        }else{
            editor?.canvas.loadFromJSON(file.source_canvas_json, function () {
                editor?.canvas.renderAll.bind(editor?.canvas.renderAll());
            })
            // var size = {name:'togglesidebar',width:file.source_canvas_json.background.width,height:file.source_canvas_json.background.height,fill:file.source_canvas_json.background.fill}
            // handlesize(size)
        }
    }



    const handleOpenAndClose = () => {
        if(pageSwitch === false){
            setPageSwitch(true)

        }else{
        setPageSwitch(false)
        }
    }

    

    return (
        <>
            <div className="page-container2">
                <div className="pages-preview-container">
                    {pageData?.map((file, key) => {
                        return (
                            <div className="page-bar-grid-item2">
                                <div className="page-inner-container2" onClick={(e) => loadPage(file, key)}>
                                    {file.image === null ? (<Skeleton height={300} className={file.id === translatedImageid ? "page-images-active" : "page-images"} />) : (<img className={file.id === translatedImageid ? "page-images-active" : "page-images"} src={LABS_API + '/' + file.image} />)}
                                </div>
                            </div>

                        )
                    })
                    }


                </div>
                <div className='open-close-page-bar' onClick={handleOpenAndClose} >
                    {pageSwitch ?
                        (
                            <ArrowDropUpIcon />
                        )
                        :
                        (
                            <ArrowDropDownIcon />
                        )
                    }
                </div>
            </div>

        </>

    )


}

export default ProjectBar;