import React, { useState, useRef, useCallback, useEffect } from "react";
import SearchIcon from '@mui/icons-material/Search';
import FontFaceObserver from 'fontfaceobserver';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Masonry from '@mui/lab/Masonry';
import Grow from '@mui/material/Grow';
import Skeleton from '@mui/material/Skeleton';


const Template = (props) => {

    const {
        editor,
        canvaswidth,
        canvasheight,
        LABS_API,
        handleAuthTrue,
        name,
        pageNo,
        getGlobalTemplate,
        loading,
        setShowTemplateNotice,
        setTemplateData,
        setTemplateTotalData,
        settemplateFrom,
        designid,
        loadFabricJSONDataTemplate,
        handleSave
    } = props;


    const [focusOnSearch, setFocusOnSearch] = useState(false)
    const nextpage = useRef()
    const [globalTemplate, setglobalTemplate] = useState([])
    const typeSearch = useRef()
    const [inputText, setInputText] = useState("");
    const loadingtemplate = useRef(false)

    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        setInputText(lowerCase);
    };

    const getphotodata = async (e) => {
        loadingtemplate.current = true 
        var data = await getGlobalTemplate(e ? e : 1)
        setglobalTemplate(prev => [...prev, ...data.data])
        loadingtemplate.current = false 
        if (data.next != null) {
            nextpage.current = true
        } else {
            nextpage.current = false
        }
    }
    useEffect(() => {
        getphotodata()
    }, [])

    const filteredData = globalTemplate.filter((el) => {
        //if no input the return the original
        if (inputText === '') {
            return el;
        }
        //return the item which contains the user input
        else {
            return el.file_name?.toLowerCase().includes(inputText)
        }
    })
   

    const saveTemplate = () => {
        // setIsSaved(false)
        var temp = editor.canvas.viewportTransform
        editor.canvas.renderAll()
        editor.canvas.viewportTransform = [1, 0, 0, 1, 0, 0]
        const thumbail = editor.canvas.toDataURL({
            format: 'jpeg',
            quality: 0.5,
            width: canvaswidth,
            height: canvasheight
        })
        const exportImage = editor.canvas.toDataURL({
            format: 'png',
            width: canvaswidth,
            height: canvasheight
        })
        editor.canvas.viewportTransform = temp
        editor.canvas.renderAll()
        function dataURLtoFile(dataurl, filename) {

            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        }

        var file = dataURLtoFile(thumbail, 'thumbnail_' + name + '.jpeg');
        var file2 = dataURLtoFile(exportImage, "PageNo_" + pageNo + ".png");

        const tempdata = JSON.stringify(editor.canvas.toJSON(['id','projectid', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable','brushtype','brushtype2','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name','oldWidth','oldHeight','oldScaleX','oldScaleY']));

        (async function () {
            var formdata = new FormData();
            formdata.append("json_page", tempdata);
            formdata.append("export_page", file2);

            formdata.append("thumbnail_page", file)
            formdata.append("file_name", name)
            formdata.append("width", canvaswidth)
            formdata.append("height", canvasheight)

            var requestOptions = {
                method: 'POST',
                headers: handleAuthTrue(new Headers()),
                body: formdata,
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/template-design/", requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                console.log(response);
                // getGlobalTemplate(1)
                setglobalTemplate([])
                getphotodata()
                // var dataphoto  = await getGlobalTemplate(1)
                // setglobalTemplate( prev => [...prev,...dataphoto.data])
                // if (data.next != null) {
                //     nextpage.current = true
                // } else { 
                //     nextpage.current = false
                // }

            }
            else {
                // setIsSaved(true)
                console.log('error');
            }
        })();


    }


    const fetchTemplate = (id) => {

        (async function () {
            document.querySelector('.canvas-space').classList.add('loading-json-blur');
            document.querySelector('#loading-wrapper').style.setProperty('display', 'grid');
            settemplateFrom('global')
            var requestOptions = {
                method: 'GET',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + `/canvas/template-design-get/${id}`, requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                // console.log(response.template_globl_pag[0].json_page);
                setTemplateData(response.template_globl_pag[0].json_page)
                setTemplateTotalData(response)
                if(designid != null){
                    setShowTemplateNotice(true)
                }else{
                    loadFabricJSONDataTemplate(response.template_globl_pag[0].json_page)
                    
                }

            }
            else {
                // setIsSaved(true)
                console.log('error');
                // document.querySelector('.canvas-space').classList.remove('loading-json-blur');
                // document.querySelector('#loading-wrapper').style.setProperty('display', 'none');
            }
        })();


    }

    const deleteTemplate = (id) => {
        (async function () {
            var requestOptions = {
                method: 'DELETE',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + `/canvas/template-design/${id}`, requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                // console.log(response)
                // getMyTemplate()                
                setglobalTemplate([])
                getphotodata()
                // var dataphoto  = await getGlobalTemplate(1)
                // setglobalTemplate( prev => [...prev,...dataphoto.data])
                // if (data.next != null) {
                //     nextpage.current = true
                // } else { 
                //     nextpage.current = false
                // }
            }
            else {
                console.log('error');
            }
        })();



    }

  


    const saveAsTemplate = (id) => {
        (async function () {
            var formdata = new FormData();
            formdata.append("template_global_id", id);
            var requestOptions = {
                method: 'POST',
                headers: handleAuthTrue(new Headers()),
                body: formdata,
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/mytemplate-design/", requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                // console.log(response);
            }
            else {
                console.log('error');
            }
        })();

    }


    const page = useRef(1)
    const observer = useRef(); // ref to store observer
    const lastElementRef = useCallback((element) => {
        //element is the react element being referenced
        // disconnect observer set on previous last element
        if (observer.current) observer.current.disconnect();
        // if there's no more data to be fetched, don't set new observer
        if (!nextpage.current) return;
        // set new observer
        observer.current = new IntersectionObserver(async (entries) => {
            // increase page number when element enters (is intersecting with) viewport.
            // This triggers the pagination hook to fetch more items in the new page
            if (entries[0].isIntersecting && nextpage.current) {
                page.current += 1
                getphotodata(page.current)
                // var data = await  getGlobalTemplate(page.current)
                // setglobalTemplate( prev => [...prev,...data.data])
                // if (data.next != null) {
                //     nextpage.current = true
                // } else { 
                //     nextpage.current = false
                // }
            }
            else {
                // no more templates
                // console.log('nomore');
            }
        });

        // observe/monitor last element
        if (element) observer.current.observe(element);
    });




    return (
        <div className="sidebar-content">
            <div className="sidebar-tabs-heading">
                <span>Template</span>
                <button className="save-text-template" onClick={saveTemplate}>Save template</button>
            </div>
            <div className={focusOnSearch ? "main-tab-search active-search-bar" : "main-tab-search"}>
                <SearchIcon className="seacrh-icon" onClick={inputHandler} />
                <span className="search-placeholder-text">
                    <input type="text" onKeyDown={inputHandler} onClick={() => {
                        setFocusOnSearch(true)
                    }} onBlur={(e) => {
                        setFocusOnSearch(false);
                    }} className="searcher" ref={typeSearch} onChange={inputHandler} placeholder="Search..." />
                </span>
            </div>
            {!loadingtemplate.current && filteredData.length == 0 && <>No Result found</>  }
            <div className="grid-template" >

                <Masonry columns={2} spacing={0.8}>
                    {filteredData.length != 0 && filteredData?.map((value, i) => {
                        return (
                            <Grow
                            in={true}
                            style={{ transformOrigin: '0 0 0' }}
                            {...(true ? { timeout: 1000 } : {})} >

                                <div ref={i === filteredData.length - 1 ? lastElementRef : undefined}>
                                    {/* <div > */}
                                    <div
                                        className="template-global"
                                        onClick={(e) => {
                                            fetchTemplate(value.id)
                                        }}>
                                        <div className="tool-container">
                                            <Tooltip
                                                title="Delete" placement="top" arrow>
                                                <DeleteForeverIcon className="delete-icon" onClick={(e) => {
                                                    e.stopPropagation()
                                                    deleteTemplate(value.id)
                                                }} />
                                            </Tooltip>
                                            <Tooltip
                                                title="Add to my template" placement="top" arrow>
                                                <LibraryAddIcon className="delete-icon" onClick={(e) => {
                                                    e.stopPropagation()
                                                    // saveAsTemplate(filesource.canvas_design)
                                                    saveAsTemplate(value.id)
                                                }} />

                                            </Tooltip>
                                        </div>
                                        <img className="main-bar-image-temp" src={LABS_API + value.thumbnail_page} />
                                    </div>
                                </div>
                                
                            </Grow>
                        )
                    })}

                    {/* {true && Array(10).fill(null).map((value, key,index) => {
                        return  <Slide direction="up" in={true} key={key} ><div>
                            <Skeleton variant="rectangular" width={150} height={(450/2)*index} /></div></Slide>
                            
                    })} */}
                    {loadingtemplate.current &&
                        <>
                            <Skeleton variant="rectangular" width={150} height={300} />
                            <Skeleton variant="rectangular" width={150} height={100} />
                            <Skeleton variant="rectangular" width={150} height={320} />
                            <Skeleton variant="rectangular" width={150} height={200} />
                            <Skeleton variant="rectangular" width={150} height={320} />
                            <Skeleton variant="rectangular" width={150} height={120} />
                            <Skeleton variant="rectangular" width={150} height={220} />
                            <Skeleton variant="rectangular" width={150} height={170} />
                            <Skeleton variant="rectangular" width={150} height={180} />
                        </>
                    }



                </Masonry>

                {/* {filteredData.length === 0 &&
                    <>
                        {Array(10).fill(null).map((value, key) => {
                            return (
                                <Skeleton variant="rectangular" width={150} height={150} />
                            )
                        })}
                    </>} */}
                {/* <div className="template-half"> */}

                {/* {firstHalf?.map((value, key) => {
                        return (
                            <div>

                                <div className="template-global" onClick={(e) => {
                                    fetchTemplate(value.id)
                                }}>
                                    <div className="tool-container">
                                        <Tooltip

                                            title="Delete" placement="top" arrow>
                                            <DeleteForeverIcon className="delete-icon" onClick={(e) => {
                                                e.stopPropagation()
                                                deleteTemplate(value.id)

                                            }} />
                                        </Tooltip>
                                        <Tooltip
                                            title="Add to my template" placement="top" arrow>
                                            <LibraryAddIcon className="delete-icon" onClick={(e) => {
                                                e.stopPropagation()
                                                // saveAsTemplate(filesource.canvas_design)
                                                saveAsTemplate(value.id)
                                            }} />

                                        </Tooltip>

                                    </div>
                                    <img className="main-bar-image-temp" src={LABS_API + value.thumbnail_page} />
                                </div>
                              

                            </div>
                        )

                    })
                    }
                </div>
                <div className="template-half">
                    {secondHalf?.map((value, key) => {
                        return (
                            <div>
                                <div className="template-global" onClick={(e) => {
                                    // loadFabricJSONData(valueInner.json_page)
                                    fetchTemplate(value.id)
                                }}>
                                    <div className="tool-container">
                                        <Tooltip

                                            title="Delete" placement="top" arrow>
                                            <DeleteForeverIcon className="delete-icon" onClick={(e) => {
                                                e.stopPropagation()
                                                // deleteTemplate(value.id)
                                                deleteTemplate(value.id)


                                            }} />
                                        </Tooltip>
                                        <Tooltip
                                            title="Add to my template" placement="top" arrow>
                                            <LibraryAddIcon className="delete-icon" onClick={(e) => {
                                                e.stopPropagation()
                                                // saveAsTemplate(filesource.canvas_design)
                                                saveAsTemplate(value.id)
                                            }} />

                                        </Tooltip>

                                    </div>
                                    <img className="main-bar-image-temp" src={LABS_API + value.thumbnail_page} />
                                </div>
                              

                            </div>
                        )

                    })
                    } */}
                {/* </div> */}

            </div>
        </div>

    )


}

export default Template;