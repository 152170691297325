import React, { useEffect, useLayoutEffect, useState, useRef, useCallback } from "react";
// import Skeleton from '@mui/material/Skeleton';
import SearchIcon from '@mui/icons-material/Search';
import Config from "../../config/Config";
import axios from "axios";
import Skeleton from '@mui/material/Skeleton';
import Select, { components } from "react-select";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VirtualScroll from "../VirtualScroll";



const AiGeneration = (props) => {


    const {
        onAddImage,
        handleSidebarClose,
        onAddImageAi
    } = props

    const [focusOnSearch, setFocusOnSearch] = useState(false)
    const [description, setDescription] = useState('')
    const descriptionTextRef = useRef(null)
    const [creditsAvailable, setCreditsAvailable] = useState(null);
    const [addonCredit, setAddonCredit] = useState(0);
    const [subscriptionCredit, setSubscriptionCredit] = useState(0);
    const [showCreditAlertModal, setShowCreditAlertModal] = useState(false)
    const [aiImageHistoryList, setAiImageHistoryList] = useState([])
    const [isGenerateLoading, setIsGenerateLoading] = useState(false)
    const [selectedNumberOfCopies, setSelectedNumberOfCopies] = useState(null)    // default value
    const [selectedTone, setSelectedTone] = useState(null)

    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
        if (index == 2) {
            aiImageHistory()
        }
    };
    const handleDescriptionText = (e) => {
        if (e.target?.value?.length <= 600) {
            setDescription(e.target?.value)
        }
    }

    /* Get current user's purchased credits data */
    const getCreditStatus = () => {
        axios({
            url: `${Config.BASE_URL}/workspace/dashboard_credit_status`,
            auth: true,
            success: (response) => {
                setCreditsAvailable(
                    response?.data?.credits_left?.addon +
                    response?.data?.credits_left?.subscription
                );
                setAddonCredit(response?.data?.credits_left?.addon);
                setSubscriptionCredit(response?.data?.credits_left?.subscription);
            },
        });
    };
    const handleAuthTrue = (authentication) => {
        let token = Config.userStateSub != null ? Config.userStateSub.token : "";
        authentication.append("Authorization", `Bearer ${token}`)
        return authentication;
    }



    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <ArrowDropDownIcon className="arrow-icon-color" />
                </components.DropdownIndicator>
            )
        );
    };




    const getAiGeneratedImage = (selectedText) => {
        (async function () {
            setIsGenerateLoading(true)
            let formdata = new FormData();
            formdata.append('no_of_image', 1)
            formdata.append('prompt', selectedText)

            var requestOptions = {
                method: 'POST',
                headers: handleAuthTrue(new Headers()),
                body: formdata,
                redirect: 'follow'
            };

            let data = await fetch(`${Config.BASE_URL}/openai/ai_image_gen/`, requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                // setSourceLanguageOptions(response);
                // setTargetLanguageOptions(response);
                onAddImageAi(response.gen_img[0].generated_image)
                setIsGenerateLoading(false)

            }
            else {
                setIsGenerateLoading(false)
                console.log('error');
            }
        })();
        // axios({
        //     url: `${Config.BASE_URL}/openai/ai_image_gen/`,
        //     method: "POST",
        //     data: formdata,
        //     auth: handleAuthTrue(new Headers()),
        //     success: (response) => {
        //         let AiImgUrl = response.data.gen_img[0].generated_image
        //         let AiImgName = response.data.prompt
        //         console.log(AiImgName)
        //         console.log(AiImgUrl)

        //         setIsGenerateLoading(false)
        //         // createImage(window.getSelection().focusNode.parentElement,AiImgUrl,AiImgName)
        //         getCreditStatus()

        //     },
        //     error: (err) => {
        //         if(err.response.status === 400){
        //             if(err.response.data.msg == 'Insufficient Credits'){
        //                 // Config.toast('Insufficient Credits', 'warning')
        //                 setShowCreditAlertModal(true)
        //             }else{
        //                 Config.toast('No output, try again with different text', 'warning')

        //             }

        //         }
        //         if(err.response.status === 500){
        //             Config.toast('AI server is under maintenance, please try again later', 'error')
        //         }
        //         setIsGenerateLoading(false)
        //     }
        // })

    }


    const aiImageHistory = () => {
        (async function () {

            var requestOptions = {
                method: 'GET',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(`${Config.BASE_URL}/openai/image_history/`, requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                setAiImageHistoryList(response)
            }
            else {

                console.log('error');
            }
        })();
    }

    const typeSearch = useRef()

    const [inputText, setInputText] = useState("");
    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        setInputText(lowerCase);
    };


    const filteredData = aiImageHistoryList.filter((el) => {
        //if no input the return the original
        if (inputText === '') {
            return el;
        }
        //return the item which contains the user input
        else {
            return el.prompt?.toLowerCase().includes(inputText)
        }
    })


    const customProjectTypeSelectStyles = {
        placeholder: (provided, state) => ({
            ...provided,
            color: "#3C4043",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "24px",
        }),
        menu: (provided, state) => ({
            ...provided,
            padding: "6px 0px 0px 0px",
            boxShadow: "0px 3px 6px #00000029",
            border: "1px solid #DADADA",
            borderRadius: "4px",
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: "0px solid #CED4DA",
            borderLeft: "2px solid transparent",
            color: state.isSelected ? "#ffffff" : state.isDisabled ? "#cccccc" : "#7E7E7E",
            background: state.isSelected ? "#F4F5F7" : "#ffffff",
            display: "flex",
            marginBottom: "0.2rem",
            padding: "4px 6px",
            color: "#292929",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "24px",
            "&:hover": {
                background: "#F4F5F7",
                borderLeft: "2px solid #0074D3",
                cursor: "pointer",
            },
        }),
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? "1px solid #0074D3" : "1px solid #D3D8DC",
            outline: state.isFocused ? "1px solid #0074D3" : "none",
            backgroundColor: state.isSelected ? "#ffffff" : state.isDisabled ? "#cccccc" : "#7E7E7E",
            // backgroundColor: "#FFFFFF",
            borderRadius: 4,
            transtion: 0.3,
            color: "#222222",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontWeight: "500",
            lineHeight: "24px",
            boxShadow: 0,
            padding: "0px",
            // width: 150,
            height: state.isFocused ? 42 : 42,
            "&:hover": {
                cursor: "pointer",
            },
        }),
        menuList: (base) => ({
            ...base,
            // height: "100px",

            "::-webkit-scrollbar": {
                width: "8px"
            },
            "::-webkit-scrollbar-track": {
                background: "transparent"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#DADDE0",
                border: "8px solid #DADDE0 !important",
                borderRadius: "50px",
            },

        }),
    };

    let copiesOptions = [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
    ]

    let toneOptions = [
        { label: '256 x 256', value: 256 },
        { label: '512 x 512', value: 512 },
        { label: '1080 x 1080', value: 1080 },
    ]


    const AiGenList = filteredData.map((file, key) => {
        return (
            <div key={key} className="grid-Inpaint-upload">
                <div className="upoad-image-details">
                    <img className="inpaint-images" src={file.gen_img[0].generated_image} onClick={(e) => {
                        onAddImage(e,'png')}} onDragEnd={(e) => {
                            onAddImage(e,'png')}} />
                    <div className="image-details">
                        <span className="details-ai-tab"><span className="heading-image-ai-tab">Discription: </span><span className="name-image-ai-tab">{file.prompt}</span></span>
                    </div>
                </div>
            </div>
        )
    })

    return (
        <>
            <div className="container graphictab">
                <div className="bloc-tabs tab-heading">
                    <button className={toggleState === 1 ? "tabs active-tabs" : "tabs"} onClick={() => { toggleTab(1) }}>
                        AI image generation
                    </button>
                    <button className={toggleState === 2 ? "tabs active-tabs" : "tabs"} onClick={() => { toggleTab(2) }}  >
                        AI images
                    </button>
                </div>
                <div className="sidebar-content">
                    {/* <div className="sidebar-tabs-heading">
                        <span>AI image</span>
                        <CloseIcon className="close-icon" onClick={handleSidebarClose} />
                    </div> */}
                    {toggleState != 1 &&
                        <div className={focusOnSearch ? "main-tab-search active-search-bar" : "main-tab-search"}>
                            <SearchIcon className="seacrh-icon" onClick={inputHandler} />
                            <span className="search-placeholder-text">
                                <input type="text" onKeyDown={inputHandler} onClick={() => {
                                    setFocusOnSearch(true)
                                }} onBlur={(e) => {
                                    setFocusOnSearch(false);
                                }} className="searcher" ref={typeSearch} onChange={inputHandler} placeholder="Search..." />
                            </span>
                        </div>
                    }
                    {toggleState == 1 && <div className="ai-image-gen-wrapper">
                        <div className="title">
                            <span>
                                Describe
                                <span className="asterik-symbol">*</span>
                            </span>
                            <span className="words-count">{description?.length ? description?.length : 0}/600</span>
                        </div>
                        <textarea
                            // placeholder={subCategoryRef.current?.find(each => each.id === selectedSubCategory?.value)?.sub_category_fields[0]?.help_text} 
                            ref={descriptionTextRef}
                            value={description}
                            onChange={handleDescriptionText}
                            className="prompt-generating-textarea"
                        />
                         <div className="tone-generatint-form-options last-select-row">
                            <div className="content-generatint-form-options">
                                <div>Select resolution</div>
                                <Select
                                    style={{ background: 'red' }}
                                    menuPlacement={'auto'}
                                    components={{ DropdownIndicator }}
                                    options={toneOptions}
                                    value={selectedTone}
                                    placeholder='256 x 256'
                                    isDisabled={true}
                                    default={toneOptions[0]}
                                    styles={customProjectTypeSelectStyles}
                                    onChange={(selected) => setSelectedTone(selected)}
                                />
                            </div>
                            <div className="content-generatint-form-options">
                                <div>Copies</div>
                                <Select
                                    menuPlacement={'auto'}
                                    components={{ DropdownIndicator }}
                                    value={selectedNumberOfCopies}
                                    default={copiesOptions[0]}
                                    placeholder='1'
                                    isDisabled={true}
                                    options={copiesOptions}
                                    styles={customProjectTypeSelectStyles}
                                    onChange={(selected) => setSelectedNumberOfCopies(selected)}
                                />
                            </div>
                        </div>
                        <button className="aiGeneratingButtonFull" onMouseUp={() => { getAiGeneratedImage(description) }} disabled={isGenerateLoading}>
                            <div className="generating-button paste-but" style={{ display: "flex", alignItem: "center", gap: "5px" }}>
                                <div className="text-center">
                                    {isGenerateLoading && (
                                        <div className="save-btn-spinner">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    )}

                                    {isGenerateLoading ? "Generating" : "Generate"}
                                </div>
                                <img src={Config.HOST_URL + "media/icons/doubleArrow.svg"} className="generate-icon" />
                            </div>
                        </button>
                       
                    </div>}
                    {toggleState == 2 &&
                        <div className="grid-inpaint grid-ai-history-height">
                            {aiImageHistoryList == '' &&
                                <>
                                    {Array(7).fill(null).map((value, key) => {
                                        return (
                                            <div key={key} className="grid-Inpaint-upload">
                                            <div className="upoad-image-details">
                                            <Skeleton variant="rectangular" width={150} height={150} />
                                                <div className="image-details">
                                                    <span className="details-ai-tab"><span className="heading-image-ai-tab">
                                                    <Skeleton variant="text" width={82} height={20} />
                                                        </span>
                                                    <span className="name-image-ai-tab">
                                                    <Skeleton variant="text" width={140} height={20} />
                                                    <Skeleton variant="text" width={140} height={20} />
                                                    <Skeleton variant="text" width={70} height={20} />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    })}
                                </>}

                            {(filteredData.length === 0) &&
                                <span className="no-result-found">No Result found</span>
                                
                            }
                            {/* {filteredData.map((file, key) => {
                                return (
                                    <div key={key} className="grid-Inpaint-upload">
                                        <div className="upoad-image-details">
                                            <img className="inpaint-images" src={file.gen_img[0].generated_image} onClick={onAddImage} onDragEnd={onAddImage} />
                                            <div className="image-details">
                                                <span className="details-ai-tab"><span className="heading-image-ai-tab">Discription: </span><span className="name-image-ai-tab">{file.prompt}</span></span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })} */}
                             <VirtualScroll
                            height="82vh"
                            totalElements = {filteredData.length}
                            rowHeight={150}
                            items={AiGenList}
                            visibleItemsLength={10}
                            >
                            </VirtualScroll>
                        </div>
                    }
                </div>
            </div>

        </>

    )

}

export default AiGeneration;