import React, { useEffect, useLayoutEffect, useState, useRef, useCallback } from "react";
import Skeleton from '@mui/material/Skeleton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import useFetch from "./photofetch";
import Config from "../../config/Config";
import Slide from '@mui/material/Slide';



const Photos = (props) => {
    const{
        handleSidebarClose,
        // setPhotosearchword,
        fectchPhotos,
        images,
        onAddImage,
        onDragAddImage,
        debounce
    } =props

    const photosearchinputer = useRef()
    const [photosearchword, setPhotosearchword] = useState('dogs')
    const [focusOnSearch, setFocusOnSearch] = useState(false)

    const fetchImageOnSearch = (e) => {
        if (e.key === 'Enter') {
            if ((e.target.value.length > 0) && (e.target.value != ' ')) {
                fectchPhotos()
            }
            else {
                setPhotosearchword('dog')
            }
        }
    }

    const handleSearchChange = (e) => {
        if (e.target.value.length > 0 && e.target.value != ' ') {
            setPhotosearchword(e.target.value)
        }
        else {
            setPhotosearchword('dog')
        }
    }



    const [oddpagephotos, setoddpagephotos] = useState(1);
    const [evenpagephotos, setEvenpagephotos] = useState(2);
    const { loading, error, list , firstoddhalf ,secondevenhalf  } = useFetch(photosearchword, oddpagephotos, evenpagephotos );
    const loader1 = useRef(null);
    const loader2 = useRef(null);
  
 
  
    const handleObserver = useCallback((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        setoddpagephotos((prev) => prev + 2);
        setEvenpagephotos((prev) => prev + 2)
      }
    }, []);
    
    useEffect(() => {
      const option = {
        root: null,
        rootMargin: "20px",
        threshold: 0
      };
      const observer = new IntersectionObserver(handleObserver, option);
      if (loader1.current) observer.observe(loader1.current);
      if (loader2.current) observer.observe(loader2.current);
    }, [handleObserver]);


    

    return (
        <div className="sidebar-content">
            <div className="sidebar-tabs-heading">
                <span>Photos</span>
                {/* <CloseIcon className="close-icon" onClick={handleSidebarClose} /> */}
            </div>
            <div className={focusOnSearch ? "main-tab-search active-search-bar" : "main-tab-search"}>
                <SearchIcon className="seacrh-icon" />
                <span className="search-placeholder-text">
                    <input type="text" 
                    onKeyDown={(e) => {fetchImageOnSearch(e) }} 
                    onClick={() => {
                        setFocusOnSearch(true)
                    }} 
                    onBlur={(e) => {
                        setFocusOnSearch(false);
                    }} 
                    className="searcher" 
                    ref={photosearchinputer} 
                    onChange={(e) => {debounce(handleSearchChange,e)}} 
                    placeholder="Search..." />
                </span>
            </div>
            <div className=" grid-photos api-photos">
                <div className="odd-photos">
                    {firstoddhalf?.map((item, i) => (
                        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                            <img className="images-section" src={item.previewURL} data-id={item.fullHDURL} onClick={(e) => {
                        onAddImage(e,'png')}} onDragEnd={(e) => {
                            onAddImage(e,'png')}} alt="" />
                        </Slide>
                    ))}
                    loading ...
                    <div ref={loader1} />

                    <Skeleton variant="rectangular" width={160} height={50} />
                    <Skeleton variant="rectangular" width={160} height={200} />
                    <Skeleton variant="rectangular" width={160} height={320} />
                    <Skeleton variant="rectangular" width={160} height={150} />
                    <Skeleton variant="rectangular" width={160} height={300} />
                    <Skeleton variant="rectangular" width={160} height={100} />
                    {error && <p>Error!</p>}

                </div>
                <div className="even-photos">
                    {secondevenhalf?.map((item, i) => (
                        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                            <img className="images-section" src={item.previewURL} data-id={item.fullHDURL} onClick={(e) => {
                        onAddImage(e,'png')}} onDragEnd={onDragAddImage} alt="" />
                        </Slide>
                    ))}
                    <div ref={loader2} />
                    loading ... 
                    <Skeleton variant="rectangular" width={160} height={150} />
                    <Skeleton variant="rectangular" width={160} height={300} />
                    <Skeleton variant="rectangular" width={160} height={100} />
                    <Skeleton variant="rectangular" width={160} height={320} />
                    <Skeleton variant="rectangular" width={160} height={200} />
                    <Skeleton variant="rectangular" width={160} height={320} />
                    {error && <p>Error!</p>}
                </div>



            </div>
            
        </div>

    )


}

export default Photos;



 {/* {images?.map((file, key) => {
                        return (
                            <div key={key} className="grid-item-images" >
                                <img className="images-img" src={file.previewURL} data-id={file.fullHDURL} onClick={onAddImage} onDragEnd={onAddImage} />
                            </div>
                        )
                    })} */}
                {/* {firstoddhalf?.map((item, i) => (
                    <img className="images-section"  src={item.fullHDURL} alt="" />
                ))} */}
                {/* { loading &&
                    <>
                        <Skeleton variant="rectangular" width={150} height={150} />
                        <Skeleton variant="rectangular" width={150} height={150} />
                        <Skeleton variant="rectangular" width={150} height={150} />
                        <Skeleton variant="rectangular" width={150} height={150} />
                        <Skeleton variant="rectangular" width={150} height={150} />
                        <Skeleton variant="rectangular" width={150} height={150} />
                        <Skeleton variant="rectangular" width={150} height={150} />
                        <Skeleton variant="rectangular" width={150} height={150} />
                        <Skeleton variant="rectangular" width={150} height={150} />
                        <Skeleton variant="rectangular" width={150} height={150} />
                        <Skeleton variant="rectangular" width={150} height={150} />
                    </>
                } */}