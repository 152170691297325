import HeaderCanvas from '../layouts/HeaderCanvas'
import React, { useEffect, useState, useRef } from "react"
import Sidebar from '../components/Sidebar'
import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import Toolbar from '../components/Toolbar'
import Footer from '../components/Footer';
import Config from "../config/Config";
import { useNavigate } from "react-router-dom";
import PagesBar from '../components/sidebarcomponents/PagesBar';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Layers from '../components/sidebarcomponents/Layers';
import GraphemeSplitter from 'grapheme-splitter'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from '@mui/material/Tooltip';
import FontFaceObserver from 'fontfaceobserver';
import { ReactComponent as SideBarToggle } from '../assets/icons/SideBarToggle.svg'
import { useLocation } from 'react-router-dom';
import Select, { components } from "react-select";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AlignGuidelines } from "fabric-guideline-plugin";
import Guides from "@scena/react-guides";
import Gesto from "gesto";
import TranslateIcon from '@mui/icons-material/Translate';
import SettingsIcon from '@mui/icons-material/Settings';
import Popover from '@mui/material/Popover';
import 'fabric-history';

const AilaysaCanvasEditor = (props) => {

    const {
        setTabSwitch,
        setTab,
        setJsdata,
    } = props;

    const [undoHistory, setUndoHistory] = useState([]);
    const [redoHistory, setRedoHistory] = useState([]);
  
   

    const [designid, setDesignid] = useState(null)
    const [translatedImageid, setTranslatedImageid] = useState(null)
    const [selected, setselected] = useState([])
    const [selectedPage, setSelectedPage] = useState()
    const canvasdiv = useRef()
    const contsiner1ref = useRef()
    const [canvaswidth, setCanvaswidth] = useState(800)
    const [canvasheight, setCanvasheight] = useState(600)
    const [scaleCanvas, setScaleCanvas] = useState(1)
    const [canvasBgColor, setCanvasBgColor] = useState('rgba(255,255,255,1)')
    const [backgroundEdit, setBackgroundEdit] = useState(false)
    const [backgroundConfirmation, setBackgroundConfirmation] = useState(false)
    const [tabIndex, setTabIndex] = useState(16);
    const [activeFontFamilyShow, setActiveFontFamilyShow] = useState()
    const [name, setName] = useState('Untitled Project')
    const [canvasmodified, setCanvasmodified] = useState(false)
    const [autoSaved, setAutoSaved] = useState(false)
    const [designtemp, setDesigntemp] = useState(false)
    const [translateimagetemp, setTranslateimagetemp] = useState(false)
    const [pageNo, setPageNo] = useState(1)
    const [pageData, setPageData] = useState()
    const [pageTab, setPagetab] = useState(false)
    const LABS_API = Config.LABS_API
    const [pageSwitch, setPageSwitch] = useState(false)
    const [imageTransleSource, setImageTransleSource] = useState(null)
    const [cropImageSource, setCropImageSource] = useState(null)
    const [enablesnap, setEnablesnap] = useState(false)
    const shadow = new fabric.Shadow({
        color: 'rgba(0,0,0,0.8)',
        blur: 0.01,
    });
    const bgopacity = useRef('rgba(255,255,255,0.1)')
    const { selectedObjects,editor, onReady } = useFabricJSEditor();
    const [autoChecked, setAutoChecked] = useState(false);
    const [manualChecked, setManualChecked] = useState(true);
    const URL_SEARCH_PARAMS = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const [isSaved, setIsSaved] = useState(true)
    const main = useRef(true)
    var clipPath
    var tempcall = useRef(true)
    var toggleedittodrag = useRef(false)
    const typingTimeout = useRef(0);
    const typing = useRef(false);
    const canvascontainer = useRef();
    var clientWidth = canvascontainer?.current?.clientWidth
    var clientHeight = canvascontainer?.current?.clientHeight
    const zoomSize = 0.80
    const scaleMultiplierX = useRef()
    const scaleMultiplierY = useRef()
    const firsttimeparamsload = useRef(true)
    const { state } = useLocation();
    const getsodrag = useRef()
    const [translateLanguages, setTranslateLanguages] = useState(null)
    const [renderrer, setRerender] = useState()


    // ruler
    const horizonalGuidesRef = useRef();
    const verticalGuidesRef = useRef();
    const ruleonoff = useRef(true)
    const scrollx = useRef(0)
    const scrollXextrascroll = useRef(0)
    const scrolly = useRef(0)
    const scrollYextrascroll = useRef(0)
    const zoomvalue = useRef(1)
    const [horizontalGuides, setHorizontalGuides] = useState([]);
    const [verticalGuides, setVerticalGuides] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState(null);
    const [pageId, setPageId] = useState()
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);

    function save() {
        // clear the redo stack
        const tempdata = editor.canvas.toJSON(['id','projectid', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable','brushtype','brushtype2','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name','oldWidth','oldHeight','oldScaleX','oldScaleY']);
        // initial call won't have a state
          setUndoStack((stack) => [...stack, tempdata]);
            setRedoStack([])

        }

    const [newPageChecked, setNewPageChecked] = useState(false)

    const saveStateToHistory = () => {
        // const json = JSON.stringify(editor.canvas.toJSON());
        const json = editor.canvas.toJSON(['id','projectid', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable','brushtype','brushtype2','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name','oldWidth','oldHeight','oldScaleX','oldScaleY']);
        setUndoHistory([...undoHistory, json]);
        setRedoHistory([]);
      };

    // const fetchJson = () => {
    //     fetch('https://api.polotno.dev/templates/2021-10-16-hd-welcome-shool.json')
    //     .then(response => {
    //       return response.json();
    //     }).then(data => {
    //       console.log(data);
    //     })
    //   }

    useEffect(() => {
        toggleruler()
    }, [])

    const handleAuthTrue = (authentication) => {
        let token = Config.userState != null ? Config.userState.token : "";
        authentication.append("Authorization", `Bearer ${token}`)
        return authentication;
    }

    function loadFabricJSONData(file, jsonData) {
        // Get all font family names from the JSON data, including nested groups
        document.querySelector('.canvas-space').classList.add('loading-json-blur');
        document.querySelector('#loading-wrapper').style.setProperty('display', 'grid');
        const fontFamilies = [];

        function extractFontFamilies(obj) {
            if (obj.fontFamily && !fontFamilies.includes(obj.fontFamily)) {
                fontFamilies.push(obj.fontFamily);
            }
            if (obj.type === 'group' && obj.objects) {
                obj.objects.forEach((groupObj) => extractFontFamilies(groupObj));
            }
        }

        jsonData.json.objects.forEach((obj) => extractFontFamilies(obj));

        // Remove duplicate font families
        const uniqueFontFamilies = [...new Set(fontFamilies)];
        // Check if all font families are loaded in the DOM
        uniqueFontFamilies.forEach((fontFamily) => {
            var link = document.createElement('link')
            link.href = `https://fonts.googleapis.com/css?family=${fontFamily}`
            link.rel = 'stylesheet'
            link.setAttribute('name', fontFamily)
            document.querySelector('head').appendChild(link)
        });
        if (uniqueFontFamilies.length == 0) {
            loadDesign(file, jsonData)
            
        }
        // If all font families are loaded, load the JSON data in the canvas
        uniqueFontFamilies.forEach((obj, index) => {
            var font = new FontFaceObserver(obj);
            font.load().then(function () {
                console.log('Font is available');
                if (index == (uniqueFontFamilies.length - 1)) {
                    setTimeout(() => {
                        loadDesign(file, jsonData)

                    }, 1000);
                }


            }, function () {
                console.log('Font is not available');
                font.load().then(function () {
                    console.log('Font is available');
                    setTimeout(() => {
                        loadDesign(file, jsonData)

                    }, 1000);
                }, function () {
                    console.log('Font is not available');
                });
            });
        })
    }


    const notify = () => toast.info('Inpaint space is cleared. Space is setup now for Image Design ', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",

    });


    const handleConfirmBackGround = () => {
        let backGround =  editor.canvas.getObjects().find(obj => obj.name == 'Background-new')
        // console.log(Math.abs(backGround?.left));
        // console.log(Math.abs(backGround?.top));
        // console.log(editor.canvas.backgroundImage);
        var rectclip = new fabric.Rect({ width: Math.floor(canvaswidth), height: Math.floor(canvasheight),absolutePositioned:true});
        if(backGround){
            backGround.set({
                id: 'background',
                name: 'Background-current',
                lockMovementX: true,
                lockMovementY: true,
                editable: false,
                selectable: false,
                evented: false,
                originalwidth:backGround.width,
                originalheight:backGround.height,
                originalleft:backGround.left,
                originaltop:backGround.top,
                // cropX:Math.abs(editor.canvas.getActiveObject().left),
                // cropY:Math.abs(editor.canvas.getActiveObject().top),
                // top:0,
                // left:0,
                clipPath:rectclip,
            })
            backGround.setCoords();
            editor.canvas.background = ""
            editor.canvas.requestRenderAll()
        }
    }


    useEffect(()=>{
        if(editor){
            if(window.location.pathname.includes('image-translate')){
                editor.canvas.selection = false
                // editor.canvas.isDrawingMode = true
            }
            else{
                editor.canvas.isDrawingMode = false
                editor.canvas.selection = true
        }
        }
    },[window.location.pathname])

    useEffect(()=>{
        console.log(selectedObjects);
    },[selectedObjects])


    const getMyDesignbyId = async (id) => {

        var requestOptions = {
            method: 'GET',
            headers: handleAuthTrue(new Headers()),
            redirect: 'follow'
        };

        let data = await fetch(LABS_API + `/canvas/canvas-designs/${id}`, requestOptions)
        if (data.status === 200) {
            let response = await data.json()
            return response
        }
        else {
            return 'Id not exist'
        }
    }

    var splitter = new GraphemeSplitter();

    const getMyImageTranslationbyId = async (id) => {

        var requestOptions = {
            method: 'GET',
            headers: handleAuthTrue(new Headers()),
            redirect: 'follow'
        };

        let data = await fetch(LABS_API + `/image/imageupload/${id}`, requestOptions)
        if (data.status === 200) {
            let response = await data.json()
            return response
        }
        else {
            return 'Id not exist'
        }
    }





    const undo = () => {

        editor.canvas.undo();
        // editor.canvas.renderAll();

    }


    const redo = () => {

        editor.canvas.redo();
        // editor.canvas.renderAll();


    }



    var splitter = new GraphemeSplitter();

    // const getMyUploadImageTranslate = () => {
    //     (async function () {


    //         var requestOptions = {
    //             method: 'GET',
    //             headers: handleAuthTrue(new Headers()),
    //             redirect: 'follow'
    //         };

    //         let data = await fetch(LABS_API + "/image/imageloadlist/", requestOptions)
    //         if (data.status === 200) {
    //             let response = await data.json()
    //             // setUploadimgForImageTranslate(response.reverse())

    //         }
    //         else {

    //             // console.log('error');
    //         }
    //     })();


    // }
    const loadTranslatedImage = (e) => {
        setDesignid(null)
        if (e.image_inpaint_creation.length > 0) {

        }
        else {
            navigate(`/ailaysa-canvas/image-translate/?project=${e.id}`)

            setName(e.project_name)
            setTranslatedImageid(e.id)
            setManualChecked(true)
            setCanvasheight(e.height)
            setCanvaswidth(e.width)
            setCropImageSource(LABS_API + '/' + e.image)

            editor.canvas.loadFromJSON(e.mask_json, function () {
                editor.canvas.renderAll.bind(editor.canvas.renderAll());

            })
            var size = size = { name: 'loadcanvas', width: e.width, height: e.height, fill: 'rgba(255,255,255,1)' }
            handlesize(size)
        }

    }

    if (editor) {
        editor.canvas.background = 'rgba(0,0,0)'
    }

    // console.log(state)
    const addImageOcr = (ocrImage, id) => {


        const img = new Image();
        img.src = ocrImage;

        img.onload = function () {

            let clipPath = new fabric.Rect({ width: this.height, height: this.width, dirty: false, fill: 'rgba(255,255,255,1)', objectCaching: false, strokeWidth: 0, shadow: shadow });
            editor.canvas.backgroundImage = clipPath
            setCanvaswidth(this.width);
            setCanvasheight(this.height)
            editor.canvas.backgroundImage.width = this.width
            editor.canvas.backgroundImage.height = this.height
            var size = size = { name: 'loadcanvas', width: this.width, height: this.height }

            handlesize(size)
        }
        editor.canvas.renderAll()

        setImageTransleSource(id);
        setCropImageSource(ocrImage)
        var filterBrightness = new fabric.Image.filters.Brightness({
            brightness: 0,
        });
        var filterContrast = new fabric.Image.filters.Contrast({
            contrast: 0,
        });
        var filterSaturation = new fabric.Image.filters.Saturation({
            saturation: 0,
        });
        var filterBlur = new fabric.Image.filters.Blur({
            blur: 0,
        });
        var filterVibrance = new fabric.Image.filters.Vibrance({
            vibrance: 0,
        });

        var filterNoice = new fabric.Image.filters.Noise({
            noise: 0,
        });

        var filterHue = new fabric.Image.filters.HueRotation({
            rotation: 0,
        });
        // var filterPixalate = new fabric.Image.filters.Pixelate({
        //     blocksize: 0,
        // });
        var filterBlackandWhite = new fabric.Image.filters.BlackWhite();


        fabric.Image.fromURL(ocrImage, (bg) => {

            var obg = bg.set({

                top: 0,
                left: 0,
                id: 'background',
                name: 'Background-static',
                lockMovementX: true,
                lockMovementY: true,
                editable: false,
                selectable: false,
                evented: false,
                globalCompositeOperation: 'source-atop',
                objectCaching: false,

            })


            obg.filters.push(filterBrightness, filterContrast, filterSaturation, filterBlur, filterVibrance, filterNoice, filterHue)
            editor.canvas.sendToBack(obg)
            editor.canvas.renderAll();


        }, { crossOrigin: 'anonymous' });
        document.querySelector('.canvas-space').classList.remove('loading-json-blur');
        document.querySelector('#loading-wrapper').style.setProperty('display', 'none');
        // setTabIndex(9)


    }








    // check params to load design
    useEffect(() => {

        if (editor != undefined && firsttimeparamsload.current == true) {
            (async function () {
                if (URL_SEARCH_PARAMS.get('project')) {
                    if (window.location.pathname == '/ailaysa-canvas/design/') {
                        let file = await getMyDesignbyId(URL_SEARCH_PARAMS.get('project'))
                        if (file != 'Id not exist') {
                            loadFabricJSONData(file, file.source_json[0])
                            setDesigntemp(true)
                            setDesignid(URL_SEARCH_PARAMS.get('project'))
                            editor.canvas.selection = true
                            main.current = false
                        }
                        else {
                            navigate('/ailaysa-canvas')
                            editor.canvas.selection = true

                        }
                    } else if (window.location.pathname == '/ailaysa-canvas/image-translate/') {
                        let file = await getMyImageTranslationbyId(URL_SEARCH_PARAMS.get('project'))
                        loadTranslatedImage(file)
                        editor.canvas.selection = false
                        main.current = false
                        console.log('loading current translate')
                    } else {
                        navigate('/ailaysa-canvas')
                        editor.canvas.selection = true

                    }

                }
                else {
                    if (window.location.pathname == '/ailaysa-canvas/template/') {
                        // console.log('rannn')

                        editor?.canvas.loadFromJSON(state, function () {
                            editor?.canvas.renderAll.bind(editor?.canvas.requestRenderAll());
                        })
                        var size = { name: 'loadcanvas', width: state.backgroundImage.width, height: state.backgroundImage.height, fill: state.backgroundImage.fill }
                        handlesize(size)
                        // editor.canvas.backgroundImage = new fabric.Rect({ width: json.backgroundImage.width, height: json.backgroundImage.height, fill: json.backgroundImage.fill });
                        setCanvasBgColor(state.backgroundImage.fill)
                        setTimeout(() => {
                            document.querySelector('.canvas-space').classList.remove('loading-json-blur');
                            document.querySelector('#loading-wrapper').style.setProperty('display', 'none');
                        }, 500);
                    } else if (window.location.pathname == '/ailaysa-canvas/image-translate/') {
                        let id = URL_SEARCH_PARAMS.get('image-translate')
                        // loadTranslatedImage(file)
                        // getMyUploadImageTranslate()
                        document.getElementsByClassName('react-tabs__tab')[9].click()
                        // console.log(document.getElementsByClassName('react-tabs__tab')[9])
                        addImageOcr(state, id)
                    }
                }
                firsttimeparamsload.current = false
            })()
        }
    }, [editor])

    if (editor && main.current == true) {
        editor.canvas.setDimensions({ width: clientWidth, height: clientHeight })
        editor.canvas.calcViewportBoundaries();
        editor.canvas.setWidth(canvascontainer.current.clientWidth)
        editor.canvas.setHeight(canvascontainer.current.clientHeight)
        editor.canvas.objectPooling = true;
        editor.canvas.enableRetinaScaling = false;
        editor.canvas.backgroundColor = bgopacity.current
        editor.canvas.imageSmoothingEnabled = true
        editor.canvas.skipOffscreen = true
        editor.canvas.preserveObjectStacking = true;
        editor.canvas.projectid = null

        // fabric.Object.prototype.objectCaching = false;
        fabric.Object.prototype.statefullCache = false;
        fabric.Object.prototype.noScaleCache = true;
        fabric.Object.prototype.borderColor = '#BD8AF5'
        fabric.Object.prototype.borderScaleFactor = 2
        fabric.Object.prototype.cornerStyle = "circle"
        fabric.Object.prototype.cornerColor = '#FFFFFF'
        fabric.Object.prototype.cornerSize = 9
        fabric.Object.prototype.cornerStrokeColor = '#00000029'
        fabric.Object.prototype.transparentCorners = false
        setBackgroundEdit(false)
        fabric.Group.prototype.borderDashArray = [5, 10];
        editor.canvas.renderAll();


        editor.canvas.calcOffset()

        const center = editor.canvas.getCenter();
        editor.canvas.selectionColor = 'rgba(17,119,255,0.6)'
        editor.canvas.selectionBorderColor = 'rgba(255, 255, 255, 1)'
        editor.canvas.editingBorderColor = 'rgba(102,153,255,1)'
        main.current = false
        editor.canvas.requestRenderAll()

        //     //overlay
        clipPath = new fabric.Rect({
            width: canvaswidth, height: canvasheight, dirty: false, fill: canvasBgColor, objectCaching: false, strokeWidth: 0, shadow: shadow
        });



        if (tempcall.current) {
            // centerObject();
            editor.canvas.backgroundImage = clipPath
            // editor.canvas.controlsAboveOverlay = true;
            var zoom = Math.min(editor.canvas.height / canvasheight, editor.canvas.width / canvaswidth) * zoomSize
            editor.canvas.zoomToPoint(new fabric.Point(editor.canvas.width / 2, editor.canvas.height / 2), zoom)
            editor.canvas.renderAll()

            editor.canvas.viewportTransform[4] = 0
            editor.canvas.viewportTransform[5] = 0

            // viewport adjusment
            editor.canvas.relativePan({ x: Math.abs(editor?.canvas?.getCenter().left - (editor.canvas.backgroundImage.getBoundingRect().width * editor.canvas.getZoom()) / 2), y: Math.abs(editor?.canvas?.getCenter().top - (editor.canvas.backgroundImage.getBoundingRect().height * editor.canvas.getZoom()) / 2) });
            // editor.canvas.relativePan({ x: Math.abs(editor?.canvas?.getCenter().left - (editor.canvas.clipPath.width * editor.canvas.clipPath.zoomX) / 2), y: Math.abs(editor?.canvas?.getCenter().top - (editor.canvas.clipPath.height * editor.canvas.clipPath.zoomY) / 2) });

            fabric.Object.prototype.borderOpacityWhenMoving = 1;
            const guideline = new AlignGuidelines({
                canvas: editor.canvas,
                // pickObjTypes: [{ key: "myType", value: "box" }],
                aligningOptions: {
                    lineColor: "#0074d3",
                    lineWidth: 2,
                    lineMargin: 2,
                },
            });
            guideline.init();


            // var clipPath = new fabric.Rect({ width: 500, height:500, top: 50, left: 50, fill: '#FFFFFF' });
            // editor.canvas.clipPath = clipPath
            // editor.canvas.controlsAboveOverlay = true;
            // editor.canvas.selectionColor = 'rgba(17,119,255,0.6)'
            // editor.canvas.selectionBorderColor = 'rgba(255, 255, 255, 1)'
            // editor.canvas.editingBorderColor = 'rgba(102,153,255,1)'


            editor.canvas.renderAll()
            zoomvalue.current = editor.canvas.getZoom()

            // ruler 
            scrollx.current = -((editor.canvas.viewportTransform[4] - 30) / editor.canvas.getZoom())
            scrolly.current = -((editor.canvas.viewportTransform[5] - 30) / editor.canvas.getZoom())
            horizonalGuidesRef.current.resize(editor.canvas.getZoom())
            verticalGuidesRef.current.resize(editor.canvas.getZoom())
            horizonalGuidesRef.current.scroll(scrollx.current, editor.canvas.getZoom());
            horizonalGuidesRef.current.scrollGuides(scrolly.current, editor.canvas.getZoom())
            verticalGuidesRef.current.scroll(scrolly.current, editor.canvas.getZoom());
            verticalGuidesRef.current.scrollGuides(scrollx.current, editor.canvas.getZoom());

            tempcall.current = false
        }

        console.log('viewport ' + '\n' + ' left : ', editor.canvas.viewportTransform[4] + '\n' + ' top : ', editor.canvas.viewportTransform[5])
        editor.canvas.requestRenderAll()

    }

    const loadDesign = (file, filesource) => {
        setDesigntemp(true)

        if (file.canvas_translation.length > 0) {

        }
        else {

            setName(file.file_name)
            setDesignid(filesource.canvas_design)
            setTranslatedImageid(null)
            setCanvaswidth(file.source_json[0].json.backgroundImage.width)
            setCanvasheight(file.source_json[0].json.backgroundImage.height)
            setPageNo(filesource.page_no)
            setPageData(file.source_json)
            // if (typeof (filesource.json) == 'string') {
            //     setCanvasBgColor(JSON.parse(filesource.json).background)
            // }
            // else {
            //     setCanvasBgColor(filesource.json.background)
            // }
            setCanvasBgColor(file.source_json[0].json.backgroundImage.fill)
            editor.canvas.renderAll()

            editor?.canvas?.loadFromJSON(filesource.json, function () {
                editor.canvas.renderAll.bind(editor.canvas.renderAll());
            })
            var size = { name: 'loadcanvas', width: file.source_json[0].json.backgroundImage.width, height: file.source_json[0].json.backgroundImage.height, fill: file.source_json[0].json.backgroundImage.fill }
            handlesize(size)
            navigate(`/ailaysa-canvas/design/?project=${filesource.canvas_design}`)
            document.querySelector('.canvas-space').classList.remove('loading-json-blur');
            document.querySelector('#loading-wrapper').style.setProperty('display', 'none');
            // editor.canvas.backgroundImage = new fabric.Rect({ width: file.source_json[0].json.backgroundImage.width, height: file.source_json[0].json.backgroundImage.height,fill:file.source_json[0].json.backgroundImage.fill });
        }
        // setSendThumb(filesource.canvas_design)

    }


    function generateUID(length) {
        return window.btoa(String.fromCharCode(...window.crypto.getRandomValues(new Uint8Array(length * 2)))).replace(/[+/]/g, "").substring(0, length);
    }

    function makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const Duplicatecopyobj = useRef(null)
    editor?.canvas?.getActiveObject()?.clone(function (cloned) {
        Duplicatecopyobj.current = cloned;
    });
    const DuplicateCopy = () => {

    }


    const DuplicatePaste = () => {
        // clone again, so you can do multiple copies.
        Duplicatecopyobj.current.clone(function (clonedObj) {
            editor.canvas.discardActiveObject();
            clonedObj.set({
                name: 'Duplicate_' + generateUID(5) + makeid(6),
                left: clonedObj.left + 10,
                top: clonedObj.top + 10,
                evented: true,
                status: "Unconfirmed"
            });
            if (clonedObj.type === 'activeSelection') {
                // active selection needs a reference to the canvas.
                clonedObj.canvas = editor.canvas;
                clonedObj.forEachObject(function (obj) {
                    editor.canvas.add(obj);
                });
                // this should solve the unselectability
                clonedObj.setCoords();
            } else {
                editor.canvas.add(clonedObj);
            }
            Duplicatecopyobj.current.top += 10;
            Duplicatecopyobj.current.left += 10;
            editor.canvas.setActiveObject(clonedObj);
            editor.canvas.requestRenderAll();
        });
    }
    const duplicate = () => {
        DuplicateCopy();
        DuplicatePaste()
    }


    const getJsonData = () => {

        // setCanvasBgColor('#000000')
        editor.canvas.getObjects('path').forEach(e => {
            if (e.globalCompositeOperation == 'source-over') {
                e.set({ stroke: '#fff' })
            }
            else {
                e.globalCompositeOperation = 'source-over'
                e.set({ stroke: '#000' })
                // editor.canvas.remove(e)
            }
        });
        editor.canvas.renderAll()


        setTimeout(() => {
            const base64 = editor.canvas.toDataURL({
                format: 'png',
                enableRetinaScaling: true
            });
            const link = document.createElement("a");
            link.href = base64;
            link.download = 'sample.png';
            setJsdata(base64)
            link.click();
            setTabSwitch("AilaysaLanguageSelectPage")
        }, 2000);



    }

    var splitter = new GraphemeSplitter();

    const _splitTextIntoLines = function (text) {
        var lines = text.split(this._reNewline),
            newLines = new Array(lines.length),
            newLine = ['\n'],
            newText = [];
        for (var i = 0; i < lines.length; i++) {
            newLines[i] = splitter.splitGraphemes(lines[i]);
            // newLines[i] = fabric.util.string.graphemeSplit(lines[i]);
            newText = newText.concat(newLines[i], newLine);
        }
        newText.pop();
        return { _unwrappedLines: newLines, lines: lines, graphemeText: newText, graphemeLines: newLines };
    }

    fabric.util.object.extend(fabric.Text.prototype, {
        _splitTextIntoLines: _splitTextIntoLines,
    });




    // // disable ctrl + mousewheel for windows
    useEffect(() => {
        const fun1 = (event) => {
            if ((event.keyCode === 107 && event.ctrlKey === true) ||
                (event.keyCode === 109 && event.ctrlKey === true)) {
                event.preventDefault();
                event.stopPropagation();

            }
        }
        const fun2 = (event) => {
            if (event.ctrlKey === true) {
                // console.log('ctrl wheeler1')
                event.preventDefault();
                event.stopPropagation();
            }

        }
        const fun3 = (event) => {
            if (event.ctrlKey === true) {
                // console.log('ctrl wheeler2')
                event.preventDefault();
                event.stopPropagation();
            }
        }

        if (document.querySelector('.container1')) {

            contsiner1ref.current?.addEventListener("keydown", (e) => { fun1(e) });

            contsiner1ref.current?.addEventListener("mousewheel", (e) => { fun2(e) });

            contsiner1ref.current?.addEventListener("DOMMouseScroll", (e) => { fun3(e) });

            return (() => {
                contsiner1ref.current?.removeEventListener("keydown", (e) => { fun1(e) });

                contsiner1ref.current?.removeEventListener("mousewheel", (e) => { fun2(e) });

                contsiner1ref.current?.removeEventListener("DOMMouseScroll", (e) => { fun3(e) });
            })
        }
    })

    // hold ctrl key to trigger zoom and grab 
    useEffect(() => {
        const keyup = (e) => {
            if (e.keyCode == 17) {
                e.preventDefault()
                toggleedittodrag.current = false
                console.log('up');
                if (document.querySelector('.upper-canvas')) {
                    // document.querySelector('.upper-canvas').style.cursor = 'default'
                }
                if (editor) {
                    fabric.Object.prototype.selectable = true;
                    fabric.Object.prototype.evented = true;
                    fabric.Canvas.prototype.selection = true;
                    // fabric.Canvas.prototype.setCursor
                    // = function () {
                    //     let value = 'default'; // Add your own logic here to override
                    //     this.upperCanvasEl.style.cursor = value;
                    // };
                    // fabric.Canvas.prototype.defaultCursor = 'default'
                    editor.canvas.requestRenderAll()
                }
            }
        }
        const keydown = (e) => {
            if (e.keyCode == 17) {
                e.preventDefault()
                toggleedittodrag.current = true
                console.log('down');
                if (document.querySelector('.upper-canvas')) {
                    // document.querySelector('.upper-canvas').style.cursor = 'grab'
                    if (editor) {
                        fabric.Object.prototype.selectable = false;
                        fabric.Object.prototype.evented = false;
                        fabric.Canvas.prototype.selection = false;
                        // fabric.Canvas.prototype.setCursor
                        // = function () {
                        //     let value = 'grab'; // Add your own logic here to override
                        //     this.upperCanvasEl.style.cursor = value;
                        //     };
                        // fabric.Canvas.prototype.defaultCursor = 'grab'
                        editor.canvas.requestRenderAll()
                    }
                }
            }
        }

        document.addEventListener('keyup', keyup)
        document.addEventListener('keydown', keydown)

        return () => {
            document.removeEventListener('keyup', keyup)
            document.removeEventListener('keydown', keydown)
        }
    })

    // drag
    useEffect(() => {
        function startPan(event) {
            if (event.button == 0 && toggleedittodrag.current) {
                editor.canvas.discardActiveObject().renderAll();
                getsodrag.current = true
                var x0 = event.screenX,
                    y0 = event.screenY;
                function continuePan(event) {
                    getsodrag.current = true
                    editor.canvas.discardActiveObject().renderAll();
                    var x = event.screenX,
                        y = event.screenY;
                    editor.canvas.relativePan({ x: x - x0, y: y - y0 });
                    x0 = x;
                    y0 = y;
                }
                function stopPan(event) {
                    getsodrag.current = false
                    window.removeEventListener('mousemove', continuePan);
                    window.removeEventListener('mouseup', stopPan);
                    // editor.canvas.defaultCursor = 'default'
                    // editor.canvas.renderAll()
                };
                window.addEventListener('mousemove', continuePan);
                window.addEventListener('mouseup', stopPan);
                window.addEventListener('contextmenu', cancelMenu);
            }

        };

        function cancelMenu(event) {
            window.removeEventListener('contextmenu', cancelMenu);
            event.preventDefault();
        }

        canvascontainer?.current?.addEventListener('mousedown', startPan)

        return (() => {
            canvascontainer?.current?.removeEventListener('mousedown', startPan)
        })
    })

    const debounce = (callback, e) => {
        if (typingTimeout.current) clearTimeout(typingTimeout.current);
        typing.current = false;
        typingTimeout.current = setTimeout(() => {
            // projectSearchTerm?.length && callback();
            callback(e);
        }, 500);
    };





    // when window resize canvas center
    useEffect(() => {
        if (editor && canvascontainer) {
            const changecanvassize = () => {
                editor.canvas.setDimensions({ width: canvascontainer?.current?.clientWidth, height: canvascontainer?.current?.clientHeight })
                editor.canvas.calcViewportBoundaries();
                editor.canvas.setWidth(canvascontainer.current.clientWidth)
                editor.canvas.setHeight(canvascontainer.current.clientHeight)
                editor.canvas.renderAll()



                var zoom = Math.min(editor.canvas.height / Math.floor(canvasheight), editor.canvas.width / Math.floor(canvaswidth)) * zoomSize
                editor.canvas.zoomToPoint(new fabric.Point(editor.canvas.width / 2, editor.canvas.height / 2), zoom)
                editor.canvas.renderAll()



                editor.canvas.viewportTransform[4] = 0
                editor.canvas.viewportTransform[5] = 0


                editor.canvas.renderAll()

                editor.canvas.relativePan({ x: Math.abs(editor?.canvas?.getCenter().left - (editor.canvas.backgroundImage.getBoundingRect().width * editor.canvas.getZoom()) / 2), y: Math.abs(editor?.canvas?.getCenter().top - (editor.canvas.backgroundImage.getBoundingRect().height * editor.canvas.getZoom()) / 2) });
                // editor.canvas.relativePan({ x: Math.abs(editor?.canvas?.getCenter().left - (editor.canvas.clipPath.width * editor.canvas.clipPath.zoomX) / 2), y: Math.abs(editor?.canvas?.getCenter().top - (editor.canvas.clipPath.height * editor.canvas.clipPath.zoomY) / 2) });
                editor.canvas.requestRenderAll()

                // ruler
                zoomvalue.current = editor.canvas.getZoom()

                if (scrollXextrascroll.current == 0) {
                    scrollx.current = -((editor.canvas.viewportTransform[4] - 30) / (zoom))
                    horizonalGuidesRef.current.resize(zoom)
                    verticalGuidesRef.current.resize(zoom)
                    horizonalGuidesRef.current.scroll(scrollx.current, (zoom));
                    verticalGuidesRef.current.scrollGuides(scrollx.current, (zoom));
                }
                // else{
                //     scrollx.current = - (((editor.canvas.viewportTransform[4] / zoom) + scrollvalueX)) 
                //     horizonalGuidesRef.current.scroll(scrollx.current,(zoom));
                //     verticalGuidesRef.current.scrollGuides(scrollx.current,(zoom));
                // }
                if (scrollYextrascroll.current == 0) {
                    scrolly.current = -((editor.canvas.viewportTransform[5] - 30) / zoom)
                    horizonalGuidesRef.current.resize(zoom)
                    verticalGuidesRef.current.resize(zoom)
                    verticalGuidesRef.current.scroll(scrolly.current, (zoom));
                    horizonalGuidesRef.current.scrollGuides(scrolly.current, (zoom));
                }
                // else{
                //     scrolly.current = -(((editor.canvas.viewportTransform[5]) - scrollvalueY)/editor.canvas.getZoom()) 
                //     verticalGuidesRef.current.scroll(scrolly.current,editor.canvas.getZoom());
                //     horizonalGuidesRef.current.scrollGuides(scrolly.current,editor.canvas.getZoom());
                // }


            }

            window.addEventListener('resize', (e) => { debounce(changecanvassize) })
            return (() => {
                window.removeEventListener('resize', (e) => { debounce(changecanvassize) })
            })
        }
    })



    const handleSave = () => {
        if(window.location.pathname == '/ailaysa-canvas/design/'){
            setCanvasmodified(true)
        }
    }


    // useEffect(()=>{
    //     const saveareupdate =()=>{
    //        setTimeout(() => {
    //         if(URL_SEARCH_PARAMS.get('project')){
    //             updateDesign()
    //         }
    //         else{
    //             saveAsDesign()
    //         }
    //        }, 200);
    //     }
    //     if(editor){
    //         editor.canvas.__eventListeners["object:modified"] = []
    //         editor.canvas.on('object:modified',saveareupdate )
    //         // editor.canvas.__eventListeners["object:added"] = []
    //         // editor.canvas.on('object:added',saveareupdate )
    //         // editor.canvas.__eventListeners["object:added"] = []
    //         // editor.canvas.on('object:added',saveareupdate )
    //         // editor.canvas.__eventListeners["object:removed"] = []
    //         // editor.canvas.on('object:removed', saveareupdate)
    //     }

    // },[editor])\\







var selectedObject = useRef(null);
const isCrop = useRef(false);

 // crop events and functions
    
 const handleMouseUp =(event)=> {

    var croppingarea = editor.canvas.getObjects().find(obj => obj.custtype == 'picArea')
    var croppingimage = editor.canvas.getObjects().find(obj => obj.picArea)
    
    if (croppingarea) {
        if (croppingimage) {
            if (croppingimage.getBoundingRect().left >= croppingarea.getBoundingRect().left) {
                croppingimage.set('left', croppingarea.left);
                console.log('left');
            }
            if (croppingimage.getBoundingRect().left + croppingimage.getBoundingRect().width <= croppingarea.getBoundingRect().left + croppingarea.getBoundingRect().width) {
                console.log('right');
                croppingimage.set('left', croppingarea.left + croppingarea.width*croppingarea.scaleX - croppingimage.width*croppingimage.scaleX)
            }
            
            if (croppingimage.getBoundingRect().top >= croppingarea.getBoundingRect().top) {
                console.log('top');
                croppingimage.set('top', croppingarea.top);
            }
            if (croppingimage.getBoundingRect().top + croppingimage.getBoundingRect().height <= croppingarea.getBoundingRect().top + croppingarea.getBoundingRect().height) {
                console.log('bottom');
                croppingimage.set('top', croppingarea.top + croppingarea.height*croppingarea.scaleX - croppingimage.height*croppingimage.scaleX);
            }


            var parentWidth = croppingarea.getBoundingRect().width;
            var parentHeight = croppingarea.getBoundingRect().height;

            // Get the dimensions of the child object
            var childWidth = croppingimage.getBoundingRect().width;
            var childHeight = croppingimage.getBoundingRect().height;

            if (childWidth < parentWidth && childHeight < parentHeight) {
                croppingimage.left = croppingarea.left
                croppingimage.top = croppingarea.top
                croppingimage.scaleX = croppingimage.scalesizeX
                croppingimage.scaleY = croppingimage.scalesizeY
                editor.canvas.renderAll()
            } else {
                // Child object is equal to or larger than parent object
                croppingimage.scalesizeX = croppingimage.scaleX
                croppingimage.scalesizeY = croppingimage.scaleY
            }
        }
    }

    // if(croppingarea){
    //     if(croppingimage){
    //         if(croppingimage.getBoundingRect().left >= croppingarea.getBoundingRect().left){
    //             croppingimage.set('left', croppingarea.getBoundingRect().left);
    //         }
    //         if(croppingimage.getBoundingRect().left + croppingimage.getBoundingRect().width   <= croppingarea.getBoundingRect().left+croppingarea.getBoundingRect().width){
    //             croppingimage.set('left', croppingarea.getBoundingRect().left+croppingarea.getBoundingRect().width - croppingimage.getBoundingRect().width) 
    //         }
           
    //         if(croppingimage.getBoundingRect().top >= croppingarea.getBoundingRect().top){
    //             croppingimage.set('top', croppingarea.top);
    //         }
    //         if(croppingimage.getBoundingRect().top + croppingimage.getBoundingRect().height   <= croppingarea.getBoundingRect().top + croppingarea.getBoundingRect().height){
    //             croppingimage.set('top', croppingarea.getBoundingRect().top+croppingarea.getBoundingRect().height - croppingimage.getBoundingRect().height) ;
    //         }


    //         var parentWidth = croppingarea.getBoundingRect().width;
    //         var parentHeight = croppingarea.getBoundingRect().height;

    //         // Get the dimensions of the child object
    //         var childWidth = croppingimage.getBoundingRect().width;
    //         var childHeight = croppingimage.getBoundingRect().height;

    //         if (childWidth < parentWidth && childHeight < parentHeight) {
    //             croppingimage.left = croppingarea.getBoundingRect().left
    //             croppingimage.top = croppingarea.getBoundingRect().top
    //             croppingimage.scaleX = croppingimage.scalesizeX
    //             croppingimage.scaleY = croppingimage.scalesizeY
    //             editor.canvas.renderAll()
    //         } else {
    //             croppingimage.scalesizeX = croppingimage.scaleX
    //             croppingimage.scalesizeY = croppingimage.scaleY
    //         }

    //     }
    // }

}
const handleObjectModified =(e)=>{
    if (selectedObject.current && selectedObject.current.custtype === 'picArea') {
        selectedObject.current.width *= selectedObject.current.scaleX;
        selectedObject.current.height *= selectedObject.current.scaleY;

        selectedObject.current.scaleX = 1;
        selectedObject.current.scaleY = 1;
    }
    if (selectedObject.current && selectedObject.current.type === 'image' && !isCrop.current) {
        selectedObject.current.oldScaleX = selectedObject.current.scaleX;
        selectedObject.current.oldScaleY = selectedObject.current.scaleY;            
    }

    if(selectedObject.current.picArea && isCrop.current && e.target.custtype) {

        // selectedObject.current.picArea.scaleToWidth(e.target.width*e.target.scaleX);
        selectedObject.current.picArea.setCoords();
        editor.canvas.renderAll();
        selectedObject.current.picArea.width = e.target.width*e.target.scaleX;
        selectedObject.current.picArea.height = e.target.height*e.target.scaleY;
        selectedObject.current.picArea.scaleX = 1;
        selectedObject.current.picArea.scaleY = 1;
        selectedObject.current.picArea.setCoords();
    }

    selectedObject.current.setCoords();
    editor.canvas.renderAll(); 
}
const handleMouseDown =(e)=>{

    var aObj = e.target;
    if (!aObj?.scalesizeX && aObj && (aObj.__corner === 'br' || aObj.__corner === 'bl' || aObj.__corner === 'tr' || aObj.__corner === 'tl')) {
        aObj.scalesizeX = aObj.scaleX
        aObj.scalesizeY = aObj.scaleY
    }
}
const handleSelectioncreated =(e)=>{
    if(isCrop.current && (e.selected[0] && e.selected[0].custtype !== 'picArea')){
        cropImage();
    }
    else{
        selectedObject.current = e.selected[0];
    }
    
}
const handleSelectionUpdated =(e)=>{
    // selectedObject.current = e.selected[0];
    // selectedObject.current = e.selected[0];
    
    if(e.selected[0]?.custtype != "picArea" &&  !e.selected[0].picArea){
        console.log('ssss');
        cropImage();
        selectedObject.current = e.selected[0];
    }

}
const handleSelectionClear =(e)=>{
    if(isCrop.current){
        cropImage();
        console.log('sssss');
    }

    editor.canvas?.getObjects('image').forEach(e=>{
       if(e?.name == 'Background-new'){
        handleConfirmBackGround()
       }
    })
}



const  cropImage=()=> {
    console.log(selectedObject.current);
    console.log('cropping.....');
    selectedObject.current.setControlsVisibility({ mtr: true ,mr:true,ml:true,mb:true,mt:true})
    isCrop.current = false;
    selectedObject.current.lockScalingFlip = false

    console.log('smplll3333');

    if (selectedObject.current && selectedObject.current.type === 'image' && selectedObject.current.picArea ) {
        console.log('smplll');
        if ((selectedObject.current.width * selectedObject.current.scaleX < selectedObject.current.picArea.width * selectedObject.current.picArea.scaleX) || (selectedObject.current.height * selectedObject.current.scaleY < selectedObject.current.picArea.height * selectedObject.current.picArea.scaleY)) {} else {
            //functionality to crop

            selectedObject.current.oldScaleX = selectedObject.current.scaleX;
            selectedObject.current.oldScaleY = selectedObject.current.scaleY;
            
            console.log(selectedObject.current.scaleX, selectedObject.current.oldScaleX);

            var oldAngle = selectedObject.current.picArea.angle;
            var oldLeft = selectedObject.current.picArea.left;
            var oldTop = selectedObject.current.picArea.top;

            rotateSelection(selectedObject.current, 360 - oldAngle);
            
            //crop
            selectedObject.current.cropX = (selectedObject.current.picArea.left - selectedObject.current.left) / selectedObject.current.scaleX;
            selectedObject.current.cropY = (selectedObject.current.picArea.top - selectedObject.current.top) / selectedObject.current.scaleY;
            selectedObject.current.width = selectedObject.current.picArea.width / selectedObject.current.scaleX;
            selectedObject.current.height = selectedObject.current.picArea.height / selectedObject.current.scaleY;
            selectedObject.current.left = selectedObject.current.picArea.left;
            selectedObject.current.top = selectedObject.current.picArea.top;
            // selectedObject.current.scaleX = selectedObject.current.picArea.scaleX
            // selectedObject.current.scaleY = selectedObject.current.picArea.scaleY
            // selectedObject.current 
            rotateSelection(selectedObject.current, oldAngle);

            selectedObject.current.left = oldLeft;
            selectedObject.current.top = oldTop;
        }
        selectedObject.current.setCoords();
        selectedObject.current.opacity = selectedObject.current.oldOpacity;
        selectedObject.current.selectable = true;
        selectedObject.current.hasRotatingPoint = true;
        editor.canvas.remove(selectedObject.current.picArea);
        selectedObject.current.picArea = null;
        editor.canvas.discardActiveObject();
        editor.canvas.renderAll();




        // if(selectedObject.current.oldOriginX == 'center') {
        //     var oldAngle = selectedObject.current.angle;
        //     if(oldAngle) {
        //         selectedObject.current.angle = 0;
        //         selectedObject.current.setCoords();

        //         selectedObject.current.originX = selectedObject.current.oldOriginX;
        //         selectedObject.current.originY = selectedObject.current.oldOriginY;
        //         selectedObject.current.setCoords();

        //         ///selectedObject.current.rotate(360-oldAngle);
        //         selectedObject.current.left += (selectedObject.current.width*selectedObject.current.scaleX)/2;
        //         selectedObject.current.top += (selectedObject.current.height*selectedObject.current.scaleY)/2; 
        //         selectedObject.current.rotate(oldAngle);
        //         //selectedObject.current.angle = oldAngle;
        //         selectedObject.current.setCoords();                    
        //     } else {
        //         selectedObject.current.originX = selectedObject.current.oldOriginX;
        //         selectedObject.current.originY = selectedObject.current.oldOriginY;
        //         selectedObject.current.setCoords();

        //         selectedObject.current.left += (selectedObject.current.width*selectedObject.current.scaleX)/2;
        //         selectedObject.current.top += (selectedObject.current.height*selectedObject.current.scaleY)/2; 
        //         selectedObject.current.setCoords();                              
        //     }
        // }
        // editor.canvas.renderAll();
    }
}
const  rotateSelection=(picture, angle)=> {

    editor.canvas.discardActiveObject();
    var sel = new fabric.ActiveSelection([picture, picture.picArea], {
        canvas: editor.canvas,
    });
    editor.canvas.setActiveObject(sel);
    sel.rotate(angle);
    editor.canvas.requestRenderAll();
    
    //unselect
    editor.canvas.discardActiveObject();
    editor.canvas.requestRenderAll();        
}
const addPictureArea=(picture)=> {
    var picArea = new fabric.Rect({
        fill: null,
        originX: picture.originX,
        originY: picture.originY,
        width: picture.width * picture.scaleX,
        height: picture.height * picture.scaleY,
        left: picture.left,
        top: picture.top,
        angle: picture.angle,
        strokeWidth:null,
        hasRotatingPoint: false,
        lockMovementX:true,
        lockMovementY:true,
        perPixelTargetFind:true,
        boundingX:picture.getBoundingRect().width,
        boundingY:picture.getBoundingRect().height,
        // bounding:picture.getBoundingRect().width,
        // scaleX:picture.scaleX,
        // scaleY:picture.scaleY,
        // strokeWidth:'1',
        stroke:'#111',
        strokeWidth:null,
        shadow:new fabric.Shadow({
            color: 'rgba(0,0,0,1)',
            blur:2,
        }),
        objectControlsVisibility:true
        // selectable: false
    });
    picArea.setControlsVisibility({ mtr: false ,mr:false,ml:false,mb:false,mt:false})
    picArea.custtype = 'picArea';
    editor.canvas.add(picArea);
    picArea.setCoords();

    var picIndex = editor.canvas.getObjects().indexOf(picture);
    // if(!picIndex) {
    //     picArea.moveTo(0);
    //     picture.moveTo(1);
    // } else
    //     picArea.moveTo(picIndex);

    picture.picArea = picArea;
    editor.canvas.renderAll();
}
const callcrop=(e,i)=>{

    if(editor.canvas.getActiveObject().name != 'Background-new' && 'Background-current' && 'Background-static' ){
        var target
    if(i = 'active'){
        target = editor.canvas.getActiveObject()
    }else{
        target = editor.canvas.findTarget(e);
    }
    if (target && target.type === 'image' && !target.picArea) {
        
        target.oldOpacity = target.opacity;
        // target.oldOpacity = 0.7652;
        target.oldOriginX = target.originX;
        target.oldOriginY = target.originY;
        selectedObject.current.lockScalingFlip = true

        if(target.oldOriginX == 'center') {

            target.originX = 'left';
            target.originY = 'top';
            target.setCoords();

             var oldAngle = target.angle;
             if(oldAngle) {
                 //target.rotate(360-oldAngle);
                 target.angle = 0;
                 target.left -= (target.width*target.scaleX)/2;
                 target.top -= (target.height*target.scaleY)/2; 
                 //target.angle = oldAngle;
                 target.rotate(oldAngle);
                 target.setCoords();                    
             } else {
                 target.left -= (target.width*target.scaleX)/2;
                 target.top -= (target.height*target.scaleY)/2; 
                 target.setCoords();                              
             }
        }
        target.setControlsVisibility({ mtr: false ,mr:false,ml:false,mb:false,mt:false})
        addPictureArea(target);
        
        target.opacity = 0.7;
        target.hasRotatingPoint = false;
        if (target.cropX || target.cropY) {
            var oldAngle = target.angle;
            var oldLeft = target.picArea.left;
            var oldTop = target.picArea.top;

            rotateSelection(target, 360 - oldAngle);

            if(target.cropX)
            target.left = target.picArea.left - target.cropX * target.scaleX;
            if(target.cropY)
            target.top = target.picArea.top - target.cropY * target.scaleY;

            target.scaleX = target.oldScaleX;
            target.scaleY = target.oldScaleY;
            target.width = target.oldWidth;
            target.height = target.oldHeight;
            target.cropX = 0;
            target.cropY = 0;

            rotateSelection(target, oldAngle);

            target.left -= target.picArea.left - oldLeft;
            target.top -= target.picArea.top - oldTop;
            target.setCoords();

            target.picArea.left = oldLeft;
            target.picArea.top = oldTop;
            target.picArea.aaaaaa += 1 
            target.picArea.setCoords();
        } else {
            if(target.width < target.oldWidth)
                target.width = target.oldWidth;
            if(target.height < target.oldHeight)
                target.height = target.oldHeight;
            target.setCoords();
        }
        editor.canvas.setActiveObject(target);
        editor.canvas.renderAll();
        isCrop.current = true;
        
    }
    }

}


    const imeventtrue = useRef(true)
    //   zoom trigger one time
    useEffect(() => {
            if(!isCrop.current){
                selectedObject.current = editor?.canvas.getActiveObject()
            }
        if (editor && imeventtrue.current == true) {
            const canvaszoom = (opt) => {
                if (toggleedittodrag.current == true) {
                    editor.canvas.__eventListeners['mouse:wheel'] = {}

                    // console.log(opt);
                    var delta = opt.e.deltaY;
                    var zoom = editor.canvas.getZoom();
                    zoom *= 0.999 ** delta;
                    zoomvalue.current = zoom
                    const center = editor.canvas.getCenter();

                    if (zoom > 20) zoom = 20;
                    if (zoom < 0.01) zoom = 0.01;
                    editor.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
                    // editor.canvas.zoomToPoint({ x: center.left, y: center.top }, zoom);

                    opt.e.preventDefault();
                    opt.e.stopPropagation();
                    editor.canvas.requestRenderAll()

                    zoomvalue.current = editor.canvas.getZoom()

                    if (scrollXextrascroll.current == 0) {
                        scrollx.current = -((editor.canvas.viewportTransform[4] - 30) / (zoom))
                        horizonalGuidesRef.current.resize(zoom)
                        verticalGuidesRef.current.resize(zoom)
                        horizonalGuidesRef.current.scroll(scrollx.current, (zoom));
                        verticalGuidesRef.current.scrollGuides(scrollx.current, (zoom));
                    }
                    // else{
                    //     scrollx.current = - (((editor.canvas.viewportTransform[4] / zoom) + scrollvalueX)) 
                    //     horizonalGuidesRef.current.scroll(scrollx.current,(zoom));
                    //     verticalGuidesRef.current.scrollGuides(scrollx.current,(zoom));
                    // }
                    if (scrollYextrascroll.current == 0) {
                        scrolly.current = -((editor.canvas.viewportTransform[5] - 30) / zoom)
                        horizonalGuidesRef.current.resize(zoom)
                        verticalGuidesRef.current.resize(zoom)
                        verticalGuidesRef.current.scroll(scrolly.current, (zoom));
                        horizonalGuidesRef.current.scrollGuides(scrolly.current, (zoom));
                    }
                    // else{
                    //     scrolly.current = -(((editor.canvas.viewportTransform[5]) - scrollvalueY)/editor.canvas.getZoom()) 
                    //     verticalGuidesRef.current.scroll(scrolly.current,editor.canvas.getZoom());
                    //     horizonalGuidesRef.current.scrollGuides(scrolly.current,editor.canvas.getZoom());
                    // }
                }

            }
            const dblclickbackground = (e) => {


                if (!editor.canvas.getActiveObject()) {
                    let backGround = editor.canvas.getObjects().find(obj => obj.name == 'Background-current')

                    if (backGround) {
                        // var rectclip = new fabric.Rect({ width: Math.floor(canvaswidth), height: Math.floor(canvasheight)});
                        editor.canvas.__eventListeners['mouse:dblclick'] = []
                        editor.canvas.renderAll()
                        backGround.set({
                            name: 'Background-new',
                            evented: true,
                            editable: true,
                            selectable: true,
                            lockMovementX: false,
                            lockMovementY: false,
                            clipPath: null,
                            // cropX:0,
                            // cropY:0,
                            // left:backGround.originalleft,
                            // top:backGround.originaltop,
                            // width:backGround.originalwidth,
                            // height:backGround.originalheight,
                            // scaleX:editor.canvas.getZoom(),
                            // scaleY:editor.canvas.getZoom(),
                        })
                        editor.canvas.setActiveObject(backGround)
                        editor.canvas.requestRenderAll()
                    }
                }
            }

            // const canvaszoomcenter = (opt) => {
            //     if (toggleedittodrag.current == true) {

            //         editor.canvas.calcViewportBoundaries();
            //         var delta = opt.e.deltaY;
            //         var pointer = editor.canvas.getPointer(opt.e);
            //         var zoom = editor.canvas.getZoom();
            //         const center = editor.canvas.getCenter();
            //         zoom *= 0.999 ** delta;

            //         if (zoom > 20) zoom = 20;
            //         if (zoom < 0.01) zoom = 0.0001;
            //         // editor.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            //         editor.canvas.zoomToPoint({ x: center.left, y: center.top }, zoom);
            //         if (clipPath.getBoundingRect().width == editor.canvas.width) {
            //             // console.log(true)
            //         }
            //         // editor.canvas.zoomToPoint({ x: (clientWidth/(width + width) ) <= 0.6 ? (editor.canvas.width/4)  : (editor.canvas.width - width) /2 , y:(clientHeight/(height + height) ) <= 0.6 ? (editor.canvas.height/4)  : (editor.canvas.height - height) /2 }, zoom);
            //         opt.e.preventDefault();
            //         opt.e.stopPropagation()
            //         editor.canvas.renderAll();
            //         // console.log(clipPath.getBoundingRect())
            //     }
            // }

            editor.canvas.__eventListeners["mouse:wheel"] = []
            editor.canvas.on('mouse:wheel', canvaszoom)


            editor.canvas.__eventListeners["object:modified"] = []
            editor.canvas.on('object:modified', handleSave)


            editor.canvas.__eventListeners["object:removed"] = []
            editor.canvas.on('object:removed', handleSave)


            editor.canvas.__eventListeners["mouse:over"] = []
            editor?.canvas.on('mouse:over', mouseInObject);


            editor.canvas.__eventListeners["mouse:out"] = []
            editor.canvas.on('mouse:out', mouseOutObject)


            editor.canvas.__eventListeners["mouse:dblclick"] = []
            editor.canvas.on('mouse:dblclick', dblclickbackground)


            editor.canvas.on('mouse:up', handleMouseUp );
            editor.canvas.on('object:modified',handleObjectModified );
            editor.canvas.on('mouse:down',handleMouseDown );
            editor.canvas.on('selection:created',handleSelectioncreated );
            editor.canvas.on('selection:updated',handleSelectionUpdated);
            editor.canvas.on('selection:cleared', handleSelectionClear );


            fabric.util.addListener(editor.canvas.upperCanvasEl, 'dblclick',callcrop);
         
            fabric.Image.prototype._renderFill = (function _renderFill(ctx) {
                return function _renderFill(ctx) {
                    var elementToDraw = this._element,
                        w = this.width,
                        h = this.height,
                        sW = Math.min(elementToDraw.naturalWidth || elementToDraw.width, w * this._filterScalingX),
                        sH = Math.min(elementToDraw.naturalHeight || elementToDraw.height, h * this._filterScalingY),
                        x = -w / 2,
                        y = -h / 2,
                        sX = this.cropX * this._filterScalingX,
                        sY = this.cropY * this._filterScalingY;
                    elementToDraw && ctx.drawImage(elementToDraw, sX, sY, sW, sH, x, y, w, h);
                }
            })(fabric.Image.prototype._renderFill)
            // object snapping line by Dhananjezhian
            // https://gist.github.com/rlingineni/a77d4e7a3fcc2a1beb499baefa7a7b89  ====> Ref (its optional function now we using package for obj snapping and guide     line)

            // var ctx = editor?.canvas?.getSelectionContext(),
            // aligningLineOffset = 10,
            // aligningLineMargin = 4,
            // aligningLineWidth = 1,
            // aligningLineColor = 'rgba(74, 21, 173)',
            // viewportTransform,
            // zoom = editor?.canvas?.getZoom();

            // function drawVerticalLine(coords) {
            //     drawLine(
            //         coords.x + 0.5,
            //         coords.y1 > coords.y2 ? coords.y2 : coords.y1,
            //         coords.x + 0.5,
            //         coords.y2 > coords.y1 ? coords.y2 : coords.y1);
            // }
            // function drawHorizontalLine(coords) {
            //     drawLine(
            //         coords.x1 > coords.x2 ? coords.x2 : coords.x1,
            //         coords.y + 0.5,
            //         coords.x2 > coords.x1 ? coords.x2 : coords.x1,
            //         coords.y + 0.5);
            // }
            // function drawLine(x1, y1, x2, y2) {
            //     ctx.save();
            //     ctx.lineWidth = aligningLineWidth;
            //     ctx.strokeStyle = aligningLineColor;
            //     ctx.beginPath();
            //     ctx.moveTo(((x1 * zoom + viewportTransform[4])), ((y1 * zoom + viewportTransform[5])));
            //     ctx.lineTo(((x2 * zoom + viewportTransform[4])), ((y2 * zoom + viewportTransform[5])));
            //     ctx.stroke();
            //     ctx.restore();
            // }
            // function isInRange(value1, value2) {
            //     value1 = Math.round(value1);
            //     value2 = Math.round(value2);
            //     for (var i = value1 - aligningLineMargin, len = value1 + aligningLineMargin; i <= len; i++) {
            //         if (i === value2) {
            //             return true;
            //         }
            //     }
            //     return false;
            // }


            // var verticalLines = [],
            // horizontalLines = [];


            // editor?.canvas.on('mouse:down', function () {
            //     zoom = editor?.canvas?.getZoom();
            //     viewportTransform = editor?.canvas?.viewportTransform;
            //     editor.canvas.__eventListeners["object:moving"]=[]
            //     editor?.canvas.on('object:moving', function (e) {

            //         var activeObject = editor?.canvas?.getActiveObject(),
            //             canvasObjects = editor?.canvas?.getObjects(),
            //             activeObjectCenter = activeObject?.getCenterPoint(),
            //             activeObjectLeft = activeObjectCenter?.x,
            //             activeObjectTop = activeObjectCenter?.y,
            //             activeObjectBoundingRect = editor?.canvas?.getActiveObject()?.getBoundingRect(),
            //             activeObjectHeight = editor?.canvas?.getActiveObject()?.getBoundingRect().height / viewportTransform[3],
            //             activeObjectWidth = editor?.canvas?.getActiveObject()?.getBoundingRect().width / viewportTransform[0],
            //             horizontalInTheRange = false,
            //             verticalInTheRange = false,
            //             transform = editor.canvas._currentTransform;


            //         if (!transform) return;


            //         // It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into functions,
            //         // but we're not doing it here for perf. reasons -- as this a function that's invoked on every mouse move


            //         for (var i = canvasObjects.length; i--;) {


            //             if (canvasObjects[i] === activeObject) continue;


            //             var objectCenter = canvasObjects[i]?.getCenterPoint(),
            //                 objectLeft = objectCenter?.x,
            //                 objectTop = objectCenter?.y,
            //                 objectBoundingRect = canvasObjects[i]?.getBoundingRect(),
            //                 objectHeight = objectBoundingRect?.height / viewportTransform[3],
            //                 objectWidth = objectBoundingRect?.width / viewportTransform[0];


            //             // snap by the horizontal center line (horizontal)
            //             if (isInRange(objectLeft, activeObjectLeft)) {
            //                 verticalInTheRange = true;
            //                 verticalLines.push({
            //                     x: objectLeft,
            //                     y1: (objectTop < activeObjectTop)
            //                         ? (objectTop - objectHeight / 2 - aligningLineOffset)
            //                         : (objectTop + objectHeight / 2 + aligningLineOffset),
            //                     y2: (activeObjectTop > objectTop)
            //                         ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
            //                         : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
            //                 });
            //                 activeObject?.setPositionByOrigin(new fabric.Point(objectLeft, activeObjectTop), 'center', 'center');
            //             }
            //             // horisontal to (move middle right to horisontal)
            //             if (isInRange(activeObjectLeft + activeObjectWidth / 2, objectLeft)) {
            //                 verticalInTheRange = true;
            //                 verticalLines.push({
            //                     x: objectLeft,
            //                     y1: (objectTop < activeObjectTop)
            //                         ? (objectTop - objectHeight / 2 - aligningLineOffset)
            //                         : (objectTop + objectHeight / 2 + aligningLineOffset),
            //                     y2: (activeObjectTop > objectTop)
            //                         ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
            //                         : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
            //                 });
            //                 activeObject?.setPositionByOrigin(new fabric.Point(objectLeft - activeObjectWidth / 2, activeObjectTop), 'center', 'center');
            //             }
            //             // horisontal to (move middle left to horisontal)
            //             if (isInRange(activeObjectLeft - activeObjectWidth / 2, objectLeft)) {
            //                 verticalInTheRange = true;
            //                 verticalLines.push({
            //                     x: objectLeft,
            //                     y1: (objectTop < activeObjectTop)
            //                         ? (objectTop - objectHeight / 2 - aligningLineOffset)
            //                         : (objectTop + objectHeight / 2 + aligningLineOffset),
            //                     y2: (activeObjectTop > objectTop)
            //                         ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
            //                         : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
            //                 });
            //                 activeObject?.setPositionByOrigin(new fabric.Point(objectLeft + activeObjectWidth / 2, activeObjectTop), 'center', 'center');
            //             }
            //              // horisontal to (snap left  to horisontal)
            //             if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft)) {
            //                 verticalInTheRange = true;
            //                 verticalLines.push({
            //                     x: objectLeft + objectWidth / 2,
            //                     y1: (objectTop < activeObjectTop)
            //                         ? (objectTop - objectHeight / 2 - aligningLineOffset)
            //                         : (objectTop + objectHeight / 2 + aligningLineOffset),
            //                     y2: (activeObjectTop > objectTop)
            //                         ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
            //                         : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
            //                 });
            //                 activeObject?.setPositionByOrigin(new fabric.Point(objectLeft + objectWidth / 2, activeObjectTop), 'center', 'center');
            //             }
            //             //  horisontal to (snap left to horisontal)
            //             if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft)) {
            //                 verticalInTheRange = true;
            //                 verticalLines.push({
            //                     x: objectLeft - objectWidth / 2,
            //                     y1: (objectTop < activeObjectTop)
            //                         ? (objectTop - objectHeight / 2 - aligningLineOffset)
            //                         : (objectTop + objectHeight / 2 + aligningLineOffset),
            //                     y2: (activeObjectTop > objectTop)
            //                         ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
            //                         : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
            //                 });
            //                 activeObject?.setPositionByOrigin(new fabric.Point(objectLeft - objectWidth / 2, activeObjectTop), 'center', 'center');
            //             }
            //             // snap middle bottom to vertical
            //             if (isInRange(objectTop, activeObjectTop - activeObjectHeight / 2)) {
            //                 horizontalInTheRange = true;
            //                 horizontalLines.push({
            //                     y: objectTop,
            //                     x1: (objectLeft < activeObjectLeft)
            //                         ? (objectLeft - objectWidth / 2 - aligningLineOffset)
            //                         : (objectLeft + objectWidth / 2 + aligningLineOffset),
            //                     x2: (activeObjectLeft > objectLeft)
            //                         ? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
            //                         : (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
            //                 });
            //                 activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop + activeObjectHeight / 2), 'center', 'center');
            //             }
            //             // snap  middle up to vertical
            //             if (isInRange(objectTop, activeObjectTop + activeObjectHeight / 2)) {
            //                 horizontalInTheRange = true;
            //                 horizontalLines.push({
            //                     y: objectTop,
            //                     x1: (objectLeft < activeObjectLeft)
            //                         ? (objectLeft - objectWidth / 2 - aligningLineOffset)
            //                         : (objectLeft + objectWidth / 2 + aligningLineOffset),
            //                     x2: (activeObjectLeft > objectLeft)
            //                         ? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
            //                         : (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
            //                 });
            //                 activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop - activeObjectHeight / 2), 'center', 'center');
            //             }
            //             // snap by the left edge (left to left)
            //             if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
            //                 verticalInTheRange = true;
            //                 verticalLines.push({
            //                     x: objectLeft - objectWidth / 2,
            //                     y1: (objectTop < activeObjectTop)
            //                         ? (objectTop - objectHeight / 2 - aligningLineOffset)
            //                         : (objectTop + objectHeight / 2 + aligningLineOffset),
            //                     y2: (activeObjectTop > objectTop)
            //                         ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
            //                         : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
            //                 });
            //                 activeObject.setPositionByOrigin(new fabric.Point(objectLeft - objectWidth / 2 + activeObjectWidth / 2, activeObjectTop), 'center', 'center');
            //             }
            //             // snap by the right edge (left to right)
            //             if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
            //                 verticalInTheRange = true;
            //                 verticalLines.push({
            //                     x: objectLeft + objectWidth / 2,
            //                     y1: (objectTop < activeObjectTop)
            //                         ? (objectTop - objectHeight / 2 - aligningLineOffset)
            //                         : (objectTop + objectHeight / 2 + aligningLineOffset),
            //                     y2: (activeObjectTop > objectTop)
            //                         ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
            //                         : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
            //                 });
            //                 activeObject.setPositionByOrigin(new fabric.Point(objectLeft + objectWidth / 2 + activeObjectWidth / 2, activeObjectTop), 'center', 'center');

            //             }
            //             // snap by the left edge  (right to left )
            //             if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
            //                 verticalInTheRange = true;
            //                 verticalLines.push({
            //                     x: objectLeft - objectWidth / 2,
            //                     y1: (objectTop < activeObjectTop)
            //                         ? (objectTop - objectHeight / 2 - aligningLineOffset)
            //                         : (objectTop + objectHeight / 2 + aligningLineOffset),
            //                     y2: (activeObjectTop > objectTop)
            //                         ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
            //                         : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
            //                 });
            //                 activeObject.setPositionByOrigin(new fabric.Point(objectLeft - objectWidth / 2 - activeObjectWidth / 2, activeObjectTop), 'center', 'center');
            //             }
            //             // snap by the right edge (right to right)
            //             if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
            //                 verticalInTheRange = true;
            //                 verticalLines.push({
            //                     x: objectLeft + objectWidth / 2,
            //                     y1: (objectTop < activeObjectTop)
            //                         ? (objectTop - objectHeight / 2 - aligningLineOffset)
            //                         : (objectTop + objectHeight / 2 + aligningLineOffset),
            //                     y2: (activeObjectTop > objectTop)
            //                         ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
            //                         : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
            //                 });
            //                 activeObject.setPositionByOrigin(new fabric.Point(objectLeft + objectWidth / 2 - activeObjectWidth / 2, activeObjectTop), 'center', 'center');

            //             }
            //             // snap by the vertical center line (vertical)
            //             if (isInRange(objectTop, activeObjectTop)) {
            //                 horizontalInTheRange = true;
            //                 horizontalLines.push({
            //                     y: objectTop,
            //                     x1: (objectLeft < activeObjectLeft)
            //                         ? (objectLeft - objectWidth / 2 - aligningLineOffset)
            //                         : (objectLeft + objectWidth / 2 + aligningLineOffset),
            //                     x2: (activeObjectLeft > objectLeft)
            //                         ? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
            //                         : (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
            //                 });
            //                 activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop), 'center', 'center');
            //             }
            //             // snap by the top edge (top to top)
            //             if (isInRange(objectTop - objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
            //                 horizontalInTheRange = true;
            //                 horizontalLines.push({
            //                     y: objectTop - objectHeight / 2,
            //                     x1: (objectLeft < activeObjectLeft)
            //                         ? (objectLeft - objectWidth / 2 - aligningLineOffset)
            //                         : (objectLeft + objectWidth / 2 + aligningLineOffset),
            //                     x2: (activeObjectLeft > objectLeft)
            //                         ? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
            //                         : (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
            //                 });
            //                 activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop - objectHeight / 2 + activeObjectHeight / 2), 'center', 'center');
            //             }
            //             // snap by the bottom edge  (top to bottom)
            //             if (isInRange(objectTop + objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
            //                 horizontalInTheRange = true;
            //                 horizontalLines.push({
            //                     y: objectTop + objectHeight / 2,
            //                     x1: (objectLeft < activeObjectLeft)
            //                         ? (objectLeft - objectWidth / 2 - aligningLineOffset)
            //                         : (objectLeft + objectWidth / 2 + aligningLineOffset),
            //                     x2: (activeObjectLeft > objectLeft)
            //                         ? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
            //                         : (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
            //                 });
            //                 activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop + objectHeight / 2 + activeObjectHeight / 2), 'center', 'center');
            //             }
            //             // snap by the top edge (bottom to top)
            //             if (isInRange(objectTop - objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
            //                 horizontalInTheRange = true;
            //                 horizontalLines.push({
            //                     y: objectTop - objectHeight / 2,
            //                     x1: (objectLeft < activeObjectLeft)
            //                         ? (objectLeft - objectWidth / 2 - aligningLineOffset)
            //                         : (objectLeft + objectWidth / 2 + aligningLineOffset),
            //                     x2: (activeObjectLeft > objectLeft)
            //                         ? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
            //                         : (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
            //                 });
            //                 activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop - objectHeight / 2 - activeObjectHeight / 2), 'center', 'center');
            //             }
            //             // snap by the bottom edge (botoom to bottom)
            //             if (isInRange(objectTop + objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
            //                 horizontalInTheRange = true;
            //                 horizontalLines.push({
            //                     y: objectTop + objectHeight / 2,
            //                     x1: (objectLeft < activeObjectLeft)
            //                         ? (objectLeft - objectWidth / 2 - aligningLineOffset)
            //                         : (objectLeft + objectWidth / 2 + aligningLineOffset),
            //                     x2: (activeObjectLeft > objectLeft)
            //                         ? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
            //                         : (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
            //                 });
            //                 activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop + objectHeight / 2 - activeObjectHeight / 2), 'center', 'center');
            //             }

            //         }


            //         if (!horizontalInTheRange) {
            //             horizontalLines.length = 0;
            //         }
            //         if (!verticalInTheRange) {
            //             verticalLines.length = 0;
            //         }
            //         // editor?.canvas.off('object:moving')

            //     });
            //     // editor?.canvas.off('mouse:down')
            // });

            // editor?.canvas?.on('before:render', function () {
            //     editor?.canvas?.clearContext(ctx);
            //     // console.log('before')
            //     // editor?.canvas.off('before:render')
            //     // editor?.canvas?.clearContext();
            // });

            // editor?.canvas.on('after:render', function () {
            //     // console.log('after')

            //     for (var i = verticalLines.length; i--;) {
            //         drawVerticalLine(verticalLines[i]);
            //     }
            //     for (var i = horizontalLines.length; i--;) {
            //         drawHorizontalLine(horizontalLines[i]);
            //     }


            //     verticalLines.length = horizontalLines.length = 0;

            // });

            // editor?.canvas.on('mouse:up', function () {
            //     // console.log('up')
            //     verticalLines.length = 0;
            //     horizontalLines.length = 0;
            //     // console.log('up')
            //     // editor?.canvas.off('object:moving')
            //     // editor?.canvas.off('before:render')
            //     // editor?.canvas.off('mouse:down')
            //     // editor?.canvas.off('mouse:up')
            //     // editor?.canvas.off('after:render')
            //     // editor?.canvas.off('mouse:up')
            //     editor?.canvas.renderAll();
            // });

            imeventtrue.current = false
            // return () => { editor.canvas.off('mouse:wheel', canvaszoom) }



        }
    })
    useEffect(() => {
        if (editor) {
            const box = document.querySelector('.container')
            var scrollX, scrollY;
            new Gesto(canvascontainer.current).on("dragStart", e => {
                scrollX = scrollx.current
                scrollY = scrolly.current;
                if (e.inputEvent.target === box || e.inputEvent.target.nodeName === "A") {
                    return false;
                }
            }).on("drag", e => {
                if (toggleedittodrag.current == true && getsodrag.current == true) {
                    scrollX = (scrollX) - (e.deltaX / editor.canvas.getZoom());
                    scrollY = (scrollY) - (e.deltaY / editor.canvas.getZoom())
                    scrollx.current = scrollX;
                    scrolly.current = scrollY;
                    horizonalGuidesRef.current?.scroll(scrollX, editor.canvas.getZoom());
                    horizonalGuidesRef.current?.scrollGuides(scrollY, editor.canvas.getZoom());
                    verticalGuidesRef.current?.scroll(scrollY, editor.canvas.getZoom());
                    verticalGuidesRef.current?.scrollGuides(scrollX, editor.canvas.getZoom());
                }
            })
        }
    })


    const handlesize = (size) => {

        if (editor.canvas.backgroundImage) {
            scaleMultiplierX.current = (Math.floor(size.width)) / canvaswidth;
            scaleMultiplierY.current = (Math.floor(size.height)) / canvasheight;
        }

        var objects = editor.canvas.getObjects();
        if (size?.resize == true) {
            for (var i in objects) {
                objects[i].scaleX = objects[i].scaleX * scaleMultiplierX.current;
                objects[i].scaleY = objects[i].scaleY * scaleMultiplierX.current;
                objects[i].left = objects[i].left * scaleMultiplierX.current;
                objects[i].top = objects[i].top * scaleMultiplierX.current;
                objects[i].setCoords();
                editor.canvas.renderAll()
            }
        }

        editor.canvas.setDimensions({ width: canvascontainer.current.clientWidth, height: canvascontainer.current.clientHeight })
        editor.canvas.width = canvascontainer.current.clientWidth
        editor.canvas.height = canvascontainer.current.clientHeight
        editor.canvas.calcViewportBoundaries();
        editor.canvas.renderAll()

        setCanvaswidth(Math.floor(size.width))
        setCanvasheight(Math.floor(size.height))

        clipPath = new fabric.Rect({ width: Math.floor(size.width), height: Math.floor(size.height), fill: size.fill ? size.fill : 'rgba(255,255,255,1)', objectCaching: false, strokeWidth: 0, shadow: shadow });
        editor.canvas.objectPooling = true;
        editor.canvas.enableRetinaScaling = false;
        editor.canvas.imageSmoothingEnabled = true
        editor.canvas.skipOffscreen = true
        editor.canvas.backgroundColor = bgopacity.current
        editor.canvas.backgroundImage = clipPath
        fabric.Object.prototype.borderOpacityWhenMoving = 1;
        editor.canvas.calcOffset()
        // editor.canvas.controlsAboveOverlay = true
        var zoom = Math.min(editor.canvas.height / Math.floor(size.height), editor.canvas.width / Math.floor(size.width)) * zoomSize
        editor.canvas.zoomToPoint(new fabric.Point(editor.canvas.width / 2, editor.canvas.height / 2), zoom)
        editor.canvas.renderAll()

        editor.canvas.viewportTransform[4] = 0
        editor.canvas.viewportTransform[5] = 0

        editor.canvas.relativePan({ x: Math.abs(editor?.canvas?.getCenter().left - (editor.canvas.backgroundImage.getBoundingRect().width * editor.canvas.getZoom()) / 2), y: Math.abs(editor?.canvas?.getCenter().top - (editor.canvas.backgroundImage.getBoundingRect().height * editor.canvas.getZoom()) / 2) });
        // editor.canvas.relativePan({ x: Math.abs(editor?.canvas?.getCenter().left - (editor.canvas.clipPath.width * editor.canvas.clipPath.zoomX) / 2), y: Math.abs(editor?.canvas?.getCenter().top - (editor.canvas.clipPath.height * editor.canvas.clipPath.zoomY) / 2) });
        editor.canvas.requestRenderAll();


        zoomvalue.current = editor.canvas.getZoom()

        if (scrollXextrascroll.current == 0) {
            scrollx.current = -((editor.canvas.viewportTransform[4] - 30) / (zoom))
            horizonalGuidesRef.current.resize(zoom)
            verticalGuidesRef.current.resize(zoom)
            horizonalGuidesRef.current.scroll(scrollx.current, (zoom));
            verticalGuidesRef.current.scrollGuides(scrollx.current, (zoom));
        }
        // else{
        //     scrollx.current = - (((editor.canvas.viewportTransform[4] / zoom) + scrollvalueX)) 
        //     horizonalGuidesRef.current.scroll(scrollx.current,(zoom));
        //     verticalGuidesRef.current.scrollGuides(scrollx.current,(zoom));
        // }
        if (scrollYextrascroll.current == 0) {
            scrolly.current = -((editor.canvas.viewportTransform[5] - 30) / zoom)
            horizonalGuidesRef.current.resize(zoom)
            verticalGuidesRef.current.resize(zoom)
            verticalGuidesRef.current.scroll(scrolly.current, (zoom));
            horizonalGuidesRef.current.scrollGuides(scrolly.current, (zoom));
        }
        // else{
        //     scrolly.current = -(((editor.canvas.viewportTransform[5]) - scrollvalueY)/editor.canvas.getZoom()) 
        //     verticalGuidesRef.current.scroll(scrolly.current,editor.canvas.getZoom());
        //     horizonalGuidesRef.current.scrollGuides(scrolly.current,editor.canvas.getZoom());
        // }


    }

    //create bullets for text

    useEffect(() => {
        const romanize = (num) => {
            var lookup = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 }, roman = '', i;
            for (i in lookup) {
                while (num >= lookup[i]) {
                    roman += i;
                    num -= lookup[i];
                }
            }
            return roman;
        }

        const getahphabetic = (x) => {
            var alpha = '';
            while (x > -1) {
                alpha = String.fromCharCode(65 + x % 26) + alpha;
                x = Math.floor(x / 26) - 1;
            }
            return alpha;
        }

        // fabric.Textbox.prototype._renderTextLine = function (method, ctx, line, left, top, lineIndex) {

        //     const _applyTextStyle = this._applyTextStyle.bind(this);

        //     const style0 = this.getCompleteStyleDeclaration(lineIndex, 0);
        //     const bullet = this.listType === "bullet" ? [this.listBullet] : this.listType == "number" ? [this.listCounter + 1 + "."] : this.listType === "Alpha" ? [(getahphabetic((this.listCounter + 1) - 1) + ".")] : this.listType === "alpha" ? [(getahphabetic((this.listCounter + 1) - 1).toLowerCase() + ".")] : this.listType === "romen" ? [romanize(this.listCounter + 1) + "."] : true;
        //     const bulletLeft = left - style0.fontSize;

        //     if (bullet !== true && line.length) {
        //         this._renderChars(method, ctx, bullet, bulletLeft, top, lineIndex);
        //     }

        //     if (line.length) {
        //         if (!this.isWrapping) {
        //             // render the bullet
        //             this._renderChars(method, ctx, bullet, bulletLeft, top, lineIndex);
        //             this.isWrapping = !this.isEndOfWrapping(lineIndex);
        //             if (!this.isWrapping) this.listCounter++;
        //         } else if (this.isEndOfWrapping(lineIndex)) {
        //             this.isWrapping = false;
        //             this.listCounter++;
        //         }
        //     }

        //     if (lineIndex === this.textLines.length - 1) {
        //         this.isWrapping = false;
        //         this.listCounter = 0;
        //     }

        //     this._applyTextStyle(method, ctx, lineIndex);
        //     // render the text line
        //     this._renderChars(method, ctx, line, left, top, lineIndex);
        // }



    }, [])


    const languages = [

        { label: 'Arabic', value: 4, code: 'ar' },
        // { label: 'Chinese', value: 108, code: 'zh-CN' },
        { label: 'English', value: 17, code: 'en' },
        { label: 'Japanese', value: 39, code: 'ja' },
        { label: 'Tamil', value: 77, code: 'ta' },

    ];

    // useEffect(()=>{
    //     if(editor){
    //         // console.log(canvasBgColor)
    //         // editor.canvas.backgroundImage = new fabric.Rect({ width: canvaswidth, height: canvasheight,fill:canvasBgColor });
    //         // editor.canvas.renderAll()
    //     }
    // },[canvasBgColor,canvaswidth,canvasheight])



    // canvas center  snapping line by
    // // https://github.com/fabricjs/fabric.js/blob/master/lib/centering_guidelines.js ===>ref

    // useEffect(() => {
    //     var canvasWidth = editor?.canvas.getWidth(),
    //         canvasHeight = editor?.canvas.getHeight(),
    //         canvasWidthCenter = canvasWidth / 2,
    //         canvasHeightCenter = canvasHeight / 2,
    //         canvasWidthCenterMap = {},
    //         canvasHeightCenterMap = {},
    //         centerLineMargin = 4,
    //         centerLineColor = 'rgba(74, 21, 173)',
    //         centerLineWidth = 1,
    //         ctx = editor?.canvas.getSelectionContext(),
    //         viewportTransform;

    //     for (var i = canvasWidthCenter - centerLineMargin, len = canvasWidthCenter + centerLineMargin; i <= len; i++) {
    //         canvasWidthCenterMap[Math.round(i)] = true;
    //     }
    //     for (var i = canvasHeightCenter - centerLineMargin, len = canvasHeightCenter + centerLineMargin; i <= len; i++) {
    //         canvasHeightCenterMap[Math.round(i)] = true;
    //     }

    //     function showVerticalCenterLine() {
    //         showCenterLine(canvasWidthCenter + 0.5, 0, canvasWidthCenter + 0.5, canvasHeight);
    //     }

    //     function showHorizontalCenterLine() {
    //         showCenterLine(0, canvasHeightCenter + 0.5, canvasWidth, canvasHeightCenter + 0.5);
    //     }

    //     function showCenterLine(x1, y1, x2, y2) {
    //         //  var viewportTransform;
    //         ctx.save();
    //         ctx.strokeStyle = centerLineColor;
    //         ctx.lineWidth = centerLineWidth;
    //         ctx.beginPath();
    //         ctx.moveTo(x1 * viewportTransform[0], y1 * viewportTransform[3]);
    //         ctx.lineTo(x2 * viewportTransform[0], y2 * viewportTransform[3]);
    //         ctx.stroke();
    //         ctx.restore();
    //     }

    //     var afterRenderActions = [],
    //         isInVerticalCenter,
    //         isInHorizontalCenter;

    //     editor?.canvas.on('mouse:down', function () {
    //         // console.log('down')
    //         viewportTransform = editor?.canvas.viewportTransform;
    //         editor?.canvas.on('object:moving', function (e) {
    //             // console.log('move')
    //             var object = e.target,
    //                 objectCenter = object.getCenterPoint(),
    //                 transform = editor?.canvas._currentTransform;

    //             if (!transform) return;
    //             //   console.log(Math.round(objectCenter.x) in canvasWidthCenterMap)

    //             if (Math.round(objectCenter.x) in canvasWidthCenterMap) {
    //                 isInVerticalCenter = (Math.round(objectCenter.x) in canvasWidthCenterMap)
    //             } else {
    //                 isInVerticalCenter = (Math.round(objectCenter.x) in canvasWidthCenterMap)
    //             }
    //             if ((Math.round(objectCenter.y) in canvasHeightCenterMap)) {
    //                 isInHorizontalCenter = (Math.round(objectCenter.y) in canvasHeightCenterMap);
    //             }
    //             else {
    //                 isInHorizontalCenter = (Math.round(objectCenter.y) in canvasHeightCenterMap);
    //             }

    //             if (isInHorizontalCenter || isInVerticalCenter) {
    //                 object.setPositionByOrigin(new fabric.Point((isInVerticalCenter ? canvasWidthCenter : objectCenter.x), (isInHorizontalCenter ? canvasHeightCenter : objectCenter.y)), 'center', 'center');
    //             }
    //         });
    //     });



    //     editor?.canvas.on('before:render', function () {
    //         editor?.canvas.clearContext(ctx);
    //         // console.log('befre')
    //     });

    //     editor?.canvas.on('after:render', function () {
    //         // console.log('after')
    //         if (isInVerticalCenter) {
    //             showVerticalCenterLine();
    //         }
    //         if (isInHorizontalCenter) {
    //             showHorizontalCenterLine();
    //         }
    //     });

    //     editor?.canvas.on('mouse:up', function () {
    //         // clear these values, to stop drawing guidelines once mouse is up
    //         //   console.log('up')
    //         isInVerticalCenter = isInHorizontalCenter = null;
    //         editor?.canvas.renderAll();
    //         editor.canvas.off('before:render')
    //         editor.canvas.off('after:render')
    //         editor.canvas.off('object:moving')
    //         editor.canvas.off('mouse:down')
    //         editor.canvas.off('mouse:up')
    //     });
    // },[editor])


    const undoChanges = () => {
        // editor.canvas.undo()
        // editor.canvas.renderAll()
    }

    const redoChanges = () => {
        // editor.canvas.redo()
        // editor.canvas.renderAll()
    }









    const updateDesign = async() => {

        if (editor) {
            // setIsSaved(false)
            var temp = editor.canvas.viewportTransform
            editor.canvas.renderAll()
            editor.canvas.viewportTransform = [1, 0, 0, 1, 0, 0]
            const thumbail = editor.canvas.toDataURL({
                format: 'jpeg',
                quality: 0.5,
                width: canvaswidth,
                height: canvasheight
            })
            const exportImage = editor.canvas.toDataURL({
                format: 'png',
                width: canvaswidth,
                height: canvasheight
            })
            editor.canvas.viewportTransform = temp
            // editor.canvas.renderAll()
            // console.log(thumbail);

            function dataURLtoFile(dataurl, filename) {

                var arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);

                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }

                return new File([u8arr], filename, { type: mime });
            }

            var file = dataURLtoFile(thumbail, 'thumbnail_' + name + "_" + designid + '.jpeg');
            var file2 = dataURLtoFile(exportImage, "PageNo_" + pageNo + ".png");


            const tempdata = JSON.stringify(editor.canvas.toJSON(['id','projectid', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable','brushtype','brushtype2','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name','oldWidth','oldHeight','oldScaleX','oldScaleY']));
            // (async function () {
                // setAutoSaved(true)
                var formdata = new FormData();
                formdata.append("source_json_file", tempdata);
                formdata.append("thumbnail_src", file)
                formdata.append("export_img_src", file2);
                formdata.append("file_name", name)
                // formdata.append("width", canvasWidth)
                formdata.append("src_page", pageNo)
                formdata.append("width", canvaswidth)
                formdata.append("height", canvasheight)

                // formdata.append("languages", result);

                var requestOptions = {
                    method: 'PUT',
                    headers: handleAuthTrue(new Headers()),
                    body: formdata,
                    redirect: 'follow'
                };

                let data = await fetch(LABS_API + "/canvas/canvas-designs/" + designid + "/", requestOptions)
                if (data.status === 200) {
                    // setAutoSaved(false)
                    let response = await data.json()
                    // console.log(response);
                    setPageData(response.source_json)
                    // setLastUpdated(new Date().toTimeString().slice(0, 8))
                    // setTimeout(() => {
                    //     setIsSaved(true)
                    // }, 1800);


                }
                else {
                    setAutoSaved(false)
                    // setIsSaved(true)
                    console.log('error');
                }

            // })();
        }


    }

    const saveAsDesign = async() => {

        // if(designid === null && translatedImageid !== null){
        //     notify()
        //     editor.canvas.isDrawingMode = false
        //     setTranslatedImageid(null)
        //     setManualChecked(false)
        //     editor.canvas.clear()
        //     navigate('/ailaysa-canvas')
        //     setCanvasBgColor('rgba(255,255,255,1)')
        //     editor.canvas.renderAll() 
        // }


        if (editor && designid == null) {
            let backGround = editor.canvas.getObjects().find(obj => obj.name == 'Background-static')
            editor.canvas.remove(backGround)
            // setIsSaved(false)
            var temp = editor.canvas.viewportTransform
            editor.canvas.renderAll()
            editor.canvas.viewportTransform = [1, 0, 0, 1, 0, 0]
            const thumbail = editor.canvas.toDataURL({
                format: 'jpeg',
                quality: 0.5,
                width: canvaswidth,
                height: canvasheight
            })
            const exportImage = editor.canvas.toDataURL({
                format: 'png',
                width: canvaswidth,
                height: canvasheight
            })
            editor.canvas.viewportTransform = temp
            editor.canvas.renderAll()
            function dataURLtoFile(dataurl, filename) {

                var arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);

                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }

                return new File([u8arr], filename, { type: mime });
            }

            var file = dataURLtoFile(thumbail, 'thumbnail_' + name + '.jpeg');
            var file2 = dataURLtoFile(exportImage, "PageNo_" + pageNo + ".png");

            const tempdata = JSON.stringify(editor.canvas.toJSON(['id','projectid', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable','brushtype','brushtype2','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name','oldWidth','oldHeight','oldScaleX','oldScaleY']));
            // console.log(JSON.stringify(editor.canvas.toJSON(['id', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable', 'name', 'breakWords', 'textBackgroundColor', 'evented', , 'listType', 'listBullet', 'listCounter', 'isWrapping', 'objectCaching'])))
            // (async function () {
                var formdata = new FormData();
                formdata.append("source_json_file", tempdata);
                formdata.append("export_img_src", file2);

                formdata.append("thumbnail_src", file)
                formdata.append("file_name", name)
                formdata.append("width", canvaswidth)
                formdata.append("height", canvasheight)

                var requestOptions = {
                    method: 'POST',
                    headers: handleAuthTrue(new Headers()),
                    body: formdata,
                    redirect: 'follow'
                };

                let data = await fetch(LABS_API + "/canvas/canvas-designs/", requestOptions)
                if (data.status === 200) {
                    let response = await data.json()
                    // console.log(response);
                    navigate(`/ailaysa-canvas/design/?project=${response.id}`)
                    setPageData(response.source_json)
                    setAutoSaved(true)
                    setDesignid(response.id)
                    editor.canvas.projectid = response.id

                    // setTimeout(() => {
                    //     setIsSaved(true)
                    // }, 1800);
                }
                else {
                    // setIsSaved(true)
                    console.log('error');
                }
            // })();

        }

    }




    const mouseInObject = (e) => {
        if (toggleedittodrag.current == true) {
            editor.canvas.discardActiveObject().renderAll();
            editor.canvas.renderAll()
            return 
        }
        // console.log(e.selected[0]?.custtype != "picArea" &&  !e.selected[0].picArea);
       if (e.target) {
            if (e.target.type != 'group') {
                editor.canvas.requestRenderAll()
            }
            if(e.target?.custtype != "picArea" &&  !e.target?.picArea){
                e.target.on('mouseover', function () {
                    if (this === this.canvas.getActiveObject()) return;
                    this._renderControls(this.canvas.getContext(), {
                        hasControls: false,
                        borderColor: '#BD8AF5',
                        borderScaleFactor: 2,
                        cornerStyle: "circle",
                        cornerColor: '#FFFFFF',
                        cornerSize: 9,
                        cornerStrokeColor: '#00000029',
                        transparentCorners: false,
                    });
                });
            }
            editor.canvas.renderAll()

        }

    }

    const mouseOutObject = (e) => {
        if (toggleedittodrag.current == true) {
            editor.canvas.discardActiveObject().renderAll();
            editor.canvas.renderAll()
            return 
        }
        if (e.target) {
            e.target.on('mouseout', function () {
                this.canvas.requestRenderAll();
            });
            editor.canvas.renderAll()
        }
    }




useEffect(() => {
    if (editor) {
        if (designid != null && designtemp === true) {
            if (canvasmodified) {
                updateDesign()
                setCanvasmodified(false)
            }
        }
        if (designid == null && designtemp === false) {
            if (canvasmodified) {
                if (tabIndex != 9) {
                    saveAsDesign()
                }
                setDesigntemp(true)
                setCanvasmodified(false)
            }
        }
    }
},[canvasmodified])
   





    const handlePageSideBar = () => {

        if (!pageTab) {
            setPagetab(true)

        } else {
            setPagetab(false)

        }
    }

    const handleOpenAndClose = () => {
        if (!pageSwitch) {
            setPageSwitch(true)
        } else {
            setPageSwitch(false)

        }
    }




    // Background Handle 








    const updateMaskImage = async() => {
        // setIsSaved(false)
        // (async function () {
            const tempdata = JSON.stringify(editor.canvas.toJSON(['id','projectid', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable','brushtype','brushtype2','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name','oldWidth','oldHeight','oldScaleX','oldScaleY']));

            var formdata = new FormData();
            formdata.append("mask_json", tempdata);
            formdata.append("project_name", name)

            var requestOptions = {
                method: 'PUT',
                body: formdata,
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };
            let data = await fetch(LABS_API + "/image/imageupload/" + translatedImageid + "/", requestOptions)
            if (data.status === 204) {
                let response = await data.json()
                // setTimeout(() => {
                //  setIsSaved(true)

                // }, 1800);
            }
        // })();
    }





    const [contextMenu, setContextMenu] = React.useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                :
                null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };


    const forward = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.bringForward(editor.canvas.getActiveObject())
                editor.canvas.renderAll();
            }
        }
    }
    const backward = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.sendBackwards(editor.canvas.getActiveObject())
                editor.canvas.renderAll();
            }
        }
    }
    const front = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.bringToFront(editor.canvas.getActiveObject())
                editor.canvas.renderAll();
            }
        }
    }
    const back = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.sendToBack(editor.canvas.getActiveObject())
                editor.canvas.renderAll();
            }
        }
    }
    const handledelete = () => {

        if(!(editor.canvas.getActiveObject()?.picArea || editor.canvas.getActiveObject()?.custtype)){
            editor.canvas.remove(...editor.canvas.getActiveObjects()).discardActiveObject().requestRenderAll()
        }
    }

    const [ruleron, setruleron] = useState(true)
    const toggleruler = () => {
        ruleonoff.current = !ruleonoff.current
        setruleron(current => !current)
        if (ruleonoff.current) {
            document.querySelectorAll('.ruler')[0].style.display = 'block'
            document.querySelectorAll('.ruler')[1].style.display = 'block'
            document.querySelector('.box').style.display = 'block'
            // document.querySelector('.canvas-space').style.left = '30px'
            // document.querySelector('.canvas-space').style.top = '30px'
        }
        else {
            document.querySelectorAll('.ruler')[0].style.display = 'none'
            document.querySelectorAll('.ruler')[1].style.display = 'none'
            document.querySelector('.box').style.display = 'none'
            // document.querySelector('.canvas-space').style.left = '0px'
            // document.querySelector('.canvas-space').style.top = '0px'
            // if(editor){
            //     var size = { name: 'togglesidebar', width: canvaswidth, height: canvasheight, fill: canvasBgColor }
            //     handlesize(size)
            // }
        }
    }


    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <ArrowDropDownIcon className="arrow-icon-color" />
                </components.DropdownIndicator>
            )
        );
    };

    const customProjectTypeSelectStyles = {
        placeholder: (provided, state) => ({
            ...provided,
            color: "#3C4043",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontWeight: "500",
            lineHeight: "24px",
            zIndex: 7

        }),
        menu: (provided, state) => ({
            ...provided,
            borderBottom: "1px solid #DADADA",
            borderRadius: "0px",
            minWidth: "180px"
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: "0px solid #CED4DA",
            borderLeft: "2px solid transparent",
            color: state.isSelected ? "#ffffff" : state.isDisabled ? "#cccccc" : "#7E7E7E",
            background: state.isSelected ? "#F4F5F7" : "#ffffff",
            display: "flex",
            marginBottom: "0.2rem",
            padding: "4px 6px",
            color: "#292929",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontWeight: "400",
            minWidth: "150px",
            lineHeight: "24px",
            "&:hover": {
                background: "#F4F5F7",
                borderLeft: "2px solid #0074D3",
                borderRight: "2px solid #0074D3",
                cursor: "pointer",
            },
            zIndex: 7
        }),
        control: (base, state) => ({
            ...base,
            backgroundColor: "#FFFFFF",
            transtion: 0.3,
            color: "#3C4043",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontWeight: "500",
            lineHeight: "24px",
            boxShadow: 0,
            "&:hover": {
                cursor: "pointer",
            },
            zIndex: 6

        }),
        menuList: (base) => ({
            ...base,

            "::-webkit-scrollbar": {
                width: "4px",
                height: "0px",
            },
            "::-webkit-scrollbar-track": {
                background: "#f1f1f1"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#888"
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555"
            }
        })
    };


    const handleChangeOptions = (selected) => {
        console.log('clicked')
        setSelectedLanguages(selected.value)

    }


    function textWidth(text, size) {
        let div = document.createElement("div");
        div.innerText = text;
        div.style.whiteSpace = 'nowrap';
        div.style.fontSize = size + "px"
        div.style.width = 'fit-content';
        document.querySelector('body').appendChild(div);
        let width = div.clientWidth;
        document.querySelector('body').removeChild(div);
        return width;
    }


    function calculateFontSize(width, height, text) {
        return (height / 2) + (width / text.length);
    }


    //   function getTextWidth(text, fSize, font = 'Helvetica') {
    //     const canvas = document.createElement('canvas');
    //     const context = canvas.getContext('2d');

    //     context.font = `${fSize}px ${font}`

    //     return context.measureText(text).width;

    //   }

    //   function adjustFontSize(maxTextWidth, maxTextHeight, fontSize, text){

    //     let fSize = fontSize;
    //     let fSize = maxTextHeight/1.2 *  maxTextWidth / (textWidth(response.text,each.fontSize)+1);
    //     let currentTextWidth = getTextWidth(text, fSize);

    //     //decrease font size until the text fits  
    //     while (currentTextWidth > maxTextWidth || fSize > maxTextHeight) {
    //       fSize -= 1;
    //       currentTextWidth = getTextWidth(text, fSize);
    //     }

    //     return fSize;
    //   }


    const trans = ( lang) => {
        (async function () {
            let text = []
            editor.canvas.getObjects('textbox').forEach((each) => {
                text.push(each.text)
            })
            var formdata = new FormData();
            text.forEach((each) => {
                formdata.append("text", each);
            })
            formdata.append("tar_lang_id", lang);

            var requestOptions = {
                method: 'POST',
                headers: handleAuthTrue(new Headers()),
                body: formdata,
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/instane-translate/", requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                console.log(response.translated_text_list)
                updateText(response.translated_text_list)
                // let size = {
                //     w: each.width,
                //     h: each.height,
                //     f: each.fontSize
                // }
                // each.set({
                //     text: response.text,

                // })
                // editor.canvas.renderAll();
                // each.set({
                //     fontSize: size.h / 1.3 * size.w / (textWidth(response.text, each.fontSize) + 1),
                //     minWidth: size.w,
                //     width: size.w,
                //     height: size.h
                // })
                // editor.canvas.renderAll();

            }
            else {
                console.log('error');
            }
        })();

    }


    const updateText = (data) => {

        editor.canvas.getObjects('textbox').forEach((each,index) => {
            let size = {
                    w: each.width,
                    h: each.height,
                    f: each.fontSize
                }
            each.set({
                text: data[index],
                    fontSize: size.h / 1.3 * size.w / (textWidth(data[index], each.fontSize) + 1),
                    // minWidth: size.w,
                    width: size.w,
                    height: size.h
            })
            
        })
        editor.canvas.renderAll()
        updateDesign()
    }

    const duplicatePage = () => {

        editor.canvas.discardActiveObject()
        var temp = editor.canvas.viewportTransform
        editor.canvas.renderAll()
        editor.canvas.viewportTransform = [1, 0, 0, 1, 0, 0]
        const thumbail = editor.canvas.toDataURL({
            format: 'jpeg',
            quality: 0.5,
            width: canvaswidth,
            height: canvasheight
        })
        const exportImage = editor.canvas.toDataURL({
            format: 'png',
            width: canvaswidth,
            height: canvasheight
        })
        editor.canvas.viewportTransform = temp
        editor.canvas.renderAll()

        function dataURLtoFile(dataurl, filename) {

            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        }

        var file = dataURLtoFile(thumbail, 'thumbnail_' + name + "_" + designid + '.jpeg');
        var file2 = dataURLtoFile(exportImage, "PageNo_" + pageNo + ".png");


        const tempdata = JSON.stringify(editor.canvas.toJSON(['id','projectid', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable','brushtype','brushtype2','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name','oldWidth','oldHeight','oldScaleX','oldScaleY']));
        (async function () {
            var formdata = new FormData();
            formdata.append("source_json_file", tempdata);
            formdata.append("thumbnail_src", file)
            formdata.append("export_img_src", file2);
            formdata.append("src_page", pageData.length + 1)



            var requestOptions = {
                method: 'PUT',
                headers: handleAuthTrue(new Headers()),
                body: formdata,
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/canvas-designs/" + designid + "/", requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                setPageData(response.source_json)
                // setPageNo(response.source_json[response.source_json.length - 1].page_no)
            }
            else {

                console.log('error');
            }

        })();

    }

    const handleInstantTranslate = (language) => {
        
        if(newPageChecked){
            duplicatePage(language)
            handleOpenAndClose()
            instaTranslateFunction(language)

        }else{
            instaTranslateFunction(language)
        }
    }

    // const instaTranslateFunctionDuplicate = (language) => {
       
    //         trans(language)
           


    // }

    const instaTranslateFunction = (language) => {
            trans(language)

    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseSettings = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleCheckedOfNewPage = () => {
        setNewPageChecked(true)
    }
    console.log(newPageChecked)


    const [canvas, setCanvas] = useState(null);
    const [history, setHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);

    return (
        <React.Fragment>
            <div className='container1' ref={contsiner1ref}>
                <HeaderCanvas
                    undoChanges={undoChanges}
                    redoChanges={redoChanges}
                    redoStack={redoStack}
                    setTabIndex={setTabIndex}
                    tabIndex={tabIndex}
                    name={name}
                    setName={setName}
                    autoSaved={autoSaved}
                    saveAsDesign={saveAsDesign}
                    editor={editor}
                    updateMaskImage={updateMaskImage}
                    canvaswidth={canvaswidth}
                    canvasheight={canvasheight}
                    handleAuthTrue={handleAuthTrue}
                    LABS_API={LABS_API}
                    designid={designid}
                    designtemp={designtemp}
                    updateDesign={updateDesign}
                    setCanvasmodified={setCanvasmodified}
                    setDesigntemp={setDesigntemp}
                    translatedImageid={translatedImageid}
                    selected={selected}
                    setselected={setselected}
                    setCanvasBgColor={setCanvasBgColor}
                    setImageTransleSource={setImageTransleSource}
                    imageTransleSource={imageTransleSource}
                    autoChecked={autoChecked}
                    isSaved={isSaved}
                    setCanvaswidth={setCanvaswidth}
                    setCanvasheight={setCanvasheight}
                    setAutoSaved={setAutoSaved}
                    setDesignid={setDesignid}
                    fabric={fabric}
                    handlesize={handlesize}
                    canvasBgColor={canvasBgColor}
                    setTranslateLanguages={setTranslateLanguages}
                    setPageData={setPageData}
                    setUndoHistory={setUndoHistory}
                    redoHistory={redoHistory}
                    setRedoHistory={setRedoHistory}
                    undoHistory={undoHistory}
                    saveStateToHistory={saveStateToHistory}
                    undoStack={undoStack}
                    setUndoStack={setUndoStack}
                    save={save}
                    setRedoStack={setRedoStack}
                />
                {/* <button onClick={undo}>
                    undo
                </button>
                <button onClick={redo}>
                    redo
                </button> */}
                <div className='ailaysa-canvas-editor-main-wrapper'>
                    <div className='ailaysa-canvas-sidebar-col-wrapper'>
                        <Sidebar
                            enablesnap={enablesnap}
                            setEnablesnap={setEnablesnap}
                            scaleCanvas={scaleCanvas}
                            fabric={fabric}
                            editor={editor}
                            setCanvasBgColor={setCanvasBgColor}
                            canvasBgColor={canvasBgColor}
                            setBackgroundEdit={setBackgroundEdit}
                            setCanvaswidth={setCanvaswidth}
                            canvaswidth={canvaswidth}
                            setCanvasheight={setCanvasheight}
                            canvasheight={canvasheight}
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            setActiveFontFamilyShow={setActiveFontFamilyShow}
                            designid={designid}
                            setDesignid={setDesignid}
                            setName={setName}
                            name={name}
                            handleAuthTrue={handleAuthTrue}
                            LABS_API={LABS_API}
                            setAutoSaved={setAutoSaved}
                            autoSaved={autoSaved}
                            setDesigntemp={setDesigntemp}
                            designtemp={designtemp}
                            setPageData={setPageData}
                            backgroundConfirmation={backgroundConfirmation}
                            setBackgroundConfirmation={setBackgroundConfirmation}
                            setCropImageSource={setCropImageSource}
                            setAutoChecked={setAutoChecked}
                            autoChecked={autoChecked}
                            manualChecked={manualChecked}
                            setManualChecked={setManualChecked}
                            translatedImageid={translatedImageid}
                            setTranslatedImageid={setTranslatedImageid}
                            setTranslateimagetemp={setTranslateimagetemp}
                            translateimagetemp={translateimagetemp}
                            setImageTransleSource={setImageTransleSource}
                            selected={selected}
                            setselected={setselected}
                            updateDesign={updateDesign}
                            notify={notify}
                            handlesize={handlesize}
                            scaleMultiplierX={scaleMultiplierX}
                            getMyDesignbyId={getMyDesignbyId}
                            debounce={debounce}
                            setCanvasmodified={setCanvasmodified}
                            imageTransleSource={imageTransleSource}
                            shadow={shadow}
                            pageData={pageData}
                            setPageNo={setPageNo}
                            handleSave={handleSave}
                            save={save}
                        />
                    </div>
                    <div ref={canvasdiv} className='ailaysa-canvas-working-col-wrapper'>
                        <Toolbar
                            saveAsDesign={saveAsDesign}
                            updateDesign={updateDesign}
                            designid={designid}
                            undoChanges={undoChanges}
                            redoChanges={redoChanges}
                            canvaswidth={canvaswidth}
                            canvasheight={canvasheight}
                            fabric={fabric}
                            editor={editor}
                            setTabIndex={setTabIndex}
                            tabIndex={tabIndex}
                            activeFontFamilyShow={activeFontFamilyShow}
                            scaleCanvas={scaleCanvas}
                            setBackgroundConfirmation={setBackgroundConfirmation}
                            setCropImageSource={setCropImageSource}
                            backgroundEdit={backgroundEdit}
                            setBackgroundEdit={setBackgroundEdit}
                            setCanvasBgColor={setCanvasBgColor}
                            autoChecked={autoChecked}
                            manualChecked={manualChecked}
                            forward={forward}
                            backward={backward}
                            front={front}
                            back={back}
                            handledelete={handledelete}
                            duplicate={duplicate}
                            translatedImageid={translatedImageid}
                            setTranslatedImageid={setTranslatedImageid}
                            setImageTransleSource={setImageTransleSource}
                            imageTransleSource={imageTransleSource}
                            name={name}
                            updateMaskImage={updateMaskImage}
                            setIsSaved={setIsSaved}
                            generateUID={generateUID}
                            makeid={makeid}
                            ruleonoff={ruleonoff.current}
                            cropImage={cropImage}
                            callcrop={callcrop}
                            setManualChecked={setManualChecked}
                            handleConfirmBackGround={handleConfirmBackGround}

                        />

                        <div className='canvas-editor-space' ref={canvascontainer} style={{ maxWidth: '100%', maxHeight: '100%', background: 'rgba(211, 211, 211, 0.418)' }} >
                            <div onContextMenu={editor?.canvas.getActiveObject() && handleContextMenu} style={{ border: backgroundEdit ? '3px solid #BD8AF5' : 'none', cursor: 'context-menu', background: 'rgba(255,255,255,0.1)' }}>
                                <FabricJSCanvas id="canvas" className="canvas-space" onReady={onReady} />
                                {/* <div id="loading-wrapper">
                                      <div id="loading-text">LOADING</div>
                                         <div id="loading-content"></div>
                                    </div> */}
                                <div id="loading-wrapper">
                                    <div class="loader">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                                {<Menu
                                    className='contextmenu'
                                    open={contextMenu !== null}
                                    onClose={handleClose}
                                    anchorReference="anchorPosition"
                                    anchorPosition={
                                        contextMenu !== null
                                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                            : undefined
                                    }
                                >
                                    <MenuItem onClick={() => {
                                        duplicate()
                                        handleClose()
                                    }}>Duplicate</MenuItem>

                                    <MenuItem className='contextmenuli' onClick={() => {
                                        front()
                                        handleClose();
                                    }}>Bring front</MenuItem>
                                    <MenuItem className='contextmenuli' onClick={() => {
                                        forward()
                                        handleClose();
                                    }}>Bring forward</MenuItem>
                                    <MenuItem className='contextmenuli' onClick={() => {
                                        backward()
                                        handleClose();
                                    }}>Send backward</MenuItem>
                                    <MenuItem className='contextmenuli' onClick={() => {
                                        back()
                                        handleClose();
                                    }}>Send back</MenuItem>
                                    <MenuItem className='contextmenuli' onClick={() => {
                                        handledelete()
                                        handleClose();
                                    }}>Delete</MenuItem>
                                </Menu>}

                                <div className="box"></div>
                                <div className="ruler horizontal"
                                // onWheel={(e) => {
                                //     const deltaX = e.deltaX;
                                //     const deltaY = e.deltaY;
                                //     const scrollX = horizonalGuidesRef.current.getRulerScrollPos() + deltaX;
                                //     const scrollY = verticalGuidesRef.current.getRulerScrollPos() + deltaY;

                                //     scrollx.current = scrollX
                                //     scrolly.current = scrollY

                                //     scrollYextrascroll.current =  scrollY
                                //     scrollXextrascroll.current =  scrollX

                                //     console.log(scrollX,scrollY);

                                //     horizonalGuidesRef.current.scroll(scrollX,editor.canvas.getZoom());
                                //     horizonalGuidesRef.current.scrollGuides(scrollY,editor.canvas.getZoom());
                                //     verticalGuidesRef.current.scroll(scrollY,editor.canvas.getZoom());
                                //     verticalGuidesRef.current.scrollGuides(scrollX,editor.canvas.getZoom());
                                //     // horizonalGuidesRef.current.
                                //     // horizonalGuidesRef.current.scroll
                                //   }}

                                >
                                    <Guides
                                        onChangeGuides={(e) => {
                                            setHorizontalGuides({
                                                horizonalGuidesRef: e.guides
                                            });
                                        }}
                                        backgroundColor='#f0f0f0'
                                        textColor='#111'
                                        unit={100}

                                        // unit={zoomvalue.current <= 0.4 ?  10 : zoomvalue.current <= 0.5 ? 30 : zoomvalue.current <= 0.8 ? 100 : zoomvalue.current >= 1 ? 250 : 500 }
                                        // lineColor='rgba(74, 21, 173)'
                                        snaps={[0, canvasheight / 2, canvasheight]}
                                        ref={horizonalGuidesRef}
                                        zoom={zoomvalue.current}
                                        type="horizontal"
                                        rulerStyle={{
                                            left: "30px",
                                            width: "calc(100% - 30px)",
                                            height: "100%"
                                        }}
                                        range={[0, canvaswidth]}
                                        displayDragPos={true}
                                        displayGuidePos={true}
                                        useResizeObserver={true}

                                    />

                                </div>
                                <div className="ruler vertical"
                                // onWheel={(e) => {
                                //     const deltaX = e.deltaX;
                                //     const deltaY = e.deltaY;
                                //     const scrollX = horizonalGuidesRef.current.getRulerScrollPos() + deltaX;
                                //     const scrollY = verticalGuidesRef.current.getRulerScrollPos() + deltaY;

                                //     scrollx.current = scrollX
                                //     scrolly.current = scrollY

                                //     scrollYextrascroll.current =  scrollY
                                //     scrollXextrascroll.current =  scrollX

                                //     console.log(scrollY,scrollY);

                                //     horizonalGuidesRef.current.scroll(scrollX,editor.canvas.getZoom());
                                //     horizonalGuidesRef.current.scrollGuides(scrollY,editor.canvas.getZoom());
                                //     // horizonalGuidesRef.current.loadGuides([2]);
                                //     verticalGuidesRef.current.scroll(scrollY,editor.canvas.getZoom());
                                //     verticalGuidesRef.current.scrollGuides(scrollX,editor.canvas.getZoom());
                                //   }}

                                >


                                    <Guides
                                        // onChangeGuides={(e) => {
                                        //     console.log(e);    
                                        //     // e.guides.splice(e.index, 1)
                                        //     // e.isChange = false
                                        //     // e.isRemove = true
                                        //     // e.distX = 0
                                        //     // e.distY = 0
                                        //     horizonalGuidesRef.current.resize(zoom)
                                        //     verticalGuidesRef.current.resize(zoom)
                                        //     console.log(zoomvalue.current);
                                        //     console.log('hrule',horizonalGuidesRef.current.getRulerScrollPos());
                                        //     console.log('vline',verticalGuidesRef.current.getGuideScrollPos());
                                        //     console.log('vrule',verticalGuidesRef.current.getRulerScrollPos());
                                        //     console.log('hguide',horizonalGuidesRef.current.getGuideScrollPos());

                                        //   }}
                                        onChangeGuides={(e) => {
                                            setVerticalGuides({
                                                verticalGuidesRef: e.guides
                                            });
                                        }}
                                        range={[0, canvasheight]}
                                        snaps={[0, canvaswidth / 2, canvaswidth]}
                                        OnClickRuler={() => { console.log('sample') }}
                                        backgroundColor='#f0f0f0'
                                        textColor='#111'
                                        // unit={zoomvalue.current <= 0.4 ?  10 : zoomvalue.current <= 0.5 ? 30 : zoomvalue.current <= 0.8 ? 100 : zoomvalue.current >= 1 ? 250 : 500 }
                                        unit={100}
                                        zoom={zoomvalue.current}
                                        // zoom={37}
                                        direction='end'
                                        textAlign="right"
                                        // range ={[0,1080]}
                                        // rangeBackgroundColor ='red'
                                        selectedRanges={100}
                                        // defaultPixelScale ={7}
                                        selectedBackgroundColor='yellow'
                                        // segment={'10'}
                                        // rangeBackgroundColor={'green'}
                                        // selectedRanges={[0,1080]}
                                        // selectedRanges ={100}
                                        // selectedBackgroundColor = 'yellow'
                                        // lineColor='rgba(74, 21, 173)'
                                        ref={verticalGuidesRef}
                                        type="vertical"
                                        rulerStyle={{
                                            top: "30px",
                                            height: "calc(100% - 30px)",
                                            width: "100%"
                                        }}
                                        displayDragPos={true}
                                        displayGuidePos={true}
                                        useResizeObserver={true}

                                    />

                                </div>

                               {(tabIndex != 9 || !window.location.pathname.includes('/ailaysa-canvas/image-translate/'))  &&  <span class="tg-list-item">
                                    <label for="cb4"><h5>Ruler</h5></label>
                                    <input class="tgl tgl-flat" id="cb4" value={ruleron} onClick={() => { toggleruler() }} type="checkbox" />
                                    <label class="tgl-btn" for="cb4"></label>
                                </span>}


                            </div>


                        </div>
                        {!pageSwitch && <>
                            {(tabIndex != 9 || !window.location.pathname.includes('/ailaysa-canvas/image-translate/'))  &&  <Tooltip
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: '#2A2A2A',
                                            '& .MuiTooltip-arrow': {
                                                color: '#2A2A2A',
                                            },
                                        },
                                    },
                                }}
                                title={'Show page panel'} placement="top-start">
                                <div className="close-toggle-sidebar-down-outside" onClick={() => { handleOpenAndClose() }}>
                                    <SideBarToggle className="close" />
                                </div>
                            </Tooltip>}
                        </>
                            
                        }
                        {pageSwitch ?
                            (<>
                                <div className='footer2'>
                                    <PagesBar
                                        canvaswidth={canvaswidth}
                                        canvasheight={canvasheight}
                                        fabric={fabric}
                                        editor={editor}
                                        pageData={pageData}
                                        pageNo={pageNo}
                                        setPageNo={setPageNo}
                                        LABS_API={LABS_API}
                                        setCanvasBgColor={setCanvasBgColor}
                                        setDesigntemp={setDesigntemp}
                                        setPageData={setPageData}
                                        name={name}
                                        handleAuthTrue={handleAuthTrue}
                                        designid={designid}
                                        backgroundConfirmation={backgroundConfirmation}
                                        setPageSwitch={setPageSwitch}
                                        pageSwitch={pageSwitch}
                                        handlesize={handlesize}
                                        setSelectedPage={setSelectedPage}
                                        selectedPage={selectedPage}
                                        setPageId={setPageId}
                                        pageId={pageId}
                                    />

                                </div>
                            
                            
                            </>
                                
                            ) : (
                                <>
                                    {(tabIndex != 9 || !window.location.pathname.includes('/ailaysa-canvas/image-translate/'))  &&   <div className='footer'>
                                        <Footer
                                            canvaswidth={canvaswidth}
                                            canvasheight={canvasheight}
                                            fabric={fabric}
                                            editor={editor}
                                            setTabIndex={setTabIndex}
                                            tabIndex={tabIndex}
                                            activeFontFamilyShow={activeFontFamilyShow}
                                            scaleCanvas={scaleCanvas}
                                            setScaleCanvas={setScaleCanvas}
                                            name={name}
                                            designid={designid}
                                            setTab={setTab}
                                            backgroundConfirmation={backgroundConfirmation}
                                            handleOpenAndClose={handleOpenAndClose}
                                            setTabSwitch={setTabSwitch}
                                            getJsonData={getJsonData}

                                        />

                                    </div>}

                                </>
                            )

                        }


                       {(tabIndex != 9 || !window.location.pathname.includes('/ailaysa-canvas/image-translate/'))  &&   <div class="word-count-writter-wrapper">
                            <Select
                                // style={{ background: 'red' }}
                                menuPlacement={'auto'}
                                components={{ DropdownIndicator }}
                                placeholder='Translate to..'
                                // value={selectedLanguages}
                                options={translateLanguages}
                                styles={customProjectTypeSelectStyles}
                                // onChange={(selected) => {
                                //     console.log(selectedLanguages)
                                //     setSelectedLanguages(selected)}}
                                onChange={(selected) => { handleChangeOptions(selected) }}
                            />
                            <div>
                            <button class="word-count-number-str" onClick={() => handleInstantTranslate(selectedLanguages)} disabled={((editor?.canvas?.getObjects('textbox')?.length > 0) && selectedLanguages != null) ? false : true}>
                                <TranslateIcon />
                            </button>
                            <button class="word-count-number-str-setting" onClick={(e) => {
                                handleClick(e)
                            }}>
                                <SettingsIcon className='settings-insta-translate-icon'/>
                            </button>
                            </div>
                            <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleCloseSettings}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <div className="insta-translate-settings-wrap">
                               <input type='checkbox' checked={newPageChecked} onChange={handleCheckedOfNewPage}/><span>Add to new Page</span>
                            </div>
                        </Popover>
                        </div>}
                    </div>
                    {(tabIndex != 9 || !window.location.pathname.includes('/ailaysa-canvas/image-translate/'))  &&   <div className='ailaysa-canvas-sidebar-col-wrapper'>
                        {pageTab &&
                            <>
                                <Layers
                                    fabric={fabric}
                                    editor={editor}
                                    handlePageSideBar={handlePageSideBar} />
                            </>
                        }
                        {!pageTab && <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: '#2A2A2A',
                                        '& .MuiTooltip-arrow': {
                                            color: '#2A2A2A',
                                        },
                                    },
                                },
                            }}
                            title={'Show layer panel'} placement="top-start">
                            <div className="close-toggle-sidebar-right" onClick={() => { handlePageSideBar() }}>
                                <SideBarToggle className="close" />
                            </div>

                        </Tooltip>}
                    </div>}
                </div>

            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={2000}
                limit={1}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light" />

        </React.Fragment>
    )
}

export default AilaysaCanvasEditor;