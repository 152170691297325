import React, { useEffect, useLayoutEffect, useState, useRef, useCallback } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import { Skeleton } from "@mui/material";
import FontFaceObserver from 'fontfaceobserver';
import { ReactComponent as SideBarToggle } from '../../assets/icons/SideBarToggle.svg'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Popover from '@mui/material/Popover';

const PagesBar = (props) => {

    const {
        editor,
        setCanvasBgColor,
        canvasheight,
        canvaswidth,
        designid,
        handleAuthTrue,
        LABS_API,
        pageData,
        pageNo,
        setPageNo,
        setDesigntemp,
        setPageData,
        name,
        workspaceSwitch,
        sourceCavasData,
        sourceCavasDataJson,
        setSourceCanvasData,
        setCanvasTranslatedJsonId,
        setPageSwitch,
        pageSwitch,
        handlesize,
        setSelectedPage,
        selectedPage,
        setPageId,
        pageId,
        canvasTranslatedJsonId,
        targetlanNo,
        fabric
    } = props


    const typeSearch = useRef()
    // const [pageSwitch, setPageSwitc] = useState(true)

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [inputText, setInputText] = useState(null);
    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value?.toLowerCase();
        // setPageNo(e.target.value)
        setInputText(lowerCase);
    };

    const filteredData = pageData?.filter((el) => {
        //if no input the return the original
        if (inputText === null || inputText === '') {
            return el;
        }
        //return the item which contains the user input
        else {
            // console.log(el.page_no);
            return el.page_no == inputText
        }
    })

    // console.log(pageData);
    useEffect(() => {

    }, [sourceCavasData])


    function loadFabricJSONData(file, key) {
        // Get all font family names from the JSON data, including nested groups
        document.querySelector('.canvas-space').classList.add('loading-json-blur');
        document.querySelector('#loading-wrapper').style.setProperty('display', 'grid');



        const fontFamilies = [];

        function extractFontFamilies(obj) {
            if (obj.fontFamily && !fontFamilies.includes(obj.fontFamily)) {
                fontFamilies.push(obj.fontFamily);
            }
            if (obj.type === 'group' && obj.objects) {
                obj.objects.forEach((groupObj) => extractFontFamilies(groupObj));
            }
        }

        file.json.objects.forEach((obj) => extractFontFamilies(obj));

        // Remove duplicate font families
        const uniqueFontFamilies = [...new Set(fontFamilies)];
        // Check if all font families are loaded in the DOM
        uniqueFontFamilies.forEach((fontFamily) => {
            // document.querySelectorAll('head link[name]')
            var link = document.createElement('link')
            link.href = `https://fonts.googleapis.com/css?family=${fontFamily}`
            link.rel = 'stylesheet'
            link.setAttribute('name', fontFamily)
            document.querySelector('head').appendChild(link)

        });
        if (uniqueFontFamilies.length == 0) {
            console.log('ran empty')
            loadPage(file, key)
            // document.querySelector('.canvas-space').classList.remove('loading-json-blur');
            // document.querySelector('#loading-wrapper').style.setProperty('display', 'none');
        }
        // Get all link tags with a href attribute containing "fonts.googleapis.com"
        const fontLinks = document.querySelectorAll('link[href*="fonts.googleapis.com"]');

        // Create an array to store the unique href values
        const uniqueLinks = [];

        // Loop through the fontLinks and check if the href value is already in the uniqueLinks array
        fontLinks.forEach(link => {
            const href = link.getAttribute('href');
            if (!uniqueLinks.includes(href)) {
                uniqueLinks.push(href);
            } else {
                link.remove(); // Remove duplicate link tag
            }
        });

        // If all font families are loaded, load the JSON data in the canvas
        uniqueFontFamilies.forEach((obj, index) => {
            var font = new FontFaceObserver(obj);
            font.load().then(function () {
                console.log('Font is available');
                if (index == (uniqueFontFamilies.length - 1)) {
                    setTimeout(() => {
                        loadPage(file, key)

                    }, 1000);
                }


            }, function () {
                console.log('Font is not available');
                console.log('Font is not available');
                font.load().then(function () {
                    console.log('Font is available');
                    setTimeout(() => {
                        loadPage(file, key)


                    }, 1000);
                }, function () {
                    console.log('Font is not available');
                });

            });
        })
    }



    const loadPage = (file, key) => {
        setDesigntemp(true)
        setPageNo(file.page_no)
        if (workspaceSwitch === 'Workspace') {
            setCanvasTranslatedJsonId(file.canvas_trans_json === undefined ? null : file.canvas_trans_json)
            setSourceCanvasData(sourceCavasDataJson[key].json)

        }
        if (typeof (file.json) == 'string') {

            setCanvasBgColor(JSON.parse(file.json).background)
        }
        else {

            setCanvasBgColor(file.json.background)
        }
        editor?.canvas.loadFromJSON(file.json, function () {
            editor?.canvas.renderAll.bind(editor.canvas.renderAll());
        })
        setCanvasBgColor(file.json.backgroundImage?.fill)
        var size = { name: 'togglesidebar', width: file.json.backgroundImage?.width, height: file.json.backgroundImage?.height, fill: file.json.backgroundImage?.fill }

        handlesize(size)
        document.querySelector('.canvas-space').classList.remove('loading-json-blur');
        document.querySelector('#loading-wrapper').style.setProperty('display', 'none');
    }


    const addPage = () => {

        editor.canvas.discardActiveObject()

        const some = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAQAAAC0NkA6AAAALUlEQVR42u3NMQEAAAgDINc/9Mzg4QcFSDvvIpFIJBKJRCKRSCQSiUQikUhuFtAOY89wCn1dAAAAAElFTkSuQmCC'

        function dataURLtoFile(dataurl, filename) {

            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        }

        var file = dataURLtoFile(some, 'thumbnail_' + name + "_" + designid + '.jpeg');
        // var file2    = dataURLtoFile(exportImage, "PageNo_" + pageNo + ".png");


        (async function () {
            var formdata = new FormData();

            if(canvasTranslatedJsonId == undefined){

                formdata.append("source_json_file", JSON.stringify({ "version": "5.3.0", "objects": [], "backgroundImage": {
                    "type": "rect",
                    "version": "5.3.0",
                    "originX": "left",
                    "originY": "top",
                    "left": 0,
                    "top": 0,
                    "width": canvaswidth,
                    "height": canvasheight,
                    "fill": "rgba(255,255,255,1)",
                    "stroke": null,
                    "strokeWidth": 0,
                    "strokeDashArray": null,
                    "strokeLineCap": "butt",
                    "strokeDashOffset": 0,
                    "strokeLineJoin": "miter",
                    "strokeUniform": false,
                    "strokeMiterLimit": 4,
                    "scaleX": 1,
                    "scaleY": 1,
                    "angle": 0,
                    "flipX": false,
                    "flipY": false,
                    "opacity": 1,
                    "shadow": {
                        "color": "rgba(0,0,0,0.8)",
                        "blur": 0.01,
                        "offsetX": 0,
                        "offsetY": 0,
                        "affectStroke": false,
                        "nonScaling": false
                    },
                    "visible": true,
                    "backgroundColor": "",
                    "fillRule": "nonzero",
                    "paintFirst": "fill",
                    "globalCompositeOperation": "source-over",
                    "skewX": 0,
                    "skewY": 0,
                    "rx": 0,
                    "ry": 0,
                    "lockMovementX": false,
                    "lockMovementY": false,
                    "perPixelTargetFind": false,
                    "objectCaching": false
                } }));
                formdata.append("thumbnail_src", file)
                formdata.append("src_page", pageData.length + 1)
    
            }else{
                formdata.append("tar_page", pageData.length + 1)
                formdata.append("canvas_translation", canvasTranslatedJsonId)
                formdata.append("target_canvas_json", JSON.stringify({ "version": "5.3.0", "objects": [], "backgroundImage": {
                    "type": "rect",
                    "version": "5.3.0",
                    "originX": "left",
                    "originY": "top",
                    "left": 0,
                    "top": 0,
                    "width": canvaswidth,
                    "height": canvasheight,
                    "fill": "rgba(255,255,255,1)",
                    "stroke": null,
                    "strokeWidth": 0,
                    "strokeDashArray": null,
                    "strokeLineCap": "butt",
                    "strokeDashOffset": 0,
                    "strokeLineJoin": "miter",
                    "strokeUniform": false,
                    "strokeMiterLimit": 4,
                    "scaleX": 1,
                    "scaleY": 1,
                    "angle": 0,
                    "flipX": false,
                    "flipY": false,
                    "opacity": 1,
                    "shadow": {
                        "color": "rgba(0,0,0,0.8)",
                        "blur": 0.01,
                        "offsetX": 0,
                        "offsetY": 0,
                        "affectStroke": false,
                        "nonScaling": false
                    },
                    "visible": true,
                    "backgroundColor": "",
                    "fillRule": "nonzero",
                    "paintFirst": "fill",
                    "globalCompositeOperation": "source-over",
                    "skewX": 0,
                    "skewY": 0,
                    "rx": 0,
                    "ry": 0,
                    "lockMovementX": false,
                    "lockMovementY": false,
                    "perPixelTargetFind": false,
                    "objectCaching": false
                } }));
                formdata.append("canvas_translation_tar_thumb", file);
                formdata.append("tar_lang", targetlanNo);

            }



            var requestOptions = {
                method: 'PUT',
                headers: handleAuthTrue(new Headers()),
                body: formdata,
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/canvas-designs/" + designid + "/", requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                setPageData(response.source_json)
                setPageNo(response.source_json[response.source_json.length - 1].page_no)
                setCanvasBgColor('#fff')
                editor?.canvas.loadFromJSON(response.source_json[response.source_json.length - 1].json, function () {
                    editor?.canvas.renderAll.bind(editor.canvas.renderAll());
                })
                var size = { name: 'togglesidebar', width: canvaswidth, height: canvasheight, fill: '#ffffff' }
                handlesize(size)



            }
            else {

                console.log('error');
            }

        })();

    }

    const duplicatePage = () => {

        editor.canvas.discardActiveObject()
        var temp = editor.canvas.viewportTransform
        editor.canvas.renderAll()
        editor.canvas.viewportTransform = [1, 0, 0, 1, 0, 0]
        const thumbail = editor.canvas.toDataURL({
            format: 'jpeg',
            quality: 0.5,
            width: canvaswidth,
            height: canvasheight
        })
        const exportImage = editor.canvas.toDataURL({
            format: 'png',
            width: canvaswidth,
            height: canvasheight
        })
        editor.canvas.viewportTransform = temp
        editor.canvas.renderAll()

        function dataURLtoFile(dataurl, filename) {

            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        }

        var file = dataURLtoFile(thumbail, 'thumbnail_' + name + "_" + designid + '.jpeg');
        var file2 = dataURLtoFile(exportImage, "PageNo_" + pageNo + ".png");


        const tempdata = JSON.stringify(editor.canvas.toJSON(['id','projectid', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable','brushtype','brushtype2','evented','perPixelTargetFind','listType','listBullet','listCounter','isWrapping' ,'objectCaching','originalwidth','originalheight','originalleft','originaltop','name','oldWidth','oldHeight','oldScaleX','oldScaleY']));
        (async function () {
            var formdata = new FormData();
            if(canvasTranslatedJsonId == undefined){
                formdata.append("source_json_file", tempdata);
                formdata.append("thumbnail_src", file)
                formdata.append("export_img_src", file2);
                formdata.append("src_page", pageData.length + 1)
    
            }else{
                formdata.append("tar_page", pageData.length + 1)
                formdata.append("canvas_translation", canvasTranslatedJsonId)
                formdata.append("target_canvas_json", tempdata);
                formdata.append("canvas_translation_tar_thumb", file);
                formdata.append("canvas_translation_tar_export", file2);
                formdata.append("tar_lang", targetlanNo);
            }
          


            var requestOptions = {
                method: 'PUT',
                headers: handleAuthTrue(new Headers()),
                body: formdata,
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/canvas-designs/" + designid + "/", requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                // setPageData(response.source_json)
                setPageNo(response.source_json[response.source_json.length - 1].page_no)

            }
            else {

                console.log('error');
            }

        })();

    }


    const fetchPageDataOnEnter = (e) => {
        if (e.key === 'Enter') {
            if ((e.target.value.length > 0) && (e.target.value != ' ')) {
                loadPage(filteredData[0], 0)
            }
        }
    }


    const handleOpenAndClose = () => {
        if (pageSwitch === false) {
            setPageSwitch(true)

        } else {
            setPageSwitch(false)
        }
    }

    const deletePage = (id,pg,pageId) =>{
        (async function () {
            var requestOptions = {
                method: 'DELETE',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + `/canvas/canvas-designs/${id}/?can_src=${pageId}&page_no=${pg}`, requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                console.log(response)
            }
            else {
                // setIsSaved(true)
                console.log('error');
               
            }
        })();

        console.log(id)
        console.log(pg)

      
    }


    return (
        <>
            <div className="page-container2">
                <div className="close-toggle-sidebar-down" onClick={() => { handleOpenAndClose() }}>
                    <SideBarToggle className="close" />
                </div>
                <div className="page">
                    <div className="page-icon-container" >
                        <ContentCopyIcon onClick={duplicatePage} className="page-head-icon" />
                    </div>
                    <div >
                        <input type="text" onKeyDown={fetchPageDataOnEnter} className="searcher-page2" ref={typeSearch} onChange={inputHandler} placeholder={pageNo} />
                    </div>
                    <div >
                        <AddIcon onClick={addPage} className="page-head-icon" />
                    </div>


                </div>
                <div className="pages-preview-container">
                    {filteredData?.map((file, key) => {

                        return (
                            <> 
                             {pageData != '' && <div className="page-bar-grid-item2">
                            <div className="page-inner-container2" onClick={(e) => loadFabricJSONData(file, key)}>
                                {/* src={LABS_API + '/' + file.thumbnail} */}
                                {file.thumbnail === null ? (<Skeleton height={300} className={file.page_no === pageNo ? "page-images-active" : "page-images"} />) : (<img className={file.page_no === pageNo ? "page-images-active" : "page-images"} src={LABS_API + '/' + file.thumbnail} />)}
                                <span className={file.page_no === pageNo ? "page-no-active" : "page-no"}>{file.page_no}</span>
                                <span className="page-more-icon" onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedPage(file.page_no)
                                    setPageId(file.id)
                                    handleClick(e)
                                }}>
                                    <MoreHorizIcon />
                                </span>
                            </div>
                        </div>}

                            
                            </>
                          
                        )
                    })
                    }


                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="page-wise-more-wrap">
                        <span className="page-delte-individual" onClick={() => {
                            deletePage(designid,selectedPage,pageId)}}>
                            Delete
                        </span>
                    </div>
                </Popover>
            </div>

        </>

    )


}

export default PagesBar;